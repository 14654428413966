import { defineMessages } from 'react-intl';

export default defineMessages({
    referenceNumber: {
        id: 'anonymous.fnol.page.confirmation.Claim Reference number',
        defaultMessage: 'Your Claim reference number { num }'
    },
    confirmationPageSuccessfullySubmitted: {
        id: 'anonymous.fnol.page.confirmation.Claim was successfully submitted',
        defaultMessage: 'Your claim was successfully submitted'
    },
    confirmationPageThankForSubmitting: {
        id: 'anonymous.fnol.page.confirmation.Thank you for submitting a claim. Our Adjustment team will be in contact shortly.',
        defaultMessage: 'Thank you for submitting a claim. Our loss adjustment team will be in contact shortly.'
    },
    confirmationViaEmail: {
        id: 'anonymous.fnol.page.confirmation.email.Confirmation will be sent to your mailbox.',
        defaultMessage: 'You will receive a confirmation in your mailbox.'
    },
    confirmationPageKingRegards: {
        id: 'anonymous.fnol.page.confirmation.Kind regards, NN',
        defaultMessage: 'Kind regards, NN Insurance'
    },
    brokerRegisterAnotherClaimButton: {
        id: 'anonymous.fnol.page.confirmation.Register another claim',
        defaultMessage: 'Register another claim'
    }
});
