import React from 'react';
import PropTypes from 'prop-types';
import createHOC from 'react-create-hoc';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

const withErrorBoundary = createHOC('withErrorBoundary', (ComponentToWrap, errorBoundaryProps) => {
    function WithErrorBoundary({
        onError, shouldSetUnhandledRejection, resetError, ...props
    }) {
        // arguments defined on the page/component overrides props from parent
        const {
            onError: errorBoundaryOnError = onError,
            shouldSetUnhandledRejection: errorBoundaryShouldOverride = shouldSetUnhandledRejection,
            resetError: errorBoundaryShouldReset = resetError
        } = errorBoundaryProps;
        return (
            <ErrorBoundary
                resetError={errorBoundaryShouldReset}
                onError={errorBoundaryOnError}
                shouldSetUnhandledRejection={errorBoundaryShouldOverride}
            >
                <ComponentToWrap {...props} />
            </ErrorBoundary>
        );
    }

    WithErrorBoundary.propTypes = {
        onError: PropTypes.func,
        shouldSetUnhandledRejection: PropTypes.bool,
        resetError: PropTypes.bool
    };

    WithErrorBoundary.defaultProps = {
        onError: () => <div />, // default behaviour is to ignore the error
        shouldSetUnhandledRejection: false,
        resetError: true
    };

    return WithErrorBoundary;
}, { factory: true });

export default withErrorBoundary;
