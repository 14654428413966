import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolQuickPageHeaderFirstLine: {
        id: 'anonymous.fnol.quick-flow.Sorry, but based on the information you have provided, we are unable to process your request online.',
        defaultMessage: 'Based on the information you have provided, we are unable to process your request online.'
    },
    fnolQuickPageHeaderSecondLine: {
        id: 'anonymous.fnol.quick-flow.Please complete the form below with basic information about your request and send it to us.',
        defaultMessage: 'Please complete the form below.'
    },
    fnolQuickPageHeaderThirdLine: {
        id: 'anonymous.fnol.quick-flow.We will contact you as soon as possible.',
        defaultMessage: 'We will contact you as soon as possible.'
    },
    fnolQuickFlowPageUrgentCallNumber: {
        id: 'anonymous.fnol.quick-flow.phone',
        defaultMessage: '{ phoneNumber }'
    },
    fnolQuickPagePolicyHolderContactDetails: {
        id: 'anonymous.fnol.quick-flow.Contact details of policyholder',
        defaultMessage: 'Contact details of policyholder'
    },
    fnolQuickFlowFirstName: {
        id: 'anonymous.fnol.quick-flow.First name',
        defaultMessage: 'First name'
    },
    fnolQuickFlowLastName: {
        id: 'anonymous.fnol.quick-flow.Last name',
        defaultMessage: 'Last name'
    },
    fnolQuickFlowDateOfBirth: {
        id: 'anonymous.fnol.quick-flow.Date of Birth',
        defaultMessage: 'Date of birth'
    },
    fnolQuickFlowPhoneNumber: {
        id: 'anonymous.fnol.quick-flow.Phone number',
        defaultMessage: 'Phone number'
    },
    fnolQuickFlowPhoneNumberTooltip: {
        id: 'anonymous.fnol.quick-flow.Please provide 00 and your country code before you start writing your phone number.',
        defaultMessage: 'Please enter 00 and your country code before you start writing your phone number.'
    },
    fnolQuickFlowEmailAddress: {
        id: 'anonymous.fnol.quick-flow.Email address',
        defaultMessage: 'Email address'
    },
    fnolQuickFlowAddressHeader: {
        id: 'anonymous.fnol.quick-flow.Address',
        defaultMessage: 'Address'
    },
    fnolQuickPageRequestDetails: {
        id: 'anonymous.fnol.quick-flow.Request details',
        defaultMessage: 'Request details'
    },
    fnolQuickFlowPolicyType: {
        id: 'anonymous.fnol.quick-flow.Policy type',
        defaultMessage: 'Policy type'
    },
    fnolQuickFlowPolicyTypeHome: {
        id: 'anonymous.fnol.quick-flow.Home',
        defaultMessage: 'Home'
    },
    fnolQuickFlowPolicyTypeCar: {
        id: 'anonymous.fnol.quick-flow.Car',
        defaultMessage: 'Vehicle damage'
    },
    fnolQuickFlowPolicyTypeFamily: {
        id: 'anonymous.fnol.quick-flow.Family',
        defaultMessage: 'Family'
    },
    fnolQuickFlowPolicyTypeLegalAssistance: {
        id: 'anonymous.fnol.quick-flow.Legal Assistance',
        defaultMessage: 'I need legal assistance'
    },
    fnolQuickFlowPolicyTypeTooltip: {
        id: 'anonymous.fnol.quick-flow.Please mark one checkbox, what the declaration will be about.',
        defaultMessage: 'Please indicate what the declaration will be about.'
    },
    fnolQuickFlowIsTheSameAsAlreadyProvided: {
        id: 'anonymous.fnol.quick-flow.Is it the same address you have already provided?',
        defaultMessage: 'Is it the same address that you have already provided?'
    },
    fnolQuickFlowInsuredInsuredAddressHeader: {
        id: 'anonymous.fnol.quick-flow.Insured address',
        defaultMessage: 'Insured address'
    },
    fnolQuickFlowLicensePlate: {
        id: 'anonymous.fnol.quick-flow.License Plate Number',
        defaultMessage: 'License plate number'
    },
    fnolQuickFlowPolicyNumber: {
        id: 'anonymous.fnol.quick-flow.Policy number',
        defaultMessage: 'Policy number'
    },
    fnolQuickFlowPolicyNumberTooltip: {
        id: 'anonymous.fnol.quick-flow.You can find the policy number on the 1st page of your policy contract. Please provide policy number without any separators (e.g. dashes or any other signs)',
        defaultMessage: 'You can find the policy number on the first page of your policy contract, on your international insurance card (for a car claim) or in your insurance/bank application. Please provide policy number without any separators (e.g. dashes or any other signs)'
    },
    fnolQuickFlowLossDate: {
        id: 'anonymous.fnol.quick-flow.Loss date',
        defaultMessage: 'Loss date'
    },
    fnolQuickFlowLossDateTooltip: {
        id: 'anonymous.fnol.quick-flow.Please provide the exact day the loss happened.',
        defaultMessage: 'Please provide the exact day the loss happened. If unknown, please provide the date that you discovered the damage.'
    },
    fnolQuickFlowWhatHappened: {
        id: 'anonymous.fnol.quick-flow.What happened',
        defaultMessage: 'What happened?'
    },
    fnolQuickFlowWhatHappenedPlaceholder: {
        id: 'anonymous.fnol.quick-flow.Describe the circumstances and the damage in your own words',
        defaultMessage: 'Describe the circumstances and the damage'
    },
    fnolQuickFlowWhatHappenedTooltip: {
        id: 'anonymous.fnol.quick-flow.Please write short description of damage in your own words.',
        defaultMessage: 'Please write short description of damage in your own words.'
    },
    fnolQuickFlowWhereDidItHappen: {
        id: 'anonymous.fnol.quick-flow.Where did it happen',
        defaultMessage: 'Where did it happen?'
    },
    fnolQuickFlowWhereDidItHappenTooltip: {
        id: 'anonymous.fnol.quick-flow.Please provide the location where incident happened',
        defaultMessage: 'Please provide the location where the accident happened'
    },
    fnolQuickFlowSubmitButton: {
        id: 'anonymous.fnol.quick-flow.Send',
        defaultMessage: 'Send'
    },
    fnolQuickFlowSubmitSuccessfullTitle: {
        id: 'anonymous.fnol.quick-flow.Your responses were successfully sent.',
        defaultMessage: 'Your responses were successfully sent.'
    },
    fnolQuickFlowSubmitSuccessfullBody: {
        id: 'anonymous.fnol.quick-flow.Wait for email with further information from us.',
        defaultMessage: 'We will send an email shortly with further information.'
    },
    fnolQuickFlowSubmitOkMessage: {
        id: 'anonymous.fnol.quick-flow.Ok',
        defaultMessage: 'OK'
    },
    fnolQuickFlowSubmitErrorTitle: {
        id: 'anonymous.fnol.quick-flow.Error occured while sending your responses.',
        defaultMessage: 'An error occurred while sending your responses.'
    },
    fnolQuickFlowSubmitErrorBody: {
        id: 'anonymous.fnol.quick-flow.Please try again.',
        defaultMessage: 'Please try again.'
    },
});
