/* eslint-disable max-len */
import React, { Component } from 'react';
import _ from 'lodash';
import { InputField, Icon } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import AutoCompleteSearchResults from '../AutoCompleteSearchResults/AutoCompleteSearchResults';
import * as utils from '../../../../../framework/packages/@jutro/components/_esm/googleUtils';
import messages from './AutoCompleteInput.messages';
import styles from './AutoCompleteInput.module.scss';

class AutoCompleteInput extends Component {
    static contextType = TranslatorContext;

    constructor(props) {
        super(props);
        if (!window.google || !window.google.maps) {
            throw new Error('google map api not found');
        }
        this.mapsApi = window.google.maps;
        this.autocomplete = new window.google.maps.places.AutocompleteService();
        this.geocoder = new window.google.maps.Geocoder();

        this.state = {
            showResults: false,
            places: [],
            inputValue: props.initialCountry,
            initialCountry: props.initialCountry
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.initialCountry !== state.initialCountry) {
            // Change state
            return {
                showResults: false,
                places: [],
                inputValue: props.initialCountry,
                initialCountry: props.initialCountry
            };
        }
        return null; // No change to state
    }

    writeValue = (value) => {
        setTimeout(() => {
            this.autocomplete.getPlacePredictions({ input: value },
                (predictions) => {
                    this.setState({
                        showResults: true,
                        places: predictions || []
                    });
                });
        }, 500);

        this.setState({ inputValue: value });

        if (!value) {
            this.setState({ places: [] });
        }
    };

    showList = () => {
        this.setState({
            showResults: true
        });
    };

    hideList = (event) => {
        const isOptionSelected = /place/g.test(_.get(event, 'relatedTarget.className', ''));
        this.setState({
            showResults: isOptionSelected
        });
    };

    geoCodePlace = (request) => {
        return new Promise((resolve, reject) => {
            this.geocoder.geocode(
                request,
                (results, status) => {
                    const translatedAddress = utils.mapGooglePlacesAddressToDTO(_.get(results, '0.address_components'));
                    if (status === this.mapsApi.GeocoderStatus.OK && results.length > 0) {
                        resolve(translatedAddress);
                    } else {
                        reject(Error(`Google Geocode API error : ${status}`));
                    }
                }
            );
        });
    };

    selectAddress = (e) => {
        const selectedPlace = e.target.textContent;
        const { handleMapClick } = this.props;
        const { places } = this.state;
        const getPlaceId = places.find((place) => place.description === selectedPlace).place_id;
        const geocodeRequest = { placeId: getPlaceId };
        this.geoCodePlace(geocodeRequest).then((address) => {
            handleMapClick(address);
        });
        this.setState({
            showResults: false,
            inputValue: selectedPlace,
            places: []
        });
    };

    render() {
        const { showResults, places, inputValue } = this.state;
        const translator = this.context;
        return (
            <React.Fragment>
                <div className={styles.mapSearch}>
                    <div className={styles.content}>
                        <div className={styles.toggle}>
                            <InputField
                                id="searchBox"
                                onValueChange={this.writeValue}
                                onFocus={this.showList}
                                className={styles.searchBoxContainer}
                                onBlur={this.hideList}
                                controlClassName={styles.searchBox}
                                placeholder={translator(messages.autoCompletePlaceholder)}
                                value={inputValue}
                                autoComplete={false}
                            />
                            <Icon className={styles.icon} icon="mi-search" />
                        </div>
                        {showResults && (
                            <AutoCompleteSearchResults
                                selectAddress={(value) => this.selectAddress(value)}
                                places={places}
                            />
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AutoCompleteInput;
