import { defineMessages } from 'react-intl';

export default defineMessages({
    useExistingLocation: {
        id: 'policycommon.directives.schedules.location.Use existing location?',
        defaultMessage: 'Use existing location?',
    },
    existingLocation: {
        id: 'policycommon.directives.schedules.location.Existing Location',
        defaultMessage: 'Existing Location',
    },
    newLocation: {
        id: 'policycommon.directives.schedules.location.New Location',
        defaultMessage: 'New Location',
    },
    isNonSpecificLocation: {
        id: 'policycommon.directives.schedules.Is Non Specific Location',
        defaultMessage: 'Is it a non-specific location?',
    }
});
