/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import styles from './DragAndDrop.module.scss';

class DragAndDrop extends Component {
    constructor(props) {
        super(props);
        this.dragAndDropDivRef = React.createRef(null);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDrop = this.handleDrop.bind(this);

        this.state = { isDragging: false };
    }

    static propTypes = {
        onFilesDragged: PropTypes.func.isRequired,
    };


    handleDragOver = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({ isDragging: true });
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.dropEffect = 'copy';
    }

    handleDragEnter = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({ isDragging: true });
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.dropEffect = 'copy';
    }

    handleDragLeave = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({ isDragging: false });
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.dropEffect = 'none';
    }

    handleDrop = (event) => {
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
        this.setState({ isDragging: false });
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const { onFilesDragged } = this.props;
            onFilesDragged(event.dataTransfer.files);
        }
    }

    render() {
        const { id, children } = this.props;
        const { isDragging } = this.state;
        return (
            <div
                id={id}
                className={
                    isDragging
                        ? cx(styles.dragDropDivOnDrag)
                        : cx(styles.dragDropDivNotDragging)
                }
                ref={this.dragAndDropDivRef}
                onDragOver={this.handleDragOver}
                onDragEnter={this.handleDragEnter}
                onDragLeave={this.handleDragLeave}
                onDrop={this.handleDrop}
            >
                {children}
            </div>
        );
    }
}

export default DragAndDrop;
