/* eslint-disable max-len */
import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withRouter } from 'react-router-dom';
import { ServiceManager } from '@jutro/services';
import { TranslatorContext, LocaleService, getNativeLocaleLanguage } from '@jutro/locale';
import { Link } from '@jutro/components';
import classNames from 'classnames';
import { BreakpointTrackerContext } from '@jutro/layout';
import metadata from './DefaultHeader.metadata.json5';
import styles from './DefaultHeader.module.scss';
import messages from './DefaultHeader.messages';
import ClientContext from '../../../../contexts/ClientContext/ClientContext';
import ClientOrientedAppHeaderLogoWrapper from '../../../../components/CustomApplicationHeader/ClientOrientedAppHeaderLogoWrapper';

function DefaultHeader() {
    const translator = useContext(TranslatorContext);
    const clientTypeContext = useContext(ClientContext);
    const localeService = ServiceManager.getService('locale-service');
    const breakpoint = useContext(BreakpointTrackerContext);
    const [showPhoneContextMenu, setShowPhoneContextMenu] = useState(false);
    const [phoneMenuRef, setPhoneMenuRef] = useState(false);

    const togglePhoneMenu = useCallback(() => {
        setShowPhoneContextMenu(!showPhoneContextMenu);
    }, [showPhoneContextMenu]);

    const shouldTogglePhoneMenu = useCallback((e) => {
        if (phoneMenuRef === e.target) {
            togglePhoneMenu();
        }
    }, [phoneMenuRef, togglePhoneMenu]);

    const getMenuRef = useCallback((node) => {
        setPhoneMenuRef(node);
    }, []);

    const navigateToNN = () => window.open('https://www.nn.be/');

    const getAvailableLanguages = () => {
        const availableLanguages = LocaleService.getAvailableLanguages();
        const defaultLanguages = LocaleService.settings ? LocaleService.settings.getAvailableLanguages() : [LocaleService.locale];
        const languages = availableLanguages || defaultLanguages;
        return languages.filter((language) => language !== 'de-DE');
    };

    const handleLanguageValueChange = useCallback((language) => {
        localeService.saveLanguage(language);
    }, [localeService]);

    const renderLanguageLabel = useCallback((locale) => {
        const fullLabel = getNativeLocaleLanguage(locale);
        const indexOfBracket = fullLabel.indexOf('(');
        return indexOfBracket >= 0 ? fullLabel.substring(0, indexOfBracket) : fullLabel;
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            togglePhoneMenu,
            navigateToNN
        },
        resolveComponentMap: {
            customHeaderLogoProvider: ClientOrientedAppHeaderLogoWrapper
        }
    };

    const isTestEnvironment = useMemo(() => {
        return process.env.REACT_APP_IS_TEST_ENVIRONMENT === 'true'
    }, [])

    const overrideProps = {
        headerGridContainer: {
            columns: isTestEnvironment
                ? ["auto", "auto", "auto"]
                : ["auto", "auto"]
        },
        demoEnvironmentLabel: {
            visible: isTestEnvironment
        },
        demoEnvironmentLabelPhone: {
            visible: isTestEnvironment
        },
        globalizationChooserField: {
            onLanguageValueChange: handleLanguageValueChange,
            languageValue: localeService.getStoredLanguage(),
            availableLanguageValues: getAvailableLanguages(),
            renderLanguageLabel: renderLanguageLabel
        },
        phoneMenu: {
            ref: getMenuRef,
            className: classNames('gw-phone-header', {
                'gw-phone-header-open': showPhoneContextMenu
            })
        },
        mobileHome: {
            onClick: togglePhoneMenu
        }
    };
    

    const headerMetadata = useMemo(() => {
        if (breakpoint === 'phone' || breakpoint === 'tablet') {
            return metadata.componentContent.content.filter((item) => item.id === 'phoneHeader');
        }
        return metadata.componentContent.content.filter((item) => item.id === 'headerFragment');
    }, [breakpoint]);

    return (
        <React.Fragment>
            {
                renderContentFromMetadata(headerMetadata, overrideProps, resolvers)
            }
        </React.Fragment>
    );
}

export default withRouter(DefaultHeader);
