/* eslint-disable max-len */
import _ from 'lodash';
import { buildAddressString, buildBankAccountDetails } from '../../pages/WizardFnolSummaryPage/CommonSummaryFunctions';
import { getTypecodeTranslation } from '../../utils/TypeCodeTranslationUtil';
import messages from './WizardFNOLSummaryProperty.messages';
import repairMessages from '../repairComponents/WizardFNOLPropertyRepair/WizardFNOLPropertyRepair.messages';
import estimateMessages from '../repairComponents/estimatesComponents/EstimateComponents.messages';
import civilLiabilityMessages from '../BasicInformationPropertyCivil/BasicInformationPropertyCivil.messages';
import legalAssistanceMessages from '../BasicInformationPropertyLegal/BasicInformationPropertyLegal.messages';

export function buildPropertyDescription(translator, wizardDTO) {
    let propertyDescription = '';
    const propertyDamages = wizardDTO.value.propertyDamage;
    if (_.isEmpty(propertyDamages)) return propertyDescription;

    const filteredPropertyDamages = wizardDTO.value.propertyDamage.filter((propDamg) => propDamg.isSelected);
    if (_.isEmpty(filteredPropertyDamages)) return propertyDescription;

    filteredPropertyDamages.forEach((propDamage) => {
        propertyDescription += `${getTypecodeTranslation(translator, propDamage.damagedProperty, 'inlb_FrontEndDmgProperty')}`;
        if (!!propDamage.otherDamagesDescription) propertyDescription += ` - ${propDamage.otherDamagesDescription}`;

        if (!_.isEmpty(propDamage.address)) {
            propertyDescription += `${buildAddressString(propDamage.address, translator)}`;
        } else {
            propertyDescription += '\n';
        }

        const filteredDamagedDetails = propDamage.damageDetailsList.filter((propDamgDet) => propDamgDet.isSelected);
        if (!_.isEmpty(filteredDamagedDetails)) {
            filteredDamagedDetails.forEach((detailedDmg) => {
                propertyDescription += `  - ${getTypecodeTranslation(translator, detailedDmg.damageDetail, 'inlb_FrontEndDmgDetails')}`;
                if (!!detailedDmg.otherDamagesDescription) propertyDescription += ` - ${detailedDmg.otherDamagesDescription}`;
                propertyDescription += '\n';
            });
        }
    });

    return propertyDescription;
}

export function buildRepairDetailsDamage(translator, wizardDTO) {
    const repairDTOValue = wizardDTO.value.repairDetails;
    if (_.isEmpty(repairDTOValue)) return '';

    switch (repairDTOValue.repairOptionChoice) {
        case 'PreferredVendor':
            return translator(repairMessages.wizardFNOLPropertyRepairChoiceRecommendedFacility);
        case 'NewVendor': {
            let repairDetails = translator(repairMessages.wizardFNOLPropertyRepairChoiceOwnVendor);

            if (repairDTOValue.settledViaPVProcedure !== undefined) {
                repairDetails += `\n${translator(estimateMessages.fNOLRepairSettledViaPVProcedure)} - `;
                repairDetails += repairDTOValue.settledViaPVProcedure
                    ? translator({
                        id: 'fnol.ho.views.ho-fire-details.Yes',
                        defaultMessage: 'Yes'
                    })
                    : translator({
                        id: 'fnol.ho.views.ho-fire-details.No',
                        defaultMessage: 'No'
                    });
            }

            if (repairDTOValue.pvpaymentPercentage !== undefined) {
                repairDetails += `\n${translator(estimateMessages.fNOLRepairEstimatesPVPaymentPercentage)} - ${getTypecodeTranslation(translator, repairDTOValue.pvpaymentPercentage, 'inlb_PaymentPercentage')}`;
            }

            if (repairDTOValue.pvpaymentVatIncluded !== undefined) {
                repairDetails += `\n${translator(estimateMessages.fNOLRepairEstimatesVATIncluded)} - `;
                repairDetails += repairDTOValue.pvpaymentVatIncluded
                    ? translator({
                        id: 'fnol.ho.views.ho-fire-details.Yes',
                        defaultMessage: 'Yes'
                    })
                    : translator({
                        id: 'fnol.ho.views.ho-fire-details.No',
                        defaultMessage: 'No'
                    });
            }

            if (repairDTOValue.alreadyReceivedEstimate !== undefined) {
                repairDetails += `\n${translator(estimateMessages.fNOLRepairEstimatesAlreadyReceived)} - `;
                repairDetails += repairDTOValue.alreadyReceivedEstimate
                    ? translator({
                        id: 'fnol.ho.views.ho-fire-details.Yes',
                        defaultMessage: 'Yes'
                    })
                    : translator({
                        id: 'fnol.ho.views.ho-fire-details.No',
                        defaultMessage: 'No'
                    });
            }

            if (repairDTOValue.alreadyReceivedEstimate || repairDTOValue.settledViaPVProcedure) {
                if (!_.isEmpty(repairDTOValue.estimatedBuildingRepairCost)) repairDetails += `\n${translator(messages.wizardFnolSummaryPageRepairDetailsPropertyBuildingEstimate)} ${repairDTOValue.estimatedBuildingRepairCost.amount} ${repairDTOValue.estimatedBuildingRepairCost.currency}`;
                if (!_.isEmpty(repairDTOValue.estimatedContentRepairCost)) repairDetails += `\n${translator(messages.wizardFnolSummaryPageRepairDetailsPropertyContentEstimate)} ${repairDTOValue.estimatedContentRepairCost.amount} ${repairDTOValue.estimatedContentRepairCost.currency}`;
            }

            if (repairDTOValue.damageAlreadyRepaired !== undefined) {
                repairDetails += `\n${translator(messages.wizardFnolSummaryPageRepairDetailsPropertyAlreadyRepaired)} - ${getTypecodeTranslation(translator, repairDTOValue.damageAlreadyRepaired, 'inlb_FrontEndDmgRepaired')}`;
            }

            if (!_.isEmpty(repairDTOValue.bankAccountDetails)) {
                repairDetails += buildBankAccountDetails(repairDTOValue.bankAccountDetails, translator);
            }

            return repairDetails;
        }
        case 'NoRepair': {
            return translator(repairMessages.wizardFNOLPropertyRepairNoVendorChoice);
        }
        default: throw new Error('Unsupported repair option');
    }
}

export function buildPropertyCivilLiability(translator, wizardDTO) {
    let damageToOthersDescription = '';
    const { civilLiabilityDamage } = wizardDTO.value;
    if (_.isEmpty(civilLiabilityDamage)) return damageToOthersDescription;

    damageToOthersDescription += `${getTypecodeTranslation(translator, civilLiabilityDamage.damageCause, 'inlb_FrontendCLCausedDmg')}`;

    if (civilLiabilityDamage.roadUser) damageToOthersDescription += `\n${getTypecodeTranslation(translator, civilLiabilityDamage.roadUser, 'inlb_FrontendRoadUser')}`;
    if (civilLiabilityDamage.who) damageToOthersDescription += `\n${getTypecodeTranslation(translator, civilLiabilityDamage.who, 'inlb_FrontendWho')}`;
    if (civilLiabilityDamage.age) damageToOthersDescription += `\n${getTypecodeTranslation(translator, civilLiabilityDamage.age, 'inlb_FrontendAgeRanges')}`;
    if (civilLiabilityDamage.petDescription) damageToOthersDescription += `\n${civilLiabilityDamage.petDescription}`;
    if (civilLiabilityDamage.otherDescription) damageToOthersDescription += `\n${civilLiabilityDamage.otherDescription}`;

    return damageToOthersDescription;
}

export function buildPropertyLegalAssistance(translator, wizardDTO) {
    let legalAssistanceDescription = '';
    const { legalAssistanceDamage } = wizardDTO.value;
    if (_.isEmpty(legalAssistanceDamage)) return legalAssistanceDescription;

    let detailedLADamageDescription = '';
    if (legalAssistanceDamage.worksToMyBuilding) detailedLADamageDescription += `\n - ${translator(legalAssistanceMessages.worksToMyBuilding)}`;
    if (legalAssistanceDamage.worksToAnotherBuilding) detailedLADamageDescription += `\n - ${translator(legalAssistanceMessages.worksToAnotherBuilding)}`;
    if (legalAssistanceDamage.roadworks) detailedLADamageDescription += `\n - ${translator(legalAssistanceMessages.roadWorks)}`;
    if (legalAssistanceDamage.thirdrdParty) detailedLADamageDescription += `\n - ${translator(legalAssistanceMessages.thirdParty)}`;
    if (legalAssistanceDamage.other) detailedLADamageDescription += `\n - ${translator(legalAssistanceMessages.myPropertyOther)}: ${legalAssistanceDamage.otherDescription}`;

    if (legalAssistanceDamage.material) detailedLADamageDescription += `\n - ${translator(legalAssistanceMessages.meOrFamilyMaterial)}: ${legalAssistanceDamage.materialDescription}`;
    if (legalAssistanceDamage.physical) detailedLADamageDescription += `\n - ${translator(legalAssistanceMessages.meOrFamilyPhysical)}:`;
    if (legalAssistanceDamage.who) detailedLADamageDescription += `\n     - ${getTypecodeTranslation(translator, legalAssistanceDamage.who, 'inlb_FrontendWho')}`;
    if (legalAssistanceDamage.age) detailedLADamageDescription += `\n     - ${getTypecodeTranslation(translator, legalAssistanceDamage.age, 'inlb_FrontendAgeRanges')}`;

    if (legalAssistanceDamage.petDescription) detailedLADamageDescription += `\n${legalAssistanceDamage.petDescription}`;
    if (legalAssistanceDamage.propertyOtherDescription) detailedLADamageDescription += `\n${legalAssistanceDamage.propertyOtherDescription}`;

    legalAssistanceDescription += `${getTypecodeTranslation(translator, legalAssistanceDamage.damageReason, 'inlb_FrontendLAReason')}`;
    if (!_.isEmpty(detailedLADamageDescription)) {
        legalAssistanceDescription += `:${detailedLADamageDescription}`;
    }
    return legalAssistanceDescription;
}

export function generatePropertyPartSnapshot(translator, wizardDTO) {
    const propDataSnapshot = [];

    if (!_.isEmpty(buildPropertyCivilLiability(translator, wizardDTO))) {
        propDataSnapshot.push({
            label: translator(civilLiabilityMessages.whatWhoCausedDamage),
            value: buildPropertyCivilLiability(translator, wizardDTO)
        });
    }

    if (!_.isEmpty(buildPropertyLegalAssistance(translator, wizardDTO))) {
        propDataSnapshot.push({
            label: translator(legalAssistanceMessages.whyNeedLegalAssistance),
            value: buildPropertyLegalAssistance(translator, wizardDTO)
        });
    }

    if (!_.isEmpty(buildPropertyDescription(translator, wizardDTO))) {
        propDataSnapshot.push({
            label: translator(messages.fnolSummaryAboutProperty),
            value: buildPropertyDescription(translator, wizardDTO)
        });
    }

    if (!_.isEmpty(buildRepairDetailsDamage(translator, wizardDTO))) {
        propDataSnapshot.push({
            label: translator(messages.wizardFnolSummaryPropertyRepairDetails),
            value: buildRepairDetailsDamage(translator, wizardDTO)
        });
    }

    return propDataSnapshot;
}
