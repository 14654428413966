import { defineMessages } from 'react-intl';

export default defineMessages({
    authenticationLandingPageHeader: {
        id: 'anonymous.authentication.landingPage.Welcome at NN myclaim',
        defaultMessage: 'Welcome at NN myclaim'
    },
    authenticationLandingPageHeaderLineTwo: {
        id: 'anonymous.authentication.landingPage.NN helps you to report your damage',
        defaultMessage: 'NN helps you to report your damage'
    },
    authenticationPageItsMeOption: {
        id: 'anonymous.authentication.landingPage.Log in with itsme ®',
        defaultMessage: 'Log in with itsme ®'
    },
    fnolSearchPolicyGDPRTextAgreeWith: {
        id: 'anonymous.authentication.landingPage.I agree with NN ',
        defaultMessage: 'I agree with the '
    },
    fnolSearchPolicyGDPRPrivacyPolicyLink: {
        id: 'anonymous.authentication.landingPage.privacy policy ',
        defaultMessage: 'Privacy Policy of NN '
    },
    fnolSearchPolicyGDPRTextAnd: {
        id: 'anonymous.authentication.landingPage.and ',
        defaultMessage: ' and '
    },
    fnolSearchPolicyGDPRTextAssuraliaAntiFraudLink: {
        id: 'anonymous.authentication.landingPage.Assuralia ani-fraud statement.',
        defaultMessage: ' the Assuralia anti-fraud statement '
    },
    fnolSearchPolicyGDPRTextInfoLabel: {
        id: 'anonymous.authentication.landingPage.I confirm that all the information provided will be true and accurate to the best of my knowledge.',
        defaultMessage: 'I confirm that all the information provided will be true and accurate to the best of my knowledge.'
    },
    authenticationPageNext: {
        id: 'anonymous.authentication.landingPage.Next',
        defaultMessage: 'Next'
    }
});
