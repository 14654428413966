/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import styles from './PageContainer.module.scss';

function PageContainer(props) {
    const { children } = props;
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);

    // block drag and dropping files into unwanted places
    const dragBlockListener = (event) => {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.dropEffect = 'none';
    };

    useEffect(() => {
        document.addEventListener('dragover', dragBlockListener);
        document.addEventListener('drop', dragBlockListener);
        document.addEventListener('dragenter', dragBlockListener);

        return () => {
            document.removeEventListener('dragover', dragBlockListener);
            document.removeEventListener('drop', dragBlockListener);
            document.removeEventListener('dragenter', dragBlockListener);
        };
    }, []);

    return (
        <React.Fragment>
            <div>
                {partnerContext.generateHeader(translator)}
            </div>
            <div className={styles.pageContainer}>
                {children}
            </div>
            <div className={styles.footerContainerDiv}>
                {partnerContext.generateFooter(translator)}
            </div>
        </React.Fragment>
    );
}

export default withRouter(PageContainer);
