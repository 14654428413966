export function getTypecodeTranslation(translator, code, typelist) {
    return translator({
        id: `typekey.${typelist}.${code}`,
        defaultMessage: code
    });
}

export function translateTypecodeToCodeName(translator, code, typelist) {
    return {
        code,
        name: getTypecodeTranslation(translator, code, typelist)
    };
}
