/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { RadioButtonField } from '@jutro/components';
import styles from './CustomRadioButton.module.scss';

function CustomRadioButton(props) {
    const { iterationData } = props;

    return (
        <div>
            <div>
                <RadioButtonField
                    autoTrim={false}
                    availableValues={[]}
                    dataType="string"
                    disabled={false}
                    flavor="tiles"
                    id="radiobutton"
                    label={iterationData.label}
                    labelPosition="top"
                    tooltip={iterationData.toolTip}
                    readOnly={false}
                    required={true}
                    showRequired={true}
                    schemaRequired={false}
                    showErrors={false}
                    showValidationIcon={false}
                />
            </div>
            {iterationData.availableValues.map((iterationDataEntry) => {
                return (
                    <div key={iterationDataEntry.id}>
                        <RadioButtonField
                            autoTrim={false}
                            availableValues={[iterationDataEntry.value]}
                            dataType="string"
                            disabled={false}
                            flavor="tiles"
                            hideLabel={true}
                            id="radiobutton"
                            labelPosition="top"
                            onValueChange={iterationData.onSelect}
                            readOnly={false}
                            required={false}
                            value={iterationDataEntry.selected ? iterationDataEntry.value.code : null}
                            schemaRequired={false}
                            showErrors={false}
                            showValidationIcon={false}
                        />
                        { (iterationDataEntry.selected && iterationDataEntry.iterationData) && (
                            <div className={styles.leftMargin}>
                                <CustomRadioButton iterationData={iterationDataEntry.iterationData} />
                            </div>
                        )}
                    </div>
                );
            })
            }
        </div>
    );
}

export default CustomRadioButton;
