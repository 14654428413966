import { useCallback, useRef, useEffect } from 'react';
import { FNOLService } from 'nn-capability-fnol';
import { useHistory } from 'react-router-dom';

function useErrorHandler() {
    const history = useHistory();
    const handleError = useCallback((error, claimNumber) => {
        if (!error.appErrorCode) {
            const errorObject = {
                errorMessage: error.message.replace('<', '[').replace('>', ']'),
                errorStackTrace: error.stack.replace('<', '[').replace('>', ']')
            };
            FNOLService.logClientError(errorObject, history, []);
        }

        history.push({
            pathname: '/contact-call-center',
            state: {
                redirectPath: '/',
                claimNumber,
                allowSaveAndResumeMessage: true
            }
        });
    }, [history]);
    const handlers = useRef({ handleError });

    useEffect(() => {
        handlers.current = { handleError };
    }, [handleError]);
    return handlers.current;
}

export default useErrorHandler;
