/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-console */
import _ from 'lodash';
import React, { useCallback, useState, useContext, useEffect } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { FNOLService } from 'nn-capability-fnol';
import FNOLAddress from '../../Address/Address';
import { isAutoLOB, isMotorLOB } from '../../../utils/PolicyTypeUtil';
import useBreakpointHandler from '../../../hooks/useBreakpointHandler';
import { getTypecodeTranslation } from '../../../utils/TypeCodeTranslationUtil';
import LossDetailsCar from '../LossDetailsCar/LossDetailsCar';
import PartnerContext from '../../../contexts/PartnerContext/PartnerContext';
import styles from './LossDetailsVehicle.module.scss';
import metadata from './LossDetailsVehicle.metadata.json5';
import messages from './LossDetailsVehicle.messages';
import PleaseWait from '../../PleaseWait/PleaseWait';
import useUnmountHandler from '../../../hooks/useUnmountHandler';
import useTypelistHandler from '../../../hooks/useTypelistHandler';
import getAvailableYears from '../../../utils/VehicleUtils';
import { minDriverAge } from '../../../utils/Constants';
import LossDetailsMotor from '../LossDetailsMotor/LossDetailsMotor';

function LossDetailsVehicle(props) {
    const isComponentMounted = useUnmountHandler();
    const { getValuesFromTypelistIncludeFilter } = useTypelistHandler();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const [isLoading, setIsLoading] = useState(false);

    const {
        id,
        history,
        onValueChange,
        value: fnolVM,
        onValidate
    } = props;

    const fnolDTO = fnolVM.value;
    if (!fnolDTO.vehicleDamage) {
        fnolDTO.vehicleDamage = {};
    }
    if (!fnolDTO.vehicleDamage.vehicle) {
        fnolDTO.vehicleDamage.vehicle = {};
    }

    if (!fnolDTO.trailerDamage) {
        fnolDTO.trailerDamage = {};
    }

    if (!fnolDTO.otherDamages) {
        fnolDTO.otherDamages = {};
    }

    if (!fnolDTO.trailerDamage.trailer) {
        fnolDTO.trailerDamage.trailer = {};
    }

    if (!fnolDTO.vehicleDamage.driver) {
        fnolDTO.vehicleDamage.driver = {};
        fnolDTO.vehicleDamage.driver.address = {};
    }

    if (!fnolDTO.vehicleDamage.vehicleDamagePoints) {
        fnolDTO.vehicleDamage.vehicleDamagePoints = [];
    }

    const { vehicleDamage: vehicleDamageDTO } = fnolDTO;

    const {
        onValidate: setComponentValidation,
        disregardFieldValidation,
        registerComponentValidation,
        isComponentValid
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const [
        isMobileDevice
    ] = useBreakpointHandler();

    const handleValueChange = useCallback((value, changedPath) => {
        if (changedPath.includes('vehicleDamage') && !fnolDTO.vehicleDamage.haveChanged) {
            _.set(fnolDTO, 'vehicleDamage.haveChanged', true);
        }

        if (changedPath.includes('vehicleDamage.otherDamages') && !fnolDTO.otherDamages.haveChanged) {
            _.set(fnolDTO, 'otherDamages.haveChanged', true);
        }

        if (changedPath.includes('trailerDamage.') && !fnolDTO.trailerDamage.haveChanged) {
            _.set(fnolDTO, 'trailerDamage.haveChanged', true);
        }

        if (changedPath.includes('otherDamages.') && !fnolDTO.otherDamages.haveChanged) {
            _.set(fnolDTO, 'otherDamages.haveChanged', true);
        }

        if (changedPath.includes('motorDamage.') && !fnolDTO.motorDamage.haveChanged) {
            _.set(fnolDTO, 'motorDamage.haveChanged', true);
        }

        onValueChange(value, changedPath);
    }, [fnolDTO, onValueChange]);

    const getTypelistValues = useCallback((field, filterName) => {
        const typeList = viewModelService.create(
            {},
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.DriverDTO'
        );

        let result;
        if (!filterName) {
            result = typeList[field].aspects.availableValues[0].typelist.codes;
        } else {
            result = typeList[field].aspects.availableValues[0].typelist.filters
                .filter((f) => f.name === filterName)[0].codes;
        }
        return result.map((t) => {
            return {
                code: t.code,
                name: translator({
                    id: t.name,
                    defaultMessage: t.name
                })
            };
        });
    }, [translator, viewModelService]);

    // onChange ///////////////////////////////////////////
    const queryOtherDriver = (() => {
        const { driver } = vehicleDamageDTO;
        const { firstName, lastName, dateOfBirth } = driver;
        if (!isComponentMounted()) return;
        if (!firstName || !lastName || !dateOfBirth) {
            handleValueChange(false, 'supportiveValues.driverContactFoundOnPolicy');
            return;
        }
        setIsLoading(true);
        const driverOnPolicyQuestionDTO = viewModelService.create(
            {
                sessionUUID: fnolDTO.sessionUUID,
                policyNumber: fnolDTO.policyNumber,
                claimNumber: fnolDTO.claimNumber,
                dateOfBirth: dateOfBirth,
                firstName: firstName,
                lastName: lastName
            },
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.DriverOnPolicyExistsQuestionDTO'
        );
        FNOLService.searchDriverOnPolicy(driverOnPolicyQuestionDTO.value, history, { partnerContext, translator }).then(
            (searchDriverAnswerDTO) => {
                if (!isComponentMounted() || !searchDriverAnswerDTO) return;
                if (fnolDTO.supportiveValues.driverContactFoundOnPolicy !== searchDriverAnswerDTO.driverFound) {
                    handleValueChange(searchDriverAnswerDTO.driverFound, 'supportiveValues.driverContactFoundOnPolicy');
                }
            }
        ).finally(() => {
            if (isComponentMounted()) setIsLoading(false);
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const queryOtherDriverDebounced = useCallback(
        _.debounce(queryOtherDriver, 800), [fnolDTO.supportiveValues.driverContactFoundOnPolicy]
    );

    // will check in CC if driver is found on policy
    useEffect(() => {
        const { driver, whoWasTheDriver } = vehicleDamageDTO;
        if (!driver
            || !driver.firstName
            || !driver.lastName
            || !driver.dateOfBirth
            || (whoWasTheDriver !== 'other' || (!!fnolDTO.supportiveValues.authFlowOtherDriverContactSelection
                && fnolDTO.supportiveValues.authFlowOtherDriverContactSelection !== 'other'))
        ) {
            return;
        }
        queryOtherDriverDebounced();
    }, [
        vehicleDamageDTO.driver.firstName,
        vehicleDamageDTO.driver.lastName,
        vehicleDamageDTO.driver.dateOfBirth,
        vehicleDamageDTO.whoWasTheDriver,
        queryOtherDriverDebounced,
        vehicleDamageDTO,
        fnolDTO.supportiveValues.authFlowOtherDriverContactSelection]);

    const checkDriverDateOfBirthErrors = useCallback(() => {
        const minAge = minDriverAge();
        const someYearsAgo = new Date();
        someYearsAgo.setFullYear(someYearsAgo.getFullYear() - minAge);
        const { dateOfBirth } = vehicleDamageDTO.driver;
        if (dateOfBirth) {
            const dobConvertedToDate = new Date(
                dateOfBirth.year, dateOfBirth.month, dateOfBirth.day
            );
            if (dobConvertedToDate.getTime() > someYearsAgo.getTime()) {
                const errMessage = translator(
                    messages.otherDateOfBirthErrTooYoung, { minimumAge: minAge }
                );
                return ({ msg: errMessage, isValid: false });
            }
        }
        return ({ msg: '', isValid: true });
    }, [vehicleDamageDTO.driver, translator]);

    const validationDriverDOBCallBack = useCallback(() => {
        const dateOfBirthError = checkDriverDateOfBirthErrors();
        return (dateOfBirthError.isValid);
    }, [checkDriverDateOfBirthErrors]);

    useEffect(() => {
        registerComponentValidation(validationDriverDOBCallBack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ((vehicleDamageDTO.whoWasTheDriver !== 'other')
            || (vehicleDamageDTO.whoWasTheDriver === 'other' && fnolDTO.supportiveValues.driverContactFoundOnPolicy)
        ) {
            disregardFieldValidation('customDriverAddressInput');
        }
    }, [vehicleDamageDTO.whoWasTheDriver, disregardFieldValidation,
        fnolDTO.supportiveValues.driverContactFoundOnPolicy]);

    const onWhoWasTheDriverInputChange = useCallback((value) => {
        handleValueChange(value, 'vehicleDamage.whoWasTheDriver');
        if (value === 'other') {
            handleValueChange('driver', 'vehicleDamage.driver.roleOnPolicy');
        }
    }, [handleValueChange]);

    const providedStyleOrNoStyleOnMobile = useCallback((className) => {
        return isMobileDevice() ? null : className;
    }, [isMobileDevice]);

    const mobileOrRegularStyle = useCallback((mobileClassName, className) => {
        return isMobileDevice() ? mobileClassName : className;
    }, [isMobileDevice]);

    const dateOfBirthError = checkDriverDateOfBirthErrors();

    const onWasDriverInjuredChange = useCallback((newValue) => {
        handleValueChange(newValue, 'vehicleDamage.wasTheDriverInjured');
    }, [handleValueChange]);

    const otherDriversData = useCallback(() => {
        const authenticatedDetails = fnolDTO.authenticatedFlowDetails;
        if (!authenticatedDetails || _.isEmpty(authenticatedDetails.potentialDrivers)) return {};

        const drivers = authenticatedDetails.potentialDrivers.map((potentialDriver) => {
            return {
                code: `${potentialDriver.firstName}/${potentialDriver.lastName}/${potentialDriver.roleOnPolicy}`,
                name: `${potentialDriver.firstName} ${potentialDriver.lastName} - ${getTypecodeTranslation(translator, potentialDriver.roleOnPolicy, 'ContactRole')}`
            };
        });
        drivers.push({
            code: 'other',
            name: translator({
                id: 'anonymous.wizard.fnol.pages.summary.Other',
                defaultMessage: 'Other'
            })
        });
        return drivers;
    }, [fnolDTO.authenticatedFlowDetails, translator]);

    const onOtherDriverSelect = useCallback((selectedCode) => {
        handleValueChange(selectedCode, 'supportiveValues.authFlowOtherDriverContactSelection');
        if (selectedCode === 'other') {
            handleValueChange({
                address: {},
                roleOnPolicy: 'driver'
            }, 'vehicleDamage.driver');
        } else {
            const splittedCode = selectedCode.split('/');
            const matchingContact = fnolDTO.authenticatedFlowDetails.potentialDrivers.filter((driver) => {
                return driver.firstName === splittedCode[0]
                    && driver.lastName === splittedCode[1]
                    && driver.roleOnPolicy === splittedCode[2];
            });
            handleValueChange(matchingContact[0], 'vehicleDamage.driver');
        }
    }, [fnolDTO.authenticatedFlowDetails, handleValueChange]);

    const overrides = {
        vehicleMakeModelContainer: {
            className: providedStyleOrNoStyleOnMobile('flexContainerJustify')
        },
        vehicleMakeInput: {
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        vehicleModelInput: {
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        vehicleYearInput: {
            className: mobileOrRegularStyle('phonePickerContainer', 'pickerContainer'),
            availableValues: getAvailableYears()
        },
        vehicleLicensePlateVINContainer: {
            className: providedStyleOrNoStyleOnMobile('flexContainerJustify')
        },
        vehicleLicensePlateInput: {
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        vehicleVinInput: {
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        otherDriverFirstLastNameContainer: {
            className: providedStyleOrNoStyleOnMobile('flexContainerJustify')
        },
        otherFirstName: {
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        otherLastName: {
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        otherDateOfBirth: {
            className: mobileOrRegularStyle('phonePickerContainer', 'pickerContainer'),
            showErrors: (!dateOfBirthError.isValid),
            validationMessages: (dateOfBirthError.msg !== '' ? [dateOfBirthError.msg] : [])
        },
        otherDriverPhoneEmailContainer: {
            className: providedStyleOrNoStyleOnMobile('flexContainerJustify')
        },
        driverPhoneNumber: {
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        driverEmail: {
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        otherDamagesContainerCheckboxes: {
            className: providedStyleOrNoStyleOnMobile('flexContainer')
        },
        otherDamagesContainerRight: {
            className: providedStyleOrNoStyleOnMobile('leftMargin')
        },
        whoWasTheDriverInput: {
            onValueChange: onWhoWasTheDriverInputChange,
            availableValues: getValuesFromTypelistIncludeFilter('com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.VehicleDamageDTO', 'whoWasTheDriver', 'motor_car_flow_values'),
            value: vehicleDamageDTO.whoWasTheDriver
        },
        otherDriverRadioButton: {
            onValueChange: onOtherDriverSelect,
            availableValues: otherDriversData(),
            visible: vehicleDamageDTO.whoWasTheDriver === 'other' && !!fnolDTO.authenticatedFlowDetails && !_.isEmpty(otherDriversData()),
            value: fnolDTO.supportiveValues.authFlowOtherDriverContactSelection
        },
        isDriverTheBoB: {
            disabled: fnolDTO.vehicleDamage.driver.isCarSharer,
            visible: fnolDTO.supportiveValues.showCarSharerQuestion === true
                ? fnolDTO.vehicleDamage.driver.isCarSharer === false && !!fnolDTO.supportiveValues.showBoBQuestion
                : !!fnolDTO.supportiveValues.showBoBQuestion
        },
        isDriverTheCarSharer: {
            visible: !!fnolDTO.supportiveValues.showCarSharerQuestion,
            disabled: fnolDTO.vehicleDamage.driver.isBoB
        },
        wasTheDriverInjured: {
            visible: vehicleDamageDTO.whoWasTheDriver !== 'no_driver',
            onValueChange: onWasDriverInjuredChange,
        },
        otherDriverContainer: {
            visible: vehicleDamageDTO.whoWasTheDriver === 'other' && (fnolDTO.supportiveValues.authFlowOtherDriverContactSelection === 'other'
                || !fnolDTO.authenticatedFlowDetails
                || _.isEmpty(otherDriversData()))
        },
        customDriverContainer: {
            visible: vehicleDamageDTO.wasTheDriverInjured === true
                && vehicleDamageDTO.whoWasTheDriver === 'other'
                && (fnolDTO.supportiveValues.driverContactFoundOnPolicy === false || (!!fnolDTO.supportiveValues.authFlowOtherDriverContactSelection
                && fnolDTO.supportiveValues.authFlowOtherDriverContactSelection !== 'other'))
        },
        otherDriverLanguageGenderContainer: {
            className: providedStyleOrNoStyleOnMobile('flexContainerJustify')
        },
        genderInput: {
            availableValues: getTypelistValues('gender'),
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        languageInput: {
            availableValues: getTypelistValues('languageType', 'FrontEndSelectable'),
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        customDriverAddressInput: {
            onWriteValue: handleValueChange,
            policyType: fnolDTO.policyType,
            isCityMandatory: false,
            isPostalCodeMandatory: false,
            isStreetNameMandatory: false,
            isStreetNumberMandatory: false
        },
        pleaseWait: {
            value: isLoading
        },
        carLossDetails: {
            visible: isAutoLOB(fnolVM.lob.value),
            handleValueChange: handleValueChange,
            value: fnolVM
        },
        motorLossDetails: {
            visible: isMotorLOB(fnolVM.lob.value),
            handleValueChange: handleValueChange,
            value: fnolVM
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleValueChange,
            onValidate: setComponentValidation,
        },
        resolveComponentMap: {
            address: FNOLAddress,
            pleaseWait: PleaseWait,
            carLossDetails: LossDetailsCar,
            motorLossDetails: LossDetailsMotor
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={fnolVM}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={setComponentValidation}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default LossDetailsVehicle;
