import { defineMessages } from 'react-intl';

export default defineMessages({
    wizardFNOLBasicInformationTitle: {
        id: 'anonymous.wizard.fnol.pages.Basic Information',
        defaultMessage: 'Basic information'
    },
    wizardFNOLBasicInformationPolicyNumber: {
        id: 'anonymous.wizard.fnol.pages.policy-number.Policy Number',
        defaultMessage: 'Your Policy Number: {polNum}'
    },
    wizardFNOLBasicInformationDeclarationLabel: {
        id: 'anonymous.wizard.fnol.pages.basic-information.declaration.What do you wish to submit a declaration for?',
        defaultMessage: 'What do you wish to submit a declaration for?'
    },
    wizardFNOLBasicInformationLossCause: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Loss Cause',
        defaultMessage: 'Circumstances of your damage'
    },
    wizardFNOLBasicInformationWhatHappend: {
        id: 'anonymous.wizard.fnol.pages.basic-information.What happened?',
        defaultMessage: 'What happened? (Maximum of 1333 characters)'
    },
    wizardFNOLBasicInformationWhatHappendValidationMessage: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Please note that only a maximum of 1333 characters is allowed in the field "What happened?". If needed, you can still upload later on  an extensive description of the circumstances in attachment of your notification.',
        defaultMessage: 'Please note that only a maximum of 1333 characters is allowed in the field "What happened?". If needed, you can still upload later on  an extensive description of the circumstances in attachment of your notification.'
    },
    wizardFNOLBasicInformationWhenDidItHappen: {
        id: 'anonymous.wizard.fnol.pages.basic-information.When did it happen?',
        defaultMessage: 'When did it happen?'
    },
    wizardFNOLBasicInformationWhatHappendPlaceholder: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Describe the circumstances and the damage in your own words',
        defaultMessage: 'Describe the circumstances and the damage in your own words'
    },
    wizardFNOLBasicInformationAddressLabel: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Where did it happen?',
        defaultMessage: 'Where did it happen? If unknown, please introduce the location where you were when seeing for the first time the damage'
    },
    wizardFNOLBasicInformationHelpAreaTooltip: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Please decide in which area do you need our help.',
        defaultMessage: 'Please select the kind of incident you want to introduce.'
    },
    wizardFNOLBasicInformationQuestionTooltip: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Please mark one of the options to go further.',
        defaultMessage: 'Please mark one of the options to go further.'
    },
    wizardFNOLBasicInformationLossCauseTooltip: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Please pick one Loss Cause from the list.',
        defaultMessage: 'Please select one circumstance from the list.'
    },
    wizardFNOLBasicInformationDamageTypeTooltip: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Please pick one or more Damage Types which you would like to submit a declaration for.',
        defaultMessage: 'Please select one or more damage descriptions for which you would like to enter a claim'
    },
    wizardFNOLBasicInformationWhatHappendTooltip: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Please write short description of damage in your own words.',
        defaultMessage: 'Please write a short description of what happened. Avoid sharing medical details.'
    },
    wizardFNOLBasicInformationContactCallCenterRedirectTitle: {
        id: 'anonymous.wizard.fnol.pages.basic-information.We are not able to process your claim online.',
        defaultMessage: 'Due to the urgency of your incident, we would like to help you with this quickly.'
    },
    wizardFNOLBasicInformationContactCallCenterRedirectBody: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Based on the answers that were provided, we are unable to process you claim online. Please reach as via call to +32 2 550 06.',
        defaultMessage: 'Please call us on following number:'
    },
    wizardFNOLBasicInformationBuildingDamage: {
        id: 'anonymous.wizard.fnol.pages.basic-information.There only building damage and damage to the garden?',
        defaultMessage: 'There only building damage and damage to the garden?'
    },
    wizardFNOLBasicInformationDamageTypeOtherDescription: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Specify*',
        defaultMessage: 'Specify*'
    },
    wizardFNOLBasicInformationAuthenticatedAddressSelectorOnPolicy: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Address on policy',
        defaultMessage: 'Address on policy'
    },
    wizardFNOLBasicInformationAuthenticatedAddressSelectorOther: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Other location',
        defaultMessage: 'Other location'
    },
    wizardFNOLBasicInformationOtherLossLocationType: {
        id: 'anonymous.wizard.fnol.pages.basic-information.Type of Location',
        defaultMessage: 'Type of Location'
    }
});
