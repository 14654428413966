/* eslint-disable import/prefer-default-export */
export function carGlassURL() {
    return 'https://www.carglass.be/';
}

export function maxTotalFileSizeBytes() {
    return 20 * 1024 * 1024; // 20 MB
}

export function maxTotalFilesCount() {
    return 25;
}

export function minDriverAge() {
    return 18;
}

export function oldestCarYearPossible() {
    return 1910;
}

export function dateSeparator() {
    return '/';
}
