function daysInMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
}

function today(increment = 0) {
    const date = new Date();
    if (increment) {
        date.setDate(date.getDate() + increment);
    }
    date.setHours(0, 0, 0, 0);
    return {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate()
    };
}

function compareDateWithTime(date1, date2) {
    const date1NoTime = new Date(date1);
    const date2NoTime = new Date(date2);

    if (date1NoTime > date2NoTime) {
        return 1;
    }
    if (date1NoTime < date2NoTime) {
        return -1;
    }
    return 0;
}

function toDate(localDateTime) {
    const hour = localDateTime.hour || 0;
    const minute = localDateTime.minute || 0;

    if (localDateTime) {
        return new Date(localDateTime.year, localDateTime.month, localDateTime.day, hour, minute);
    }
    return null;
}

/**
 * This class is used in conjunction with the Expression Language only. Do not call directly.
 */
export default {
    daysInMonth: daysInMonth,
    daysInLocalDate: (ld) => daysInMonth(ld.year, ld.month),
    toMidnightDate: (localDate) => {
        if (localDate) {
            return new Date(localDate.year, localDate.month, localDate.day);
        }
        return null;
    },
    today,
    compareDateWithTime,
    toDate
};
