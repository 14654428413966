import INGPartnerValuesProvider from './ing/INGPartnerValuesProvider';
import DefaultPartnerValuesProvider from './default/DefaultPartnerValuesProvider';
import CardoenPartnerValuesProvider from './cardoen/CardoenPartnerValuesProvider';

export default function getPartnerValuesProvider(partnerCode) {
    switch (partnerCode) {
        case 'ING':
            return new INGPartnerValuesProvider();
        case 'CARDOEN':
            return new CardoenPartnerValuesProvider();
        default:
            return new DefaultPartnerValuesProvider();
    }
}
