import { defineMessages } from 'react-intl';

export default defineMessages({
    eBikeRoomWasLocked: {
        id: 'anonymous.wizard.fnol.components.summary.ebike.Room was locked',
        defaultMessage: 'Room was locked'
    },
    eBikeRoomWasNotLocked: {
        id: 'anonymous.wizard.fnol.components.summary.ebike.Room was not locked',
        defaultMessage: 'Room was not locked'
    },
    eBikePlacedThereSince: {
        id: 'anonymous.wizard.fnol.components.summary.ebike.Placed there since',
        defaultMessage: 'Placed there since:'
    }
});
