import { defineMessages } from 'react-intl';

export default defineMessages({
    aboutTheVehicle: {
        id: 'anonymous.wizard.fnol.pages.loss-details.About the vehicle',
        defaultMessage: 'About the involved vehicle'
    },
    wizardFnolLossDetailsPageTitle: {
        id: 'anonymous.wizard.fnol.pages.loss-details.Below information can be found on the registration certificate.',
        defaultMessage: 'You can find the below information on the registration certificate of your vehicle.'
    },
    vehicleMakeInput: {
        id: 'anonymous.vehicleDamage.vehicle.make',
        defaultMessage: 'Make'
    },
    vehicleModelInput: {
        id: 'anonymous.fnol.components.lossDetails.vehicleModelInput',
        defaultMessage: 'Model'
    },
    vehicleYearInput: {
        id: 'anonymous.fnol.components.lossDetails.Year',
        defaultMessage: 'Manufacturing year'
    },
    vehicleYearInputPlaceholder: {
        id: 'anonymous.fnol.components.lossDetails.Choose Vehicle Year',
        defaultMessage: 'Select the manufacturing year of the vehicle'
    },
    vehicleLicensePlateInput: {
        id: 'anonymous.fnol.components.lossDetails.License Plate Number',
        defaultMessage: 'License plate number'
    },
    vehicleVinInput: {
        id: 'anonymous.fnol.components.lossDetails.VIN',
        defaultMessage: 'Chassis number'
    },
    aboutTheDriver: {
        id: 'anonymous.wizard.fnol.pages.loss-details.About the driver',
        defaultMessage: 'The involved driver'
    },
    whoWasTheDriverInput: {
        id: 'anonymous.fnol.components.lossDetails.Who was the driver?',
        defaultMessage: 'Who was the driver?'
    },
    wasTheDriverABOB: {
        id: 'anonymous.fnol.components.lossDetails.Was the driver a BOB?',
        defaultMessage: 'Was the driver a BOB?'
    },
    wasTheDriverACarSharer: {
        id: 'anonymous.fnol.components.lossDetails.Was the driver a Car Sharer?',
        defaultMessage: 'Was the driver a Car Sharer?'
    },
    wasTheDriverInjured: {
        id: 'anonymous.fnol.components.lossDetails.Was the driver injured?',
        defaultMessage: 'Was the driver injured?'
    },
    otherFirstName: {
        id: 'anonymous.fnol.components.lossDetails.First Name',
        defaultMessage: 'First name'
    },
    otherLastName: {
        id: 'anonymous.fnol.components.lossDetails.Last Name',
        defaultMessage: 'Last name'
    },
    otherDateOfBirth: {
        id: 'anonymous.fnol.components.lossDetails.Date of birth',
        defaultMessage: 'Date of birth'
    },
    otherDateOfBirthErrTooYoung: {
        id: 'anonymous.fnol.components.lossDetails.Driver must be or older',
        defaultMessage: 'Driver must be {minimumAge} or older'
    },
    driverGender: {
        id: 'anonymous.fnol.components.lossDetails.Gender',
        defaultMessage: 'Gender'
    },
    driverLanguage: {
        id: 'anonymous.fnol.components.lossDetails.Language',
        defaultMessage: 'Language'
    },
    driverPhoneNumber: {
        id: 'anonymous.fnol.components.lossDetails.Phone number',
        defaultMessage: 'Phone number'
    },
    driverEmail: {
        id: 'anonymous.fnol.components.lossDetails.Email address',
        defaultMessage: 'Email address'
    }
});
