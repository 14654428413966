/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { ImageComponent } from 'gw-components-platform-react';
import styles from './DefaultPartnerValuesProvider.module.scss';
import messages from './DefaultPartnerValuesProvider.messages';
import DefaultHeader from './DefaultHeader/DefaultHeader';

export default class DefaultPartnerValuesProvider {
    generateHeaderLogo = (translator, redirectionDetails) => {
        const { baseUrlForClient, state } = redirectionDetails;
        return (
            <div>
                <Link
                    to={{ pathname: baseUrlForClient, state: state }}
                >
                    <ImageComponent src="/nnLogo2.png" className={styles.nnLogoStyle} />
                </Link>
            </div>
        );
    }

    generateHeader = () => {
        return <DefaultHeader/>
    }

    generateFooter = (translator) => {
        return <div>
            <p className={styles.footerHeaderTextStyle}>{translator(messages.fnolAnonymousFooterInsurer)}</p>
            <p className={styles.spanTextStyle}>{translator(messages.fnolAnonymousFooterCompanyDesc)}</p>
        </div>
    }

    partnerPhoneNumber = (translator) => {
        return translator(messages.defaultPartnerPhoneNumber);
    }

    partnerUrgentAssistLabel = (translator) => {
        return translator(messages.defaultPartnerUrgentHelpLabel, { partnerPhoneNumber: this.partnerPhoneNumber(translator) });
    }

    partnerShortLabelWithPhoneNumber = (translator) => {
        return translator(messages.defaultPartnerShortHelpWithNumber, { partnerPhoneNumber: this.partnerPhoneNumber(translator) });
    }

    partnerTypeAllowedForPVBroker = () => {
        return true;
    }
}
