/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, {
    useRef,
    useCallback,
    useMemo,
    useEffect,
    useContext,
    useState
} from 'react';
import isSameSecond from 'date-fns/isSameSecond';
import isEqual from 'date-fns/isEqual';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import {
    DATA_TYPE_OBJECT,
    DATA_TYPE_DATE_TIME,
} from '@jutro/prop-types';
import { ServiceManager } from '@jutro/services';
import { TranslatorContext } from '@jutro/locale';
import CustomHeader from './CustomHeader';

const popperModifiers = {
    preventOverflow: {
        enabled: true
    },
    flip: {
        enabled: false
    }
};

function DatePickerInternal(props) {
    const {
        id,
        placeholder,
        required,
        disabled,
        value,
        countryFormat,
        autoComplete,
        onValueChange,
        onBlur,
        className,
        dataType,
        todayButtonText,
        maxDate,
        minDate,
        popperPlacement,
        disabledKeyboardNavigation,
        formatDate,
        getFnsLocale
    } = props;

    const datePickerRef = useRef();
    const translator = useContext(TranslatorContext);

    const localeService = ServiceManager.getService('locale');
    const locale = localeService.getStoredLocale();
    const fnsLocale = useMemo(() => getFnsLocale(locale), [locale, getFnsLocale]);

    const [currentValue, setCurrentValue] = useState(null);
    const [initialValue, setInitialValue] = useState(value);

    const adjustDate = useCallback((date) => {
        if (isAfter(minDate, date)) {
            return minDate;
        }
        if (isBefore(maxDate, date)) {
            return maxDate;
        }
        return date;
    }, [maxDate, minDate]);

    const parseDate = (dateString, formatString, dateLocale) => {
        return parse(dateString, formatString, new Date(), { locale: dateLocale });
    };

    const valueToDate = useCallback((val) => {
        let dateValue = val;
        if (_.isString(val)) {
            dateValue = new Date(val);
        } else if (_.isNumber(val)) {
            dateValue = new Date(val);
        } else if (val && val.year != null) {
            const {
                year,
                month,
                day
            } = val;
            dateValue = new Date(year, month, day);
        }
        return dateValue;
    }, []);

    const minDateObject = useMemo(() => {
        return _.isNil(minDate) ? new Date(1900, 0) : valueToDate(minDate);
    }, [minDate, valueToDate]);

    const maxDateObject = useMemo(() => {
        return _.isNil(maxDate) ? new Date(2100, 0) : valueToDate(maxDate);
    }, [maxDate, valueToDate]);

    const isInRange = useCallback(
        (date) => {
            const meetsMinRequirement = isEqual(date, minDateObject)
                || isAfter(date, minDateObject);
            const meetsMaxRequirement = isEqual(date, maxDateObject)
                || isBefore(date, maxDateObject);

            return meetsMinRequirement && meetsMaxRequirement;
        }, [minDateObject, maxDateObject]
    );

    const dateValue = useMemo(() => valueToDate(initialValue), [initialValue, valueToDate]);

    useEffect(() => {
        registerLocale(locale, fnsLocale);
    }, [locale, fnsLocale]);

    useEffect(() => {
        if (value !== initialValue) {
            const date = valueToDate(value);
            const initialDate = valueToDate(initialValue);
            if (!isSameSecond(initialDate, date)) {
                setInitialValue(value);
                setCurrentValue(null);
            }
        }
    }, [initialValue, value, valueToDate]);

    const renderCustomHeader = useCallback((headerProps) => {
        const minYear = minDateObject.getUTCFullYear();
        const maxYear = maxDateObject.getUTCFullYear();
        return (
            <CustomHeader
                {...headerProps}
                locale={fnsLocale}
                minYear={minYear}
                maxYear={maxYear}
            />
        );
    }, [minDateObject, maxDateObject, fnsLocale]);

    const formatValue = useCallback((date) => {
        if (!date) return undefined;

        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        if (dataType === DATA_TYPE_OBJECT) {
            return {
                year,
                month,
                day
            };
        }
        const dateString = new Date(Date.UTC(year, month, day)).toISOString();
        return dataType === DATA_TYPE_DATE_TIME
            ? dateString
            : dateString.substr(0, 10);
    }, [dataType]);

    const handleUpdate = useCallback((date) => {
        const isEmpty = _.isNil(date);
        const adjustedDate = adjustDate(date, minDateObject, maxDateObject);
        const isValidDate = !isEmpty && isValid(adjustedDate) && isInRange(adjustedDate);
        const validDate = isValidDate ? adjustedDate : undefined;
        const val = validDate ? formatValue(validDate) : validDate;
        setInitialValue(val);
        onValueChange(val, isValidDate, isEmpty);
    }, [minDateObject, maxDateObject, isInRange, formatValue, onValueChange, adjustDate]);

    // Triggered on selecting date from calendar
    const handleChange = useCallback((date, evt) => {
        if (evt === false) {
            // this is the case when we call datePickerRef.current.setSelected
            // we only want to update month and day dropdowns but not values itself
            return;
        }
        setCurrentValue(null);
        handleUpdate(date);
    }, [handleUpdate]);

    // Triggered on manual entering of date
    const handleRawChange = useCallback((evt) => {
        const val = evt.target.value;
        setCurrentValue(val);
        let parsedDate = null;
        if (!_.isEmpty(val)) {
            parsedDate = parseDate(val, countryFormat, fnsLocale);
            if (isValid(parsedDate)) {
                datePickerRef.current.setSelected(parsedDate, false);
            }
        }
        handleUpdate(parsedDate);
        evt.preventDefault();
    }, [handleUpdate, countryFormat, fnsLocale]);

    const handleBlur = useCallback((evt) => {
        if (isValid(dateValue)) {
            const formattedDate = formatDate(dateValue, countryFormat, fnsLocale);
            if (currentValue !== formattedDate) {
                setCurrentValue(formattedDate);
            }
        }
        if (_.isFunction(onBlur)) {
            return onBlur(evt);
        }
        return true;
    }, [dateValue, onBlur, formatDate, countryFormat, fnsLocale, currentValue]);

    return (
        <ReactDatePicker
            id={id}
            ref={datePickerRef}
            showTimeSelect={false}
            locale={locale}
            dateFormat={countryFormat}
            minDate={minDateObject}
            maxDate={maxDateObject}
            selected={dateValue}
            value={currentValue}
            isClearable={false}
            required={required}
            disabled={disabled}
            placeholderText={placeholder}
            autoComplete={autoComplete ? 'on' : 'off'}
            onBlur={handleBlur}
            onChange={handleChange}
            onChangeRaw={handleRawChange}
            popperModifiers={popperModifiers}
            popperPlacement={popperPlacement}
            todayButton={translator(todayButtonText)}
            className={className}
            renderCustomHeader={renderCustomHeader}
            disabledKeyboardNavigation={disabledKeyboardNavigation}
        />
    );
}

export default DatePickerInternal;
