import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FacebookService } from 'gw-portals-facebook-js';
import { useErrors, withErrorBoundary } from 'gw-portals-error-react';

function FacebookPageComponent(props) {
    const { config, id } = props;
    const { throwReactError } = useErrors();

    const {
        facebookHandle, showSmallHeader, hideCoverPhoto, showFacepile, tabs
    } = config;

    useEffect(() => {
        let configForFacebook;
        FacebookService.getClient().then((fbClient, fbConfig) => {
            fbClient.XFBML.parse();
            configForFacebook = fbConfig;
        }).catch(throwReactError);

        return function cleanup() {
            FacebookService.removeClient(configForFacebook);
        };
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            id={id}
            className="fb-page"
            data-href={`https://www.facebook.com/${facebookHandle}`}
            data-adapt-container-width="true"
            data-small-header={showSmallHeader}
            data-hide-cover={hideCoverPhoto}
            data-show-facepile={showFacepile}
            data-tabs={tabs}
        >
            <blockquote
                cite={`https://www.facebook.com/${facebookHandle}`}
                className="fb-xfbml-parse-ignore"
            >
                <a href={`https://www.facebook.com/${facebookHandle}`}>{facebookHandle}</a>
            </blockquote>
        </div>
    );
}

FacebookPageComponent.propTypes = {
    id: PropTypes.string,
    config: PropTypes.shape({
        facebookHandle: PropTypes.string,
        hideCoverPhoto: PropTypes.bool,
        showFacepile: PropTypes.bool,
        showSmallHeader: PropTypes.bool,
        tabs: PropTypes.string
    }).isRequired
};

FacebookPageComponent.defaultProps = {
    id: undefined
};

export default withErrorBoundary({ resetError: false })(FacebookPageComponent);
