/* eslint-disable max-len */
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useContext, useState } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { CheckboxField } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import moment from 'moment';
import getAvailableYears from '../../../utils/VehicleUtils';
import {
    isEBikeTotalOrAttemptedTheft,
    isEBikeVandalismOrTotalAttemptedTheft
} from '../../../utils/DamageTypesUtil';
import useTypelistHandler from '../../../hooks/useTypelistHandler';
import styles from './LossDetailsBike.module.scss';
import metadata from './LossDetailsBike.metadata.json5';
// eslint-disable-next-line no-unused-vars
import messages from './LossDetailsBike.messages';

function LossDetailsBike(props) {
    const { getValuesFromTypelistIncludeFilter } = useTypelistHandler();
    const translator = useContext(TranslatorContext);
    const [enclosedCommonAreaSelection, setEnclosedCommonAreaSelection] = useState();
    const {
        id,
        writeValue,
        value: fnolVM,
        onValidate
    } = props;

    const fnolDTO = fnolVM.value;
    if (!fnolDTO.bikeDetailsDamages) {
        fnolDTO.bikeDetailsDamages = {};
    }

    if (!fnolDTO.bikeDetailsDamages.cyclist) {
        fnolDTO.bikeDetailsDamages.cyclist = {};
    }

    const {
        onValidate: setComponentValidation,
        registerComponentValidation,
        isComponentValid
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const validationBikeDamages = useCallback(() => {
        const bikeDetailsDTO = fnolVM.value.bikeDetailsDamages;
        if (!bikeDetailsDTO) {
            return false;
        }

        return !!bikeDetailsDTO.frontWheelDamaged
            || !!bikeDetailsDTO.backWheelDamaged
            || !!bikeDetailsDTO.handlebarsDamaged
            || !!bikeDetailsDTO.frameDamaged
            || !!bikeDetailsDTO.chainDamaged
            || !!bikeDetailsDTO.brakesDamaged
            || !!bikeDetailsDTO.pedalsDamaged
            || !!bikeDetailsDTO.totalLossTheftDamage
            || !!bikeDetailsDTO.otherPartDamaged
            || !!bikeDetailsDTO.noVisibleDamages;
    }, [fnolVM.value.bikeDetailsDamages]);

    const shouldShowDamagesContainer = useCallback(() => {
        const bikeDetailsValue = fnolVM.value.bikeDetailsDamages;
        const { whoWasTheCyclist } = bikeDetailsValue;

        switch (whoWasTheCyclist) {
            case 'i_was_cyclist':
                return bikeDetailsValue.wasCyclistInjured !== undefined;
            case 'no_cyclist':
                return true;
            case 'other_cyclist':
                return !_.isEmpty(bikeDetailsValue.cyclist.firstName)
                    && !_.isEmpty(bikeDetailsValue.cyclist.lastName)
                    && !_.isEmpty(bikeDetailsValue.cyclist.dateOfBirth)
                    && bikeDetailsValue.wasCyclistInjured !== undefined;
            default:
                return false;
        }
    }, [fnolVM.value.bikeDetailsDamages]);

    const availableEnclosedCommonAreaTypes = useMemo(() => {
        return [
            {
                code: 'garage',
                name: translator(messages.locatedInEnclosedCommonAreaGarage)
            },
            {
                code: 'basement',
                name: translator(messages.locatedInEnclosedCommonAreaBasement)
            },
            {
                code: 'other',
                name: translator(messages.locatedInEnclosedCommonAreaOther)
            }
        ];
    }, [translator]);

    const modelYearFilledIn = useCallback(() => {
        return !_.isEmpty(fnolVM.value.bikeDetailsDamages.make)
            && fnolVM.value.bikeDetailsDamages.year !== undefined;
    }, [fnolVM.value.bikeDetailsDamages.make, fnolVM.value.bikeDetailsDamages.year]);

    const shouldShowTheftDetailsContainer = useCallback(() => {
        return isEBikeVandalismOrTotalAttemptedTheft(fnolVM.value.frontEndDamageTypes) && modelYearFilledIn();
    }, [fnolVM.value.frontEndDamageTypes, modelYearFilledIn]);

    const shouldShowSecurityMeasuresContainer = useCallback(() => {
        return isEBikeTotalOrAttemptedTheft(fnolVM.value.frontEndDamageTypes) && !_.isEmpty(fnolVM.value.bikeDetailsDamages.sinceWhenWasBikeStoredThere);
    }, [fnolVM.value.bikeDetailsDamages.sinceWhenWasBikeStoredThere, fnolVM.value.frontEndDamageTypes]);

    const shouldShowInvolvedCyclistContainer = useCallback(() => {
        if (!isEBikeVandalismOrTotalAttemptedTheft(fnolVM.value.frontEndDamageTypes)) {
            return modelYearFilledIn();
        }

        if (isEBikeTotalOrAttemptedTheft(fnolVM.value.frontEndDamageTypes)) {
            return fnolVM.value.bikeDetailsDamages.forseenAGPSTracker !== undefined;
        }

        return !_.isEmpty(fnolVM.value.bikeDetailsDamages.sinceWhenWasBikeStoredThere);
    }, [fnolVM.value.bikeDetailsDamages.forseenAGPSTracker,
        fnolVM.value.bikeDetailsDamages.sinceWhenWasBikeStoredThere,
        fnolVM.value.frontEndDamageTypes, modelYearFilledIn]);

    useEffect(() => {
        registerComponentValidation(validationBikeDamages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValueChange = useCallback((value, changedPath) => {
        const newValue = _.clone(fnolDTO.bikeDetailsDamages);
        if (!fnolDTO.bikeDetailsDamages.haveChanged) {
            _.set(newValue, 'haveChanged', true);
        }

        if (changedPath === 'enclosedAreaType') {
            setEnclosedCommonAreaSelection(value);
            if (value === 'other') {
                _.set(newValue, 'locationOther', '');
            } else {
                const relatedTranslation = availableEnclosedCommonAreaTypes.find((entry) => entry.code === value);
                _.set(newValue, 'locationOther', relatedTranslation.name);
            }
        }

        if (changedPath === 'bikeLocation') {
            _.set(newValue, 'locationOther', '');

            if (value !== 'enclosedCommonArea') {
                setEnclosedCommonAreaSelection('garage');
            }
        }
        _.set(newValue, changedPath, value);

        writeValue(newValue, 'bikeDetailsDamages');
    }, [setEnclosedCommonAreaSelection, availableEnclosedCommonAreaTypes, fnolDTO.bikeDetailsDamages, writeValue]);

    const overrides = {
        bikeYearInput: {
            availableValues: getAvailableYears()
        },
        bikeTheftDetailsContainer: {
            visible: shouldShowTheftDetailsContainer()
        },
        bikeTheftSecurityMeasuresDetailsContainer: {
            visible: shouldShowSecurityMeasuresContainer()
        },
        involvedCyclistContainer: {
            visible: shouldShowInvolvedCyclistContainer()
        },
        bikeDamagesContainer: {
            visible: shouldShowDamagesContainer()
        },
        whereWasBikeLocated: {
            availableValues: getValuesFromTypelistIncludeFilter('com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.BikeDamageDTO', 'bikeLocation')
        },
        locatedInPrivateRoom_WasRoomLocked: {
            visible: fnolVM.value.bikeDetailsDamages.bikeLocation === 'privateRoom'
        },
        locatedInEnclosedCommonArea_SelectArea: {
            value: enclosedCommonAreaSelection,
            visible: fnolVM.value.bikeDetailsDamages.bikeLocation === 'enclosedCommonArea',
            availableValues: availableEnclosedCommonAreaTypes
        },
        locationSpecifyOtherField: {
            visible: fnolVM.value.bikeDetailsDamages.bikeLocation === 'publicAccessibleSpace'
                || enclosedCommonAreaSelection === 'other'
        },
        securityMeasures_RequiredLockNotUsedExplanation: {
            visible: fnolVM.value.bikeDetailsDamages.requiredLockUsed === false
        },
        securityMeasures_DoYouHaveBothLockKeys: {
            visible: fnolVM.value.bikeDetailsDamages.requiredLockUsed !== undefined
        },
        securityMeasures_WasAFixedPointsUsed: {
            visible: fnolVM.value.bikeDetailsDamages.doYouHaveBothKeysOfTheLock !== undefined
        },
        securityMeasures_ForseenAGPSTracker: {
            visible: fnolVM.value.bikeDetailsDamages.wasAFixedPointUsed !== undefined
        },
        otherPartsDamagedDescription: {
            visible: !!fnolVM.value.bikeDetailsDamages.otherPartDamaged
        },
        otherDamagesOtherDamageDescription: {
            visible: !!fnolVM.value.bikeDetailsDamages.otherDamagesOther
        },
        whoWasTheCyclistInput: {
            availableValues: getValuesFromTypelistIncludeFilter('com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.BikeDamageDTO', 'whoWasTheCyclist', 'bicycle_flow_values'),
            value: fnolVM.value.bikeDetailsDamages.whoWasTheCyclist
        },
        otherCyclistContainer: {
            visible: fnolVM.value.bikeDetailsDamages.whoWasTheCyclist === 'other_cyclist'
        },
        otherDateOfBirth: {
            maxDate: moment().toDate()
        },
        wasTheCyclistInjured: {
            visible: fnolVM.value.bikeDetailsDamages.whoWasTheCyclist !== 'no_cyclist'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
            checkboxField: CheckboxField
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={fnolVM.bikeDetailsDamages}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={setComponentValidation}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default LossDetailsBike;
