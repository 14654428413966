import { useContext, useCallback } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import ClientContext from '../../contexts/ClientContext/ClientContext';
import styles from './FNOLConfirmationPage.module.scss';
import metadata from './FNOLConfirmationPage.metadata.json5';
import messages from './FNOLConfirmationPage.messages';

function FNOLConfirmationPage(props) {
    const translator = useContext(TranslatorContext);
    const {
        fnolDTO
    } = props.location.state;
    const { history } = props;
    const { claimNumber } = fnolDTO;

    const handleBackToHome = useCallback(() => {
        return history.push('/');
    }, [history]);

    const clientContext = useContext(ClientContext);
    const isBrokerContext = clientContext.isBrokerContext();
    const redirectionDetails = clientContext.getRedirectionDetails();
    const { baseUrlForClient, state } = redirectionDetails;

    const overrides = {
        claimReferenceNumber: {
            visible: !!claimNumber,
            content: translator(messages.referenceNumber, { num: claimNumber })
        },
        brokerRegisterAnotherClaimContainer: {
            visible: isBrokerContext
        },
        brokerRegisterAnotherClaim: {
            onClick: () => history.push({ pathname: baseUrlForClient, state: state })
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleBackToHome: handleBackToHome
        },
        resolveComponentMap: {

        }
    };

    return renderContentFromMetadata(metadata.pageContent, overrides, resolvers);
}

export default withRouter(FNOLConfirmationPage);
