import LocationScheduleItem from './LocationScheduleItem/LocationScheduleItem';
import ContactScheduleItem from './ContactScheduleItem/ContactScheduleItem';
import SimpleScheduleItem from './SimpleScheduleItem/SimpleScheduleItem';

export default function generateSubComponents(props) {
    const { valueType } = props.info;

    switch (valueType) {
        case 'LOCATION':
            return LocationScheduleItem(props);
        case 'POLICYCONTACT':
            return ContactScheduleItem(props);
        default:
            return SimpleScheduleItem(props);
    }
}
