import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolContactCallCenterPageTitle: {
        id: 'anonymous.fnol.components.contact-call-center.Error occured while sending your responses.',
        defaultMessage: 'An error occurred while sending your input.'
    },
    fnolContactCallCenterSaveAndResume: {
        id: 'anonymous.fnol.components.contact-call-center.Be aware, your progress was securely stored and you can pick up right where you left to complete your notification within the next 2 days if you log in again.',
        defaultMessage: 'Be aware, your progress was securely stored and you can pick up right where you left to complete your notification within the next 2 days if you log in again.'
    },
    fnolContactCallCenterPageSubTitle: {
        id: 'anonymous.fnol.components.contact-call-center.Please try again later or call us directly. If you need urgent help call our Assist\'Line on',
        defaultMessage: 'Please try again later or call us directly. {partnerUrgentCallCenterMessage}'
    },
    fnolContactCallCenterPageUrgentCallNumber: {
        id: 'anonymous.fnol.components.contact-call-center.phone',
        defaultMessage: '{ phoneNumber }'
    },
    fnolContactCallCenterPageTryAgainButton: {
        id: 'anonymous.fnol.components.contact.Try again',
        defaultMessage: 'Try again'
    }
});
