import React, { useCallback, useEffect, useContext } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';

import metadata from './Contact.metadata.json5';
import styles from './Contact.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from './Contact.messages';

function Contact(props) {
    const {
        id,
        value: contactDto,
        onValueChange,
        onValidate,
        isContactTypeVisible,
        visible
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid
    } = useValidation(id);

    useEffect(() => {
        onValidate(isComponentValid, id);
    }, [id, isComponentValid, onValidate]);

    const translator = useContext(TranslatorContext);

    const viewModelService = useContext(ViewModelServiceContext);

    const getTypelistValues = useCallback((field, filterName) => {
        const typeList = viewModelService.create(
            {},
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.ContactDTO'
        );

        let result;
        if (!filterName) {
            result = typeList[field].aspects.availableValues[0].typelist.codes;
        } else {
            result = typeList[field].aspects.availableValues[0].typelist.filters
                .filter((f) => f.name === filterName)[0].codes;
        }
        return result.map((t) => {
            return {
                code: t.code,
                name: translator({
                    id: t.name,
                    defaultMessage: t.name
                })
            };
        });
    }, [translator, viewModelService]);

    const overrideProps = {
        contactTypeInput: {
            availableValues: getTypelistValues('contactType'),
            visible: isContactTypeVisible
        },
        companyNameInput: {
            visible: (contactDto.value.contactType === 'company')
        },
        firstNameInput: {
            visible: (contactDto.value.contactType === 'person')
        },
        lastNameInput: {
            visible: (contactDto.value.contactType === 'person')
        },
        genderInput: {
            availableValues: getTypelistValues('gender'),
            visible: (contactDto.value.contactType === 'person')
        },
        languageInput: {
            availableValues: getTypelistValues('languageType', 'FrontEndSelectable')
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    if (visible === 'false') {
        return (<div />);
    }

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={contactDto}
                overrideProps={overrideProps}
                onValueChange={onValueChange}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={setComponentValidation}
                componentMap={resolvers.resolveComponentMap}
            />
        </div>
    );
}

Contact.propTypes = {
    id: PropTypes.string.isRequired
};

export default Contact;
