import _ from 'lodash';
import React, { useCallback, useState, useContext } from 'react';
import { WizardPage, WizardPageTemplate, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { FNOLService } from 'nn-capability-fnol';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { isAutoLOB, isPropertyLOB, isMotorLOB, isBikeLOB } from '../../utils/PolicyTypeUtil';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import { getProgressBasedOnWizardStep } from '../../utils/WizardStepToProgressUtil';
import WizardFNOLAutoRepair from '../../components/repairComponents/WizardFNOLAutoRepair/WizardFNOLAutoRepair';
import WizardFNOLPropertyRepair from '../../components/repairComponents/WizardFNOLPropertyRepair/WizardFNOLPropertyRepair';
import WizardFNOLEBikeRepair from '../../components/repairComponents/WizardFNOLEBikeRepair/WizardFNOLEBikeRepair';
import styles from './WizardFNOLRepairPage.module.scss';
import metadata from './WizardFNOLRepairPage.metadata.json5';
import messages from './WizardFNOLRepairPage.messages';
import useSnapshotHandler from '../../hooks/useSnapshotHandler';
import WizardFNOLMotorRepair from '../../components/repairComponents/WizardFNOLMotorRepair/WizardFNOLMotorRepair';

function WizardFNOLRepairPage(props) {
    const [isLoading, setIsLoading] = useState(false);
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const {
        history,
        wizardData,
        updateWizardData,
        currentStepIndex,
        steps
    } = props;

    const {
        onValidate,
        isComponentValid
    } = useValidation('WizardFNOLRepairPage');

    const [resetValuesToSnapshotIfNeeded, prepareSnapshot] = useSnapshotHandler(steps,
        currentStepIndex, updateWizardData, 'repairDetailsSnapshot', history);

    const writeValue = useCallback((value, path) => {
        const {currentDTOValue} = resetValuesToSnapshotIfNeeded(wizardData, true, setIsLoading);
        if (currentDTOValue !== undefined) {
            _.set(currentDTOValue.value, path, value);
            if (!currentDTOValue.value.repairDetails.haveChanged) {
                _.set(currentDTOValue, 'repairDetails.haveChanged', true);
            }
            updateWizardData(currentDTOValue);
        }
    }, [wizardData, updateWizardData, resetValuesToSnapshotIfNeeded, setIsLoading]);

    const shouldPreselectPreferredVendor = useCallback(() => {
        if (isAutoLOB(wizardData.lob.value)) return true;
        return wizardData.supportiveValues.value
            ? wizardData.supportiveValues.hinAvailable.value
            : false;
    }, [wizardData.lob.value, wizardData.supportiveValues]);

    if (!wizardData.value.repairDetails) {
        const data = {
            estimatedRepairCost: {},
            repairFacilityAddress: {},
            haveChanged: false
        };
        _.set(data, 'repairOptionChoice', shouldPreselectPreferredVendor() ? 'PreferredVendor' : 'NewVendor');
        writeValue(data, 'repairDetails');
    }

    const overrides = {
        wizardFNOLRepairAutoComponent: {
            history: history,
            visible: isAutoLOB(wizardData.lob.value),
            writeValue: writeValue,
            wizardDTO: wizardData,
            resetValuesToSnapshotIfNeeded: resetValuesToSnapshotIfNeeded
        },
        wizardFNOLRepairMotorComponent: {
            history: history,
            visible: isMotorLOB(wizardData.lob.value),
            writeValue: writeValue,
            wizardDTO: wizardData,
            resetValuesToSnapshotIfNeeded: resetValuesToSnapshotIfNeeded
        },
        wizardFNOLRepairEBikeComponent: {
            visible: isBikeLOB(wizardData.lob.value),
            wizardDTO: wizardData,
            writeValue: writeValue
        },
        wizardFNOLRepairPropertyComponent: {
            visible: isPropertyLOB(wizardData.lob.value),
            wizardDTO: wizardData,
            writeValue: writeValue,
            showRepairInKind: shouldPreselectPreferredVendor()
        },
        wizardFNOLBasicInformationLoader: {
            visible: isLoading
        },
        wizardFnolRepairPageContainer: {
            visible: !isLoading
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            writeValue,
            onValidate,
            resetValuesToSnapshotIfNeeded
        },
        resolveComponentMap: {
            repairAutoComponent: WizardFNOLAutoRepair,
            repairPropertyComponent: WizardFNOLPropertyRepair,
            repairMotorComponent: WizardFNOLMotorRepair,
            repairEBikeComponent: WizardFNOLEBikeRepair
        }
    };

    const handleOnNextClick = useCallback(() => {
        setIsLoading(true);
        const valueToSend = wizardData.value;
        valueToSend.progress = getProgressBasedOnWizardStep(steps[currentStepIndex + 1].path);
        return FNOLService.updateClaim(valueToSend, history, { partnerContext, translator }).then(
            (responseDTO) => {
                if (!responseDTO) return;
                wizardData.value = responseDTO;
                prepareSnapshot(responseDTO);
                return wizardData;
            }
        ).finally(() => {
            setIsLoading(false);
        });
    }, [wizardData, steps, currentStepIndex, history, partnerContext,
        translator, prepareSnapshot]);

    const generateHeader = () => {
        return (
            <div>
                <h1>
                    {isBikeLOB(wizardData.lob.value)
                        ? translator(messages.wizardFnolEBikeRepairTitle)
                        : translator(messages.wizardFnolRepairTitle)}
                </h1>
                <h1>
                    {wizardData.value.claimNumber}
                </h1>
            </div>
        );
    };

    return (
        <WizardPage
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
            showCancel={false}
            onNext={handleOnNextClick}
            renderContextComponent={generateHeader}
        >
            <ViewModelForm
                model={wizardData}
                uiProps={metadata.pageContent}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                overrideProps={overrides}
                onValueChange={writeValue}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

WizardFNOLRepairPage.propTypes = wizardProps;
export default withRouter(WizardFNOLRepairPage);
