import { oldestCarYearPossible } from './Constants';

export default function getAvailableYears() {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= oldestCarYearPossible(); i -= 1) {
        years.push({
            code: i,
            name: i
        });
    }
    return years;
}
