import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolContactDetailsAccountSectionLabel: {
        id: 'anonymous.fnol.components.BankAccountNumberComponent.Please provide your Bank Account details below or leave it empty, if the Bank Account for claim handling is same as provided on your policy contract.',
        defaultMessage: 'Please provide your bank account details below. You can leave it empty if the bank account for claim handling is same as provided on your policy contract.'
    },
    fnolContactDetailsAccountSectionLabelBroker: {
        id: 'anonymous.fnol.components.BankAccountNumberComponent.Please provide bank details',
        defaultMessage: 'Please provide bank details'
    },
    fnolContactDetailsAccountName: {
        id: 'anonymous.fnol.components.BankAccountNumberComponent.Account Name',
        defaultMessage: 'Name of bank account holder'
    },
    fnolContactDetailsAccountNumber: {
        id: 'anonymous.fnol.components.BankAccountNumberComponent.IBAN(International Bank Account Number)',
        defaultMessage: 'IBAN (only needed when International Bank Account Number)'
    },
    fnolContactDetailsAccountNumberPlaceholder: {
        id: 'anonymous.fnol.components.BankAccountNumberComponent.BEXXXXXXXXXXXXXX',
        defaultMessage: 'BEXXXXXXXXXXXXXX'
    },
    fnolContactDetailsAccountNumberValidation: {
        id: 'anonymous.fnol.components.BankAccountNumberComponent.Provided account number is invalid',
        defaultMessage: 'Provided account number is invalid'
    },
    fnolContactDetailsBIC: {
        id: 'anonymous.fnol.components.BankAccountNumberComponent.BIC Code',
        defaultMessage: 'BIC code'
    },
    fnolContactDetailsBICSecondaryLabel: {
        id: 'anonymous.fnol.components.BankAccountNumberComponent.BIC Code is needed only for Bank Accounts outside of Belgium, please find your BIC code on your bank webpage.',
        defaultMessage: 'BIC Code is needed only for Bank Accounts outside of Belgium, please find your BIC code on your bank webpage.'
    }
});
