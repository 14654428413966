import { defineMessages } from 'react-intl';

export default defineMessages({
    wizardFnolAdditionalInformationPageTitle: {
        id: 'anonymous.wizard.fnol.pages.additional-information.Additional information.',
        defaultMessage: 'Additional information.'
    },
    documentValidationEstimateNotProvided: {
        id: 'anonymous.wizard.fnol.pages.additional-information.documentValidation.Estimate Not Provided',
        defaultMessage: "Since you confirmed on the previous screen that you have an estimate, please attach this document as document type 'estimate'"
    },
    ebikeTotalTheftEstimateNotProvided: {
        id: 'anonymous.wizard.fnol.pages.additional-information.documentValidation.EbikeTotalTheftEstimate',
        defaultMessage: 'The upload of following documents is mandatory: to be able to finish this notification: \n\n\t- The purchase invoice and, if present, the order form for the covered bike that has been damaged \n\t- Police report\n\nPlease upload also other documents that might help in bringing you the best service, and keep both keys of the lock disposable at our request.'
    },
    ebikeAttemptedTheftVandalismEstimateNotProvided: {
        id: 'anonymous.wizard.fnol.pages.additional-information.documentValidation.EbikeAttemptedTheftVandalismEstimate',
        defaultMessage: 'The upload of following documents is mandatory: to be able to finish this notification: \n\n\t- The purchase invoice and, if present, the order form for the covered bike that has been damaged \n\t- Photos of the damage \n\t- The estimate of the damage \n\t- Police report\n\nPlease upload also other documents that might help in bringing you the best service, such as: statement...'
    },
    ebikeOtherTheftEstimateNotProvided: {
        id: 'anonymous.wizard.fnol.pages.additional-information.documentValidation.EbikeOtherTheftEstimate',
        defaultMessage: 'The upload of following documents is mandatory: to be able to finish this notification: \n\n\t- The purchase invoice and, if present, the order form for the covered bike that has been damaged \n\t- The estimate of the damage \n\t- Police report\n\nPlease upload also other documents that might help in bringing you the best service, such as: statement...'
    },
    ebikeOtherThanTheftEstimateNotProvided: {
        id: 'anonymous.wizard.fnol.pages.additional-information.documentValidation.EbikeOtherThanTheftEstimate',
        defaultMessage: 'The upload of following documents is mandatory:  \n\n\t- The purchase invoice and, if present, the order form for the covered bike that has been damaged \n\t- The estimate of the damage \n\t- Photos of the damage\n\nPlease upload also other documents that might help in bringing you the best service, such as: police report, European Accident Statement...'
    },
    europeanAccidentStatementMissing: {
        id: 'anonymous.wizard.fnol.pages.additional-information.documentValidation.European Accident Statement Missing',
        defaultMessage: "Since you are referring for the identity of other involved parties to the European Accident Form, please attach this document as document type 'European Accident Statement'"
    },
    brokerPVDocumentMissing: {
        id: 'anonymous.wizard.fnol.pages.additional-information.documentValidation.Broker PV Document missing',
        defaultMessage: 'Broker PV is missing'
    }
});
