import React, { useCallback, useContext, useEffect } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { CheckboxField } from '@jutro/components';
import styles from './PropertyDamageDetail.module.scss';
import metadata from './PropertyDamageDetail.metadata.json5';

function PropertyDamageDetail(props) {
    const {
        id,
        onValueChange,
        path,
        data: propertyDamageDetailVM,
        onValidate,
        disabled
    } = props;

    const translator = useContext(TranslatorContext);

    const {
        onValidate: setComponentValidation,
        isComponentValid
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback((value, changedPath) => {
        propertyDamageDetailVM.haveChanged = true;
        onValueChange(value, changedPath);
    }, [onValueChange, propertyDamageDetailVM]);

    const translateTypeKey = useCallback((typeList, typeKey) => {
        return translator({
            id: `${typeList}.${typeKey}`,
            defaultMessage: typeKey
        });
    }, [translator]);

    const overrides = {
        isSelectedDetailCheckbox: {
            label: {
                id: 'propertyDamage.dmgPropertyLabel',
                defaultMessage: translateTypeKey('typekey.inlb_FrontEndDmgDetails', propertyDamageDetailVM.damageDetail)
            },
            disabled: disabled
        },
        otherDamagesDescripion: {
            visible: (propertyDamageDetailVM.isSelected && propertyDamageDetailVM.damageDetail === 'other')
        }

    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleValueChange,
            onValidate: setComponentValidation,
        },
        resolveComponentMap: {
            checkboxField: CheckboxField
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={propertyDamageDetailVM}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={setComponentValidation}
                onValueChange={handleValueChange}
            />
        </div>
    );
}


export default PropertyDamageDetail;
