import React, { Component } from 'react';
import { TranslatorContext } from '@jutro/locale';
import styles from './AutoCompleteSearchResults.module.scss';

class AutoCompleteSearchResults extends Component {
    render() {
        const { selectAddress, places } = this.props;
        return (
            <TranslatorContext.Consumer>
                {(translator) => {
                    return (
                        <div className={styles.dropdown}>
                            {places.map((place) => {
                                return (
                                    <div className={styles.placeList} key={place.id}>
                                        <div
                                            className={styles.place}
                                            onClick={selectAddress}
                                            onKeyPress={() => {}}
                                            role="button"
                                            tabIndex="0"
                                        >
                                            {place.description}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                }}
            </TranslatorContext.Consumer>
        );
    }
}

export default AutoCompleteSearchResults;
