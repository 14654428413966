/* eslint-disable */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { TranslatorContext } from '@jutro/locale';
import styles from './TextComponent.module.scss';

function TextComponent(props) {
    const { config, id } = props;
    const { sections } = config;
    const translator = useContext(TranslatorContext);

    return sections.map(({ title, content, link }) => {
        const { url, name } = link;
        return (
            <div key={title} className={styles.gwMediaSidebarTextContainer} id={id}>
                <h4>{translator(title)}</h4>
                <p>{translator(content)}</p>
                <div>
                    <a target="_blank" rel="noopener noreferrer" href={`${url}`}>
                        {translator(name)}
                    </a>
                </div>
            </div>
        );
    });
}

TextComponent.propTypes = {
    id: PropTypes.string,
    config: PropTypes.shape({
        sections: PropTypes.array
    }).isRequired
};

TextComponent.defaultProps = {
    id: undefined
};

export default TextComponent;
