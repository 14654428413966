import _ from 'lodash';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import dayjs from 'dayjs';
import { RadioButtonField } from '@jutro/components';
import { TimePicker } from 'antd';
import { TranslatorContext } from '@jutro/locale';
import styles from './CustomTimePicker.module.scss';

function CustomTimePicker(props) {
    const {
        value: lossDateDTO,
        onValueChange,
        label,
        showTimeStampQuestion,
        timeStampLabel,
        timeStampVisibleValue,
        setTimeStampVisibleValue
    } = props;
    const translator = useContext(TranslatorContext);

    const [value, setValue] = useState(lossDateDTO && lossDateDTO.hour
        ? dayjs().hour(lossDateDTO.hour).minute(lossDateDTO.minute)
        : undefined);

    const handleValueChange = useCallback((newValue) => {
        const dateTimeObject = lossDateDTO || {};

        dateTimeObject.hour = newValue.$H;
        dateTimeObject.minute = newValue.$m;
        onValueChange(dateTimeObject, 'lossDate');
        setValue(newValue);
    }, [lossDateDTO, onValueChange]);

    useEffect(() => {
        if (lossDateDTO
                && lossDateDTO.day
                && !lossDateDTO.hour
                && !_.isEmpty(value)
        ) {
            handleValueChange(value);
        }
    }, [handleValueChange, lossDateDTO, value]);

    return (
        <div className={styles.mainDivStyle}>
            {(showTimeStampQuestion && lossDateDTO && lossDateDTO.year) && (
                <RadioButtonField
                    autoTrim={false}
                    availableValues={[
                        {
                            code: 'true',
                            name: {
                                id: 'fnol.ho.views.ho-fire-details.Yes',
                                defaultMessage: 'Yes'
                            }
                        },
                        {
                            code: 'false',
                            name: {
                                id: 'fnol.ho.views.ho-fire-details.No',
                                defaultMessage: 'No'
                            }
                        }
                    ]}
                    className={styles.timePicker}
                    dataType="string"
                    disabled={false}
                    flavor="tiles"
                    hideLabel={false}
                    id="radiobutton"
                    labelPosition="top"
                    label={translator(timeStampLabel)}
                    onValueChange={setTimeStampVisibleValue}
                    readOnly={false}
                    required={false}
                    value={timeStampVisibleValue}
                    schemaRequired={false}
                    showErrors={false}
                    showValidationIcon={false}
                />
            )}
            { (showTimeStampQuestion
                ? (timeStampVisibleValue === true && lossDateDTO && lossDateDTO.year)
                : lossDateDTO && lossDateDTO.year) && (
                <span>{translator(label)}</span>
            )}
            { (showTimeStampQuestion
                ? (timeStampVisibleValue === true && lossDateDTO && lossDateDTO.year)
                : lossDateDTO && lossDateDTO.year) && (
                <TimePicker
                    label={translator(label)}
                    placeholder="HH:mm"
                    popupClassName={styles.dropdownStyle}
                    className={styles.picker}
                    value={value}
                    showSecond={false}
                    minuteStep={5}
                    onChange={handleValueChange}
                    format="HH:mm"
                    visible={!!lossDateDTO}
                    n
                />
            )}
        </div>
    );
}

export default CustomTimePicker;
