import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useAuthentication } from './AuthenticationContext';

function PrivateRoute({
    children, component, render, loginPath, ...rest
}) {
    const { isLoggedIn } = useAuthentication();
    const renderedRoute = useCallback(
        ({ location }) => {
            if (isLoggedIn) {
                return (
                    <Route {...rest} component={component} render={render}>
                        {children}
                    </Route>
                );
            }
            const nextState = { pathname: loginPath, state: { from: location, loginPath } };
            return <Redirect to={nextState} />;
        },
        [children, component, isLoggedIn, loginPath, render, rest]
    );
    return <Route {...rest} render={renderedRoute} />;
}

PrivateRoute.propTypes = {
    children: PropTypes.node,
    loginPath: PropTypes.string,
    component: PropTypes.element,
    render: PropTypes.func
};
PrivateRoute.defaultProps = {
    children: null,
    loginPath: '/login-page',
    component: undefined,
    render: undefined
};

export default PrivateRoute;
