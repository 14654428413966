import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ImageComponent } from 'gw-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import styles from './VehicleDamagePicker.module.scss';
import carParts from './VehicleParts.json';

class VehicleDamagePickerMulti extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        allPossibleDamagePoints: PropTypes.array.isRequired,
        onValueChange: PropTypes.func.isRequired,
        path: PropTypes.string.isRequired,
        value: PropTypes.array.isRequired
    };

    state = {
        damagedParts: [],
        hovered: false,
        hoveredPart: null,
        selectedParts: [],
        damagedGroups: []
    };

    constructor(props) {
        super(props);
        const { value } = this.props;

        // recreate internal state from value
        if (value && value.length > 0) {
            const { allPossibleDamagePoints } = this.props;
            const valueSelectedParts = value.map((vel) => {
                return carParts.parts.find((cp) => vel.damagePoint === cp.group).part;
            });
            const valueDamagedGroups = value.map((vel) => { return vel.damagePoint; });

            const valueDamagedParts = value.map((vel) => {
                return allPossibleDamagePoints.find((el) => el.code === vel.damagePoint);
            });

            this.state = {
                selectedParts: valueSelectedParts,
                damagedGroups: valueDamagedGroups,
                damagedParts: valueDamagedParts
            };
        }
    }

    selectPart = (groupId, partID) => {
        const { allPossibleDamagePoints } = this.props;
        if (allPossibleDamagePoints) {
            if (groupId === '-1') {
                if (partID === 'windshield') {
                    return;
                }
                console.error('unmapped part: ', groupId, partID);
                return;
            }
            const partCode = allPossibleDamagePoints.find((el) => el.code === groupId);
            const {
                selectedParts: prevSelectedParts,
                damagedParts: prevDamagedParts,
                damagedGroups: prevDamagedGroups
            } = this.state;

            if (this.isSelectedGroup(groupId)) {
                this.offDamage(partCode, prevDamagedParts);
                return;
            }

            this.setState(
                {
                    selectedParts: prevSelectedParts.concat(partID),
                    damagedParts: prevDamagedParts.concat(partCode),
                    damagedGroups: prevDamagedGroups.concat(groupId)
                },
                () => {
                    this.onCallBack();
                }
            );
        }
    };

    isSelectedGroup = (group) => {
        const { damagedGroups } = this.state;
        const existsInDamageGroups = damagedGroups.find((el) => el === group);
        return existsInDamageGroups;
    };

    getClassName = (className) => {
        return cx(styles.gw_car_part, styles[className]);
    };

    getIcon = (part, group) => {
        const { hovered, hoveredPart, selectedParts } = this.state;
        const { value } = this.props;
        const index = _.findIndex(selectedParts, (el) => el === part);
        if (index !== -1) {
            return `/newclaimvehicle/damaged/damage_${part}_on.gif`;
        }
        if (hovered && hoveredPart === part) {
            return `/newclaimvehicle/onhover/damage_${part}_hover.gif`;
        }
        if (value && value.code === group) {
            return `/newclaimvehicle/damaged/damage_${part}_on.gif`;
        }
        return `/newclaimvehicle/good/damage_${part}_off.gif`;
    };

    onHover = (hovered, part) => {
        this.setState({ hovered, hoveredPart: part });
    };

    handleKeyDown = (event, group, part) => {
        if (event.keyCode === 'Enter') {
            this.selectPart(group, part);
        }
    };

    offDamage = (typeCode) => {
        const {
            selectedParts: currentSelectedParts,
            damagedParts: currentDamagedParts,
            damagedGroups: currentDamagedGroups
        } = this.state;
        const removeIdx = _.findIndex(currentDamagedParts, { code: typeCode.code });
        if (removeIdx !== -1) {
            currentSelectedParts.splice(removeIdx, 1);
            currentDamagedParts.splice(removeIdx, 1);
            currentDamagedGroups.splice(removeIdx, 1);
        }
        this.setState({
            damagedParts: currentDamagedParts,
            selectedParts: currentSelectedParts,
            damagedGroups: currentDamagedGroups
        }, () => {
            this.onCallBack();
        });
    };

    /*
        this resets the component's internal state (selected parts) when parent component
        changes props.value to null or []
    */
    componentDidUpdate(prevProps) {
        const { value } = this.props;
        const { damagedParts: currentDamagedParts } = this.state;
        if ((!value || value.length === 0)
            && prevProps.value && prevProps.value.length > 0
            && currentDamagedParts && currentDamagedParts.length > 0) {
            /*
                The whole component should probably be refactored to not rely on it's
                internal state, but on parent's state passsed as value in props
                directly (lift the state).
                This is to facilitate reset of component's state from parent.
                Other solution could be to assign key from parent and bump it when reset
                is needed.
                Another would be to pass a reset function from child to parent.
            */
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                damagedParts: [],
                selectedParts: [],
                damagedGroups: []
            }, () => { this.onCallBack(); });
        }
    }

    onCallBack = () => {
        const { damagedParts } = this.state;
        const { onValueChange, path } = this.props;
        const value = damagedParts.map((el) => { return { damagePoint: el.code }; });
        if (onValueChange) {
            onValueChange(value, path);
        }
    };

    render() {
        const vehicleImageClass = cx(styles.gw_car, styles.gw_damage_picker);
        return (
            <div className={styles.gwCarContainer}>
                <div className={vehicleImageClass}>
                    <ImageComponent
                        id="vehicleDamageDiagram"
                        src="/newclaimvehicle/damage_diagram.gif"
                        alt="damageVehiclePart"
                    />
                    {carParts.parts.map((carpart, index) => (
                        <span
                            id={`carParts${index}`}
                            key={carpart.part}
                            className={this.getClassName(carpart.class)}
                            tabIndex={index}
                            group={carpart.group}
                            part={carpart.part}
                            onMouseEnter={() => this.onHover(true, carpart.part)}
                            onMouseOut={() => this.onHover(false)}
                            onBlur={() => this.onHover(false)}
                            onClick={() => this.selectPart(carpart.group, carpart.part)}
                            onKeyDown={(e) => this.handleKeyDown(e, carpart.group, carpart.part)}
                            role="button"
                        >
                            <ImageComponent
                                src={this.getIcon(carpart.part, carpart.group)}
                                tabIndex={index}
                                id={carpart.part}
                                alt="damageParts"
                            />
                        </span>
                    ))}
                </div>
            </div>
        );
    }
}

export default VehicleDamagePickerMulti;
