import React, { useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { FacebookService } from 'gw-portals-facebook-js';
import { useErrors, withErrorBoundary } from 'gw-portals-error-react';
import styles from './FacebookShare.module.scss';
import messages from './FacebookShare.messages';
import facebookShareConfig from './facebook.json';

function FacebookShare(props) {
    const { throwReactError } = useErrors();

    useEffect(() => {
        let configForFacebook;
        FacebookService.getClient().then((fbClient, fbConfig) => {
            fbClient.XFBML.parse();
            configForFacebook = fbConfig;
        }).catch(throwReactError);

        return function cleanup() {
            FacebookService.removeClient(configForFacebook);
        };
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { buttonText, text } = props;

    const triggerShare = useCallback(() => {
        // prepare share dialog data
        const { shareDialog } = facebookShareConfig;
        if (buttonText) {
            shareDialog.buttonText = buttonText;
        }
        if (text) {
            shareDialog.text = text;
        }

        // invoke share
        window.FB.ui(
            Object.assign(shareDialog, {
                method: 'share'
            })
        );
    }, [buttonText, text]);

    const translator = useContext(TranslatorContext);

    return (
        <div className={styles.facebookContent}>
            <div className={styles.facebookShare}>
                <p>{translator(text)}</p>

                <Button className={styles.facebookShareButton} onClick={triggerShare}>
                    <Icon icon="mi-share">
                        <span className={styles.btnMessage}>{translator(buttonText)}</span>
                    </Icon>
                </Button>
            </div>
        </div>
    );
}

FacebookShare.propTypes = {
    text: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    buttonText: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    })
};

FacebookShare.defaultProps = {
    text: messages.description,
    buttonText: messages.facebookMessageShare
};

export default withErrorBoundary({ resetError: false })(FacebookShare);
