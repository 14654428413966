import { defineMessages } from 'react-intl';

export default defineMessages({
    whyNeedLegalAssistance: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.Why do you need legal assistance',
        defaultMessage: 'Why do you need legal assistance?*'
    },
    petSpecify: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.pet.Specify',
        defaultMessage: 'Specify*'
    },
    otherSpecify: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.other.Specify',
        defaultMessage: 'Specify*'
    },
    worksToMyBuilding: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.MyProperty.WorksToMyBuilding',
        defaultMessage: 'Works to my building'
    },
    worksToAnotherBuilding: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.MyProperty.WorksToAnotherBuilding',
        defaultMessage: 'Works to another building'
    },
    roadWorks: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.MyProperty.Roadworks',
        defaultMessage: 'Roadworks'
    },
    thirdParty: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.MyProperty.ThirdParty',
        defaultMessage: '3rd party'
    },
    myPropertyOther: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.MyProperty.Other',
        defaultMessage: 'Other'
    },
    myPropertyOtherSpecify: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.MyProperty.OtherSpecify',
        defaultMessage: 'Specify*'
    },
    meOrFamilyMaterial: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.MeOrMyFamily.Material',
        defaultMessage: 'Material'
    },
    meOrFamilyMaterialSpecify: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.MeOrMyFamily.Material.Specify',
        defaultMessage: 'Specify*'
    },
    meOrFamilyPhysical: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.MeOrMyFamily.Physical',
        defaultMessage: 'Physical'
    },
    meOrMemberWhoInput: {
        id: 'anonymous.wizard.fnol.components.basicLegalProperty.MeOrMyFamily.Physical.Who',
        defaultMessage: 'Who?'
    }
});
