/* eslint-disable max-len */
import _ from 'lodash';
import React, { useCallback, useContext, useEffect } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { FNOLService } from 'nn-capability-fnol';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import styles from './BankAccountNumberComponent.module.scss';
import metadata from './BankAccountNumberComponent.metadata.json5';
// eslint-disable-next-line no-unused-vars
import messages from './BankAccountNumberComponent.messages';

function BankAccountNumberComponent(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const {
        id,
        history,
        value: bankAccountDetailsDTO,
        writeValue,
        onValidate,
        isBrokerContext,
        accountNumberRequired,
        path
    } = props;

    const {
        onValidate: onComponentValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('BankAccountDetailsComponent');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    if (!bankAccountDetailsDTO.value) {
        const data = {};
        writeValue(data, path);
    }

    const componentWriteValue = useCallback((value, localPath) => {
        _.set(bankAccountDetailsDTO.value, localPath, value);
        if (!bankAccountDetailsDTO.value.haveChanged) {
            _.set(bankAccountDetailsDTO.value, 'haveChanged', true);
        }
        writeValue(bankAccountDetailsDTO.value, `${path}`);
    }, [bankAccountDetailsDTO, writeValue, path]);

    const componentValidation = useCallback(() => {
        return !bankAccountDetailsDTO.value.bankAccountNumber
            || bankAccountDetailsDTO.value.isValid === true;
    }, [bankAccountDetailsDTO.value]);

    useEffect(() => {
        registerComponentValidation(componentValidation);
    }, [registerComponentValidation, componentValidation]);

    const queryValidateBankAccount = (bankAccountNumber) => {
        if (!bankAccountNumber) {
            componentWriteValue(undefined, 'isValid')
            return;
        }
        const validateAccountNumber = viewModelService.create(
            {
                accountNumber: bankAccountNumber
            },
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.ValidateBankAccountReqDTO'
        );

        FNOLService.validateBankAccount(validateAccountNumber.value, history, { partnerContext, translator }).then(
            (validateAccountNumberDTO) => {
                if (!validateAccountNumberDTO) return;
                componentWriteValue(validateAccountNumberDTO.isBankAccountValid, 'isValid')
            }
        );
    };

    const queryValidateBankAccountDebounced = useCallback(_.debounce(queryValidateBankAccount, 800), [bankAccountDetailsDTO.value.bankAccountNumber]);

    useEffect(() => {
        if (bankAccountDetailsDTO.value.bankAccountNumber) {
            queryValidateBankAccountDebounced(bankAccountDetailsDTO.value.bankAccountNumber);
        }
    }, [bankAccountDetailsDTO.value.bankAccountNumber, queryValidateBankAccountDebounced]);

    const shouldBICBeMandatory = () => {
        return !!bankAccountDetailsDTO.value.bankAccountNumber
            && !bankAccountDetailsDTO.value.bankAccountNumber.startsWith('BE');
    };

    const overrides = {
        fnolContactDetailsAccountSectionLabel: {
            visible: !isBrokerContext
        },
        fnolContactDetailsAccountSectionLabelBroker: {
            visible: isBrokerContext
        },
        fnolContactDetailsAccountName: {
            visible: isBrokerContext
        },
        fnolContactDetailsAccountNumber: {
            required: accountNumberRequired,
            showRequired: accountNumberRequired,
            showErrors: bankAccountDetailsDTO.value.isValid === false,
            validationMessages: bankAccountDetailsDTO.value.isValid === false
                ? [translator(messages.fnolContactDetailsAccountNumberValidation)]
                : []
        },
        fnolContactDetailsBIC: {
            required: shouldBICBeMandatory(),
            showRequired: shouldBICBeMandatory()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        },
        resolveComponentMap: {}
    };

    return (
        <ViewModelForm
            model={bankAccountDetailsDTO.value}
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            onValidationChange={onComponentValidate}
            onValueChange={componentWriteValue}
        />
    );
}

export default BankAccountNumberComponent;
