import { useContext } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import metadata from './AuthenticatedContactCallCenterPage.metadata.json5';
import styles from './AuthenticatedContactCallCenterPage.scss';
// eslint-disable-next-line no-unused-vars
import messages from './AuthenticatedContactCallCenterPage.messages';

function AuthenticatedContactCallCenterPage(props) {
    const parnerContext = useContext(PartnerContext);
    const translator = useContext(TranslatorContext);
    const phoneNumber = parnerContext.partnerPhoneNumber(translator);

    const { authenticationRelationError } = props;

    const performCall = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const overrideProps = {
        authFnolContactCallCenterPageTitle: {
            content: authenticationRelationError === true 
                ? translator(messages.authFnolContactCallCenterPageTitle)
                : translator(messages.authFnolContactCallCenterPageTitleAuthorization)
        },
        authFnolContactCallCenterPageUrgentCallButton: {
            content: phoneNumber
        },
        authFnolContactCallCenterUrgentHelp: {
            content: parnerContext.partnerUrgentAssistLabel(translator)
        }
    };

    const resolvers = {
        resolveComponentMap: {},
        resolveCallbackMap: {
            performCall: performCall
        },
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(
        metadata.pageContent,
        overrideProps,
        resolvers
    );
}

export default AuthenticatedContactCallCenterPage;
