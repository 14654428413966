import { defineMessages } from 'react-intl';

export default defineMessages({
    whatWhoCausedDamage: {
        id: 'anonymous.wizard.fnol.components.basicCivil.What who caused the damage',
        defaultMessage: 'What / who caused the damage?*'
    },
    petSpecify: {
        id: 'anonymous.wizard.fnol.components.basicCivil.pet.Specify',
        defaultMessage: 'Specify'
    },
    otherSpecify: {
        id: 'anonymous.wizard.fnol.components.basicCivil.other.Specify',
        defaultMessage: 'Specify'
    },
    who: {
        id: 'anonymous.wizard.fnol.components.basicCivil.Who',
        defaultMessage: 'Who?'
    },
    age: {
        id: 'anonymous.wizard.fnol.components.basicCivil.Age',
        defaultMessage: 'Age'
    },
    roadUserTypeInput: {
        id: 'anonymous.wizard.fnol.components.basicCivil.RoadUser',
        defaultMessage: 'Type'
    },
    propertyDamageTooltip: {
        id: 'anonymous.wizard.fnol.components.basicCivil.PropertyTooltip',
        defaultMessage: 'Another party reports moisture damage coming from my house or claims that a roof tile from my roof caused damage to his porch or that his fence or garden house was damaged by a tree or falling branches from my garden ...'
    }
});
