/* eslint-disable max-len */
import React from 'react';
import styles from './TitleWidget.module.scss';

function TitleWidget(props) {
    const { title } = props;
    let wordsPerColor;
    let currentWordIndex = 0;
    let currentColorIndex = 0;
    let wordsInCurrentColorCounter = 0;
    let colorChangingDirection = 'increment';
    const splittedTitle = title.split(' ');
    const splittedTitleLength = splittedTitle.length;

    if (splittedTitleLength <= 5) {
        wordsPerColor = 1;
    } else if (splittedTitleLength <= 12) {
        wordsPerColor = 2;
    } else {
        wordsPerColor = 3;
    }

    return (
        <div>
            {splittedTitle.map((titlePart) => {
                currentWordIndex += 1;
                wordsInCurrentColorCounter += 1;
                let applicableStyle;
                switch (currentColorIndex) {
                    case 2:
                        applicableStyle = styles.darkColor;
                        break;
                    case 1:
                        applicableStyle = styles.mediumColor;
                        break;
                    case 0:
                    default:
                        applicableStyle = styles.lightColor;
                        break;
                }

                const widget = (
                    <span key={currentWordIndex} className={applicableStyle}>
                        {titlePart}
                        { currentColorIndex !== splittedTitleLength && <span> </span> }
                    </span>
                );

                if (wordsInCurrentColorCounter === wordsPerColor) {
                    if (colorChangingDirection === 'increment') {
                        currentColorIndex += 1;
                        if (currentColorIndex === 2) colorChangingDirection = 'decrement';
                    } else {
                        currentColorIndex -= 1;
                        if (currentColorIndex === 0) colorChangingDirection = 'increment';
                    }
                    wordsInCurrentColorCounter = 0;
                }
                return widget;
            })
            }
        </div>
    );
}

export default TitleWidget;
