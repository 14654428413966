/* eslint-disable max-len */
import _ from 'lodash';

function getTypeFilterCodes(typelistVM, filterName) {
    const damageTypeTypelist = typelistVM.aspects.availableValues[0].typelist;
    return damageTypeTypelist.getFilter(filterName).codes.map((filterCode) => filterCode.code);
}

function areCodesInFilter(damageTypesVm, filterName) {
    const damageTypes = damageTypesVm.value;
    if (!damageTypes || _.isEmpty(damageTypes)) return false;

    const filterCodes = getTypeFilterCodes(damageTypesVm.children[0], filterName);
    const crossedDamageTypes = damageTypes.filter((damageType) => filterCodes.indexOf(damageType) !== -1);
    return !_.isEmpty(crossedDamageTypes);
}

export function isDamageTypeOther(damageTypeVM) {
    const damageType = damageTypeVM.value;
    if (!damageType || _.isEmpty(damageType)) return false;

    const filterCodes = getTypeFilterCodes(damageTypeVM, 'OtherDamagesTypecodes');
    return filterCodes.indexOf(damageType.code) !== -1;
}

export function isLegalAssistanceAuto(damageTypesVM) {
    return areCodesInFilter(damageTypesVM, 'LegalAssistanceTypecodes');
}

export function isOtherDamageSelected(damageTypesVM) {
    return areCodesInFilter(damageTypesVM, 'OtherDamagesTypecodes');
}

export function isEBikeTotalOrAttemptedTheft(damageTypes) {
    return damageTypes.indexOf('EBikeTotalTheft') !== -1 || damageTypes.indexOf('EBikeAttemptedTheft') !== -1;
}

export function isEBikeVandalismOrTotalAttemptedTheft(damageTypes) {
    return damageTypes.indexOf('EBikeVandalism') !== -1 || isEBikeTotalOrAttemptedTheft(damageTypes);
}
