/* eslint-disable max-len */
import React, { useContext } from 'react';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './RepairPreferredVendorPopup.metadata.json5';
import messages from './RepairPreferredVendorPopup.messages';
import styles from './RepairPreferredVendorPopup.module.scss';

function RepairPreferredVendorPopup(props) {
    const {
        isOpen,
        onResolve,
        onReject
    } = props;
    const translator = useContext(TranslatorContext);

    return (
        <ModalNext isOpen={isOpen} className={styles.body}>
            <ModalHeader title={translator(messages.preferredVendorPopupTitle)} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    classNameMap={styles}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onResolve} type="secondary">
                    {translator(messages.preferredVendorPopupCloseButton)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

export default RepairPreferredVendorPopup;
