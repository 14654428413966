const fileSizeUnits = [
    { from: 0, to: 999, symbol: 'B', divider: 1 },
    { from: 10 ** 3, to: 10 ** 6 - 1, symbol: 'KB', divider: 1024 },
    { from: 10 ** 6, to: 10 ** 9 - 1, symbol: 'MB', divider: 1024 ** 2 },
    { from: 10 ** 9, to: 10 ** 12 - 1, symbol: 'GB', divider: 1024 ** 3 }
];

function formatFileSize(fileSize) {
    const foundUnit = fileSizeUnits.find((unit) => {
        return (fileSize >= unit.from && fileSize <= unit.to);
    });

    if (foundUnit) {
        const sizeInUnit = (fileSize / foundUnit.divider).toFixed(2);
        return `${sizeInUnit} ${foundUnit.symbol}`;
    }

    // if not found - return size in bytes
    return `${fileSize} B`;
}

export default formatFileSize;
