import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Icon, Link as JutroLink } from '@jutro/components';
import styles from './LinkComponent.module.scss';

function isIntl(children) {
    return _.has(children, 'id') && _.has(children, 'defaultMessage');
}

function LinkComponent(props) {
    const { children, className, ...otherProps } = props;
    const translator = useContext(TranslatorContext);
    const linkContent = isIntl(children) ? translator(children) : children;

    if (_.has(otherProps, 'to')) {
        const classes = classNames(className, styles.link);
        if (_.has(otherProps, 'icon')) {
            return (
                <Link id={otherProps.id} className={classes} {...otherProps}>
                    <Icon icon={otherProps.icon} className={styles.icon} />
                    <span>{linkContent}</span>
                </Link>
            );
        }
        return (
            <Link className={classes} {...otherProps}>
                {linkContent}
            </Link>
        );
    }
    return (
        <JutroLink className={className} {...otherProps}>
            {linkContent}
        </JutroLink>
    );
}

LinkComponent.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any,
    className: PropTypes.string
};

LinkComponent.defaultProps = {
    children: undefined,
    className: undefined
};

export default LinkComponent;
