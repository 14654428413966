export default {
    name: 'INGTheme',
    prefix: 'ingTheme',
    rootStyle: {
        // Overrides of OOTB parameters
        '--GW-FONT-FAMILY': 'var(--GW-FONT-NNNITTY)',
        '--GW-FONT-NNNITTY': '"NN Nitti Grotesk", "Open Sans Variable", "Source Sans Variable", Arial, sans-serif',
        '--GW-TEXT-COLOR-1': '#404040',
        '--GW-BRAND-COLOR-1': '#524E91',
        '--GW-BRAND-COLOR-1-HOVER': '#3f3c70',
        '--GW-BRAND-COLOR-1-ACTIVE': '#2c2a4f',
        '--GW-BRAND-COLOR-2': 'var(--GW-BRAND-COLOR-1)',
        '--GW-COLOR-BACKGROUND-1': '#ffffff', // White
        '--GW-COLOR-BACKGROUND-2': '#F0F0F0', // Gray
        '--GW-ICON-FONT-SIZE-1': '0.9rem',
        '--GW-FONT-SIZE-LABEL': '1rem',
        '--GW-FONT-SIZE-BODY': '1rem',
        '--GW-FOCUS-COLOR': 'var(--GW-BRAND-COLOR-1)',
        '--GW-FOCUS-COLOR-LIGHT': 'var(--GW-BRAND-COLOR-1)',
        '--GW-HEADER-HEIGHT': '5.5rem',

        // Section for custom NN global variables
        '--NN-FONT-SIZE-SMALL': '0.75rem',
        '--NN-HEADER-COLOR': 'var(--GW-COLOR-BACKGROUND-2)',
        '--NN-FOOTER-COLOR': 'var(--GW-COLOR-BACKGROUND-2)',

        // Title component
        '--NN-TITLE-FIRST-COLOR': 'var(--GW-TEXT-COLOR-1)',
        '--NN-TITLE-SECOND-COLOR': 'var(--GW-TEXT-COLOR-1)',
        '--NN-TITLE-THIRD-COLOR': 'var(--GW-TEXT-COLOR-1)',

        '--NN-THEMED-TITLE-COLOR': 'var(--GW-TEXT-COLOR-1)',

        // Selectable Policy
        '--NN-SELECTABLE-POLICY-SELECTED-POLICY-HEADER-TEXT-COLOR': 'var(--GW-COLOR-BACKGROUND-1)',
        '--NN-SELECTABLE-POLICY-UNSELECTED-POLICY-HEADER-TEXT-COLOR': 'var(--GW-BRAND-COLOR-1)',
        '--NN-SELECTABLE-POLICY-SELECTED-POLICY-TEXT-COLOR': 'var(--GW-COLOR-BACKGROUND-1)',
        '--NN-SELECTABLE-POLICY-UNSELECTED-POLICY-TEXT-COLOR': 'var(--GW-TEXT-COLOR-1)'
    },
    componentStyles: {

    }
};
