import { defineMessages } from 'react-intl';

export default defineMessages({
    authenticatedFlowBikeUrgencyPopupTitle: {
        id: 'authenticated.bike.urgency.popup.Do you need urgent road side assistance ?',
        defaultMessage: 'Do you need urgent road side assistance ?'
    },
    authenticatedFlowBikeUrgencyPopupButtonYes: {
        id: 'authenticated.bike.urgency.popup.button.Yes',
        defaultMessage: 'Yes'
    },
    authenticatedFlowBikeUrgencyPopupButtonNo: {
        id: 'authenticated.bike.urgency.popup.button.No',
        defaultMessage: 'No'
    },
    authenticatedFlowBikeUrgencyPopupPrepareDocumentsLineOne: {
        id: 'authenticated.bike.urgency.popup.prepareDocuments.Uploading documents (estimate, invoice, photos, videos, ...) can be mandatory. Please make sure you have them available',
        defaultMessage: 'Uploading documents (estimate, invoice, photos, videos, ...) can be mandatory. Please make sure you have them ready'
    },
    authenticatedFlowBikeUrgencyPopupPrepareDocumentsLineTwo: {
        id: 'authenticated.bike.urgency.popup.prepareDocuments.This will speed up the processing of your claim and help us assist you better.',
        defaultMessage: 'This will help us to assist you better and faster.'
    },
    authenticatedFlowBikeUrgencyPopupButtonIUnderstand: {
        id: 'authenticated.bike.urgency.popup.prepareDocuments.I understand',
        defaultMessage: 'Continue'
    },
    authenticatedFlowBikeUrgencyPopupRedirectionTitle: {
        id: 'authenticated.bike.urgency.popup.redirect.You indicate that the vehicle can no longer be driven. We would like to help you with this quickly',
        defaultMessage: 'You indicate that the bike can no longer be ridden. We would like to help you as soon as possible.'
    },
    authenticatedFlowBikeUrgencyPopupRedirectionBody: {
        id: 'authenticated.bike.urgency.popup.prepareDocuments.Please call us on following number.',
        defaultMessage: 'Please call us on following number:'
    }
});
