import React, { useRef } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import useValidation from './useValidation';

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withValidation(WrappedComponent) {
    function WithValidation(props) {
        const { id } = props;
        const { onValidate, isComponentValid, ...remainingValidationHooks } = useValidation(
            id || getDisplayName(WrappedComponent)
        );
        const oldValidation = useRef();
        const hasValidationChanged = oldValidation.current !== isComponentValid;
        oldValidation.current = isComponentValid;

        return (
            <WrappedComponent
                {...remainingValidationHooks}
                {...props}
                setComponentValidation={onValidate}
                isComponentValid={isComponentValid}
                hasValidationChanged={hasValidationChanged}
            />
        );
    }
    hoistNonReactStatic(WithValidation, WrappedComponent);
    WithValidation.displayName = `WithValidation(${getDisplayName(WrappedComponent)})`;
    WithValidation.propTypes = {
        id: PropTypes.string
    };
    WithValidation.defaultProps = {
        id: undefined
    };
    return WithValidation;
}

export const validationPropTypes = {
    disregardFieldValidation: PropTypes.func.isRequired,
    setComponentValidation: PropTypes.func.isRequired,
    isComponentValid: PropTypes.bool.isRequired,
    initialValidation: PropTypes.func.isRequired,
    registerInitialComponentValidation: PropTypes.func.isRequired,
    registerComponentValidation: PropTypes.func.isRequired,
    isValidationInProgress: PropTypes.bool.isRequired,
    hasValidationChanged: PropTypes.bool.isRequired
};
