import React, { useContext, useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Wizard } from 'gw-portals-wizard-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { ModalNextProvider } from '@jutro/components';
import _ from 'lodash';
import { messages as commonMessages } from 'gw-platform-translations';
import { ViewModelServiceContext, withViewModelService } from '../../../../modules-react/gw-portals-viewmodel-react';
import wizardConfig from '../../config/fnol-wizard-config.json5';
import useErrorHandler from '../../hooks/useErrorHandler';
import nnCustomMessages from '../../nnCustomMessages';
import FNOLContext from '../FNOLWizardContext/FNOLWizardContext';

const CANCEL_ACTION_DATA = {
    title: nnCustomMessages.cancelWizardInProgressTitle,
    message: nnCustomMessages.cancelWizardInProgress,
    status: 'warning',
    icon: 'mi-error-outline',
    confirmButtonText: commonMessages.ok,
    cancelButtonText: commonMessages.cancelModel
};

function FNOLWizard(props) {
    const { steps, title } = wizardConfig;
    const { location, history } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const [initialClaim, setInitialClaim] = useState(null);
    const [fnolContextState, setFnolContextState] = useState({
        addInfoDocuments: [],
        stepSnapshots: {}
    });
    // Needed to trigger Save And Resume snapshot force restart only once
    const [forceRestartApplicable, setForceRestartApplicable] = useState(false);
    const errorHandler = useErrorHandler();
    const stateFromLocation = location.state;
    let initialClaimDTOFromProps;
    let forceReload;

    if (stateFromLocation !== undefined) {
        initialClaimDTOFromProps = stateFromLocation.fnolDto;
        forceReload = stateFromLocation.forceReload;
    }

    useEffect(() => {
        const initialClaimDTO = viewModelService.create(
            initialClaimDTOFromProps,
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.FnolDTO'
        );

        setForceRestartApplicable(forceReload);
        return setInitialClaim(initialClaimDTO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceReload]); // Empty Array as we don't want this code to re-run

    const handleCancelClick = useCallback(() => {
        return ModalNextProvider
            .showConfirm(CANCEL_ACTION_DATA)
            .then((result) => {
                if (result === 'cancel') {
                    return _.noop();
                }
                history.push('/');
                return _.noop();
            }, _.noop);
    }, [history]);

    const showConfirmation = useCallback(() => {
        return ModalNextProvider
            .showConfirm(CANCEL_ACTION_DATA).then((result) => {
                if (result === 'cancel') {
                    return _.stubFalse();
                }
                return _.noop();
            }, _.noop);
    }, []);

    const handleError = useCallback((error) => {
        errorHandler.handleError(error);
        return false;
    }, [errorHandler]);

    if (!initialClaim) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <FNOLContext.Provider value={{ fnolContextState, setFnolContextState }}>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={title}
                    initialData={initialClaim}
                    onCancel={handleCancelClick}
                    ignorePrompts
                    onPrevious={showConfirmation}
                    cancelActionData={CANCEL_ACTION_DATA}
                    forceReload={forceRestartApplicable}
                />
            </FNOLContext.Provider>
        </ErrorBoundary>
    );
}

export default withRouter(withViewModelService(FNOLWizard));
