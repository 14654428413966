import BrokerSSOValuesProvider from './bsso/BrokerSSOValuesProvider';
import PrivateCustomerValuesProvider from './pc/PrivateCustomerValuesProvider';

export default function getClientValuesProvider(clientCode) {
    switch (clientCode) {
        case 'BSSO':
            return new BrokerSSOValuesProvider();
        default:
            return new PrivateCustomerValuesProvider();
    }
}
