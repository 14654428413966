import { defineMessages } from 'react-intl';

export default defineMessages({
    preferredVendorPopupTitle: {
        id: 'anonymous.preferredVendor.popup.Get to know our exclusive network of authorised repairers where you are guaranteed the best service',
        defaultMessage: 'Get to know our exclusive network of authorised repairers where you are guaranteed the best service'
    },
    preferredVendorPopupSmartRepairTitle: {
        id: 'anonymous.preferredVendor.title.Smart repair',
        defaultMessage: 'Smart repair'
    },
    preferredVendorPopupSmartRepairLabel: {
        id: 'anonymous.preferredVendor.label.Quality repair using state-of-the-art techniques (Smart repair)',
        defaultMessage: 'Quality repair using state-of-the-art techniques (Smart repair)'
    },
    preferredVendorPopupReplacementVehicleTitle: {
        id: 'anonymous.preferredVendor.title.Free Replacement Vehicle',
        defaultMessage: 'Free Replacement Vehicle'
    },
    preferredVendorPopupReplacementVehicleLabel: {
        id: 'anonymous.preferredVendor.label.Mobility guarantee: free replacement vehicle for the duration of the repair and up to 12 days in the event of total loss, even if the customer does not have comprehensive insurance and is at fault(provided that the repair is carried out at an authorised garage). The customer does not pay an additional premium for this.',
        defaultMessage: 'Mobility guarantee: free replacement vehicle for the duration of the repair and up to 12 days in case of total loss, even if the customer does not have Omnium insurance and would be at fault (provided that the repair is carried out at an authorised garage). The customer does not pay an additional premium for this.'
    },
    preferredVendorPopupNoAdvancePaymentTitle: {
        id: 'anonymous.preferredVendor.title.No advance payment',
        defaultMessage: 'No advance payment'
    },
    preferredVendorPopupNoAdvancePaymentLabel: {
        id: 'anonymous.preferredVendor.label.In the event of an insured damage, the repair will be paid directly to the certified garage',
        defaultMessage: 'In the event of an insured damage, the repair will be paid directly to the certified garage'
    },
    preferredVendorPopupWarrantyTitle: {
        id: 'anonymous.preferredVendor.title.Two-year warranty',
        defaultMessage: 'Two-year warranty'
    },
    preferredVendorPopupWarrantyLabel: {
        id: 'anonymous.preferredVendor.label.At least 2 years warranty on repair, also on parts and services',
        defaultMessage: 'At least 2 years warranty on repair, also on parts and services'
    },
    preferredVendorPopupNoAccessTitle: {
        id: 'anonymous.preferredVendor.title.No excess',
        defaultMessage: 'No excess'
    },
    preferredVendorPopupNoAccessLabel: {
        id: 'anonymous.preferredVendor.label.No excess in the event of glass breakage if the repairs are carried out at Carglass or an authorised garage.',
        defaultMessage: 'No excess in the event of glass breakage if the repairs are carried out at Carglass or an authorised garage.'
    },
    preferredVendorPopupTeleExpertiseTitle: {
        id: 'anonymous.preferredVendor.title.Tele-expertise',
        defaultMessage: 'Tele-expertise'
    },
    preferredVendorPopupTeleExpertiseLabel: {
        id: 'anonymous.preferredVendor.label.Tele-expertise: the customer does not have to travel for an assessment of an insured damage (in the event of an accident under law or comprehensive insurance)',
        defaultMessage: 'Tele-expertise: the customer does not have to travel for an assessment of an insured damage (in the event of an accident under law or comprehensive insurance)'
    },
    preferredVendorPopupFreeStorageTitle: {
        id: 'anonymous.preferredVendor.title.Free storage',
        defaultMessage: 'Free storage'
    },
    preferredVendorPopupFreeStorageLabel: {
        id: 'anonymous.preferredVendor.label.Free storage of the damaged vehicle in the event of a total loss (up to a maximum of 45 days)',
        defaultMessage: 'Free storage of the damaged vehicle in the event of a total loss (up to a maximum of 45 days)'
    },
    preferredVendorPopupCloseButton: {
        id: 'anonymous.preferredVendor.popup.Close',
        defaultMessage: 'Close'
    }
});
