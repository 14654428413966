import { defineMessages } from 'react-intl';

export default defineMessages({
    authenticatedBrokerPolicySelectHeader: {
        id: 'anonymous.fnol.components.search-policy.broker.Please provide info about your client insurance',
        defaultMessage: 'Please provide info about your clients insurance'
    },
    authenticatedBrokerSearchPolicyCustomerOrientedFlow: {
        id: 'anonymous.fnol.components.search-policy.broker.This flow is customer-oriented. When you encounter "I was the driver", it refers to the policyholder',
        defaultMessage: 'This flow is customer-oriented. When you encounter "I was the driver", it refers to the policyholder'
    },
    authenticatedBrokerSearchPolicyPrepareDocuments: {
        id: 'anonymous.fnol.components.search-policy.broker.To be able to help you faster, please have photos, videos and other documents at hand.',
        defaultMessage: 'To be able to help you faster, please have photos, videos and other documents at hand.'
    },
    authenticatedBrokerSearchPolicyNumberInputPlaceholder: {
        id: 'anonymous.fnol.components.search-policy.broker.You can find the policy number on the 1st page of your policy contract. Please provide policy number without any separators (e.g. dashes or any other signs)',
        defaultMessage: 'You can find the policy number on the 1st page of your policy contract. Please provide policy number without any separators (e.g. dashes or any other signs)'
    },
    authenticatedBrokerSearchPolicyNumberInput: {
        id: 'anonymous.fnol.components.search-policy.broker.Policy Number',
        defaultMessage: 'Policy Number'
    },
    authenticatedBrokerSearchLossDate: {
        id: 'anonymous.fnol.components.search-policy.broker.Date of loss',
        defaultMessage: 'Date of loss'
    },
    authenticatedBrokerSearchLossDateTimeLabel: {
        id: 'anonymous.fnol.components.search-policy.broker.Time of loss',
        defaultMessage: 'Time of loss'
    },
    authenticatedBrokerSearchLossDateTimeStampLabel: {
        id: 'anonymous.fnol.components.search-policy.broker.Do you know at what time the loss occurred?',
        defaultMessage: 'Do you know at what time the loss occurred?'
    },
    authenticatedBrokerSearchLossDateTooltip: {
        id: 'anonymous.fnol.components.search-policy.broker.Please provide the exact date the loss happened. If you do not know, enter the date on which you noticed the damage.',
        defaultMessage: 'Please provide the exact date the loss happened. If you do not know, enter the date on which you noticed the damage.'
    },
    authenticatedBrokerSearchButtonContainer: {
        id: "anonymous.fnol.components.search-policy.broker.Let's get started!",
        defaultMessage: "Let's get started!"
    },
    authenticatedBrokerSearchErrorTextHeader: {
        id: 'anonymous.fnol.components.search-policy.broker.Sorry, the provided data are not valid, please provide correct data.',
        defaultMessage: 'Sorry, the provided data are not valid, please provide correct data.'
    },
    authenticatedBrokerSearchAgentNotAllowedErrorText: {
        id: 'anonymous.fnol.components.search-policy.broker.Sorry, you are not allowed to use this policy.',
        defaultMessage: 'Sorry, you are not allowed to use this policy.'
    }
});
