/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-console */
import _ from 'lodash';
import React, { useCallback, useContext, useEffect } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { CheckboxField } from '@jutro/components';
import { isPropertyLOB } from '../../../utils/PolicyTypeUtil';
import useBreakpointHandler from '../../../hooks/useBreakpointHandler';
import VehicleDamagePickerMulti from '../VehicleDamagePickerMulti/VehicleDamagePickerMulti';
import styles from './LossDetailsCar.module.scss';
import metadata from './LossDetailsCar.metadata.json5';
import messages from './LossDetailsCar.messages';

function LossDetailsCar(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);

    const {
        id,
        handleValueChange,
        value: fnolVM,
        onValidate
    } = props;

    const fnolDTO = fnolVM.value;

    const { vehicleDamage: vehicleDamageDTO,
        trailerDamage: trailerDamageDTO,
        otherDamages: otherDamagesDTO } = fnolDTO;

    const {
        onValidate: setComponentValidation,
        registerComponentValidation,
        isComponentValid
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const [
        isMobileDevice
    ] = useBreakpointHandler();

    const vehiclePartsValueChange = useCallback((value, changedPath) => {
        handleValueChange(value, changedPath);
        if (value && value.length > 0) {
            if (fnolDTO.vehicleDamage.dmgGlassBreak) fnolDTO.vehicleDamage.dmgGlassBreak = false;
            if (fnolDTO.vehicleDamage.dmgNoVisibleDamage) fnolDTO.vehicleDamage.dmgNoVisibleDamage = false;
        }
    }, [fnolDTO.vehicleDamage, handleValueChange]);

    const createVehicleDamageTypeList = useCallback(() => {
        const typeList = viewModelService.create(
            {},
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.VehicleDamagePointDTO'
        );
        const result = typeList.damagePoint.aspects.availableValues[0].typelist;
        return result.codes;
    }, [viewModelService]);

    const isGlassDamage = (fnolDTO.frontEndClaimCause === 'GlassBreak');

    const anyDamagesSelected = useCallback(() => {
        if (vehicleDamageDTO.vehicleDamagePoints
            && vehicleDamageDTO.vehicleDamagePoints.length > 0) {
            return true;
        }

        const {
            dmgGlassBreak,
            dmgTires,
            dmgBottom,
            dmgNoVisibleDamage
        } = vehicleDamageDTO;

        if (dmgGlassBreak || dmgTires || dmgBottom || dmgNoVisibleDamage) {
            return true;
        }
        return false;
    }, [vehicleDamageDTO]);

    const validationVehicleDamagesCallback = useCallback(() => {
        if (isGlassDamage) return true; // no check for glass
        if (!anyDamagesSelected()) return false;
        return true;
    }, [anyDamagesSelected, isGlassDamage]);

    useEffect(() => {
        registerComponentValidation(validationVehicleDamagesCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const providedStyleOrNoStyleOnMobile = useCallback((className) => {
        return isMobileDevice() ? null : className;
    }, [isMobileDevice]);

    const mobileOrRegularStyle = useCallback((mobileClassName, className) => {
        return isMobileDevice() ? mobileClassName : className;
    }, [isMobileDevice]);

    const getVehicleDamageTitle = useCallback(() => {
        const isVehicleDamageValid = validationVehicleDamagesCallback();
        let damagesTitleText = translator(messages.titleVehicleDamagesPresent);
        if (!isVehicleDamageValid) {
            damagesTitleText = translator(
                messages.titleMissingVehicleDamages
            );
        }
        return { content: damagesTitleText };
    }, [translator, validationVehicleDamagesCallback]);

    const vehicleDamageBoxesChange = useCallback((val, path) => {
        if (val) {
            if (path.includes('dmgNoVisibleDamage')) {
                if (fnolDTO.vehicleDamage.dmgGlassBreak) fnolDTO.vehicleDamage.dmgGlassBreak = false;
                if (fnolDTO.vehicleDamage.dmgTires) fnolDTO.vehicleDamage.dmgTires = false;
                if (fnolDTO.vehicleDamage.dmgBottom) fnolDTO.vehicleDamage.dmgBottom = false;
                if (fnolDTO.vehicleDamage.vehicleDamagePoints.length) fnolDTO.vehicleDamage.vehicleDamagePoints = [];
            }
            if (path.includes('dmgGlassBreak')) {
                if (fnolDTO.vehicleDamage.dmgTires) fnolDTO.vehicleDamage.dmgTires = false;
                if (fnolDTO.vehicleDamage.dmgBottom) fnolDTO.vehicleDamage.dmgBottom = false;
                if (fnolDTO.vehicleDamage.dmgNoVisibleDamage) fnolDTO.vehicleDamage.dmgNoVisibleDamage = false;
                if (fnolDTO.vehicleDamage.vehicleDamagePoints.length) fnolDTO.vehicleDamage.vehicleDamagePoints = [];
            }
            if (path.includes('dmgTires') || path.includes('dmgBottom')) {
                if (fnolDTO.vehicleDamage.dmgGlassBreak) fnolDTO.vehicleDamage.dmgGlassBreak = false;
                if (fnolDTO.vehicleDamage.dmgNoVisibleDamage) fnolDTO.vehicleDamage.dmgNoVisibleDamage = false;
            }
        }

        handleValueChange(val, path);
    }, [fnolDTO.vehicleDamage, handleValueChange]);

    const trailerDamageBoxesChange = useCallback((val, path) => {
        if (val) {
            if (path.includes('dmgTires') || path.includes('dmgBottom') || path.includes('dmgOtherDamages')) {
                if (fnolDTO.trailerDamage.dmgNoVisibleDamage) fnolDTO.trailerDamage.dmgNoVisibleDamage = false;
            }
            if (path.includes('dmgNoVisibleDamage')) {
                if (fnolDTO.trailerDamage.dmgTires) fnolDTO.trailerDamage.dmgTires = false;
                if (fnolDTO.trailerDamage.dmgBottom) fnolDTO.trailerDamage.dmgBottom = false;
                if (fnolDTO.trailerDamage.dmgOtherDamages) fnolDTO.trailerDamage.dmgOtherDamages = false;
            }
        }
        handleValueChange(val, path);
    }, [fnolDTO.trailerDamage, handleValueChange]);

    const overrides = {
        otherDamagesContainerCheckboxes: {
            className: providedStyleOrNoStyleOnMobile('flexContainer')
        },
        otherDamagesContainerRight: {
            className: providedStyleOrNoStyleOnMobile('leftMargin')
        },
        trailerLicensePlateInput: {
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        trailerVinInput: {
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        customDriverAddressInput: {
            onWriteValue: handleValueChange,
            policyType: fnolDTO.policyType,
            isCityMandatory: isPropertyLOB(fnolDTO.lob),
            isPostalCodeMandatory: isPropertyLOB(fnolDTO.lob),
            isStreetNameMandatory: isPropertyLOB(fnolDTO.lob),
            isStreetNumberMandatory: isPropertyLOB(fnolDTO.lob)
        },
        paPointOfImpactSelect: {
            allPossibleDamagePoints: createVehicleDamageTypeList(),
            onValueChange: vehiclePartsValueChange,
        },
        otherDamagesDescription: {
            visible: vehicleDamageDTO.dmgOtherDamages === true,
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        trailerDamagesContainer: {
            visible: otherDamagesDTO.dmgTrailerDamageHeavy === true
        },
        trailerOtherDamagesText: {
            visible: trailerDamageDTO.dmgOtherDamages === true,
            className: mobileOrRegularStyle('phoneInputContainer', 'marginTopContainer')
        },
        damageTitleContainer: {
            visible: (!isGlassDamage)
        },
        damagePickerAndBoxesContainer: {
            visible: (!isGlassDamage)
        },
        damageCheckBoxesR: {
            visible: (!isGlassDamage)
        },
        otherDamagesContainer: {
            visible: (!isGlassDamage)
        },
        wizardFnolLossDetailsSelectPart: getVehicleDamageTitle(),
        dmgGlassBreak: {
            onValueChange: vehicleDamageBoxesChange
        },
        dmgTires: {
            onValueChange: vehicleDamageBoxesChange
        },
        dmgBottom: {
            onValueChange: vehicleDamageBoxesChange
        },
        dmgNoVisibleDamage: {
            onValueChange: vehicleDamageBoxesChange
        },
        trailerDmgTires: {
            onValueChange: trailerDamageBoxesChange
        },
        trailerDmgBottom: {
            onValueChange: trailerDamageBoxesChange
        },
        trailerDmgOtherDamages: {
            onValueChange: trailerDamageBoxesChange
        },
        trailerNoVisibleDamage: {
            onValueChange: trailerDamageBoxesChange
        },

    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleValueChange,
            onValidate: setComponentValidation,
        },
        resolveComponentMap: {
            vehiclecollision: VehicleDamagePickerMulti,
            checkboxField: CheckboxField
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={fnolVM}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={setComponentValidation}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default LossDetailsCar;
