import { defineMessages } from 'react-intl';

export default defineMessages({
    wizardFNOLAutoRepairHeader: {
        id: 'anonymous.fnol.wizard.pages.repair.components.auto.Do you need a repair facility?',
        defaultMessage: 'Do you need a repair facility?'
    },
    wizardFNOLAutoRepairCarGlassHeader: {
        id: 'anonymous.fnol.wizard.pages.repair.components.auto.There is no franchise if the repair or replacement is done by a repairer or carglass recognized by the insurer. A franchise of 70 euros is applied in case of a repair or replacement at another repairer?',
        defaultMessage: 'There is no franchise if the repair or replacement is done by a repairer or Carglass recognized by the insurer. A franchise of 70 euros is applied in case of a repair or replacement at another repairer.'
    },
    wizardFNOLAutoRepairChoicePreferredVendor: {
        id: 'anonymous.fnol.wizard.pages.repair.components.auto.repairChoice.I want to use a recommended repair facility',
        defaultMessage: 'I want to use a recommended repair facility'
    },
    wizardFNOLAutoRepairChoicePreferredVendorMoreInfo: {
        id: 'anonymous.fnol.wizard.pages.repair.components.auto.repairChoice.More info',
        defaultMessage: 'More info'
    },
    wizardFNOLAutoRepairChoiceCarGlass: {
        id: 'anonymous.fnol.wizard.pages.repair.components.auto.repairChoice.I want to make an appointment directly with Carglass.',
        defaultMessage: 'I want to make an appointment directly with Carglass.'
    },
    wizardFNOLAutoRepairChoiceCarGlassMakeAppointment: {
        id: 'anonymous.fnol.wizard.pages.repair.components.auto.repairChoice.Make an appointment.',
        defaultMessage: 'Make an appointment'
    },
    wizardFNOLAutoRepairChoiceNewVendor: {
        id: 'anonymous.fnol.wizard.pages.repair.components.auto.repairChoice.I’ll provide repair facility details myself',
        defaultMessage: 'I’ll provide repair facility details myself'
    },
    wizardFNOLAutoRepairChoiceHaventDecided: {
        id: "anonymous.fnol.wizard.pages.repair.components.auto.repairChoice.I haven't decided yet or I don't need repair facility",
        defaultMessage: "I haven't decided yet or I don't need repair facility"
    }
});
