import { defineMessages } from 'react-intl';

export default defineMessages({
    wizardFnolSummaryPageTitle: {
        id: 'anonymous.wizard.fnol.pages.summary.Summary',
        defaultMessage: 'Summary'
    },
    wizardFnolSummaryPageReviewSummary: {
        id: 'anonymous.wizard.fnol.pages.summary.Please review the following information before submiting:',
        defaultMessage: 'Please review the following information before submitting:'
    },
    wizardFnolSummaryPagePolicyNumber: {
        id: 'anonymous.wizard.fnol.pages.summary.Policy Number',
        defaultMessage: 'Policy number'
    },
    wizardFnolSummaryPageDateOfLoss: {
        id: 'anonymous.wizard.fnol.pages.summary.Date of loss',
        defaultMessage: 'Date of loss'
    },
    wizardFnolSummaryPageDeclarationCause: {
        id: 'anonymous.wizard.fnol.pages.summary.What do you wish to submit a declaration for?',
        defaultMessage: 'Declaration for'
    },
    wizardFnolSummaryPageLossCause: {
        id: 'anonymous.wizard.fnol.pages.summary.Loss cause',
        defaultMessage: 'Circumstances'
    },
    wizardFnolSummaryPageDamageType: {
        id: 'anonymous.wizard.fnol.pages.summary.Damage Type',
        defaultMessage: 'Damage'
    },
    wizardFnolSummaryPageWhatHappend: {
        id: 'anonymous.wizard.fnol.pages.summary.What happend?',
        defaultMessage: 'What happened?'
    },
    fnolSummaryWhereDidItHappen: {
        id: 'anonymous.wizard.fnol.pages.summary.Where did it happend?',
        defaultMessage: 'Where did it happen?'
    },
    wizardFnolSubmitLabel: {
        id: 'anonymous.wizard.fnol.pages.summary.Submit',
        defaultMessage: 'Submit'
    },
    wizardFnolRedirectToContactPageTitle: {
        id: 'anonymous.wizard.fnol.pages.redirect.Sorry, we are unable to submit your claim',
        defaultMessage: 'Sorry, we are unable to submit your claim'
    },
    wizardFnolRedirectToContactPageSubTitle: {
        id: 'anonymous.wizard.fnol.pages.redirect.We have some unexpected technical issues',
        defaultMessage: 'We are experiencing some unexpected technical issues'
    },
    wizardFnolSummaryPageSupportingInformation: {
        id: 'anonymous.wizard.fnol.pages.summary.Supporting Informations',
        defaultMessage: 'Supporting Informations'
    },
    wizardFnolSummaryPageOtherParties: {
        id: 'anonymous.wizard.fnol.pages.summary.Other Parties',
        defaultMessage: 'Other involved parties'
    },
    wizardFnolSummaryPageNoOtherPartiesInvolved: {
        id: 'anonymous.wizard.fnol.pages.summary.No other parties involved',
        defaultMessage: 'No other parties involved'
    },
    wizardFnolSummaryPageReporterDetails: {
        id: 'anonymous.wizard.fnol.pages.summary.Reporter Contact Details',
        defaultMessage: 'Contact Details of the Reporter'
    },
    wizardFnolSummaryPageConsentGDPRConsents: {
        id: 'anonymous.wizard.fnol.pages.summary.Consents',
        defaultMessage: 'Consents'
    },
    wizardFnolSummaryPageConsentLineOne: {
        id: 'anonymous.wizard.fnol.pages.summary.I agree with the Privacy Policy of NN and the Assuralia anti-fraud statement.',
        defaultMessage: 'I agree with the Privacy Policy of NN and the Assuralia anti-fraud statement.'
    },
    wizardFnolSummaryPageConsentLineTwo: {
        id: 'anonymous.wizard.fnol.pages.summary.I confirm that all the information provided will be true and accurate to the best of my knowledge.',
        defaultMessage: 'I confirm that all the information provided will be true and accurate to the best of my knowledge.'
    },
    wizardFnolSummaryPageNote: {
        id: 'anonymous.wizard.fnol.pages.summary.Would you like to add something more?',
        defaultMessage: 'Would you like to add something more?'
    },
    wizardFnolSummaryPageEnterText: {
        id: 'anonymous.wizard.fnol.pages.summary.Enter text here...',
        defaultMessage: 'Enter text here...'
    },
    wizardFnolSummaryPageFiles: {
        id: 'anonymous.wizard.fnol.pages.summary.files',
        defaultMessage: 'Files'
    },
    wizardFnolSummaryPageDriverInjured: {
        id: 'anonymous.wizard.fnol.pages.summary.Injured',
        defaultMessage: 'Injured'
    },
    wizardFnolSummaryPageDriverNotInjured: {
        id: 'anonymous.wizard.fnol.pages.summary.Not injured',
        defaultMessage: 'Not injured'
    },
    wizardFnolSummaryPageDataConfirmation: {
        id: 'anonymous.wizard.fnol.pages.summary.I confirm that all the information provided is true and accurate to the best of my knowledge',
        defaultMessage: 'I confirm that all the information provided is true and accurate to the best of my knowledge. *'
    },
    wizardFnolSummaryPageDriverUnidentifiedParty: {
        id: 'anonymous.wizard.fnol.pages.summary.Additional unidentified party',
        defaultMessage: 'Additional unidentified party'
    },
    wizardFnolSummaryPagePartyWithEAS: {
        id: 'anonymous.wizard.fnol.pages.summary.See European Accident Statement',
        defaultMessage: 'See European Accident Statement'
    },
    wizardFnolSummaryPageRepairDetailsBankAccountDetails: {
        id: 'anonymous.wizard.fnol.pages.summary.Bank account details:',
        defaultMessage: 'Bank account details:'
    }
});
