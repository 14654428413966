/* eslint-disable max-len */
import _ from 'lodash';
import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { ViewModelForm, ViewModelServiceContext, withViewModelService } from 'gw-portals-viewmodel-react';
import { FNOLService } from 'nn-capability-fnol';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import moment from 'moment';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import AuthenticatedContext from '../../contexts/AuthenticatedContext/AuthenticatedContext';
import { redirectToAuthContactCallCenter } from '../../utils/RedirectionUtil';
import PleaseWait from '../../components/PleaseWait/PleaseWait';
import metadata from './AuthenticatedBrokerPolicySearch.metadata.json5';
// eslint-disable-next-line no-unused-vars
import messages from './AuthenticatedBrokerPolicySearch.messages';
import styles from './AuthenticatedBrokerPolicySearch.scss';

function AuthenticatedBrokerPolicySearch(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const { history, location } = props;
    const { state } = location;
    const policyNumberEditable = _.isEmpty(state) ? false : state.policyNumberEditable;
    const [policySearchDTO, setPolicySearchDTO] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showPolicyNotFoundError, setShowPolicyNotFoundError] = useState(false);
    const [policySearchErrorContext, setPolicySearchErrorContext] = useState();
    const [timeStampVisibleValue, setTimeStampVisibleValue] = useState();
    const partnerContext = useContext(PartnerContext);

    const {
        authenticationData
    } = useContext(AuthenticatedContext);

    const {
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('AuthenticatedBrokerPolicySelect');

    if (_.isEmpty(authenticationData.authenticatedRequestData) || !authenticationData.authenticatedRequestData.authorizationObject || authenticationData.error) {
        // Access to this page should be granted only if there is authentication token and policyNumber in URL path.
        redirectToAuthContactCallCenter(history);
    }

    useEffect(() => {
        const initialDataObject = history.location.state && history.location.state.initialData
            ? history.location.state.initialData
            : { policyNumber: authenticationData.authenticatedRequestData.authorizationObject };

        const initialSearchDTO = viewModelService.create(
            initialDataObject,
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.policy.dto.PolicySearchDTO'
        );

        return setPolicySearchDTO(initialSearchDTO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty Array as we don't want this code to re-run

    const validateTimeOfLoss = useCallback(() => {
        if (policySearchDTO && policySearchDTO.value) {
            if (timeStampVisibleValue === undefined) return false;

            return timeStampVisibleValue
                ? policySearchDTO.value.lossDate.hour !== undefined
                : true;
        }
        return true;
    }, [policySearchDTO, timeStampVisibleValue]);

    useEffect(() => {
        registerComponentValidation(validateTimeOfLoss);
    }, [registerComponentValidation, validateTimeOfLoss]);

    const onValueChange = useCallback((value, path) => {
        const updatedDTO = viewModelService.clone(policySearchDTO);
        _.set(updatedDTO.value, path, value);
        setPolicySearchDTO(updatedDTO);
    }, [policySearchDTO, viewModelService]);

    const performPolicySearch = useCallback(() => {
        setIsLoading(true);

        policySearchDTO.authRequestDetails = {
            ...authenticationData.authenticatedRequestData,
            authorizationObject: policySearchDTO.policyNumber.value
        };

        FNOLService.initializeAuthenticatedBrokerFlow(policySearchDTO.value, history, { partnerContext, translator }).then(
            (resultDTO) => {
                if (!resultDTO) return;
                if (!_.isEmpty(resultDTO.error)) {
                    const { error } = resultDTO;
                    switch (error.errorCode) {
                        case '210': // 210 - Policy not found/Duplicated policy/LOB not supported
                            setShowPolicyNotFoundError(true);
                            setPolicySearchErrorContext('policyNotValid');
                            break;
                        case '401': // 401 - agent identity not determined
                        case '403': // 403 - agent not allowed to use this policy
                            setShowPolicyNotFoundError(true);
                            setPolicySearchErrorContext('agentNotAllowedToUse');
                            break;
                        case '500': // Default error
                        default:
                            redirectToAuthContactCallCenter(history, true);
                    }
                } else {
                    resultDTO.authRequestDetails = {
                        ...authenticationData.authenticatedRequestData,
                        authorizationObject: resultDTO.policyNumber
                    }

                    history.push({
                        pathname: '/fnol',
                        state: {
                            redirectPath: '/',
                            fnolDto: resultDTO
                        }
                    });
                }
            }
        ).finally(() => {
            setIsLoading(false);
        });
    }, [policySearchDTO, authenticationData.authenticatedRequestData, history, partnerContext, translator]);

    const shouldPolicyNumberBeDisabled = useMemo(() => {
        if (policyNumberEditable) {
            return false;
        }
        return showPolicyNotFoundError === false || policySearchErrorContext === 'policyNotValid';
    }, [showPolicyNotFoundError, policyNumberEditable, policySearchErrorContext]);

    const overrideProps = {
        authenticatedBrokerSearchLossDate: {
            maxDate: moment().toDate(),
            timeStampVisibleValue: timeStampVisibleValue,
            setTimeStampVisibleValue: setTimeStampVisibleValue
        },
        authenticatedBrokerSearchButton: {
            disabled: !isComponentValid
        },
        authenticatedBrokerSearchPolicyNumberInput: {
            disabled: shouldPolicyNumberBeDisabled
        },
        authenticatedBrokerSearchPolicyNotFoundErrorText: {
            visible: showPolicyNotFoundError === true && policySearchErrorContext === 'policyNotValid'
        },
        authenticatedBrokerSearchAgentNotAllowedToUseText: {
            visible: showPolicyNotFoundError === true && policySearchErrorContext === 'agentNotAllowedToUse'
        },
        authenticatedBrokerSearchPolicyContainer: {
            visible: !isLoading
        },
        authenticatedBrokerSearchPageLoader: {
            visible: isLoading
        }
    };

    const resolvers = {
        resolveComponentMap: {
            pleaseWait: PleaseWait
        },
        resolveCallbackMap: {
            onValidate,
            performPolicySearch
        },
        resolveClassNameMap: styles
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policySearchDTO}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onValueChange={onValueChange}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </div>
    );
}

export default withViewModelService(AuthenticatedBrokerPolicySearch);
