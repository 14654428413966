import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolAddressComponentCountry: {
        id: 'anonymous.fnol.components.address.Country',
        defaultMessage: 'Country'
    },
    fnolAddressComponentCity: {
        id: 'anonymous.fnol.components.address.City',
        defaultMessage: 'City'
    },
    fnolAddressComponentPostalCode: {
        id: 'anonymous.fnol.components.address.Zip Code',
        defaultMessage: 'Zip code'
    },
    fnolAddressComponentStreet: {
        id: 'anonymous.fnol.components.address.Street Name',
        defaultMessage: 'Street name'
    },
    fnolAddressComponentStreetNumber: {
        id: 'anonymous.fnol.components.address.Street Number',
        defaultMessage: 'House Number'
    },
    fnolAddressComponentBoxNumber: {
        id: 'anonymous.fnol.components.address.Box Number',
        defaultMessage: 'Box number'
    },
    fnolAddressComponentAutoCompleteHeader: {
        id: 'anonymous.fnol.components.address.autoComplete.To use auto-complete please start typing you address below.',
        defaultMessage: 'To use the auto-complete please start typing your address below. You can just start typing the address of the location in the different fields.'
    }
});
