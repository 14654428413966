/* eslint-disable max-len */
import _ from 'lodash';

function getTypeFilterCodes(typelistVM, filterName) {
    const damageTypeTypelist = typelistVM.aspects.availableValues[0].typelist;
    return damageTypeTypelist.getFilter(filterName).codes.map((filterCode) => filterCode.code);
}

function areCodesInFilter(typelistVM, availableLossCauses, filterName) {
    if (!availableLossCauses || _.isEmpty(availableLossCauses)) return false;

    const filterCodes = getTypeFilterCodes(typelistVM, filterName);
    const crossedDamageTypes = availableLossCauses.filter((lossCause) => filterCodes.includes(lossCause));
    return !_.isEmpty(crossedDamageTypes);
}

export function isDamageTypeOther(claimVM, lossCauses) {
    return !!claimVM.frontEndClaimCause.value
        && claimVM.frontEndClaimCause.value.code === 'DamageToOthers'
        && areCodesInFilter(claimVM.frontEndLossCause, lossCauses, 'OtherDamagesTypecodes');
}

export function isLegalAssistanceProperty(claimVM, lossCauses) {
    return !!claimVM.frontEndClaimCause.value
        && claimVM.frontEndClaimCause.value.code === 'LegalAssistanceProperty'
        && areCodesInFilter(claimVM.frontEndLossCause, lossCauses, 'LegalPropertyTypecodes');
}

export function isLegalAssistanceOrCivilLiability(claimVM, lossCauses) {
    return isDamageTypeOther(claimVM, lossCauses)
        || isLegalAssistanceProperty(claimVM, lossCauses);
}

export function getLossCauseLegalAssistance(dmgReason, covers) {
    if (['DmgProperty', 'TroubleNeighbour'].includes(dmgReason)) {
        return (covers.includes('RBPR')) ? 'LegalHousingFamily' : 'LegalHousingHome';
    }

    if (['CriminalDefense', 'WasSummoned', 'Other'].includes(dmgReason)) {
        return (covers.includes('RBPR')) ? 'LegalAssistancePrivateFamily' : 'LegalHousingHome';
    }

    if (['DmgToMeOrFamily', 'DmgMyPet'].includes(dmgReason)) {
        return 'LegalAssistancePrivateFamily';
    }

    if (dmgReason === 'ContractualDispute') {
        return 'LegalAssistanceContractual';
    }

    return undefined;
}

export function getLossCauseCivilLiability(dmgCause, covers) {
    if (['MyProperty', 'MyContent', 'Neighbour', 'Other'].includes(dmgCause)) {
        return (covers.includes('BAPR')) ? 'CivilLiabilityPrivateFamily' : 'CivilLiabilityHousingHome';
    }

    if (['MyPet', 'MeOrMemberOfHousehold', 'RoadUser'].includes(dmgCause)) {
        return 'CivilLiabilityPrivateFamily';
    }

    return undefined;
}
