import { defineMessages } from 'react-intl';

export default defineMessages({
    faq: {
        id: 'portal.index.FAQ',
        defaultMessage: 'FAQ'
    },
    additionalEnrollment: {
        id: 'portal.index.Additional Enrollment',
        defaultMessage: 'Additional Enrollment'
    },
    claimList: {
        id: 'portal.index.Claims',
        defaultMessage: 'Claims'
    },
    claimDetails: {
        id: 'portal.index.Claim Details',
        defaultMessage: 'Claim Details'
    },
    createNote: {
        id: 'portal.index.Create Note',
        defaultMessage: 'Create Note'
    },
    policyDetails: {
        id: 'portal.index.Policy Details',
        defaultMessage: 'Policy Details'
    },
    accountInformation: {
        id: 'portal.index.Account Information',
        defaultMessage: 'Account Information'
    },
    homePage: {
        id: 'portal.index.Home Page',
        defaultMessage: 'Home Page'
    },
    logout: {
        id: 'header.links.Logout',
        defaultMessage: 'Logout'
    },
    titleNotification: {
        id: 'anonymous.application.naviagtion.error.title.Browser back button disabled',
        defaultMessage: 'Browser back button disabled'
    },
    subtitleNotification: {
        id: 'anonymous.application.naviagtion.error.subtitle.In order to move around the pages in application, please use inbuilt navigation',
        defaultMessage: 'Use the buttons in this application to navigate through the different steps.'
    },
    confirmNotification: {
        id: 'anonymous.application.naviagtion.error.Confirm',
        defaultMessage: 'Close'
    }
});
