import { defineMessages } from 'react-intl';

export default defineMessages({
    contactTypeInput: {
        id: 'anonymous.fnol.components.contact.Is the party person or company?',
        defaultMessage: 'Is the party a person or a legal entity?'
    },
    companyNameInput: {
        id: 'anonymous.fnol.components.contact.Company Name',
        defaultMessage: 'Company name'
    },
    firstNameInput: {
        id: 'anonymous.fnol.components.contact.First name',
        defaultMessage: 'First name'
    },
    lastNameInput: {
        id: 'anonymous.fnol.components.contact.Last name',
        defaultMessage: 'Last name'
    },
    genderInput: {
        id: 'anonymous.fnol.components.contact.Gender',
        defaultMessage: 'Gender'
    },
    languageInput: {
        id: 'anonymous.fnol.components.contact.Language',
        defaultMessage: 'Language'
    },
    emailInput: {
        id: 'anonymous.fnol.components.contact.Email',
        defaultMessage: 'Email'
    },
    phoneInput: {
        id: 'anonymous.fnol.components.contact.Phone Number',
        defaultMessage: 'Phone Number'
    }
});
