/* eslint-disable max-len */
import React, { useCallback, useContext, useEffect } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import useTypelistHandler from '../../hooks/useTypelistHandler';
import { getLossCauseCivilLiability } from '../../utils/LossCausesUtil';
import { getTypecodeTranslation } from '../../utils/TypeCodeTranslationUtil';
import CustomRadioButtonField from '../CustomRadioButtonField/CustomRadioButtonField';
import metadata from './BasicInformationPropertyCivil.metadata.json5';
import messages from './BasicInformationPropertyCivil.messages';
import styles from './BasicInformationPropertyCivil.module.scss';

function BasicInformationPropertyCivil(props) {
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        id,
        onValueChange,
        writeValue,
        value: fnolVM,
        onValidate,
        coverages
    } = props;

    const { getValuesFromTypelistIncludeFilter } = useTypelistHandler();

    if (!fnolVM.value.civilLiabilityDamage) {
        fnolVM.value.civilLiabilityDamage = {};
    }

    const {
        onValidate: onComponentValidate,
        isComponentValid
    } = useValidation('BasicInformationPropertyCivil');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const getTypelistValues = useCallback((field, filterName) => {
        return getValuesFromTypelistIncludeFilter(
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.CivilLiabilityDamageDTO',
            field,
            filterName,
        );
    }, [getValuesFromTypelistIncludeFilter]);

    const onDamageCauseChange = useCallback((newValue) => {
        writeValue(newValue, 'civilLiabilityDamage.damageCause');
        fnolVM.value.frontEndLossCause = getLossCauseCivilLiability(newValue, coverages);
    }, [coverages, fnolVM.value, writeValue]);

    const handleValueChange = useCallback((value, changedPath) => {
        onValueChange(value, changedPath);
    }, [onValueChange]);

    const overrideProps = {
        myProperty: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'MyProperty',
                name: getTypecodeTranslation(translator, 'MyProperty', 'inlb_FrontendCLCausedDmg')
            }]
        },
        myPropertyTooltip: {
            tooltipMessage: translator(messages.propertyDamageTooltip)
        },
        myContent: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'MyContent',
                name: getTypecodeTranslation(translator, 'MyContent', 'inlb_FrontendCLCausedDmg')
            }]
        },
        myPet: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'MyPet',
                name: getTypecodeTranslation(translator, 'MyPet', 'inlb_FrontendCLCausedDmg')
            }]
        },
        meOrMemberOfHousehold: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'MeOrMemberOfHousehold',
                name: getTypecodeTranslation(translator, 'MeOrMemberOfHousehold', 'inlb_FrontendCLCausedDmg')
            }]
        },
        roadUser: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'RoadUser',
                name: getTypecodeTranslation(translator, 'RoadUser', 'inlb_FrontendCLCausedDmg')
            }]
        },
        roadUserOtherSpecifiy: {
            visible: !!fnolVM.civilLiabilityDamage.roadUser.value && fnolVM.civilLiabilityDamage.roadUser.value.code === 'Other'
        },
        neighbour: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'Neighbour',
                name: getTypecodeTranslation(translator, 'Neighbour', 'inlb_FrontendCLCausedDmg')
            }]
        },
        other: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'Other',
                name: getTypecodeTranslation(translator, 'Other', 'inlb_FrontendCLCausedDmg')
            }]
        },
        myPetContainer: {
            visible: (fnolVM.value.civilLiabilityDamage.damageCause === 'MyPet')
        },
        otherContainer: {
            visible: (fnolVM.value.civilLiabilityDamage.damageCause === 'Other')
        },
        meOrMemberHouseholdContainer: {
            visible: (fnolVM.value.civilLiabilityDamage.damageCause === 'MeOrMemberOfHousehold')
        },
        meOrMemberWhoInput: {
            availableValues: getTypelistValues('who', 'PropertyCivilLiabilityFilter')
        },
        meOrMemberAgeInput: {
            availableValues: getTypelistValues('age')
        },
        roadUserContainer: {
            visible: (fnolVM.value.civilLiabilityDamage.damageCause === 'RoadUser')
        },
        roadUserWhoInput: {
            availableValues: getTypelistValues('who', 'PropertyCivilLiabilityFilter')
        },
        roadUserTypeInput: {
            availableValues: getTypelistValues('roadUser')
        },
        roadUserAgeInput: {
            availableValues: getTypelistValues('age')
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            tooltip: CustomRadioButtonField
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={fnolVM.value}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onComponentValidate}
            />
        </div>
    );
}

export default BasicInformationPropertyCivil;
