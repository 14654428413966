import { defineMessages } from 'react-intl';

export default defineMessages({
    wizardFnolRepairTitle: {
        id: 'anonymous.wizard.fnol.pages.repair.Repair',
        defaultMessage: 'Repair of your damage'
    },
    wizardFnolEBikeRepairTitle: {
        id: 'anonymous.wizard.fnol.pages.repair.ebikeTitle.Estimation',
        defaultMessage: 'Estimation'
    }
});
