/* eslint-disable max-len */
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import BasicInformationQuestionSetCheckbox from '../BasicInformationQuestionSetCheckbox/BasicInformationQuestionSetCheckbox';
import metadata from './BasicInformationQuestionSet.metadata.json5';
import styles from './BasicInformationQuestionSet.module.scss';

function BasicInformationQuestionSet(props) {
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        id,
        data,
        onValidate,
        onQuestionSetOtherDamageUpdate,
        onQuestionSetPick,
        onGetQuestionSetValue,
        onGetQuestionSetOtherDamage,
        onShowOtherDamagesInput,
        onDamageTypeSelection,
        onIsDamageTypeSelected,
        onGetMultipleDamageTypesAllowed
    } = props;

    const dtoData = viewModelService.create(
        data,
        'cc',
        'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.DamageQuestionSetsDTO'
    );

    const {
        onValidate: onComponentValidate,
        isComponentValid
    } = useValidation('BasicInformationQuestionSet');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const onGetDamageType = useCallback(() => {
        return data.damageType;
    }, [data.damageType]);

    const onIsSingleSelect = () => data.singleSelect;

    const getTypecodeTranslation = useCallback((code, typelist) => {
        return translator({
            id: `typekey.${typelist}.${code}`,
            defaultMessage: code
        });
    }, [translator]);

    const allowedMultipleDamageTypesSelected = useMemo(() => onGetMultipleDamageTypesAllowed());

    const overrideProps = {
        basicInfoQuestionSetContainer: {
            className: allowedMultipleDamageTypesSelected ? 'smallBottomMargin' : ''
        },
        basicInfoQuestionSetDamageTypeRadioButton: {
            onValueChange: (value) => onDamageTypeSelection(value, value, !allowedMultipleDamageTypesSelected),
            value: onIsDamageTypeSelected(data.damageType) ? data.damageType : '',
            availableValues: [{
                id: data.damageType,
                displayName: getTypecodeTranslation(data.damageType, 'inlb_FrontEndDamageType')
            }],
            visible: !allowedMultipleDamageTypesSelected
        },
        basicInfoQuestionSetDamageType: {
            label: getTypecodeTranslation(data.damageType, 'inlb_FrontEndDamageType'),
            visible: allowedMultipleDamageTypesSelected,
            value: onIsDamageTypeSelected(data.damageType),
            onValueChange: (value) => onDamageTypeSelection(value, data.damageType, !allowedMultipleDamageTypesSelected),
        },
        basicInfoQuestionSetQuestions: {
            data: data.availableQuestions,
            visible: !_.isEmpty(data.availableQuestions)
        },
        damageTypeQuestionSetOtherDamageDescription: {
            visible: onShowOtherDamagesInput(data.damageType),
            value: onGetQuestionSetOtherDamage(data.damageType),
            onValueChange: (value) => onQuestionSetOtherDamageUpdate(data.damageType, value)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onQuestionSetPick,
            onGetQuestionSetValue,
            onGetDamageType,
            onIsSingleSelect
        },
        resolveComponentMap: {
            questionSetCheckbox: BasicInformationQuestionSetCheckbox
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={dtoData}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onComponentValidate}
            />
        </div>
    );
}

export default BasicInformationQuestionSet;
