import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const DependencyContext = React.createContext({});

/**
 * Merges existing dependencies with new one.
 * New dependencies are addedd to the existing ones,
 * however if one or more dependencies already exist in `originalDeps`,
 * these are overridden by the corresponding ones in `newDeps`
 *
 * @param {Object} originalDeps the dependencies already available
 * @param {Object} newDeps the new dependencies to add
 * @param {boolean} warnOnOverride whether the developer should be warned of a dependency
 *                              already available in `originalDeps` being overridden
 *                              by one that is in `newDeps`
 * @returns {Object} the dependencies resulting from the merge. Dependencies in `newDeps`
 *                    will override the ones in `originalDeps`
 */
function mergeDependencies(originalDeps, newDeps, warnOnOverride) {
    const originalDepsClone = _.clone(originalDeps);
    const mergedDeps = _.mergeWith(originalDepsClone, newDeps, (originalDep, newDep, depName) => {
        if (!_.isUndefined(originalDep) && warnOnOverride) {
            // eslint-disable-next-line no-console
            console.warn(`Overriding ${depName}`);
        }
        return newDep;
    });
    return mergedDeps;
}

export function DependencyProvider(props) {
    const dependencies = useContext(DependencyContext);
    const { children, value, warnOnOverride } = props;
    const mergedDependencies = mergeDependencies(dependencies, value, warnOnOverride);
    return (
        <DependencyContext.Provider value={mergedDependencies}>
            {children}
        </DependencyContext.Provider>
    );
}

DependencyProvider.propTypes = {
    children: PropTypes.node,
    value: PropTypes.shape({}).isRequired,
    warnOnOverride: PropTypes.bool
};

DependencyProvider.defaultProps = {
    children: null,
    warnOnOverride: false
};

export function DependencyConsumer(props) {
    const { dependencies: depNames, children } = props;
    return (
        <DependencyContext.Consumer>
            {(dependencies) => {
                const providedDeps = _.pick(dependencies, depNames);
                return children(providedDeps);
            }}
        </DependencyContext.Consumer>
    );
}

DependencyConsumer.propTypes = {
    dependencies: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
        .isRequired,
    children: PropTypes.func.isRequired
};
