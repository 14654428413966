/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { setComponentMapOverrides } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import vmTranslator, { messages as commonMessages } from 'gw-platform-translations';
import { redirectToAuthContactCallCenter } from 'nn-capability-fnol-react/utils/RedirectionUtil';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';
import { FNOLService } from 'nn-capability-fnol';
import { Main, ModalNextProvider, Loader } from '@jutro/components';

import {
    PrivacyPolicyPage,
    AssuraliaAntiFraudPage,
    FNOLWizard,
    ContactCallCenterPage,
    FNOLQuickFlowPage,
    PageContainer,
    FnolConfirmationPage,
    AuthenticationLandingPage,
    AuthenticatedPolicySelect,
    AuthenticatedBrokerPolicySearch,
    AuthenticatedContactCallCenterPage,
    platformComponents as nnPlatformComponents,
    platformComponentMap as nnPlatformComponentMap
} from 'nn-capability-fnol-react';
import './App.scss';
import useErrorHandler from 'nn-capability-fnol-react/hooks/useErrorHandler';
import { ErrorBoundary } from 'gw-portals-error-react';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-react';

import {
    platformComponentMap,
    platformComponents,
    ImageComponent,
} from 'gw-components-platform-react';

import AuthenticatedContext from '../../../../common/capabilities-react/nn-capability-fnol-react/contexts/AuthenticatedContext/AuthenticatedContext';
import PartnerContext from '../../../../common/capabilities-react/nn-capability-fnol-react/contexts/PartnerContext/PartnerContext';
import getPartnerValuesProvider from '../../../../common/capabilities-react/nn-capability-fnol-react/services/partner/PartnerServiceLayer';
import ClientContext from '../../../../common/capabilities-react/nn-capability-fnol-react/contexts/ClientContext/ClientContext';
import getClientValuesProvider from '../../../../common/capabilities-react/nn-capability-fnol-react/services/client/ClientServiceLayer';

import messages from './App.messages';

setComponentMapOverrides(
    {
        ...platformComponentMap,
        ...nnPlatformComponentMap,
        img: { component: 'img' },
        WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
    },
    {
        ...platformComponents,
        ...nnPlatformComponents,
        img: ImageComponent,
        WizardPageTemplateWithTitle,
    }
);

function App(props) {
    const translator = useContext(TranslatorContext);
    const [viewModelService, setViewModelService] = useState(undefined);
    const channel = sessionStorage.getItem('channel');
    const client = sessionStorage.getItem('client');
    const errorHandler = useErrorHandler();

    const buildInitialClientData = useCallback(() => {
        return getClientValuesProvider(client.toUpperCase());
    }, [client]);

    const [clientValuesProvider] = useState(buildInitialClientData());

    const buildInitialPartnerData = useCallback(() => {
        return getPartnerValuesProvider(channel.toUpperCase());
    }, [channel]);

    const [parnerValuesProvider] = useState(buildInitialPartnerData());

    const { history } = props;

    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            setViewModelService(
                ViewModelServiceFactory.getViewModelService(result, translatorFn)
            );
        });
    }, [translator]);

    const [authenticationData, setAuthenticationData] = useState();

    useEffect(() => {
        // If authentication data is already present, don't do nothing.
        if (!_.isEmpty(authenticationData)) return;

        const internalTokenGenerationRequest = { authRequestDetails: {} };
        const urlParams = new URL(window.location.href).searchParams;

        // If there is an error, there is no point of generating internal token.
        if (urlParams.has('error')) {
            setAuthenticationData({ error: urlParams.get('error') });
            return;
        }

        // If there is no error, verify if a token is present in the url.
        if (urlParams.has('redirect')) {
            internalTokenGenerationRequest.authRequestDetails.token = urlParams.get('redirect');
        }

        if (urlParams.has('token')) {
            internalTokenGenerationRequest.authRequestDetails.token = urlParams.get('token');
        }

        // Generate internal token.
        if (urlParams.has('authenticationType')) {
            internalTokenGenerationRequest.authRequestDetails.tokenType = urlParams.get('authenticationType');
        } else if (internalTokenGenerationRequest.authRequestDetails.token !== undefined && internalTokenGenerationRequest.authRequestDetails.tokenType === undefined) {
            internalTokenGenerationRequest.authRequestDetails.tokenType = 'itsme';
        }

        if (urlParams.has('policyNumber')) {
            internalTokenGenerationRequest.authRequestDetails.authorizationObject = urlParams.get('policyNumber');
        }

        if (internalTokenGenerationRequest.authRequestDetails.token !== undefined) {
            internalTokenGenerationRequest.authRequestDetails.channel = clientValuesProvider.getAuthenticationChannel();
            internalTokenGenerationRequest.authRequestDetails.portalType = 'Customer Engage Portal';
        }

        const isRequestFromURLValid = !_.isEmpty(internalTokenGenerationRequest.authRequestDetails);

        // If authentication request cannot be done based on URL, check session storage.
        if (!isRequestFromURLValid && sessionStorage.getItem('authorizationData') !== null) {
            internalTokenGenerationRequest.authRequestDetails = JSON.parse(sessionStorage.getItem('authorizationData')).authenticatedRequestData;
        }

        // If there is no token, internal token cannot be generated.
        if (internalTokenGenerationRequest.authRequestDetails.token === undefined) {
            setAuthenticationData({});
            return;
        }

        FNOLService.generateInternalToken(internalTokenGenerationRequest, history, { parnerValuesProvider, translator })
            .then((response) => {
                if (response.error !== undefined) {
                    redirectToAuthContactCallCenter(history, true);
                    setAuthenticationData({});
                } else {
                    setAuthenticationData(() => {
                        const authorizationData = {};
                        authorizationData.authenticatedRequestData = {
                            token: response.nninternalToken,
                            tokenType: 'NNInternal',
                            portalType: internalTokenGenerationRequest.authRequestDetails.portalType,
                            channel: internalTokenGenerationRequest.authRequestDetails.channel,
                            authorizationObject: internalTokenGenerationRequest.authRequestDetails.authorizationObject
                        };
                        sessionStorage.setItem('authorizationData', JSON.stringify(authorizationData));
                        return authorizationData;
                    });
                }
            });
    }, [clientValuesProvider, history, parnerValuesProvider, translator]);

    function popStateListener() {
        const allowedSearchPaths = ['/b-auth-policy-search', '/policy-select'];
        const foundPath = allowedSearchPaths.find((path) => {
            if (location.href.includes(path)) {
                return true;
            }
        });

        if (foundPath !== undefined) {
            ModalNextProvider.showAlert({
                title: messages.titleNotification,
                message: messages.subtitleNotification,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: messages.confirmNotification
            }).catch(_.noop);
        }
    }

    window.addEventListener('popstate', popStateListener);

    const handleError = useCallback((error) => {
        errorHandler.handleError(error);
        return false;
    }, [errorHandler]);

    const buildInitialPath = () => {
        // As URLS start from /INF/EN/AP which is not recognized by application as valid paths in application context, application by default redirects every entry to main page "/".
        // What we're trying to do here is to make sure, that a exact page can be specified and entered even if "/INF/EN/AP" is present.
        const requestedURL = location.href;
        const allowedPaths = ['/b-auth-policy-search', '/policy-select'];

        const foundPath = allowedPaths.find((path) => {
            if (requestedURL.includes(path) && !_.isEmpty(authenticationData)) {
                return path;
            }
        });
        return foundPath || '/';
    };

    const validatedInitialPath = buildInitialPath();

    if (authenticationData === undefined) {
        return <Loader loaded={false} />;
    }

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider value={{
                FNOLService
            }}
            >
                <ErrorBoundary onError={handleError}>
                    <AuthenticatedContext.Provider value={{ authenticationData, setAuthenticationData }}>
                        <PartnerContext.Provider value={parnerValuesProvider}>
                            <ClientContext.Provider value={clientValuesProvider}>
                                <ViewModelServiceContext.Provider value={viewModelService}>
                                    <ModalNextProvider />
                                    <Router>
                                        <Main className="fnolMain" contentClassName="fnolMainContent" fluid>
                                            <PageContainer>
                                                <Switch>
                                                    <Route exact path="/" component={AuthenticationLandingPage} />
                                                    <Route exact path="/policy-select" component={AuthenticatedPolicySelect} />
                                                    <Route exact path="/b-auth-policy-search" component={AuthenticatedBrokerPolicySearch} />
                                                    <Route exact path="/auth-call-center" component={AuthenticatedContactCallCenterPage} />
                                                    <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
                                                    <Route exact path="/assuralia-anti-fraud" component={AssuraliaAntiFraudPage} />
                                                    <Route path="/fnol" component={FNOLWizard} />
                                                    <Route exact path="/confirmation-page" component={FnolConfirmationPage} />
                                                    <Route exact path="/contact-call-center" component={ContactCallCenterPage} />
                                                    <Route path="/quick-flow" component={FNOLQuickFlowPage} />
                                                    <Route render={() => <Redirect to={validatedInitialPath} />} />
                                                </Switch>
                                            </PageContainer>
                                        </Main>
                                    </Router>
                                </ViewModelServiceContext.Provider>
                            </ClientContext.Provider>
                        </PartnerContext.Provider>
                    </AuthenticatedContext.Provider>
                </ErrorBoundary>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}

export default App;
