
export default class BrokerSSOValuesProvider {

    getRedirectionDetails = () => {
        return {
            baseUrlForClient: '/b-auth-policy-search',
            state: { policyNumberEditable: true }
        };
    }

    isBrokerContext = () => {
        return true;
    }

    getTypefilterNameForReporterRelation = () => {
        return 'AgentFrontEndSelectable'
    }

    saveAndResumeEnabled = () => {
        return false
    }

    getAuthenticationChannel = () => {
        return "B2N"
    }
    
}
