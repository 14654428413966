/* eslint-disable no-console */
import { useRef, useEffect, useCallback } from 'react';

function useUnmountHandler() {
    const isMountedRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return useCallback(() => {
        return isMountedRef.current;
    }, []);
}

export default useUnmountHandler;
