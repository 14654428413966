import { useCallback } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withViewModelService } from 'gw-portals-viewmodel-react';
import useBreakpointHandler from '../../hooks/useBreakpointHandler';
import metadata from './PrivacyPolicyPage.metadata.json5';
import styles from './PrivacyPolicyPage.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from './PrivacyPolicyPage.messages';

function PrivacyPolicyPage(props) {
    const { history } = props;
    const [
        isMobileDevice
    ] = useBreakpointHandler();

    const redirectToAutoFlow = useCallback(() => {
        return history.push({
            pathname: '/',
            state: {
                redirectPath: '/'
            }
        });
    }, [history]);

    const overrideProps = {
        goBackLink: {
            onClick: redirectToAutoFlow
        },
        privacyPolicyItemsContainer: {
            className: isMobileDevice() ? 'phoneItemsContainer' : 'itemsContainer'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(
        metadata.pageContent,
        overrideProps,
        resolvers
    );
}

export default withViewModelService(PrivacyPolicyPage);
