import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Grid } from '@jutro/layout';
import './MediaSideBar.messages';
import mediaSidebarStyles from './MediaSideBar.module.scss';
import FacebookPageComponent from '../FacebookPageComponent/FacebookPageComponent';
import YoutubeComponent from '../YoutubeComponent/YoutubeComponent';
import TwitterComponent from '../TwitterComponent/TwitterComponent';
import ContactComponent from '../ContactComponent/ContactComponent';
import TextComponent from '../TextComponent/TextComponent';
import RssDirective from '../Directives/RssDirective/RssDirective';
// eslint-disable-next-line import/order, import/no-unresolved
import appConfig from 'app-config';

function MediaSideBar(props) {
    const { showContact } = props;
    const {
        enabled, Facebook, Rss, Contact, Text, Twitter, Youtube
    } = appConfig.mediaSidebar;

    if (enabled === true) {
        return (
            <Grid className={mediaSidebarStyles.gwMediaSidebarWrapper}>
                {!showContact && Contact.enabled && (
                    <ContactComponent
                        config={Contact.configuration}
                        key="contact"
                        id="contactSidebarWidget"
                    />
                )}
                {Text.enabled && (
                    <TextComponent config={Text.configuration} key="text" id="textSidebarWidget" />
                )}
                {Rss.enabled && (
                    <RssDirective
                        limit={Rss.configuration.limit}
                        feed={Rss.configuration.feed}
                        key="rss"
                        id="rssSidebarWidget"
                    />
                )}
                {Twitter.enabled && (
                    <TwitterComponent
                        config={Twitter.configuration}
                        key="twitter"
                        id="twitterSidebarWidget"
                    />
                )}
                {Youtube.enabled && (
                    <YoutubeComponent
                        config={Youtube.configuration}
                        key="youtube"
                        id="youtubeSidebarWidget"
                    />
                )}
                {Facebook.enabled && (
                    <FacebookPageComponent
                        config={Facebook.configuration}
                        key="facebook"
                        id="facebookSidebarWidget"
                    />
                )}
            </Grid>
        );
    }
    return null;
}

MediaSideBar.propTypes = {
    showContact: PropTypes.bool
};

MediaSideBar.defaultProps = {
    showContact: _.get(appConfig, 'mediaSidebar.Contact.enabled', false)
};

export default MediaSideBar;
