import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import htmlParser from 'html-react-parser';
import sanitizeHtml from 'sanitize-html-react';
import { TranslatorContext } from '@jutro/locale';
import { HighlightPhraseFilter } from 'gw-portals-util-js';
import { Chevron, AccordionCard } from '@jutro/components';
import ImageComponent from '../ImageComponent/ImageComponent';
import styles from './FaqItemComponent.module.scss';

function FaqItemComponent(props) {
    const translator = useContext(TranslatorContext);
    const { value, searchText, messages } = props;

    const readValue = useCallback(
        (id, compPath) => {
            const currentSection = _.get(value, compPath);
            return translator(_.find(messages, { id: currentSection }));
        },
        [messages, translator, value]
    );

    const renderAccordionHeader = useCallback(
        (isOpen) => {
            const plainTitleText = readValue('accordionHeader', 'question');
            const highlightedText = HighlightPhraseFilter.highlightText(
                plainTitleText,
                searchText,
                styles.gwHighlightedText
            );

            const parsedHTML = htmlParser(highlightedText);

            return (
                <React.Fragment>
                    <Chevron isOpen={isOpen} align="left" />
                    <div className={styles.gwQuestionTitle}>
                        <h4>{parsedHTML}</h4>
                    </div>
                </React.Fragment>
            );
        },
        [readValue, searchText]
    );

    const renderLinkComponent = useCallback(() => {
        const url = _.get(value, 'link.url');
        let text = readValue('linkText', 'link.name');
        if (!_.isUndefined(text)) {
            text = HighlightPhraseFilter.highlightText(text, searchText, styles.gwHighlightedText);
            text = htmlParser(text);
        }

        if (url === undefined) {
            return null;
        }

        return (
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.gwFaqItemLink}
            >
                {text === undefined ? url : text}
            </a>
        );
    }, [readValue, value, searchText]);

    const renderVideoComponent = useCallback(() => {
        const videoId = _.get(value, 'videoId');
        const youtubeUrl = `https://www.youtube.com/embed/${videoId}`;

        if (videoId === undefined) {
            return null;
        }

        return (
            <iframe
                title={_.get(value, 'id')}
                className={styles.gwFaqYouTube}
                frameBorder="0"
                allowFullScreen
                width="100%"
                height="100%"
                src={youtubeUrl}
            />
        );
    }, [value]);

    const renderTextComponent = useCallback(() => {
        const allowedTags = ['img', 'ul', 'li', 'h2', 'a', 'strong'];
        const escapeSymbols = {
            '<': '&lt;',
            '>': '&gt;'
        };

        const answer = readValue('answerText', 'long');
        const sanitizedAnswer = sanitizeHtml(answer, {
            allowedTags,
            textFilter: (text) => {
                return text.replace(/<|>/gi, (matched) => escapeSymbols[matched]);
            }
        });
        const highlightedText = HighlightPhraseFilter.highlightText(
            sanitizedAnswer,
            searchText,
            styles.gwHighlightedText
        );

        const parsedHTML = htmlParser(highlightedText, {
            replace: (component) => {
                if (component.name === 'img') {
                    return <ImageComponent {...component.attribs} />;
                }
                return component;
            }
        });

        return (
            <span className={styles.gwFaqText}>
                <p>{parsedHTML}</p>
            </span>
        );
    }, [readValue, searchText]);

    return (
        <div className={styles.gwFaqItemContainer}>
            <AccordionCard
                id={`accordion_${_.get(value, 'id')}`}
                header={renderAccordionHeader}
                cardTitleClassName="titleContainer"
            >
                {renderTextComponent()}
                {renderLinkComponent()}
                {renderVideoComponent()}
            </AccordionCard>
        </div>
    );
}

FaqItemComponent.propTypes = {
    value: PropTypes.shape({}).isRequired,
    searchText: PropTypes.string.isRequired,
    messages: PropTypes.shape({}).isRequired
};
export default FaqItemComponent;
