/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { ImageComponent } from 'gw-components-platform-react';
import styles from './SelectablePolicy.module.scss';
import messages from './SelectablePolicy.messages';
import { getLOBData } from '../../utils/PolicyTypeUtil';

class SelectablePolicy extends Component {
    static contextType = TranslatorContext;

    constructor(props) {
        super(props);
        this.onSelect = props.onSelect;
    }

    render() {
        const translator = this.context;
        const {
            data,
            onSelect,
            onIsSelected
        } = this.props;

        const lobData = getLOBData(data.lob);
        const isSelected = onIsSelected(data.policyNumber);

        return (
            <div className={isSelected ? styles.flexWithColor : styles.flexNoColor} onClick={() => onSelect(data)}>
                <div className={styles.imageContainer}>
                    <ImageComponent src={lobData.imagePath} className={styles.image} />
                </div>
                <div className={styles.bodyContainer}>
                    <h3 className={isSelected ? styles.selectedItemHeaderText : styles.unselectedItemHeaderText}>{translator(lobData.label)}</h3>
                    <span>{data.riskDescription}</span>
                    <span>{translator(messages.policyLabel)} {data.policyNumber}</span>
                </div>
                {/* Code commented up until discussion about Channels and Entry Points is not finished.
                <div className={styles.imageContainer}>
                    Partner
                    <ImageComponent src="/nnLogo2.png" className={styles.image} />
                </div> */}
            </div>
        );
    }
}

export default SelectablePolicy;
