import { defineMessages } from 'react-intl';

export default defineMessages({
    authFnolContactCallCenterPageTitle: {
        id: 'anonymous.fnol.components.authenticated.contact-call-center.Something went wrong',
        defaultMessage: 'Something went wrong'
    },
    authFnolContactCallCenterPageTitleAuthorization: {
        id: 'anonymous.fnol.components.authenticated.contact-call-center.You are not authorized to perform the operation',
        defaultMessage: 'You are not authorized to perform the operation'
    }
});
