import { defineMessages } from 'react-intl';

export default defineMessages({
    cancelWizardInProgressTitle: {
        id: 'anonymous.wizard.previousTitle',
        defaultMessage: 'Warning!'
    },
    cancelWizardInProgress: {
        id: 'anonymous.wizard.cancel',
        defaultMessage: 'You can review your answers in the previous steps, but if you change the data, all information already entered in the following screens will be deleted.'
    },
    vehicleStolenContextHeader: {
        id: 'anonymous.wizard.redirection.context.vehicleStolen.header',
        defaultMessage: 'You indicate that the vehicle has been stolen. We would like to help you with this quickly.'
    },
    vehicleStolenContextBody: {
        id: 'anonymous.wizard.redirection.context.vehicleStolen.body',
        defaultMessage: 'Please call us on following number:'
    },
    vehicleNotRoadworthyContextHeader: {
        id: 'anonymous.wizard.redirection.context.vehicleNotRoadworthy.header',
        defaultMessage: 'You indicate that the vehicle can no longer be driven. We would like to help you with this quickly.'
    },
    vehicleNotRoadworthyContextBody: {
        id: 'anonymous.wizard.redirection.context.vehicleNotRoadworthy.body',
        defaultMessage: 'Please call us on following number:'
    },
    motorLegalAssistanceContextHeader: {
        id: 'anonymous.wizard.redirection.context.motorLegalAssistance.header',
        defaultMessage: 'For Legal Assistance claims we require a written statement for your Claim.'
    },
    motorLegalAssistanceContextBody: {
        id: 'anonymous.wizard.redirection.context.motorLegalAssistance.body',
        defaultMessage: 'Please sent us your written declaration to myclaim@nn.be'
    },
    bikePhysicalDamageContextHeader: {
        id: 'anonymous.wizard.redirection.context.bikePhysicalDamage.header',
        defaultMessage: 'For bodily damage, we require a written statement for your claim.'
    },
    bikePhysicalDamageContextBody: {
        id: 'anonymous.wizard.redirection.context.bikePhysicalDamage.body',
        defaultMessage: 'Please sent us your written declaration to myclaim@nn.be'
    },
    legalAssitancePropertyContextHeader: {
        id: 'anonymous.wizard.redirection.context.whenDidItHappenLegalAssitance.header',
        defaultMessage: 'For Legal Assistance claims we require a written statement for your Claim.'
    },
    legalAssitancePropertyContextBody: {
        id: 'anonymous.wizard.redirection.context.whenDidItHappenLegalAssitance.body',
        defaultMessage: 'Please sent us your written declaration to myclaim@nn.be'
    },
    legalAssitanceAutoContextHeader: {
        id: 'anonymous.wizard.redirection.context.LegalAssitanceAuto.header',
        defaultMessage: 'For Legal Assistance claims we require a written statement for your Claim.'
    },
    legalAssitanceAutoContextBody: {
        id: 'anonymous.wizard.redirection.context.LegalAssitanceAuto.body',
        defaultMessage: 'Please sent us your written declaration to myclaim@nn.be'
    },
    placeOfGlassCrashRepairCarGlassContextTitle: {
        id: 'anonymous.wizard.redirection.context.glassCrashRepair.title',
        defaultMessage: 'You will be redirected to Carglass.'
    },
    placeOfGlassCrashRepairCarGlassContextBody: {
        id: 'anonymous.wizard.redirection.context.glassCrashRepair.body',
        defaultMessage: 'You can make an appointment online and get you glass break fixed'
    },
    placeOfGlassCrashRepairCarGlassContextConfirmButton: {
        id: 'anonymous.wizard.redirection.context.glassCrashRepair.confirm',
        defaultMessage: 'Go to Carglass'
    },
    placeOfGlassCrashRepairCarGlassContextReturnButton: {
        id: 'anonymous.wizard.redirection.context.glassCrashRepair.return',
        defaultMessage: 'Back'
    },
    lobsDescriptionPersonalAutoLine: {
        id: 'anonymous.lobs.description.personalAutoLine.Car insurance',
        defaultMessage: 'Car insurance'
    },
    lobsDescriptionHomeownersLineHOE: {
        id: 'anonymous.lobs.description.homeownersLineHOE.Home & Family insurance',
        defaultMessage: 'Home & Family insurance'
    },
    lobsDescriptionMotorLine: {
        id: 'anonymous.lobs.description.motorline.Moto Insurance',
        defaultMessage: 'Motorbike & Moped Insurance'
    },
    lobsDescriptionBikeLine: {
        id: 'anonymous.lobs.description.motorline.Bike Insurance',
        defaultMessage: 'Bike Insurance'
    },
    policiesChooseMessageHaveDocumentsPrepared: {
        id: 'anonymous.policy.common.To be able to help you faster, please have photos, videos and other documents at hand.',
        defaultMessage: 'To be able to help you faster, please have photos, videos and other documents at hand.'
    },
    fnolContactCallCenterFromLandingRedirectionErrorTitle: {
        id: 'anonymous.landingPage.Sorry, the policy number you provided is not related to car insurance',
        defaultMessage: 'Sorry, the policy number you provided is not related to car insurance'
    },
    fnolContactCallCenterFromLandingRedirectionErrorSubTitle: {
        id: 'anonymous.landingPage.To register another type of claim (home, family, ...) please use this address in web browser nnbe.myclaim.be',
        defaultMessage: 'To register another type of claim (home, family, ...) please use this address in your web browser: nnbe.myclaim.be'
    },
    systemUnderMaintananceTitle: {
        id: 'anonymous.systemUnderMaintenance.title',
        defaultMessage: 'This page is temporarily unavailable due to maintenance.'
    },
    systemUnderMaintananceBody: {
        id: 'anonymous.systemUnderMaintenance.body',
        defaultMessage: 'Please try again at a later stage or contact {shortPartnerLabel}. Our apologies for any inconvenience caused.'
    },
    saveAndResumeDraftClaimTitle: {
        id: 'authenticated.saveAndResume.title',
        defaultMessage: 'Important'
    },
    saveAndResumeDraftClaimBody: {
        id: 'authenticated.saveAndResume.body',
        defaultMessage: 'You can review your answers in the previous steps. However, modifying the data will result in the loss of all information, necessitating the initiation of a new claim.\n\nClick on Cancel if you just want to review the information.'
    },
    saveAndResumeDraftClaimCancel: {
        id: 'authenticated.saveAndResume.cancel',
        defaultMessage: 'Cancel'
    },
    saveAndResumeDraftClaimConfirm: {
        id: 'authenticated.saveAndResume.confirm',
        defaultMessage: 'Confirm'
    }
});
