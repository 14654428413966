/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { isEBikeTotalOrAttemptedTheft, isEBikeVandalismOrTotalAttemptedTheft } from '../../utils/DamageTypesUtil';
import metadata from './WizardFNOLSummaryEBike.metadata.json5';
import useBreakpointHandler from '../../hooks/useBreakpointHandler';
import {
    buildEBikeDescription,
    buildEBikeTheftDetails,
    buildEBikeSecurityMeasuresDetails,
    buildEBikeDamages,
    buildOtherEBikeDamage,
    buildRepairDetailsDamage,
    buildCyclistDescription,
    buildWhenDidItHappen
} from './EBikeSummarySnapshotGenerator';
import pageStyles from '../../pages/WizardFnolSummaryPage/WizardFNOLSummaryPage.module.scss';

function WizardFNOLSummaryEBike(props) {
    const translator = useContext(TranslatorContext);

    const {
        writeValue,
        wizardDTO
    } = props;

    const [
        isMobileDevice
    ] = useBreakpointHandler();
    const isMobile = isMobileDevice();

    const handleValueChange = useCallback((value, changedPath) => {
        writeValue(value, changedPath);
    }, [writeValue]);

    const overrideProps = {
        fnolSummaryEBikeWhenDidItHappen: {
            visible: !!wizardDTO.value.whenDidItHappen,
            value: buildWhenDidItHappen(wizardDTO, translator),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryEBikeAboutInvolvedBike: {
            value: buildEBikeDescription(wizardDTO),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryEBikeTheftDetails: {
            value: buildEBikeTheftDetails(wizardDTO, translator),
            labelPosition: isMobile ? 'top' : 'left',
            visible: isEBikeVandalismOrTotalAttemptedTheft(wizardDTO.value.frontEndDamageTypes)
        },
        fnolSummaryEBikeTheftSecurityMeasures: {
            value: buildEBikeSecurityMeasuresDetails(wizardDTO, translator),
            labelPosition: isMobile ? 'top' : 'left',
            visible: isEBikeTotalOrAttemptedTheft(wizardDTO.value.frontEndDamageTypes)
        },
        fnolSummaryEBikeInvolvedCyclist: {
            value: buildCyclistDescription(wizardDTO, translator),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryEBikeBikeDamages: {
            value: buildEBikeDamages(wizardDTO, translator),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryEBikeOtherDamages: {
            value: buildOtherEBikeDamage(wizardDTO, translator),
            labelPosition: isMobile ? 'top' : 'left',
            visible: !_.isEmpty(buildOtherEBikeDamage(wizardDTO, translator)),
        },
        fnolSummaryEBikeEstimation: {
            value: buildRepairDetailsDamage(wizardDTO, translator),
            labelPosition: isMobile ? 'top' : 'left'
        }
    };

    const resolvers = {
        resolveClassNameMap: pageStyles,
        resolveComponentMap: {

        },
        resolveCallbackMap: {

        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={wizardDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default WizardFNOLSummaryEBike;
