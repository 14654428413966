/* eslint-disable max-len */
// eslint-disable-next-line object-curly-newline
import React, {
    useCallback,
    useEffect,
    useContext,
    useMemo,
    useState
} from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { isPropertyLOB } from '../../utils/PolicyTypeUtil';
import { isDamageTypeOther, isLegalAssistanceProperty } from '../../utils/LossCausesUtil';
import { getTypecodeTranslation } from '../../utils/TypeCodeTranslationUtil';
import AdditionalParty from '../AdditionalParty/AdditionalParty';
import metadata from './AdditionalPartiesSection.metadata.json5';
import styles from './AdditionalPartiesSection.module.scss';
import messages from './AdditionalPartiesSection.messages';

const additionalPartiesId = 'fnolAdditionalParty';

function AdditionalPartiesSection(props) {
    const {
        id,
        path,
        value: fnolDTO,
        onValueChange,
        onValidate
    } = props;

    const translator = useContext(TranslatorContext);

    const [partyValidationResults, setPartyValidationResults] = useState({});

    const {
        onValidate: setComponentValidation,
        registerComponentValidation,
        isComponentValid,
        disregardFieldValidation
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const validateMorePartiesRadio = useCallback(() => {
        return fnolDTO.supportiveValues.areThereMoreParties.value !== undefined;
    }, [fnolDTO.supportiveValues.areThereMoreParties]);

    useEffect(() => {
        registerComponentValidation(validateMorePartiesRadio);
    }, [registerComponentValidation, validateMorePartiesRadio]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;
        onValueChange(fullPath, value);
    }, [onValueChange, path]);

    const licensePlateInvolvements = useMemo(() => {
        return ['passenger_3rd_party', 'owner_vehicle_3rd_party', 'driver_3rd_party_vehicle'];
    }, []);

    const addNewPartyAction = useCallback(() => {
        const partiesToBeUpdated = fnolDTO.value.additionalParties || [];
        const newParty = {
            contact: {
                contactType: 'person',
                address: {}
            }
        };
        partiesToBeUpdated.push(newParty);
        onValueChange('additionalParties', partiesToBeUpdated);
    }, [fnolDTO.value.additionalParties, onValueChange]);

    const removePartyAction = useCallback((partyToRemove) => {
        const currentParties = fnolDTO.value.additionalParties;
        const filteredParties = currentParties.filter((party) => party !== partyToRemove.value);

        onValueChange('additionalParties', filteredParties);
    }, [fnolDTO.value.additionalParties, onValueChange]);

    const onAreThereMorePartiesChange = useCallback((newValue) => {
        if (newValue) {
            addNewPartyAction();
        } else {
            if (fnolDTO.value.additionalParties) {
                fnolDTO.value.additionalParties.forEach((data, index) => disregardFieldValidation(`${additionalPartiesId}${index}`));
            }
            onValueChange('additionalParties', []);
        }

        onValueChange('supportiveValues.areThereMoreParties', newValue);
    }, [addNewPartyAction, fnolDTO.value.additionalParties, onValueChange, disregardFieldValidation]);

    const onUpdatePartyValidChange = useCallback((subComponentValid, subId) => {
        if (partyValidationResults[subId] === subComponentValid) return;

        const newValidList = Object.assign({}, partyValidationResults);
        newValidList[subId] = subComponentValid;
        setPartyValidationResults(newValidList);
    }, [partyValidationResults]);

    const getSummaryString = useCallback((party) => {
        let summary = '';

        switch (party.canIdentifyParty) {
            case 'yes':
                if (party.contactRole) summary += `${getTypecodeTranslation(translator, party.contactRole, 'inlb_FrontEndContactRole')}`;
                if (party.involvementType) summary += `, ${getTypecodeTranslation(translator, party.involvementType, 'inlb_FrontEndContactInv')}`;

                if (party.contact.contactType === 'person') {
                    summary += `, ${party.contact.firstName || ''} ${party.contact.lastName || ''}`;
                } else if (party.contact.contactType === 'company') {
                    summary += `, ${party.contact.companyName || ''}`;
                } else {
                    summary = messages.summaryProvideDetails;
                }
                break;
            case 'no':
                summary = translator(messages.notIdentified);
                break;
            case 'yesWithEAS':
                summary = translator(messages.seeEAS);
                break;
            default:
                break;
        }

        const translated = translator(messages.summaryString, { sum: summary });
        const title = {
            title: translated
        };
        return title;
    }, [translator]);

    const generateOverridesForAddMoreContacts = useCallback(() => {
        if (_.isEmpty(fnolDTO.additionalParties.value)) {
            return {};
        }

        const partiesListDTO = fnolDTO.additionalParties.value;
        const isPropLegalAssistance = isPropertyLOB(fnolDTO.lob.value) && isLegalAssistanceProperty(fnolDTO, fnolDTO.value.supportiveValues.availableLossCauses);
        const isPropCivilLiability = isPropertyLOB(fnolDTO.lob.value) && isDamageTypeOther(fnolDTO, fnolDTO.value.supportiveValues.availableLossCauses);

        const generated = partiesListDTO.map((value, index) => {
            const newOverride = {
                [`${additionalPartiesId}${index}`]: {
                    lobCode: fnolDTO.lob.value,
                    isPropertyLegalAssistance: isPropLegalAssistance,
                    isPropertyCivilLiability: isPropCivilLiability,
                    licensePlateVisible: (licensePlateInvolvements.some((e) => {
                        return (value && e === value.involvementType);
                    })),
                    passViewModel: true,
                    visible: true,
                    onRemovePartyAction: removePartyAction
                },
                [`additionalPartiesAccordionDetails${index}`]: {
                    ...getSummaryString(value),
                    errorState: partyValidationResults[`${additionalPartiesId}${index}`]
                }
            };
            return newOverride;
        });
        return Object.assign({}, ...generated);
    }, [fnolDTO, licensePlateInvolvements, removePartyAction, getSummaryString, partyValidationResults]);

    const overrideProps = {
        ...generateOverridesForAddMoreContacts(),
        additionalPartiesAccordion: {
            visible: fnolDTO.supportiveValues.areThereMoreParties.value === true
        },
        addMoreContacts: {
            value: fnolDTO.supportiveValues.areThereMoreParties.value,
            onValueChange: (v) => onAreThereMorePartiesChange(v)
        },
        fnolAddAdditionalPartyButton: {
            visible: !!fnolDTO.supportiveValues.areThereMoreParties.value
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            additionalParty: AdditionalParty
        },
        resolveCallbackMap: {
            onValueChange: onValueChange,
            onValidate: setComponentValidation,
            onUpdatePartyValidChange,
            addNewPartyAction,
            removePartyAction
        }
    };
    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={fnolDTO.additionalParties}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={setComponentValidation}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default AdditionalPartiesSection;
