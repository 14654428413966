import { defineMessages } from 'react-intl';

export default defineMessages({

    totalUploadedFiles: {
        id: 'anonymous.fnol.components.documentUploadList.Total uploaded files',
        defaultMessage: 'Total uploaded files: {fileCount}'
    },
    uploadedFileDetails: {
        id: 'anonymous.fnol.components.documentUploadList.uploadedFileDetails',
        defaultMessage: 'File: {fileName} ({fileSize})'
    },
    uploadOrDragAndDrop: {
        id: 'anonymous.fnol.components.documentUploadList.uploadOrDragAndDrop',
        defaultMessage: 'You can drag & drop files here'
    },
    selectFiles: {
        id: 'anonymous.fnol.components.documentUploadList.Select Files',
        defaultMessage: 'Select Files'
    }

});
