/* eslint-disable max-len */
import { useContext } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import metadata from './ContactCallCenter.metadata.json5';
import styles from './ContactCallCenter.scss';
// eslint-disable-next-line no-unused-vars
import messages from './ContactCallCenter.messages';
import ClientContext from '../../contexts/ClientContext/ClientContext';

function ContactCallCenterPage(props) {
    const partnerContext = useContext(PartnerContext);
    const clientContext = useContext(ClientContext);
    const translator = useContext(TranslatorContext);
    const {
        notificationTitle,
        notificationBody,
        showTryAgainButton = false,
        showPhoneCall = true,
        allowSaveAndResumeMessage = false
    } = props.location.state;

    const {
        history
    } = props;

    const performCall = () => {
        window.location.href = `tel:${partnerContext.partnerPhoneNumber(translator)}`;
    };

    const redirectToHomePage = () => {
        return history.push('/');
    };

    const overrideProps = {
        fnolContactCallCenterSaveAndResumePhrase: {
            visible: clientContext.saveAndResumeEnabled() && allowSaveAndResumeMessage
        },
        fnolContactCallCenterPageTitle: {
            content: notificationTitle
        },
        fnolContactCallCenterPageSubTitle: {
            content: notificationBody || translator(messages.fnolContactCallCenterPageSubTitle, { partnerUrgentCallCenterMessage: partnerContext.partnerUrgentAssistLabel(translator) })
        },
        fnolContactCallCenterPageTryAgainButton: {
            visible: showTryAgainButton
        },
        fnolContactCallCenterPageUrgentCallButton: {
            visible: showPhoneCall,
            content: translator(messages.fnolContactCallCenterPageUrgentCallNumber, { phoneNumber: partnerContext.partnerPhoneNumber(translator) })
        }
    };

    const resolvers = {
        resolveComponentMap: {},
        resolveCallbackMap: {
            performCall: performCall,
            redirectToHomePage: redirectToHomePage
        },
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(
        metadata.pageContent,
        overrideProps,
        resolvers
    );
}

export default ContactCallCenterPage;
