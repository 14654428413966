/* eslint-disable max-len */
import _ from 'lodash';
import React, { useContext, useCallback, useState } from 'react';
import { WizardPage, WizardPageTemplate, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { withRouter } from 'react-router-dom';
import { useValidation } from 'gw-portals-validation-react';
import { FNOLService } from 'nn-capability-fnol';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import ClientContext from '../../contexts/ClientContext/ClientContext';
import WizardFNOLContactDetailsComponent from '../../components/WizardFNOLContactDetailsComponent/WizardFNOLContactDetailsComponent';
import BrokerWizardFNOLContactDetailsComponent from '../../components/BrokerWizardFNOLContactDetailsComponent/BrokerWizardFNOLContactDetailsComponent';
import { getProgressBasedOnWizardStep } from '../../utils/WizardStepToProgressUtil';
import useSnapshotHandler from '../../hooks/useSnapshotHandler';
import styles from './WizardFNOLContactDetailsPage.module.scss';
import metadata from './WizardFNOLContactDetailsPage.metadata.json5';
import messages from './WizardFNOLContactDetailsPage.messages';

function WizardFNOLContactDetailsPage(props) {
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const clientContext = useContext(ClientContext);
    const isBrokerContext = clientContext.isBrokerContext();

    const {
        history,
        wizardData,
        updateWizardData,
        currentStepIndex,
        steps
    } = props;

    const {
        onValidate,
        isComponentValid
    } = useValidation('ContactDetailsPage');
    const [isLoading, setIsLoading] = useState(false);

    const [resetValuesToSnapshotIfNeeded, prepareSnapshot] = useSnapshotHandler(steps,
        currentStepIndex, updateWizardData, 'contactDetailsSnapshot', history);

    const writeValue = useCallback((value, path, allowBypass = false) => {
        const {currentDTOValue} = resetValuesToSnapshotIfNeeded(wizardData, true, setIsLoading, allowBypass);
        if (currentDTOValue !== undefined) {
            _.set(currentDTOValue.value, path, value);
            if (!wizardData.value.reporterDetails.haveChanged) {
                _.set(wizardData.value, 'reporterDetails.haveChanged', true);
            }
            updateWizardData(currentDTOValue);
        }
    }, [wizardData, updateWizardData, resetValuesToSnapshotIfNeeded, setIsLoading]);

    const handleOnNextClick = useCallback(() => {
        setIsLoading(true);
        const valueToSend = wizardData.value;
        valueToSend.progress = getProgressBasedOnWizardStep(steps[currentStepIndex + 1].path);
        return FNOLService.updateClaim(valueToSend, history, { partnerContext, translator }).then(
            (responseDTO) => {
                if (!responseDTO) return;
                wizardData.value = responseDTO;
                prepareSnapshot(responseDTO);
                return wizardData;
            }
        ).finally(() => {
            setIsLoading(false);
        });
    }, [wizardData, steps, currentStepIndex, history, partnerContext, translator, prepareSnapshot]);

    const overrides = {
        wizardFNOLContactDetailsPageLoader: {
            visible: isLoading
        },
        customerContactDetailsComponent: {
            visible: !isLoading && !isBrokerContext,
            history: history,
            wizardData: wizardData,
            currentStepIndex: currentStepIndex,
            steps: steps,
            onValidate: onValidate,
            writeValue: writeValue
        },
        brokerContactDetailsComponent: {
            visible: !isLoading && isBrokerContext,
            history: history,
            wizardData: wizardData,
            currentStepIndex: currentStepIndex,
            steps: steps,
            onValidate: onValidate,
            writeValue: writeValue
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        },
        resolveComponentMap: {
            WizardFNOLContactDetailsComponent,
            BrokerWizardFNOLContactDetailsComponent
        }
    };

    const generateHeader = () => {
        return (
            <div>
                <h1>
                    {isBrokerContext
                        ? translator(messages.fnolContactDetailsBrokerTitle)
                        : translator(messages.fnolContactDetailsTitle)
                    }
                </h1>
                <h1>
                    {wizardData.value.claimNumber}
                </h1>
            </div>
        );
    };

    return (
        <WizardPage
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
            onNext={handleOnNextClick}
            showCancel={false}
            renderContextComponent={generateHeader}
        >
            <ViewModelForm
                model={wizardData}
                uiProps={metadata.pageContent}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                overrideProps={overrides}
                onValidationChange={onValidate}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

WizardFNOLContactDetailsPage.propTypes = wizardProps;
export default withRouter(WizardFNOLContactDetailsPage);
