/* eslint-disable max-len */
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import _ from 'lodash';
import React, { useCallback, useMemo, useEffect, useState, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { BreakpointTrackerContext } from '../../../../../framework/packages/@jutro/layout';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';
import metadata from './Address.metadata.json5';
import styles from './Address.module.scss';
import { translateTypecodeToCodeName } from '../../utils/TypeCodeTranslationUtil';
// eslint-disable-next-line no-unused-vars
import messages from './Address.messages';

function FNOLAddress(props) {
    const translator = useContext(TranslatorContext);
    const {
        id,
        value: addressDTO,
        onValidate,
        onWriteValue,
        path,
        setInitialCountryToBE,
        isCountryMandatory,
        isCityMandatory,
        isPostalCodeMandatory,
        isStreetNameMandatory,
        isStreetNumberMandatory,
        isBoxNumberMandatory,
        defaultMapValue
    } = props;
    const [mapLocation, setMapLocation] = useState({});
    const [googleMapApiInitialized, setGoogleMapApiInitialized] = useState(window.google !== undefined);
    const [initializeGoogleMapsOnNextRender, setInitializeGoogleMapsOnNextRender] = useState(false);

    const usedDevice = useContext(BreakpointTrackerContext);

    const {
        onValidate: onComponentValidate,
        isComponentValid
    } = useValidation('Address');

    useEffect(() => {
        if (window.google !== undefined) {
            setGoogleMapApiInitialized(true);
        } else {
            setInitializeGoogleMapsOnNextRender((value) => !value);
        }
    }, [googleMapApiInitialized, initializeGoogleMapsOnNextRender]);

    useEffect(() => {
        const initialMapLocation = defaultMapValue || { countryCode: 'BE', country: 'Belgium' };
        setMapLocation(initialMapLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (addressDTO.country.value === undefined && setInitialCountryToBE) {
            addressDTO.country.value = 'BE';
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const isMobileDevice = useCallback(() => {
        return usedDevice === 'phone' || usedDevice === 'tablet';
    }, [usedDevice]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;
        onWriteValue(value, fullPath);
    }, [onWriteValue, path]);

    const availableCountries = useMemo(() => {
        const values = _.get(addressDTO, 'country.aspects.availableValues');
        const mappedValues = values.map((typecode) => {
            return translateTypecodeToCodeName(translator, typecode.code, 'Country');
        });

        const sortedValues = mappedValues.sort((a, b) => {
            const lowerCaseA = a.name.toLowerCase();
            const lowerCaseB = b.name.toLowerCase();

            if (lowerCaseA < lowerCaseB) {
                return -1;
            }
            return lowerCaseA > lowerCaseB ? 1 : 0;
        });
        return sortedValues;
    }, [addressDTO, translator]);

    const onMapLocationChange = useCallback((value) => {
        const updateAddressObject = {};
        if (value.addressLine1) {
            const firstWord = value.addressLine1.split(' ')[0];
            if (Number(firstWord.charAt(0))) {
                updateAddressObject.streetNumber = firstWord;
                updateAddressObject.street = value.addressLine1.replace(`${firstWord} `, '');
            } else {
                updateAddressObject.street = value.addressLine1;
            }
        }
        updateAddressObject.city = value.city;
        updateAddressObject.country = value.countryCode;
        updateAddressObject.postalCode = value.postalCode;
        onWriteValue(updateAddressObject, path);
    }, [onWriteValue, path]);

    const handleMapClick = useCallback((value) => {
        setMapLocation(value);
        onMapLocationChange(value);
    }, [onMapLocationChange]);

    const buildInitialCountry = useCallback(() => {
        const countryCode = addressDTO.value.country;
        const translated = translateTypecodeToCodeName(translator, countryCode, 'Country');
        return translated.name;
    }, [addressDTO.value.country, translator]);

    const overrideProps = {
        fnolAddressComponentForm: {
            className: !isMobileDevice() ? 'formContainer' : null
        },
        fnolAddressComponentGoogleMapContainer: {
            className: !isMobileDevice() ? 'addressMapPlace' : 'phoneAddressMapPlace'
        },
        fnolAddressComponentFormInput: {
            className: !isMobileDevice() ? 'addressInputSet' : 'phoneAddressInputSet'
        },
        fnolAddressComponentGoogleMap: {
            googleMapsApiKey: appConfig.credentials.googleMapsApiKey,
            onValueChange: handleMapClick,
            value: mapLocation
        },
        fnolAddressComponentAutoComplete: {
            handleMapClick: handleMapClick,
            visible: googleMapApiInitialized,
            initialCountry: buildInitialCountry()
        },
        fnolAddressComponentCountry: {
            availableValues: availableCountries,
            value: _.get(addressDTO.value, 'country'),
            required: isCountryMandatory,
            showRequired: isCountryMandatory
        },
        fnolAddressComponentCity: {
            required: isCityMandatory,
            showRequired: isCityMandatory
        },
        fnolAddressComponentPostalCode: {
            required: isPostalCodeMandatory,
            showRequired: isPostalCodeMandatory
        },
        fnolAddressComponentStreet: {
            required: isStreetNameMandatory,
            showRequired: isStreetNameMandatory
        },
        fnolAddressComponentStreetNumber: {
            required: isStreetNumberMandatory,
            showRequired: isStreetNumberMandatory
        },
        fnolAddressComponentBoxNumber: {
            required: isBoxNumberMandatory,
            showRequired: isBoxNumberMandatory
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            autoCompleteInput: AutoCompleteInput
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={addressDTO}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onComponentValidate}
                componentMap={resolvers.resolveComponentMap}
            />
        </div>
    );
}

FNOLAddress.defaultProps = {
    setInitialCountryToBE: true,
    isCountryMandatory: false,
    isCityMandatory: false,
    isPostalCodeMandatory: false,
    isStreetNameMandatory: false,
    isStreetNumberMandatory: false,
    isBoxNumberMandatory: false
};

export default FNOLAddress;
