/* eslint-disable max-len */
import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from 'gw-portals-validation-react';
import moment from 'moment';
import { isAutoLOB } from '../../utils/PolicyTypeUtil';
import FNOLAddress from '../Address/Address';
import styles from './BrokerWizardFNOLContactDetailsComponent.module.scss';
import BankAccountNumberComponent from '../BankAccountNumberComponent/BankAccountNumberComponent';
import useTypelistHandler from '../../hooks/useTypelistHandler';
import metadata from './BrokerWizardFNOLContactDetailsComponent.metadata.json5';
import AuthenticatedContext from '../../contexts/AuthenticatedContext/AuthenticatedContext';

function BrokerWizardFNOLContactDetailsComponent(props) {
    const { getValuesFromTypelistIncludeFilter } = useTypelistHandler();

    const {
        id,
        wizardData,
        currentStepIndex,
        steps,
        onValidate,
        writeValue
    } = props;

    const {
        onValidate: onComponentValidate,
        isComponentValid
    } = useValidation('ContactDetailsComponent');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const {
        authenticationData
    } = useContext(AuthenticatedContext);

    useEffect(() => {
        if (!_.isEmpty(authenticationData) && !steps[currentStepIndex + 1].visited) {
            writeValue(true, 'reporterDetails.haveChanged');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overrides = {
        fnolContactDetailsBirthDate: {
            maxDate: moment().toDate()
        },
        fnolContactDetailsIsInjuredInvolvement: {
            visible: wizardData.reporterDetails.value.wasInjured === true && isAutoLOB(wizardData.lob.value)
        },
        fnolContactDetailsLanguageType: {
            availableValues: getValuesFromTypelistIncludeFilter('com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.ReporterDetailsDTO', 'languageType', 'FrontEndSelectable'),
            value: wizardData.reporterDetails.languageType.value
                ? wizardData.reporterDetails.languageType.value.code
                : null
        },
        fnolContactDetailsGenders: {
            value: wizardData.reporterDetails.gender.value
                ? wizardData.reporterDetails.gender.value.code
                : null
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: onComponentValidate
        },
        resolveComponentMap: {
            address: FNOLAddress,
            bankAccountComponent: BankAccountNumberComponent
        }
    };

    return (
        <ViewModelForm
            model={wizardData}
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            onValidationChange={onComponentValidate}
            onValueChange={writeValue}
        />
    );
}

BrokerWizardFNOLContactDetailsComponent.propTypes = wizardProps;
export default withRouter(BrokerWizardFNOLContactDetailsComponent);
