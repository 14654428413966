import { defineMessages } from 'react-intl';

export default defineMessages({
    assuraliaAntiFraudGoBack: {
        id: 'anonymous.assuraliaAntiFraud.Go back',
        defaultMessage: 'Go back'
    },
    assuraliaAntiFraudHeader: {
        id: 'anonymous.assuraliaAntiFraud.Assuralia anti-fraud statement',
        defaultMessage: 'Assuralia anti-fraud statement'
    },
    assuraliaAntiFraudArticleParagraph: {
        id: 'anonymous.assuraliaAntiFraud.Article 496',
        defaultMessage: 'Article 496'
    },
    assuraliaAntiFraudArticleBody: {
        id: 'anonymous.assuraliaAntiFraud.Any fraud or attempted fraud against the insurance company not only entails the termination of the insurance contract, but is also subject to criminal prosecution on the basis of Article 496 of the Belgian Penal Code. The person concerned may also be included in the ESV Datassur file. In accordance with the law on the protection of privacy, they will be informed of this and, if necessary, have the right to have the data concerning them corrected.',
        defaultMessage: 'Any fraud or attempted fraud against the insurance company not only entails the termination of the insurance contract, but is also subject to criminal prosecution on the basis of Article 496 of the Belgian Penal Code. The person concerned may also be included in the ESV Datassur file. In accordance with the law on the protection of privacy, they will be informed of this and, if necessary, have the right to have the data concerning them corrected.'
    },
    assuraliaAntiFraudDatassurParagraph: {
        id: 'anonymous.assuraliaAntiFraud.Datassur',
        defaultMessage: 'Datassur'
    },
    assuraliaAntiFraudDatassurBody: {
        id: 'anonymous.assuraliaAntiFraud.The insurance company NN Non-Life Insurance nv communicates to ESV Datassur personal data relevant in the exclusive framework of the assessment of risks and the management of contracts and related claims. Any person who proves their identity will have the right to contact Datassur in order to access their data and where appropriate have their data rectified in accordance with the Data Protection Act of 30 July 2018. To exercise this right, the person concerned must send a dated and signed request, together with a copy of their identity card, to the following address: Datassur, Files Department, de Meeûssquare 29, B-1000 Brussels.',
        defaultMessage: 'The insurance company NN Non-Life Insurance nv communicates to ESV Datassur personal data relevant in the exclusive framework of the assessment of risks and the management of contracts and related claims. Any person who proves their identity will have the right to contact Datassur in order to access their data and where appropriate have their data rectified in accordance with the Data Protection Act of 30 July 2018. To exercise this right, the person concerned must send a dated and signed request, together with a copy of their identity card, to the following address: Datassur, Files Department, de Meeûssquare 29, B-1000 Brussels.'
    }
});
