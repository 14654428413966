/* eslint-disable max-len */
import _ from 'lodash';
import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './WizardFNOLSummaryProperty.metadata.json5';
import useBreakpointHandler from '../../hooks/useBreakpointHandler';
import {
    buildPropertyDescription,
    buildRepairDetailsDamage,
    buildPropertyCivilLiability,
    buildPropertyLegalAssistance
} from './PropertySummarySnapshotGenerator';
import pageStyles from '../../pages/WizardFnolSummaryPage/WizardFNOLSummaryPage.module.scss';

function WizardFNOLSummaryProperty(props) {
    const translator = useContext(TranslatorContext);

    const {
        writeValue,
        wizardDTO
    } = props;

    const [
        isMobileDevice
    ] = useBreakpointHandler();
    const isMobile = isMobileDevice();

    const handleValueChange = useCallback((value, changedPath) => {
        writeValue(value, changedPath);
    }, [writeValue]);

    const overrideProps = {
        fnolSummaryAboutProperty: {
            value: buildPropertyDescription(translator, wizardDTO),
            labelPosition: isMobile ? 'top' : 'left',
            visible: !_.isEmpty(buildPropertyDescription(translator, wizardDTO))
        },
        wizardFnolSummaryPropertyCivilLiability: {
            value: buildPropertyCivilLiability(translator, wizardDTO),
            labelPosition: isMobile ? 'top' : 'left',
            visible: !_.isEmpty(buildPropertyCivilLiability(translator, wizardDTO))
        },
        wizardFnolSummaryPropertyLegalAssistance: {
            value: buildPropertyLegalAssistance(translator, wizardDTO),
            labelPosition: isMobile ? 'top' : 'left',
            visible: !_.isEmpty(buildPropertyLegalAssistance(translator, wizardDTO))
        },
        wizardFnolSummaryPropertyRepairDetails: {
            value: buildRepairDetailsDamage(translator, wizardDTO),
            labelPosition: isMobile ? 'top' : 'left',
            visible: !_.isEmpty(buildRepairDetailsDamage(translator, wizardDTO))
        }
    };

    const resolvers = {
        resolveClassNameMap: pageStyles,
        resolveComponentMap: {

        },
        resolveCallbackMap: {

        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={wizardDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default WizardFNOLSummaryProperty;
