import React, {
    useState, useEffect, useContext, useCallback
} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';

import styles from './WizardPageHeader.module.scss';
import messages from './WizardPageHeader.messages';

/**
 * @prop {string} propTypes.wizardSubmission - submission view model
 * @prop {string} propTypes.isSideHeader - flag to specify side header
 */

const WizardPageHeader = (props) => {
    const translator = useContext(TranslatorContext);
    const [wizardHeaderTemplate, setWizardHeaderTemplate] = useState('');
    const [routePath, setRoutePath] = useState('');
    const [accountName, setAccountName] = useState('');
    const [lobWithQuoteNumber, setLobWithQuoteNumber] = useState('');
    const {
        wizardSubmission, isSideHeader, lobFlow, displayWizardHeader
    } = props;
    const QUOTE_TRANSACTION = [
        'CommercialProperty',
        'WC7WorkersComp',
        'Homeowners',
        'BusinessOwners',
        'PersonalAuto',
        'HOPHomeowners'
    ];

    useEffect(() => {
        const productCode = _.get(wizardSubmission.value, 'baseData.productCode');
        const jobType = _.get(wizardSubmission.value, 'baseData.jobType');
        const lob = (jobType === 'Renewal' && `${productCode}${jobType}`)
            || (jobType === 'PolicyChange' && `${productCode}${jobType}`)
            || productCode;
        const accountNumber = _.get(wizardSubmission.value, 'baseData.accountNumber');
        const getFirstName = _.get(wizardSubmission.value, 'baseData.accountHolder.firstName') || '';
        const getLastName = _.get(wizardSubmission.value, 'baseData.accountHolder.lastName') || '';
        const getDisplayName = _.get(wizardSubmission.value, 'baseData.accountHolder.displayName');
        const getAccountDisplayName = !getDisplayName
            ? `${getFirstName} ${getLastName}`
            : getDisplayName;
        const getAccountName = _.trim(getAccountDisplayName)
            || _.get(wizardSubmission.value, 'baseData.accountHolder.contactName')
            || _.get(wizardSubmission.value, 'baseData.accountName');

        if (lob && QUOTE_TRANSACTION.includes(productCode)) {
            setWizardHeaderTemplate('transaction');
        }
        /* eslint no-underscore-dangle: 0 */
        if (wizardSubmission._dtoName.indexOf('fnol') !== -1) {
            setWizardHeaderTemplate('claim');
        }

        setRoutePath(`/accounts/${accountNumber}/summary`);
        setAccountName(getAccountName);
        setLobWithQuoteNumber(
            translator(messages[_.camelCase(lob)], {
                quoteNumber:
                    _.get(wizardSubmission.value, 'quoteID')
                    || _.get(wizardSubmission.value, 'policyNumber'),
                jobId: _.get(wizardSubmission.value, 'jobID') || ''
            })
        );
    }, [QUOTE_TRANSACTION, lobFlow, translator, wizardSubmission]);

    const titleContainerClass = cx({
        [styles.gwWizardPageTitle]: !isSideHeader,
        [styles.gwWizardSidePageTitle]: isSideHeader
    });

    const categoryContainerClass = cx({
        [styles.gwWizardPageCategory]: !isSideHeader,
        [styles.gwWizardSidePageCategory]: isSideHeader
    });

    const associatedPageClass = cx({
        [styles.gwWizardPageAssociated]: !isSideHeader,
        [styles.gwWizardSidePageAssociated]: isSideHeader
    });

    const titleSeparator = isSideHeader && (
        <div className={styles.gwWizardSidePageTitleSeparator} />
    );

    const submissionQuote = wizardHeaderTemplate === 'transaction' && (
        <div className={titleContainerClass}>
            <div className={categoryContainerClass}>
                {translator(messages.accountTitle)}
                <Link className={associatedPageClass} to={routePath}>
                    {accountName}
                </Link>
            </div>
            {titleSeparator}
            {displayWizardHeader ? (
                <h1 className={cx({ [styles.gwWizardSidepageTitle]: isSideHeader })}>
                    {lobWithQuoteNumber}
                </h1>
            ) : (
                ''
            )}
        </div>
    );

    const claimHeader = useCallback(() => {
        const claimNumber = _.get(wizardSubmission.value, 'claimNumber', '');
        return wizardHeaderTemplate === 'claim' ? (
            <h1 className={styles.gwPageTitle}>
                {translator(messages.fnolNewClaim)}
                <span className={styles.gwClaimsWizardSpanNumber}>{claimNumber}</span>
            </h1>
        ) : null;
    }, [wizardHeaderTemplate, translator, wizardSubmission.value]);

    return (
        <>
            {submissionQuote}
            {claimHeader()}
        </>
    );
};

WizardPageHeader.propTypes = {
    wizardSubmission: PropTypes.shape({
        value: PropTypes.shape({}),
        _dtoName: PropTypes.string
    }).isRequired,
    isSideHeader: PropTypes.bool,
    lobFlow: PropTypes.string,
    displayWizardHeader: PropTypes.bool
};

WizardPageHeader.defaultProps = {
    isSideHeader: false,
    lobFlow: '',
    displayWizardHeader: true
};

export default WizardPageHeader;
