const docTypeMap = [
    { docType: 'photo', apiDocType: 'Photo' },
    { docType: 'video', apiDocType: 'Video' },
    { docType: 'euro_acc_statement', apiDocType: 'Document' },
    { docType: 'statement', apiDocType: 'Document' },
    { docType: 'estimate', apiDocType: 'Document' },
    { docType: 'brokerPV', apiDocType: 'Document' },
    { docType: 'purchaseInvoice', apiDocType: 'Document' },
    { docType: 'orderForm', apiDocType: 'Document' },
    { docType: 'police_report', apiDocType: 'Document' },
    { docType: 'other', apiDocType: 'Unknown' }
];

function docTypeToApiLayerMapping(documentType) {
    const foundType = docTypeMap.find((portalType) => {
        return (portalType.docType === documentType);
    });

    if (foundType) {
        return foundType.apiDocType;
    }

    // if not found - that means error
    throw new Error(`No mapping found for document ${documentType}`);
}

export default docTypeToApiLayerMapping;
