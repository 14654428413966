/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

function CurrencyInputInternal(props) {
    const {
        id,
        allowNegativeValue,
        onValueChange,
        currency,
        disabled,
        value,
        localeCode,
        decimalSeparator,
        groupSeparator,
        className,
        decimalsLimit,
        parseOrDefaultValue
    } = props;

    const handleOnValueChange = useCallback((newValue) => {
        let currencyValueObject;
        if (!newValue) {
            currencyValueObject = {
                amount: '0.00',
                currency: currency
            };
        } else {
            currencyValueObject = {
                amount: newValue.replace(decimalSeparator, '.'),
                currency: currency
            };
        }

        onValueChange(currencyValueObject);
    }, [onValueChange, currency, decimalSeparator]);

    return (
        <div>
            <CurrencyInput
                id={id}
                allowNegativeValue={allowNegativeValue}
                disabled={disabled}
                className={className.input}
                defaultValue={parseOrDefaultValue(value)}
                decimalScale={2}
                decimalSeparator={decimalSeparator}
                decimalsLimit={decimalsLimit}
                groupSeparator={groupSeparator}
                intlConfig={{ locale: localeCode, currency: currency }}
                onValueChange={(passedValue) => handleOnValueChange(passedValue)}
            />
        </div>
    );
}

export default CurrencyInputInternal;
