/* eslint-disable max-len */
import React, { useState, useCallback } from 'react';
import {
    ModalNext,
    ModalBody
} from '@jutro/components';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { redirectToContactCallCenter } from '../../utils/RedirectionUtil';
import metadata from './AuthenticatedFlowBikeUrgencyPopup.metadata.json5';
import messages from './AuthenticatedFlowBikeUrgencyPopup.messages';
import styles from './AuthenticatedFlowBikeUrgencyPopup.module.scss';

function AuthenticatedFlowBikeUrgencyPopup(props) {
    const {
        isOpen,
        onResolve,
        onReject,
        history
    } = props;
    const [urgentAssistanceNeeded, setUrgentAssistanceNeeded] = useState();

    const redirectToWrittenStatementRequired = useCallback(() => {
        redirectToContactCallCenter(
            history,
            messages.authenticatedFlowBikeUrgencyPopupRedirectionTitle,
            messages.authenticatedFlowBikeUrgencyPopupRedirectionBody,
            false,
            true
        );
        onReject();
    }, [history, onReject]);

    const overrideProps = {
        buttonUrgentRoadAssistNeededYes: {
            onClick: () => redirectToWrittenStatementRequired()
        },
        buttonUrgentRoadAssistNeededNo: {
            onClick: () => setUrgentAssistanceNeeded(false),
            className: urgentAssistanceNeeded === false ? 'selectedButtonContainer' : 'blankButtonContainer'
        },
        buttonUrgentRoadAssistIUnderstand: {
            onClick: () => onResolve(true)
        },
        prepareAdditionalDocumentsContainer: {
            visible: urgentAssistanceNeeded === false
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={styles.body}>
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    classNameMap={styles}
                    overrideProps={overrideProps}
                />
            </ModalBody>
        </ModalNext>
    );
}

export default AuthenticatedFlowBikeUrgencyPopup;
