/* eslint-disable max-len */
import _ from 'lodash';
import React, { useEffect, useCallback, useContext, useState } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { FNOLService } from 'nn-capability-fnol';
import PartnerContext from '../../../contexts/PartnerContext/PartnerContext';
import metadata from './NewVendorFragment.metadata.json5';
import styles from './NewVendorFragment.module.scss';
import messages from './NewVendorFragment.messages';
import FNOLAddress from '../../Address/Address';

function NewVendorFragment(props) {
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        id,
        history,
        repairDTO,
        onValidate,
        handleValueChange,
        garageCheckRequired
    } = props;
    const [checkedGarage, setCheckedGarage] = useState();

    const {
        onValidate: onComponentValidate,
        registerComponentValidation,
        isComponentValid
    } = useValidation('NewVendorRepairScreen');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const validationVehicleDamagesCallback = useCallback(() => {
        if (garageCheckRequired) return checkedGarage !== undefined;

        return true;
    }, [checkedGarage, garageCheckRequired]);

    useEffect(() => {
        registerComponentValidation(validationVehicleDamagesCallback);
    }, [registerComponentValidation, validationVehicleDamagesCallback]);

    const handleVendorCheck = useCallback(() => {
        const dtoValue = repairDTO.value;
        const dto = viewModelService.create(
            {
                repairFacilityName: dtoValue.repairFacilityName,
                repairFacilityCountry: dtoValue.repairFacilityAddress.country,
                repairFacilityCity: dtoValue.repairFacilityAddress.city,
                repairFacilityPostalCode: dtoValue.repairFacilityAddress.postalCode,
                repairFacilityStreet: dtoValue.repairFacilityAddress.street,
                repairFacilityStreetNumber: dtoValue.repairFacilityAddress.streetNumber,
                repairFacilityBoxNumber: dtoValue.repairFacilityAddress.boxNumber
            },
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.contact.dto.SearchContactReqDTO'
        );

        FNOLService.vendorSearch(dto.value, history, { partnerContext, translator }).then(
            (response) => {
                if (!response) return;
                setCheckedGarage(response.existingVendor);
            }
        );
    }, [history, partnerContext, repairDTO.value, translator, viewModelService]);

    const shouldDisableCheckGarageButton = useCallback(() => {
        const repairDTOValue = repairDTO.value;
        return !repairDTOValue.repairFacilityName
            || !repairDTOValue.repairFacilityAddress.country
            || !repairDTOValue.repairFacilityAddress.city;
    }, [repairDTO.value]);

    const overrideProps = {
        wizardFNOLAutoRepairChoiceNewVendorAddress: {
            onWriteValue: handleValueChange,
            isCountryMandatory: true,
            isCityMandatory: true
        },
        wizardFNOLAutoRepairChoiceNewVendorCheckGagrage: {
            visible: !!garageCheckRequired
        },
        wizardFNOLAutoRepairChoiceCheckIfVendorExistsButton: {
            disabled: shouldDisableCheckGarageButton()
        },
        wizardFNOLAutoRepairChoiceCheckIfVendorExistsTrue: {
            visible: checkedGarage === true
        },
        wizardFNOLAutoRepairChoiceNewVendorCheckGagrageFalseContainer: {
            visible: checkedGarage === false
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleVendorCheck: handleVendorCheck,
            onValidate: onComponentValidate
        },
        resolveComponentMap: {
            address: FNOLAddress,
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={repairDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onComponentValidate}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default NewVendorFragment;
