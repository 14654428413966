import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { FieldComponent, InputField, Icon } from '@jutro/components';
import styles from './TypeAheadComponent.module.scss';
import TypeAheadDropdownComponent from './TypeAheadDropdownComponent';

/**
 * Searches the items for the query.
 * If *searchByProperty* is passed, only such property is searched in the items
 *
 * @param {Array} items items to search
 * @param {string} query the query entered by the user
 * @param {string} searchByProperty a property name available in the elements passed in *items*
 * @returns {Array} an array with items filtered by the user query
 *
 */
function searchItems(items, query, searchByProperty) {
    if (_.isNil(query)) {
        return [];
    }

    const filteredItems = items.filter((item) => {
        if (searchByProperty) {
            return _.get(item, searchByProperty, '').toLowerCase().includes(query.toLowerCase());
        }
        return (item || '').includes(query.toLowerCase());
    });

    return filteredItems;
}

class TypeAheadComponent extends FieldComponent {
    /**
     * @static
     * @memberof TypeAheadComponent
     * @mixes InputField.propTypes
     * @prop {Array} items the items to be searched by the user.
     *                      The array can contain strings or objects
     * @prop {function(userInput) => (Array|Promise<Array>)} onSearchItems
     *                      a function used to search the items based on user input
     *                      The function can return an array or a promise that
     *                      will resolve to an array
     * @prop {string} renderAs a path to a property in *item* that is used to
     *                                      render the result or a function that, given an item,
     *                                      returns a string
     * @prop {function(item): string} onRenderItem a function used to get the string to be used
     *                                      when rendering an item. If **renderAs** is provided
     *                                      this is **ignored**
     * @prop {string} searchBy **only** if *items* is provided as an Array
     *                          this represents the property used when searching for user input
     * @prop {number} throttleMs number of milliseconds to wait before
     *                           re-evaluating a user input change
     * @prop {number} maxResults  maximum number of results to show in the dropdown
     */
    static propTypes = {
        ...InputField.propTypes,
        items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
        onSearchItems: PropTypes.func,
        onClearTypeaheadInput: PropTypes.func,
        renderAs: PropTypes.string,
        onRenderItem: PropTypes.func,
        searchBy: PropTypes.string,
        throttleMs: PropTypes.number,
        maxResults: PropTypes.number,
        id: PropTypes.string.isRequired
    };

    static defaultProps = {
        ...InputField.defaultProps,
        items: [],
        onSearchItems: undefined,
        onClearTypeaheadInput: undefined,
        renderAs: undefined,
        onRenderItem: undefined,
        throttleMs: 100,
        maxResults: undefined
    };

    constructor(props, context) {
        super(props, context);
        const { onSearchItems, throttleMs } = props;

        this.state = {
            rawInput: '',
            resolvedItems: []
        };

        this.dropdownRef = null;

        if (_.isFunction(onSearchItems)) {
            this.searchItems = _.throttle(onSearchItems, throttleMs);
        }
    }

    handleCancelSelection = () => {
        this.setState({
            resolvedItems: []
        });
    };

    handleRawInputChange = (value) => {
        const { items, searchBy } = this.props;

        // reset potentially resolved items
        this.setState({
            rawInput: value,
            resolvedItems: []
        });

        // case items is array
        if (_.isArray(items) && !_.isEmpty(items)) {
            this.setState({
                resolvedItems: searchItems(items, value, searchBy)
            });
        }

        // case we need to search with a function
        if (_.isFunction(this.searchItems)) {
            /*
                wrap the function in a promise, so:
                - if the function returns a value, we'll be able to treat it as a promise
                - if the function returns a promise, we'll transparently use the resulting promise
            */
            Promise.resolve(this.searchItems(value)).then((resolvedItems) => {
                this.setState({
                    resolvedItems: resolvedItems
                });
            });
        }
    };

    handleUserSelects = (item) => {
        const {
            onValueChange, path, renderAs, onRenderItem
        } = this.props;

        let renderableItem;

        if (!_.isNil(renderAs)) {
            renderableItem = _.get(item, renderAs);
        } else if (!_.isNil(onRenderItem)) {
            renderableItem = onRenderItem(item);
        } else {
            renderableItem = item;
        }

        this.setState({
            rawInput: renderableItem,
            resolvedItems: []
        });
        if (onValueChange) {
            onValueChange(item, path);
        }
    };

    handleOnFocus = () => {
        const { rawInput } = this.state;
        if (_.isEmpty(rawInput)) {
            // simulate a search for an empty string
            this.handleRawInputChange('');
        }
    };

    handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            this.handleCancelSelection();
        } else if (event.key === 'ArrowDown') {
            if (this.dropdownRef) {
                event.preventDefault();
                this.dropdownRef.focus();
            }
        }
    };

    setupDropdownRef = (domRef) => {
        this.dropdownRef = domRef;
    };

    getRawInputValue = () => {
        const { value, renderAs, onRenderItem } = this.props;
        const { rawInput } = this.state;
        let valueForRawInput;
        if (_.isNil(rawInput)) {
            if (!_.isEmpty(renderAs) && _.isString(renderAs)) {
                valueForRawInput = _.get(value, renderAs, '');
            } else {
                valueForRawInput = value;
            }
        } else if (!_.isEmpty(value)) {
            valueForRawInput = _.isFunction(onRenderItem) ? onRenderItem(value) : value;
        } else {
            valueForRawInput = rawInput;
        }
        return valueForRawInput;
    };

    render() {
        return super.render();
    }

    setInputEvents = (element) => {
        if (element) {
            const input = element.querySelector('input');
            input.addEventListener('keydown', this.handleKeyDown);
        }
    };

    clearSelectionAndInput = () => {
        const { onClearTypeaheadInput, path } = this.props;

        this.setState({
            rawInput: '',
            resolvedItems: []
        });
        if (onClearTypeaheadInput) {
            onClearTypeaheadInput(undefined, path);
        }
    };

    renderControl() {
        const { resolvedItems, rawInput } = this.state;
        const {
            renderAs, maxResults, onRenderItem, value, id
        } = this.props;
        const inputProps = {
            ...this.props,
            hideLabel: true,
            onValueChange: this.handleRawInputChange,
            onKeyDown: this.handleKeyDown,
            onFocus: this.handleOnFocus,
            layout: 'full-width',
            value: this.getRawInputValue(),
            containerClass: styles.zeroPadding
        };

        const searchStyles = classNames(styles.icon);
        const clearStyles = classNames(styles.icon, styles.clearIcon);

        const icon = _.isEmpty(rawInput) && _.isEmpty(value) ? (
            <Icon className={searchStyles} icon="mi-search" />
        ) : (
            <Icon
                id={`${id}_clearTypeaheadSearch`}
                onClick={this.clearSelectionAndInput}
                className={clearStyles}
                icon="mi-close"
            />
        );

        return (
            <div ref={this.setInputEvents} className={styles.gwTypeahead}>
                <InputField {...inputProps} />
                {icon}
                <TypeAheadDropdownComponent
                    id={id}
                    setupRef={this.setupDropdownRef}
                    items={resolvedItems}
                    onItemSelected={this.handleUserSelects}
                    onCancelSelection={this.handleCancelSelection}
                    renderAs={renderAs}
                    maxResults={maxResults}
                    onRenderItem={onRenderItem}
                />
            </div>
        );
    }
}

export default TypeAheadComponent;
