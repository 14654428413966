export const currencyFieldStyles = {
    currency: 'jut__CurrencyField__currency',
    currencySymbol: 'jut__CurrencyField__currencySymbol',
    currencyInput: 'jut__CurrencyField__currencyInput'
};

export const inputFieldStyles = {
    input: 'jut__InputField__input',
    inputLarge: 'jut__InputField__inputLarge',
    inputSmall: 'jut__InputField__inputSmall',
};
