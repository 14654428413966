import { defineMessages } from 'react-intl';

export default defineMessages({
    ebikeEstimationInformation: {
        id: 'anonymous.fnol.wizard.components.repair.ebike.Estimation information',
        defaultMessage: 'Estimated repair cost: '
    },
    ebikeEstimationBike: {
        id: 'anonymous.fnol.wizard.components.repair.ebike.Cost Bike',
        defaultMessage: 'Cost Bike'
    },
    ebikeEstimationOtherCosts: {
        id: 'anonymous.fnol.wizard.components.repair.ebike.Cost Other',
        defaultMessage: 'Cost Other'
    },
    ebikeEstimationUploadOfDocuments: {
        id: 'anonymous.fnol.wizard.components.repair.ebike.Upload of the documents with estimations is mandatory!',
        defaultMessage: 'Upload of the documents with estimations is mandatory!'
    }
});
