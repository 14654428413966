import { defineMessages } from 'react-intl';

export default defineMessages({
    wizardFNOLAutoRepairFacilityName: {
        id: 'anonymous.fnol.wizard.components.newVendorFragment.facility.Repair facility Name',
        defaultMessage: 'Name of the repair facility'
    },
    wizardFNOLAutoRepairChoiceCheckIfVendorExists: {
        id: 'anonymous.fnol.wizard.components.newVendorFragment.facility.* After you provide details of your repair facility, please check if your garage is an authorized NN repair facility',
        defaultMessage: '* After you provide details of your repair facility, please check if your garage is an authorized NN repair facility'
    },
    wizardFNOLAutoRepairChoiceCheckIfVendorExistsButton: {
        id: 'anonymous.fnol.wizard.pages.repair.facility.Check your garage',
        defaultMessage: 'Check your garage'
    },
    wizardFNOLAutoRepairChoiceCheckIfVendorExistsTrue: {
        id: 'anonymous.fnol.wizard.pages.repair.facility.Your garage is authorized NN repair facility.',
        defaultMessage: 'Your garage is authorized NN repair facility.'
    },
    wizardFNOLAutoRepairChoiceCheckIfVendorExistsFalseTopLabel: {
        id: 'anonymous.fnol.wizard.pages.repair.facility.Unfortunatelly, your garage is not a NN authorized repair facility.',
        defaultMessage: 'Unfortunately, your garage is not a NN authorized repair facility.'
    },
    wizardFNOLAutoRepairChoiceCheckIfVendorExistsFalseBottomLabel: {
        id: 'anonymous.fnol.wizard.pages.repair.facility.Please look for benefits of selecting garage from NN network.',
        defaultMessage: 'Please look for benefits of selecting garage from NN network.'
    }
});
