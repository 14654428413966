/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { IconButton, DropdownSelectField } from '@jutro/components';
import { withValidation, validationPropTypes } from 'gw-portals-validation-react';
import styles from './DocumentUploadList.module.scss';
import messages from './DocumentUploadList.messages';
import FileUploadMulti from '../FileUploadMulti/FileUploadMulti';
import allowedFilesConfig from './AllowedFileTypesForDoc.json';
import formatFileSize from '../FileUploadMulti/FormatFileSize';

/*
value =
[
    {
        id,
        files: [a.msg, b.msg],
        documentType: Email
    },
    {
        id,
        files: [a.png, b.png, c.png],
        documentType: Photo
    },
]
//        const docUploadUrl = process.env.REACT_APP_DOCUMENT_UPLOAD_URL;

*/

class DocumentUploadList extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        availableDocumentTypes: PropTypes.array.isRequired,
        value: PropTypes.array.isRequired,
        onValueChange: PropTypes.func.isRequired,
        maxTotalFilesSizeBytes: PropTypes.number.isRequired,
        maxTotalFilesCount: PropTypes.number.isRequired,
        ...validationPropTypes
    };

    state = {
        vFilesAreTooBig: false,
        vFileLimitExceeded: false,
        vErrors: [],
        errorsNeedUpdate: false
    };

    constructor(props) {
        super(props);
        this.onDocTypeChange = this.onDocTypeChange.bind(this);
        this.onFilesChange = this.onFilesChange.bind(this);
        this.onDocDelete = this.onDocDelete.bind(this);
        this.validateDocumentList = this.validateDocumentList.bind(this);
        this.setFilesAreTooBig = this.setFilesAreTooBig.bind(this);
        this.setFileLimitExceeded = this.setFileLimitExceeded.bind(this);
        this.setErrorsNeedUpdate = this.setErrorsNeedUpdate.bind(this);
        this.setVErrors = this.setVErrors.bind(this);
        // eslint-disable-next-line max-len
        this.getAllowedFileTypesStringForDocType = this.getAllowedFileTypesStringForDocType.bind(this);
        this.getExtensionFromFileName = this.getExtensionFromFileName.bind(this);

        const { value, onValueChange } = this.props;
        if (!value || value.length === 0) {
            const initializedArray = this.createLastEmptyDocIfNeeded([]);
            onValueChange(initializedArray);
        }
    }

    setErrorsNeedUpdate = (newValue) => {
        const { errorsNeedUpdate } = this.state;
        if (errorsNeedUpdate !== newValue) {
            this.setState({ errorsNeedUpdate: errorsNeedUpdate });
        }
    }

    setFilesAreTooBig = (newValue) => {
        const { vFilesAreTooBig } = this.state;
        if (vFilesAreTooBig !== newValue) {
            this.setState({ vFilesAreTooBig: newValue });
        }
    }

    setFileLimitExceeded = (newValue) => {
        const { vFileLimitExceeded } = this.state;
        if (vFileLimitExceeded !== newValue) {
            this.setState({ vFileLimitExceeded: newValue });
        }
    };

    setVErrors = (vErr, newArray) => {
        let differenceFound = false;

        if (vErr.length === newArray.length) {
            vErr.every((val, indx) => {
                differenceFound = ((val.errorCode) !== newArray[indx].errorCode);
                return (!differenceFound);
            });
        } else {
            differenceFound = true;
        }

        if (differenceFound) {
            this.setState({ vErrors: newArray });
        }
    }

    getAllowedFileTypesStringForDocType = (documentType) => {
        const fileTypesString = allowedFilesConfig
            .allowedFileTypesForDocumentType.find((el) => {
                return el.documentType === documentType;
            }).allowedFiles;
        return fileTypesString;
    }

    getExtensionFromFileName = (fileName) => {
        const fileExtensionArr = fileName.split('.');
        if (fileExtensionArr.length === 1) { // file without any extension
            return '';
        }
        return fileExtensionArr.pop();
    }

    isFileTypeInvalid = (doc) => {
        const fileTypesString = this.getAllowedFileTypesStringForDocType(doc.documentType);
        const fileTypesArr = fileTypesString.split(',');
        let invalidFilesFound = false;
        const invalidFileNames = [];
        doc.files.forEach((file) => {
            const fileExtension = this.getExtensionFromFileName(file.name);

            if (!fileTypesArr.some((e) => {
                return fileExtension.toLowerCase() === e.toLowerCase();
            })) {
                invalidFilesFound = true;
                invalidFileNames.push(file.name);
            }
        });
        return { invalidFilesFound, invalidFileNames, allowedFileTypesArr: fileTypesArr };
    }

    validateDocumentList = () => {
        const translator = this.context;

        const { value } = this.props;
        const { vErrors } = this.state;
        let totalFileSize = 0;
        let totalFileCount = 0;

        const newErrors = value.map((doc, indx) => {
            const result = { isValid: true, errorMessage: '', errorCode: 'NONE' };
            if (indx === value.length - 1) {
                return result; // skip last item (it's used to provide input for adding new doc)
            }

            doc.files.forEach((val) => { totalFileSize += val.size; });
            totalFileCount += doc.files.length;

            const {
                invalidFilesFound,
                invalidFileNames,
                allowedFileTypesArr
            } = this.isFileTypeInvalid(doc);

            if (invalidFilesFound) {
                result.isValid = false;
                result.errorCode = 'FILE_TYPE';
                const invalidFileNamesString = invalidFileNames.join(', ');
                const allowedFileTypesString = allowedFileTypesArr.join(', ');
                result.errorMessage = translator(
                    messages.invalidFileType,
                    {
                        invalidFileNamesString: invalidFileNamesString,
                        allowedFileTypesString: allowedFileTypesString
                    }
                );
            }

            return result;
        });

        this.setVErrors(vErrors, newErrors);

        const { maxTotalFilesSizeBytes, maxTotalFilesCount, requiredDocumentTypesToMessagesMap } = this.props;
        const filesAreIsToBig = (totalFileSize > maxTotalFilesSizeBytes);
        const tooManyFiles = (totalFileCount > maxTotalFilesCount);

        this.setFilesAreTooBig(filesAreIsToBig);
        this.setFileLimitExceeded(tooManyFiles);

        const errorsExist = newErrors.find((err) => { return !err.isValid; });
        if (errorsExist) {
            return false;
        }

        if (requiredDocumentTypesToMessagesMap.length !== 0) {
            let documentsValidation;
            requiredDocumentTypesToMessagesMap.forEach((validationEntry) => {
                validationEntry.types.forEach((validationEntryType) => {
                    const documentsOfRequiredTypeMissing = !value.find((el) => el.documentType === validationEntryType && el.files.length !== 0);
                    if (documentsOfRequiredTypeMissing) {
                        documentsValidation = false;
                    }
                });
            });
            if (documentsValidation === false) return false;
        }

        return true;
    };

    componentDidUpdate() {
        const {
            isComponentValid,
            id,
            onValidate,
            hasValidationChanged,
            setComponentValidation
        } = this.props;

        const isValid = this.validateDocumentList();
        if (isValid !== isComponentValid) {
            setComponentValidation(isValid, id);
        }
        if (onValidate && hasValidationChanged) {
            onValidate(isValid, id);
        }
    }

    generateId = () => {
        return (new Date()).getTime().toString();
    }

    createNewEmptyDocument = () => {
        const newDoc = {
            id: this.generateId(),
            files: []
        };
        return newDoc;
    }

    createLastEmptyDocIfNeeded = (docArray) => {
        if (!docArray || docArray.length < 1) {
            return docArray.concat(this.createNewEmptyDocument());
        }
        const lastDoc = docArray[docArray.length - 1];
        if (lastDoc.documentType && lastDoc.files && lastDoc.files.length > 0) {
            return docArray.concat(this.createNewEmptyDocument());
        }
        return docArray;
    }

    onDocTypeChange(docId, newValue) {
        const { value, onValueChange } = this.props;
        let targetDocList = value.map((doc) => {
            if (doc.id === docId) {
                return { ...doc, documentType: newValue };
            }
            return doc;
        });
        targetDocList = this.createLastEmptyDocIfNeeded(targetDocList);
        onValueChange(targetDocList);
    }

    onFilesChange(docId, newValue) {
        const { value, onValueChange } = this.props;
        let targetDocList = value.map((doc) => {
            if (doc.id === docId) {
                return { ...doc, files: newValue };
            }
            return doc;
        });
        targetDocList = this.createLastEmptyDocIfNeeded(targetDocList);
        onValueChange(targetDocList);
    }

    onDocDelete(docId) {
        const { value, onValueChange } = this.props;
        let targetDocList = value.filter((doc) => doc.id !== docId);
        targetDocList = this.createLastEmptyDocIfNeeded(targetDocList);
        onValueChange(targetDocList);
    }

    displayErrorString = (doc, indx) => {
        const { vErrors } = this.state;
        if (indx < vErrors.length && vErrors[indx].errorCode !== 'NONE') {
            return (
                <p className={cx(styles.pError)}>
                    { vErrors[indx].errorMessage }
                </p>
            );
        }
        return '';
    }

    render() {
        const translator = this.context;
        const {
            availableDocumentTypes,
            value,
            maxTotalFilesSizeBytes,
            maxTotalFilesCount,
            defaultDocumentType,
            requiredDocumentTypesToMessagesMap,
            isSaveAndResumeEnabled
        } = this.props;
        const { vFilesAreTooBig, vFileLimitExceeded } = this.state;

        console.log('defaultdctype', defaultDocumentType);
        const docList = value.map((doc, indx) => (
            <div className={cx(styles.oneDocumentDiv)} key={`dv-${doc.id}`}>
                { this.displayErrorString(doc, indx) }
                <div className={cx(styles.docTypeSelect)}>
                    <DropdownSelectField
                        key={`ds-${doc.id}`}
                        availableValues={availableDocumentTypes}
                        value={doc.documentType}
                        defaultValue={defaultDocumentType}
                        onValueChange={(newValue) => this.onDocTypeChange(doc.id, newValue)}
                        label={messages.documentTypeLabel}
                        placeholder={messages.documentTypePlaceholder}
                        id="documentTypeDropdown"
                        readOnly={doc.files && doc.files.length > 0}
                    />
                </div>
                <div className={cx(styles.fileUploadComponent)}>
                    <FileUploadMulti
                        key={`fu-${doc.id}`}
                        value={doc.files}
                        onValueChange={(newValue) => this.onFilesChange(doc.id, newValue)}
                        readOnly={doc.files}
                        disabled={!doc.documentType}
                        className={cx(styles.fileUploadComponent)}
                    />
                </div>
                <div className={cx(styles.deleteDocIcon)}>
                    <IconButton
                        key={`del-${doc.id}`}
                        id="deleteButton"
                        icon="mi-delete-forever"
                        iconColor="dark"
                        type="action"
                        tooltip={{
                            text: messages.deleteThisDocument,
                            placement: 'top'
                        }}
                        disabled={!doc.documentType}
                        onClick={() => this.onDocDelete(doc.id)}
                    />
                </div>
            </div>
        ));

        let topErrorFilesTooBigString = '';
        let topErrorFilesLimitExceededString = '';
        let topErrorEstimateRequiredButNotProvided = '';
        if (vFilesAreTooBig) {
            topErrorFilesTooBigString
                += translator(
                    messages.filesAreTooBig,
                    {
                        maxSize: formatFileSize(maxTotalFilesSizeBytes)
                    }
                );
        }
        if (vFileLimitExceeded) {
            topErrorFilesLimitExceededString
                += translator(
                    messages.fileLimitExceeded,
                    {
                        maxCount: maxTotalFilesCount
                    }
                );
        }

        if (requiredDocumentTypesToMessagesMap.length !== 0) {
            requiredDocumentTypesToMessagesMap.forEach((validationEntry) => {
                let addValidationMessageForEntry = false;
                validationEntry.types.forEach((validationEntryType) => {
                    const documentsOfRequiredTypeMissing = !value.find((el) => el.documentType === validationEntryType && el.files.length !== 0);
                    if (documentsOfRequiredTypeMissing) {
                        addValidationMessageForEntry = true;
                    }
                });

                if (addValidationMessageForEntry) {
                    topErrorEstimateRequiredButNotProvided += translator(validationEntry.validationMessage);
                }
            });
        }

        return (
            <div className={cx(styles.documentListDiv)}>
                <h2>
                    { translator(messages.documentsTitle) }
                </h2>
                <p>
                    { translator(messages.documentsSubTitle) }
                </p>
                {isSaveAndResumeEnabled && <p>
                    { translator(messages.documentsSaveAndResumeParagraph) }
                </p>}
                <span className={cx(styles.pError)}>
                    { topErrorEstimateRequiredButNotProvided }
                </span>
                <div className={cx(styles.docsDiv)}>
                    {docList}
                    <p className={cx(styles.pError)}>
                        { topErrorFilesTooBigString }
                        { vFilesAreTooBig && <br /> }
                        { topErrorFilesLimitExceededString }
                    </p>
                </div>
            </div>
        );
    }
}

export default withValidation(DocumentUploadList);
