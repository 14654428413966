import { defineMessages } from 'react-intl';

export default defineMessages({
    privacyPolicyGoBack: {
        id: 'anonymous.privacyPolicy.Go back',
        defaultMessage: 'Go back'
    },
    privacyPolicyHeader: {
        id: 'anonymous.privacyPolicy.Privacy statement and rights of registered people.',
        defaultMessage: 'Privacy statement and rights of registered people.'
    },
    privacyPolicyPersonalDataParagraph: {
        id: 'anonymous.privacyPolicy.All personal data communicated to NN will only be used for one of the following purposes: evaluation of insured risks; managing the commercial relationship, the insurance policy and the claims covered by the contract; monitoring of our portfolio; and fraud prevention or prevention of misuse. Only for those purposes can that data be shared, where needed, to a reinsurance company, an expert, an advisor or another partner as stated in the General Conditions. The data can be accessed by the Acceptance Team and by the Claims Handling department, as well as the Legal department and Compliance as part of a potential litigation, for controlling purposes or by Audit, strictly within the boundaries of the mission and work these departments must carry out.',
        defaultMessage: 'All personal data communicated to NN will only be used for one of the following purposes: evaluation of insured risks; managing the commercial relationship, the insurance policy and the claims covered by the contract; monitoring of our portfolio; and fraud prevention or prevention of misuse. Only for those purposes can that data be shared, where needed, to a reinsurance company, an expert, an advisor or another partner as stated in the General Conditions. The data can be accessed by the Acceptance Team and by the Claims Handling department, as well as the Legal department and Compliance as part of a potential litigation, for controlling purposes or by Audit, strictly within the boundaries of the mission and work these departments must carry out.'
    },
    privacyPolicyInsuredPersonParagraph: {
        id: 'anonymous.privacyPolicy.The insured person (the user) hereby explicitly gives its consent that the provided data can be treated by the above mentioned departments, when needed to properly manage contracts and claims, and by the Medical department for data relative to its health (if applicable). The insured person agrees that the policy holder and the intermediary may have knowledge of the contract content and its potential exclusions. Failure to communicate requested data to NN may lead to the impossibility or even the refusal to engage into a commercial relationship, to pursue such relationship or to perform an operation requested by the concerned person.',
        defaultMessage: 'The insured person (the user) hereby explicitly gives its consent that the provided data can be treated by the above mentioned departments, when needed to properly manage contracts and claims, and by the Medical department for data relative to its health (if applicable). The insured person agrees that the policy holder and the intermediary may have knowledge of the contract content and its potential exclusions. Failure to communicate requested data to NN may lead to the impossibility or even the refusal to engage into a commercial relationship, to pursue such relationship or to perform an operation requested by the concerned person.'
    },
    privacyPolicyEveryPersonParagraph: {
        id: 'anonymous.privacyPolicy.Every person who can prove its identity has the right to receive a communication of its data stored, by sending a signed and dated request to the Privacy department. This request will have to include a copy of its identity card, and will have to mention the name and address of the doctor to whom our counseling doctor can provide any data we might keep related to its medical condition. The person has the right to request a correction or deletion of its data in case of errors, and has the right to oppose any use for direct marketing purposes.',
        defaultMessage: 'Every person who can prove its identity has the right to receive a communication of its data stored, by sending a signed and dated request to the Privacy department. This request will have to include a copy of its identity card, and will have to mention the name and address of the doctor to whom our counseling doctor can provide any data we might keep related to its medical condition. The person has the right to request a correction or deletion of its data in case of errors, and has the right to oppose any use for direct marketing purposes.'
    },
    privacyPolicyInsurerHeader: {
        id: 'anonymous.privacyPolicy.Insurer',
        defaultMessage: 'Insurer'
    },
    privacyPolicyInsurerFirstParagraph: {
        id: 'anonymous.privacyPolicy.NN Non-Life Insurance NV, a Dutch incorporated company, authorised to insure risks in Belgium as an insurance company registered in Belgium with NBB (National Bank of Belgium) under code number 1449.',
        defaultMessage: 'NN Non-Life Insurance NV, a Dutch incorporated company, authorised to insure risks in Belgium as an insurance company registered in Belgium with NBB (National Bank of Belgium) under code number 1449.'
    },
    privacyPolicyInsurerSecondParagraph: {
        id: 'anonymous.privacyPolicy.Registered office: Prinses Beatrixlaan 35, 2595 AK The Hague, Netherlands - Trade Register number 27127537, regulated by the Nederlandsche Bank (Dutch central bank). NN Non-Life Insurance NV is able to act as an insurance intermediary in Belgium on the basis of the free movement of services.',
        defaultMessage: 'Registered office: Prinses Beatrixlaan 35, 2595 AK The Hague, Netherlands - Trade Register number 27127537, regulated by De Nederlandsche Bank (Dutch central bank). NN Non-Life Insurance NV is able to act as an insurance intermediary in Belgium on the basis of the free movement of services.'
    },
    privacyPolicyBelgiumRepresentativeHeader: {
        id: 'anonymous.privacyPolicy.Representative in Belgium',
        defaultMessage: 'Representative in Belgium'
    },
    privacyPolicyBelgiumRepresentativeParagraph: {
        id: 'anonymous.privacyPolicy.NN Insurance Services Belgium SA/NV, competent in particular for settling claims in Belgium for NN Non-Life Insurance NV, an insurance agent registered with the FSMA under number 0890.270.750. Registered office: Fonsnylaan 38, B-1060 Brussels, Belgium - Brussels Register of Companies - VAT BE 0890.270.750 - www.nn.be',
        defaultMessage: 'NN Insurance Services Belgium SA/NV, competent in particular for settling claims in Belgium for NN Non-Life Insurance NV, an insurance agent registered with the FSMA under number 0890.270.750. Registered office: Fonsnylaan 38, B-1060 Brussels, Belgium - Brussels Register of Companies - VAT BE 0890.270.750 - www.nn.be'
    }
});
