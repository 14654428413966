/* eslint-disable max-len */
import _ from 'lodash';
import React, { useEffect, useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import FNOLRepairEstimatesWrapper from '../estimatesComponents/FNOLRepairEstimatesWrapper/FNOLRepairEstimatesWrapper';
import metadata from './WizardFNOLPropertyRepair.metadata.json5';
import styles from './WizardFNOLPropertyRepair.module.scss';
import messages from './WizardFNOLPropertyRepair.messages';

function WizardFNOLPropertyRepair(props) {
    const {
        id,
        value: repairDTO,
        wizardDTO,
        onValidate,
        writeValue,
        showRepairInKind,
        path
    } = props;
    const translator = useContext(TranslatorContext);

    const {
        onValidate: onComponentValidate,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('WizardFNOLPropertyRepair');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;
        writeValue(value, fullPath);
    }, [writeValue, path]);

    const renderSecondaryLabelPreferredVendor = useCallback(
        () => {
            return (
                <React.Fragment>
                    <div>{translator(messages.ownVendorSecondaryLabelHeader)}</div>
                    <ol>
                        <li>{translator(messages.ownVendorSecondaryLabelContractorClause)}</li>
                        <li>{translator(messages.ownVendorSecondaryLabelQuickRepairClause)}</li>
                        <li>{translator(messages.ownVendorSecondaryLabelQuotationsClause)}</li>
                        <li>{translator(messages.ownVendorSecondaryLabelExemptionClause)}</li>
                        <li>{translator(messages.ownVendorSecondaryLabelWeArrangeEverythingClause)}</li>
                        <li>{translator(messages.ownVendorSecondaryLabelQualityClause)}</li>
                        <li>{translator(messages.ownVendorSecondaryLabelWarrantyClause)}</li>
                    </ol>
                </React.Fragment>
            );
        }, [translator]
    );

    const onValueChangeWithRepairDetailsReset = useCallback((value, changedPath) => {
        handleValueChange(null, 'alreadyReceivedEstimate');
        handleValueChange(null, 'damageAlreadyRepaired');
        handleValueChange({}, 'estimatedRepairCost');
        handleValueChange({}, 'repairFacilityAddress');
        handleValueChange(null, 'repairFacilityName');
        handleValueChange(value, changedPath);
        if (value !== 'NewVendor') {
            disregardFieldValidation(['FNOLRepairEstimates', 'fNOLRepairEstimatesReceived']);
        }
    }, [handleValueChange, disregardFieldValidation]);

    const overrideProps = {
        wizardFNOLPropertyRepairHeaderFirstText: {
            visible: showRepairInKind
        },
        wizardFNOLPropertyRepairHeaderSecondText: {
            visible: showRepairInKind
        },
        wizardFNOLPropertyRepairChoicePreferredVendor: {
            value: repairDTO.repairOptionChoice.value.code,
            renderSecondaryLabel: renderSecondaryLabelPreferredVendor,
            onValueChange: onValueChangeWithRepairDetailsReset,
            visible: showRepairInKind
        },
        wizardFNOLPropertyRepairChoiceOwnVendor: {
            value: repairDTO.repairOptionChoice.value.code,
            onValueChange: onValueChangeWithRepairDetailsReset,
            availableValues: [{
                id: 'newVendor',
                code: 'NewVendor',
                displayName: showRepairInKind ? messages.wizardFNOLPropertyRepairChoiceOwnVendor : messages.wizardFNOLPropertyRepairChoiceOwnVendorNoRepairInKind
            }]
        },
        wizardFNOLPropertyRepairChoiceOwnVendorContent: {
            visible: repairDTO.repairOptionChoice.value.code === 'NewVendor'
        },
        wizardFNOLPropertyRepairNoVendorChoice: {
            value: repairDTO.repairOptionChoice.value.code,
            onValueChange: onValueChangeWithRepairDetailsReset,
            visible: showRepairInKind
        },
        wizardFNOLPropertyRepairChoiceNoVendorContent: {
            visible: repairDTO.repairOptionChoice.value.code === 'NoRepair'
        },
        fNOLRepairEstimatesReceived: {
            writeValue: handleValueChange,
            repairDTO: repairDTO,
            wizardDTO: wizardDTO,
            showBuildingContent: true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            estimatesReceivedWrapperComponent: FNOLRepairEstimatesWrapper,
        },
        resolveCallbackMap: {
            onValidate: onComponentValidate
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={repairDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onComponentValidate}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default WizardFNOLPropertyRepair;
