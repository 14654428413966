import { defineMessages } from 'react-intl';

export default defineMessages({
    wizardFnolSummaryPageAboutVehicle: {
        id: 'anonymous.wizard.fnol.pages.summary.About the vehicle',
        defaultMessage: 'About the involved vehicle'
    },
    wizardFnolSummaryPageWhoWasDriver: {
        id: 'anonymous.wizard.fnol.pages.summary.Who was the driver?',
        defaultMessage: 'Who was the driver?'
    },
    fnolSummaryVehicleDamage: {
        id: 'anonymous.wizard.fnol.pages.summary.About the vehicle damage',
        defaultMessage: 'About the vehicle damage'
    },
    wizardFnolSummaryPageAboutVehicleElectronics: {
        id: 'anonymous.wizard.fnol.pages.summary.Built in electronics (audio, DVD, carkit, GPS, ...)',
        defaultMessage: 'Built in electronics (audio, DVD, carkit, GPS, ...)'
    },
    wizardFnolSummaryPageAboutVehicleSpoiler: {
        id: 'anonymous.wizard.fnol.pages.summary.Spoiler, antenna, wiper',
        defaultMessage: 'Spoiler, antenna, wiper'
    },
    wizardFnolSummaryPageAboutVehicleRims: {
        id: 'anonymous.wizard.fnol.pages.summary.Rims, wheel covers',
        defaultMessage: 'Rims, wheel covers'
    },
    wizardFnolSummaryPageAboutVehicleOtherDamage: {
        id: 'anonymous.wizard.fnol.pages.summary.Other damages',
        defaultMessage: 'Other damage'
    },
    wizardFnolSummaryPageAboutVehicleOtherDamageGlassBreak: {
        id: 'anonymous.wizard.fnol.pages.summary.Glass Break',
        defaultMessage: 'Glass break'
    },
    wizardFnolSummaryPageAboutVehicleOtherDamageTires: {
        id: 'anonymous.wizard.fnol.pages.summary.Tires',
        defaultMessage: 'Tires'
    },
    wizardFnolSummaryPageAboutVehicleOtherDamageBottom: {
        id: 'anonymous.wizard.fnol.pages.summary.Bottom',
        defaultMessage: 'Bottom'
    },
    wizardFnolSummaryPageAboutVehicleOtherDamageNoVisibleDamage: {
        id: 'anonymous.wizard.fnol.pages.summary.No visible damage',
        defaultMessage: 'No visible damage'
    },
    wizardFnolSummaryPageNoDriver: {
        id: 'anonymous.wizard.fnol.pages.summary.No driver',
        defaultMessage: 'There was no driver'
    },
    wizardFnolSummaryPageOtherVehicleDamages: {
        id: 'anonymous.wizard.fnol.pages.summary.Other damages',
        defaultMessage: 'Other damage'
    },
    wizardFnolSummaryOtherDamagesTrailerLight: {
        id: 'anonymous.wizard.fnol.pages.summary.Trailer damage below 750 kg',
        defaultMessage: 'Damages to a trailer below 750 kg'
    },
    wizardFnolSummaryOtherDamagesTrailerHeavy: {
        id: 'anonymous.wizard.fnol.pages.summary.Trailer damage above 750 kg',
        defaultMessage: 'Damages to a trailer above 750 kg'
    },
    wizardFnolSummaryOtherDamagesBikeTrailer: {
        id: 'anonymous.wizard.fnol.pages.summary.Baggage or bike carrier',
        defaultMessage: 'Baggage carrier or bike carrier'
    },
    wizardFnolSummaryOtherDamagesBicycleDamage: {
        id: 'anonymous.wizard.fnol.pages.summary.Bicycle',
        defaultMessage: 'Transported Bicycle'
    },
    wizardFnolSummaryOtherDamagesElectronics: {
        id: 'anonymous.wizard.fnol.pages.summary.Electronics including computers',
        defaultMessage: 'Electronics including computers'
    },
    wizardFnolSummaryOtherDamagesClothingEquipment: {
        id: 'anonymous.wizard.fnol.pages.summary.Luggage, clothing & equipment',
        defaultMessage: 'Luggage, clothing & equipment'
    },
    wizardFnolSummaryOtherDamagesOtherDamage: {
        id: 'anonymous.wizard.fnol.pages.summary.Other',
        defaultMessage: 'Other'
    },
    wizardFnolSummaryTrailerDamageTires: {
        id: 'anonymous.wizard.fnol.pages.summary.Tires',
        defaultMessage: 'Tires'
    },
    wizardFnolSummaryTrailerDamageBottom: {
        id: 'anonymous.wizard.fnol.pages.summary.Bottom',
        defaultMessage: 'Bottom'
    },
    wizardFnolSummaryTrailerDamageOther: {
        id: 'anonymous.wizard.fnol.pages.summary.Other',
        defaultMessage: 'Other'
    },
    wizardFnolSummaryTrailerDamageTrailerDetails: {
        id: 'anonymous.wizard.fnol.pages.summary.Trailer details',
        defaultMessage: 'Details of the trailer'
    },
    wizardFnolSummaryTrailerDamageDamageLabel: {
        id: 'anonymous.wizard.fnol.pages.summary.Trailer Damage',
        defaultMessage: 'Damages to the trailer'
    },
    wizardFnolSummaryPageRepairDetails: {
        id: 'anonymous.wizard.fnol.pages.summary.Do you need a repair facility?',
        defaultMessage: 'Do you need a repair facility?'
    },
    wizardFnolSummaryPageRepairDetailsNoRepair: {
        id: 'anonymous.wizard.fnol.pages.summary.No repair facility needed',
        defaultMessage: 'No repair facility needed'
    }
});
