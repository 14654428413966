import _ from 'lodash';
import React, { useMemo, useEffect, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import useBreakpointHandler from '../../../../hooks/useBreakpointHandler';
import { isNotEmptyOrZero } from '../../../../utils/CurrencyAmountUtils';
import metadata from './FNOLRepairEstimates.metadata.json5';
import styles from './FNOLRepairEstimates.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from '../EstimateComponents.messages';

function FNOLRepairEstimates(props) {
    const {
        id,
        repairDTO,
        onValidate,
        writeValue,
        showBuildingContent
    } = props;

    const [
        isMobileDevice
    ] = useBreakpointHandler();

    const {
        onValidate: onComponentValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('FNOLRepairEstimates');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const componentValidation = useCallback(() => {
        const repairDTOValue = repairDTO.value;
        if (showBuildingContent && repairDTOValue.alreadyReceivedEstimate) {
            return isNotEmptyOrZero(repairDTOValue.estimatedBuildingRepairCost)
                || isNotEmptyOrZero(repairDTOValue.estimatedContentRepairCost);
        }

        if (!showBuildingContent && repairDTOValue.alreadyReceivedEstimate) {
            return isNotEmptyOrZero(repairDTOValue.estimatedRepairCost);
        }
        return true;
    }, [repairDTO, showBuildingContent]);

    useEffect(() => {
        registerComponentValidation(componentValidation);
    }, [registerComponentValidation, componentValidation]);

    const availableDamageRepairedAnswers = useMemo(() => {
        const values = _.get(repairDTO, 'damageAlreadyRepaired.aspects.availableValues');
        return values.map((typecode) => {
            return {
                code: typecode.code,
                name: {
                    id: typecode.name,
                    defaultMessage: typecode.name
                }
            };
        });
    }, [repairDTO]);

    const mobileOrRegularStyle = useCallback((mobileClassName, className) => {
        return isMobileDevice() ? mobileClassName : className;
    }, [isMobileDevice]);

    const overrideProps = {
        fNOLRepairEstimatesRepairCost: {
            visible: !showBuildingContent,
            className: mobileOrRegularStyle('phoneRepairCost', 'repairCost')
        },
        fNOLRepairEstimatesBuildingRepairCost: {
            className: mobileOrRegularStyle('phoneRepairCostWithMargin', 'repairCostWithMargin')
        },
        fNOLRepairEstimatesContentRepairCost: {
            className: mobileOrRegularStyle('phoneRepairCostWithMargin', 'repairCostWithMargin')
        },
        fNOLRepairEstimatesReceivedYesBuildingContent: {
            visible: showBuildingContent
        },
        fNOLRepairEstimatesReceivedYes: {
            visible: repairDTO.alreadyReceivedEstimate.value === true
        },
        fNOLRepairDamageAlreadyFixed: {
            availableValues: availableDamageRepairedAnswers,
            value: repairDTO.value.damageAlreadyRepaired
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={repairDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onComponentValidate}
                onValueChange={writeValue}
            />
        </div>
    );
}

FNOLRepairEstimates.defaultProps = {
    showBuildingContent: false
};

export default FNOLRepairEstimates;
