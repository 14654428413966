import { defineMessages } from 'react-intl';

export default defineMessages({
    fNOLRepairSettledViaPVProcedure: {
        id: 'anonymous.fnol.repair.estimates.Claim settled via PV Broker procedure?',
        defaultMessage: 'Claim settled via PV Broker procedure?'
    },
    fNOLRepairEstimatesAlreadyReceived: {
        id: 'anonymous.fnol.repair.estimates.Have you already received the estimate?',
        defaultMessage: 'Have you already received the estimate?'
    },
    fNOLRepairDamageAlreadyFixed: {
        id: 'anonymous.fnol.repair.estimates.Is the damage already repaired?',
        defaultMessage: 'Is the damage already repaired?'
    },
    fNOLRepairDamageFinalEstimate: {
        id: 'anonymous.fnol.repair.estimates.Is this your only estimate?',
        defaultMessage: 'Is this your only estimate?'
    },
    fNOLRepairEstimatesRepairCost: {
        id: 'anonymous.fnol.repair.estimates.Estimated Repair Cost',
        defaultMessage: 'Estimated repair cost'
    },
    fNOLRepairEstimatesRepairCostBuildingOrContent: {
        id: 'anonymous.fnol.repair.estimates.Please provide the estimate for Building or Content or both, depending on your damage.',
        defaultMessage: 'Please provide the estimate for building or content or both, depending on your damage.'
    },
    fNOLRepairEstimatesReceivedYesUploadDocument: {
        id: 'anonymous.fnol.repair.estimates.Please upload documents with estimate in the next step',
        defaultMessage: 'Please upload documents with estimate in the next step'
    },
    fNOLRepairEstimatesReceivedBuilding: {
        id: 'anonymous.fnol.repair.estimates.Building',
        defaultMessage: 'Building'
    },
    fNOLRepairEstimatesReceivedBuildingLabel: {
        id: 'anonymous.fnol.repair.estimates.Please provide the net amount',
        defaultMessage: 'Please provide the net amount'
    },
    fNOLRepairEstimatesPVPaymentPercentage: {
        id: 'anonymous.fnol.repair.estimates.Payment %',
        defaultMessage: 'Payment %'
    },
    fNOLRepairEstimatesVATIncluded: {
        id: 'anonymous.fnol.repair.estimates.VAT included',
        defaultMessage: 'VAT included'
    },
    fNOLRepairEstimatesReceivedContent: {
        id: 'anonymous.fnol.repair.estimates.Content',
        defaultMessage: 'Content'
    },
    fNOLRepairEstimatesReceivedContentLabel: {
        id: 'anonymous.fnol.repair.estimates.Please provide the gross amount',
        defaultMessage: 'Please provide the gross amount'
    }
});
