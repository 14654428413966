import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './QuoteTableAccordionCardIterableComponent.metadata.json5';
import styles from './QuoteTableAccordionCardIterableComponent.module.scss';

function QuoteTableAccordionCardIterableComponent(props) {
    const { tableData, renderCellContent, data: columnData } = props;

    const coveragesData = { tableData };

    const generatePhoneTableOverrides = useCallback(() => {
        const overrides = tableData.map((tableInfo, index) => ({
            [`quoteTable${index}`]: {
                data: tableInfo.data,
                title: tableInfo.header
            },
            [`quoteTableColumn${index}`]: {
                renderCell: renderCellContent,
                data: tableInfo.tableContent.find(
                    (content) => content.code === columnData.code
                )
            }
        }));

        return Object.assign({}, ...overrides);
    }, [tableData, renderCellContent, columnData]);

    if (!tableData) {
        return null;
    }

    const overrideProps = {
        ...generatePhoneTableOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coveragesData}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}


/**
 * @memberof gw-components-platform-react.QuoteTableAccordionCardIterableComponent
 * @property {Object} propTypes.tableData - value of the table data
 * @property {function(clauseCellUniqueID, index, props)} propTypes.renderCellContent - the
 *                                      function invoked when the cell is rendered
 * @property {Object} propTypes.data - value of the column data
 */
QuoteTableAccordionCardIterableComponent.propTypes = {
    tableData: PropTypes.shape([]).isRequired,
    renderCellContent: PropTypes.func.isRequired,
    data: PropTypes.shape({
        code: PropTypes.string
    }).isRequired
};

export default QuoteTableAccordionCardIterableComponent;
