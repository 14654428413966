import { useContext } from 'react';
import { isPropertyLOB } from '../../../../utils/PolicyTypeUtil';

import ClientContext from '../../../../contexts/ClientContext/ClientContext';
import PartnerContext from '../../../../contexts/PartnerContext/PartnerContext';
import FNOLRepairEstimates from '../FNOLRepairEstimates/FNOLRepairEstimates';
import BrokerFNOLRepairEstimates from '../BrokerFNOLRepairEstimates/BrokerFNOLRepairEstimates';

function FNOLRepairEstimatesWrapper(props) {
    const clientContext = useContext(ClientContext);
    const partnerContext = useContext(PartnerContext);

    const canBeSettledViaPV = partnerContext.partnerTypeAllowedForPVBroker();

    const propsToPass = { ...props };
    propsToPass.canBeSettledViaPV = canBeSettledViaPV && isPropertyLOB(props.wizardDTO.lob.value);

    const isBrokerContext = clientContext.isBrokerContext();

    return isBrokerContext
        ? BrokerFNOLRepairEstimates(propsToPass)
        : FNOLRepairEstimates(propsToPass);
}

export default FNOLRepairEstimatesWrapper;
