/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './WizardFNOLSummaryAuto.metadata.json5';
import useBreakpointHandler from '../../../hooks/useBreakpointHandler';
import {
    buildVehicleDescription,
    buildDriverDescription,
    buildRepairDetailsDamage
} from '../VehicleSummarySnapshotCommonFunctions';
import {
    notEmptyVehicleDamages,
    buildVehicleDamage,
    buildOtherVehicleDamage
} from './AutoSummarySnapshotGenerator';
import pageStyles from '../../../pages/WizardFnolSummaryPage/WizardFNOLSummaryPage.module.scss';
import messages from './WizardFNOLSummaryAuto.messages';
import pageMessages from '../../../pages/WizardFnolSummaryPage/WizardFNOLSummaryPage.messages';

function WizardFNOLSummaryAuto(props) {
    const translator = useContext(TranslatorContext);

    const {
        writeValue,
        wizardDTO
    } = props;

    const [
        isMobileDevice
    ] = useBreakpointHandler();
    const isMobile = isMobileDevice();

    const handleValueChange = useCallback((value, changedPath) => {
        writeValue(value, changedPath);
    }, [writeValue]);

    const overrideProps = {
        fnolSummaryWhereAboutVehicle: {
            visible: !!wizardDTO.value.vehicleDamage,
            value: buildVehicleDescription(wizardDTO),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryWhoWasDriver: {
            visible: !!wizardDTO.value.vehicleDamage,
            value: buildDriverDescription(wizardDTO, translator),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryVehicleDamage: {
            visible: notEmptyVehicleDamages(wizardDTO),
            value: buildVehicleDamage(wizardDTO, translator),
            labelPosition: isMobile ? 'top' : 'left'
        },
        wizardFnolSummaryPageOtherVehicleDamages: {
            visible: !_.isEmpty(buildOtherVehicleDamage(wizardDTO, translator)),
            value: buildOtherVehicleDamage(wizardDTO, translator),
            labelPosition: isMobile ? 'top' : 'left'
        },
        wizardFnolSummaryPageRepairDetails: {
            visible: !_.isEmpty(wizardDTO.value.repairDetails),
            value: buildRepairDetailsDamage(wizardDTO, translator),
            labelPosition: isMobile ? 'top' : 'left'
        }
    };

    const resolvers = {
        resolveClassNameMap: pageStyles,
        resolveComponentMap: {

        },
        resolveCallbackMap: {

        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={wizardDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default WizardFNOLSummaryAuto;
