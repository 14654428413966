import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { Button } from '@jutro/components';
import styles from './FileUploadMulti.module.scss';
import formatFileSize from './FormatFileSize';
import DragAndDrop from '../DragAndDrop/DragAndDrop';
import messages from './FileUploadMulti.messages';

class FileUploadMulti extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        onValueChange: PropTypes.func.isRequired,
        value: PropTypes.array.isRequired,
        disabled: PropTypes.bool.isRequired
    };

    state = {
    };

    constructor(props) {
        super(props);
        this.onFilesChange = this.onFilesChange.bind(this);
        this.uploadButtonClick = this.uploadButtonClick.bind(this);
        this.fileUploadRef = React.createRef();
        this.onFilesDragged = this.onFilesDragged.bind(this);
    }

    onFilesChange = (event) => {
        const { onValueChange } = this.props;
        const arrayOfFiles = Array.from(event.target.files);
        onValueChange(arrayOfFiles);
    }

    uploadButtonClick = () => {
        this.fileUploadRef.current.click();
    }

    renderToBeUploaded = () => {
        const translator = this.context;

        return (
            <DragAndDrop
                id="dragAndDrop"
                onFilesDragged={this.onFilesDragged}
            >
                <div className={cx(styles.uploadDiv)}>
                    <p>
                        { translator(messages.uploadOrDragAndDrop) }
                    </p>
                    <Button
                        type="filled"
                        icon="mi-cloud-upload"
                        onClick={this.uploadButtonClick}
                    >
                        { translator(messages.selectFiles) }
                    </Button>
                    <input
                        className={cx(styles.hiddenStandardInputFile)}
                        type="file"
                        onChange={this.onFilesChange}
                        multiple
                        ref={this.fileUploadRef}
                    />
                </div>
            </DragAndDrop>
        );
    }

    onFilesDragged = (files) => {
        console.log('dragged - files', files);
        const { onValueChange } = this.props;
        const arrayOfFiles = Array.from(files);
        onValueChange(arrayOfFiles);
    }

    renderAlreadyUploaded = () => {
        const translator = this.context;
        const { value } = this.props;

        const fileDetails = value.map((file) => {
            const upladedFileString = translator(
                messages.uploadedFileDetails, {
                    fileName: file.name,
                    fileSize: formatFileSize(file.size)
                }
            );

            return (
                <li key={file.name}>
                    { upladedFileString }
                </li>
            );
        });

        return (
            <div>
                <ul>{fileDetails}</ul>
                <p>
                    {
                        translator(messages.totalUploadedFiles, { fileCount: value.length })
                    }
                </p>
            </div>
        );
    }

    renderDisabled = () => {
        return (
            <div />
        );
    }

    render = () => {
        const { value, disabled } = this.props;

        if (disabled) {
            return this.renderDisabled();
        }

        if (value && value.length > 0) {
            return this.renderAlreadyUploaded();
        }

        if (!value || value.length === 0) {
            return this.renderToBeUploaded();
        }

        throw new Error('Invalid state for FileUploadMulti');
    }
}

export default FileUploadMulti;
