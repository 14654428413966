/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useContext, useEffect, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import FNOLAddress from '../../Address/Address';
import FNOLRepairEstimatesWrapper from '../estimatesComponents/FNOLRepairEstimatesWrapper/FNOLRepairEstimatesWrapper';
import metadata from './WizardFNOLMotorRepair.metadata.json5';
import styles from './WizardFNOLMotorRepair.module.scss';
import messages from './WizardFNOLMotorRepair.messages';
import NewVendorFragment from '../NewVendorFragment/NewVendorFragment';

function WizardFNOLMotorRepair(props) {
    const {
        id,
        history,
        value: repairDTO,
        wizardDTO,
        onValidate,
        writeValue,
        path
    } = props;

    const {
        onValidate: onComponentValidate,
        isComponentValid,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation('WizardFNOLAutoRepair');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const componentValidation = useCallback(() => {
        const repairDTOValue = repairDTO.value;

        switch (repairDTO.repairOptionChoice.value.code) {
            case 'PreferredVendor': return !_.isEmpty(repairDTOValue.repairFacilityAddress);
            case 'NoRepair': return repairDTOValue.alreadyReceivedEstimate === false
                || (!_.isEmpty(repairDTOValue.estimatedRepairCost)
                && repairDTOValue.estimatedRepairCost.amount !== null
                && repairDTOValue.estimatedRepairCost.amount !== 0);
            default: return true;
        }
    }, [repairDTO]);

    useEffect(() => {
        registerComponentValidation(componentValidation);
    }, [registerComponentValidation, componentValidation]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;
        writeValue(value, fullPath);
    }, [writeValue, path]);

    const onValueChangeWithRepairDetailsReset = useCallback((value, changedPath) => {
        const parentDTO = {
            repairOptionChoice: value,
            repairFacilityAddress: {},
            estimatedRepairCost: {}
        };

        writeValue(parentDTO, path);

        if (value !== 'NoRepair') {
            disregardFieldValidation(['FNOLRepairEstimates', 'fNOLRepairEstimatesReceived']);
        }
        if (value !== 'NewVendor') {
            disregardFieldValidation(['wizardFNOLAutoRepairChoiceNewVendorAddress']);
            disregardFieldValidation(['wizardFNOLMotorRepairNewVendorComponent']);
        }
    }, [writeValue, path, disregardFieldValidation]);

    const overrideProps = {
        wizardFNOLMotorRepairChoiceNewVendor: {
            value: repairDTO.repairOptionChoice.value.code,
            onValueChange: onValueChangeWithRepairDetailsReset
        },
        wizardFNOLMotorRepairNewVendorContent: {
            visible: repairDTO.repairOptionChoice.value.code === 'NewVendor'
        },
        wizardFNOLMotorRepairNewVendorComponent: {
            history: history,
            handleValueChange: handleValueChange,
            onValidate: onValidate,
            garageCheckRequired: false,
            repairDTO: repairDTO
        },
        wizardFNOLMotorRepairChoiceHaventDecided: {
            value: repairDTO.repairOptionChoice.value.code,
            onValueChange: onValueChangeWithRepairDetailsReset
        },
        wizardFNOLMotorRepairChoiceHaventDecidedContent: {
            visible: repairDTO.repairOptionChoice.value.code === 'NoRepair'
        },
        fNOLRepairEstimatesReceived: {
            writeValue: handleValueChange,
            repairDTO: repairDTO,
            showBuildingContent: false,
            wizardDTO: wizardDTO
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            estimatesReceivedWrapperComponent: FNOLRepairEstimatesWrapper,
            address: FNOLAddress,
            newVendorComponent: NewVendorFragment
        },
        resolveCallbackMap: {
            onValidate: onComponentValidate
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={repairDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onComponentValidate}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default WizardFNOLMotorRepair;
