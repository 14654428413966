import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getConfiguration } from '@jutro/config';

import { DigitalAuthContextProvider } from './AuthenticationContext';
import CloudAuthContextProvider from './CloudAuthenticationContext';

function AuthContextProvider({ onAuthDataCreation, children }) {
    const config = getConfiguration() || {};
    const cloudAuthEnabled = config.JUTRO_AUTH_ENABLED || false;
    const shouldCheckGrantedAuthorities = config.GRANTED_AUTHORITIES_ENABLED || false;

    if (cloudAuthEnabled) {
        return (
            <CloudAuthContextProvider
                onAuthDataCreation={onAuthDataCreation}
                shouldCheckGrantedAuthorities={shouldCheckGrantedAuthorities}
            >
                {children}
            </CloudAuthContextProvider>
        );
    }
    return (
        <DigitalAuthContextProvider onAuthDataCreation={onAuthDataCreation}>
            {children}
        </DigitalAuthContextProvider>
    );
}

AuthContextProvider.propTypes = {
    onAuthDataCreation: PropTypes.func,
    children: PropTypes.node.isRequired
};

AuthContextProvider.defaultProps = {
    onAuthDataCreation: _.identity
};

export default AuthContextProvider;
