import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentToggleMonthly: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Monthly',
        defaultMessage: 'Monthly'
    },
    paymentToggleAnnually: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Pay In Full',
        defaultMessage: 'Pay In Full'
    },
    quoteAmountMonthly: {
        id: 'quoteandbind.common.directives.common.Monthly',
        defaultMessage: '/ mo.'
    },
    quoteAmountAnually: {
        id: 'quoteandbind.common.directives.common.Annually',
        defaultMessage: '/ pa.'
    },
    buyNow: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Buy Now',
        defaultMessage: 'Buy Now',
    },
    recalculate: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Recalculate',
        defaultMessage: 'Recalculate',
    },
    resetCoverages: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Reset Coverages',
        defaultMessage: 'Reset Coverages',
    },
    moreInfo: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.mobileQuoteView.mobile-coverage-summary.mobile-coverage-summary.More info / Change options',
        defaultMessage: 'More info / Change options',
    },
});
