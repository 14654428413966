import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { QuestionSetsParser } from 'gw-portals-questionsets-js';
import { QuestionSetUtils } from 'gw-portals-util-js';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import messages from './QuestionSetComponent.messages';

function QuestionSetComponent(props) {
    const translator = useContext(TranslatorContext);
    const {
        id,
        onValidate,
        onValueChange,
        value: aQuestionSetAnswersValue,
        path: pathToAnswers,
        labelPosition,
        title
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        registerComponentValidation
    } = useValidation(id);
    const [questionSetsMetadata, setQuestionSetsMetadata] = useState(null);

    useEffect(() => {
        import(
            /* webpackChunkName: "question-sets-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'question-sets-metadata'
        ).then((data) => {
            const { default: result } = data;
            setQuestionSetsMetadata(result);
        });
    }, []);

    const checkComponentValidation = useCallback(() => {
        return questionSetsMetadata ? true : null;
    }, [questionSetsMetadata]);

    useEffect(() => {
        registerComponentValidation(checkComponentValidation);
    }, [checkComponentValidation, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const handleValueChange = useCallback(
        (value, path) => {
            if (value === undefined) {
                return;
            }

            const { code: questionSetCode, answers: questionSetAnswer } = aQuestionSetAnswersValue;
            const qsOrderedQuestions = questionSetsMetadata[questionSetCode].orderedQuestions;

            QuestionSetUtils.cleanDependantQuestions(
                qsOrderedQuestions,
                value,
                path,
                questionSetAnswer
            );

            let val = value;

            if (value.code) {
                val = value.code;
            }
            if (val.length === 0) {
                val = null;
            }
            _.set(aQuestionSetAnswersValue, `answers.${path}`, val);

            if (onValueChange) {
                onValueChange(aQuestionSetAnswersValue, pathToAnswers);
            }
        },
        [aQuestionSetAnswersValue, onValueChange, pathToAnswers, questionSetsMetadata]
    );

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: labelPosition
        }
    };

    if (!questionSetsMetadata) {
        return null;
    }

    const { viewModel: qsViewModel, presentationMetadata: qsMetadata } = new QuestionSetsParser(
        aQuestionSetAnswersValue,
        questionSetsMetadata,
        translator
    );

    return (
        <div>
            <h2 id={`${id}_questionSetHeader`} className="wizardTitle">
                {translator(title)}
            </h2>
            <ViewModelForm
                uiProps={qsMetadata}
                model={qsViewModel}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
            />
        </div>
    );
}

/**
 * @memberof gw-components-platform-react.QuestionSetComponent
 * @property {Object} propTypes.value - value of the property being rendered
 * @property {string} propTypes.path - the path where this component is attached
 * @property {function(newAnswers, pathToAnswers)} propTypes.onValueChange - the
 *                                      function invoked when the value is changed
 */
QuestionSetComponent.propTypes = {
    value: PropTypes.shape({
        code: PropTypes.string
    }).isRequired,
    path: PropTypes.string.isRequired,
    onValueChange: PropTypes.func,
    onValidate: PropTypes.func,
    labelPosition: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    })
};
QuestionSetComponent.defaultProps = {
    onValueChange: undefined,
    onValidate: undefined,
    labelPosition: 'left',
    id: undefined,
    title: messages.pageTitle
};
export default QuestionSetComponent;
