import _ from 'lodash';
// eslint-disable-next-line object-curly-newline
import React, { useCallback, useEffect, useContext, useMemo } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';

import FNOLAddress from '../Address/Address';
import metadata from './AdditionalParty.metadata.json5';
import styles from './AdditionalParty.module.scss';
import { isAutoLOB, isMotorLOB, isPropertyLOB, isBikeLOB } from '../../utils/PolicyTypeUtil';
// eslint-disable-next-line no-unused-vars
import messages from './AdditionalParty.messages';
import Contact from '../Contact/Contact';
import AllowedInvolvements from './AllowedInvolvements.json';
import ContactTypeRestricted from './ContactTypeRestricted.json';

function AdditionalParty(props) {
    const {
        id,
        onValueChange,
        path,
        onValidate,
        visible,
        data,
        licensePlateVisible,
        onUpdatePartyValidChange,
        lobCode,
        isPropertyLegalAssistance,
        isPropertyCivilLiability,
        onRemovePartyAction
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        disregardFieldValidation,
    } = useValidation(id);

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);

    useEffect(() => {
        if (!visible) {
            onValidate(true, id);
            return;
        }

        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate, visible]);

    useEffect(() => {
        onUpdatePartyValidChange(!isComponentValid, id);
    }, [id, isComponentValid, onUpdatePartyValidChange]);

    const getContactTypeRestricted = useCallback((role, involvement) => {
        const restr = ContactTypeRestricted.restrictions;
        const roleRestriction = restr.find((r) => { return (role !== undefined && r.role === role); });
        const invRestriction = restr.find((r) => { return (involvement !== undefined && r.inv === involvement); });
        if (roleRestriction) {
            return roleRestriction.lockTo;
        }
        if (invRestriction) {
            return invRestriction.lockTo;
        }
        return undefined; // means no restriction
    }, []);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;
        onValueChange(value, fullPath);

        if (!data.value.haveChanged) {
            _.set(data, 'value.haveChanged', true);
        }
    }, [data, onValueChange, path]);

    // this is place to swap person <=> company depending on role and involvement
    const adjustPersonType = useCallback((restriction) => {
        const contactType = _.get(data, 'value.contact.contactType');
        if (restriction && restriction !== contactType) {
            handleValueChange(restriction, 'value.contact.contactType');
        }
    }, [data, handleValueChange]);

    const getTypelistValues = useCallback((field, filterName) => {
        const typeList = viewModelService.create(
            {},
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.AdditionalPartyDTO'
        );

        let result;
        if (!filterName) {
            result = typeList[field].aspects.availableValues[0].typelist.codes;
        } else {
            result = typeList[field].aspects.availableValues[0].typelist.filters
                .filter((f) => f.name === filterName)[0].codes;
        }
        return result.map((t) => {
            return {
                code: t.code,
                name: translator({
                    id: t.name,
                    defaultMessage: t.name
                })
            };
        });
    }, [translator, viewModelService]);

    const getClaimType = useCallback(() => {
        if (isAutoLOB(lobCode) || isMotorLOB(lobCode)) {
            return 'vehicle';
        }

        if (isPropertyLOB(lobCode)) {
            if (isPropertyLegalAssistance) {
                return 'property_legal';
            }

            return 'property';
        }

        if (isBikeLOB(lobCode)) {
            return 'ebike';
        }

        return undefined;
    }, [isPropertyLegalAssistance, lobCode]);

    const getFilteredInvolvementsForRole = useCallback((role, lobFilter) => {
        if (!role) {
            return getTypelistValues('involvementType', lobFilter);
        }
        const allInvolvements = getTypelistValues('involvementType', lobFilter);
        const inv = AllowedInvolvements.involvementForRole.find(
            (r) => { return r.role === role && r.claimType.includes(getClaimType()); }
        ).involvements;

        const filteredList = allInvolvements.filter(
            (invAll) => {
                return inv.findIndex((allowed) => {
                    return allowed === invAll.code;
                }) !== -1;
            }
        );
        return filteredList;
    }, [getClaimType, getTypelistValues]);

    const getWasInjuredValues = useMemo(() => {
        const values = [];
        values.push({ code: 'true', name: translator(messages.wasInjuredInputValueYes) });
        values.push({ code: 'false', name: translator(messages.wasInjuredInputValueNo) });
        return values;
    }, [translator]);

    const contactRole = _.get(data, 'value.contactRole');
    const contactInvolvement = _.get(data, 'value.involvementType');
    const contactType = _.get(data, 'value.contact.contactType');
    const canIdentifyParty = _.get(data, 'value.canIdentifyParty');

    let lobFilter = '';
    if (isAutoLOB(lobCode) || isMotorLOB(lobCode)) { lobFilter = 'vehicle_filter'; }
    if (isPropertyLOB(lobCode)) {
        if (isPropertyLegalAssistance) {
            lobFilter = 'legal_property_filter';
        } else {
            lobFilter = 'property_filter';
        }
    }
    if (isBikeLOB(lobCode)) { lobFilter = 'ebike_filter'; }

    const getFilteredInvolvementsForRoleMemo = useMemo(
        () => { return getFilteredInvolvementsForRole(contactRole, lobFilter); },
        [contactRole, lobFilter, getFilteredInvolvementsForRole]
    );

    const contactTypeRestrictedMemo = useMemo(() => {
        return getContactTypeRestricted(contactRole, contactInvolvement);
    }, [contactInvolvement, contactRole, getContactTypeRestricted]);

    adjustPersonType(contactTypeRestrictedMemo);

    const contactVisible = !(contactRole === 'police_dept' && contactInvolvement === 'other') && !!contactInvolvement;
    const addressVisible = !(contactRole === 'police_dept' && contactInvolvement === 'other') && !!contactInvolvement;

    useEffect(() => {
        if (!canIdentifyParty) {
            disregardFieldValidation(['contactComponent', 'addressComponent']);
        }
    }, [canIdentifyParty, contactVisible, disregardFieldValidation, id, isComponentValid]);

    useEffect(() => {
        if (!contactVisible) {
            disregardFieldValidation('contactComponent');
        }
    }, [contactVisible, disregardFieldValidation, id, isComponentValid]);

    useEffect(() => {
        if (!addressVisible) {
            disregardFieldValidation('addressComponent');
        }
    }, [addressVisible, disregardFieldValidation, isComponentValid]);

    const wasInjuredVisible = useCallback(() => {
        return (contactType === 'person') && !!contactInvolvement;
    }, [contactInvolvement, contactType]);

    const includeWasInjuredIntoCondition = useCallback((condition) => {
        if (wasInjuredVisible()) {
            return data.value.wasInjured !== undefined && condition;
        }
        return condition;
    }, [data, wasInjuredVisible]);

    const wrapDeleteButtonOnClick = useCallback(() => {
        onValidate(true, id);
        onRemovePartyAction(data);
    }, [data, id, onRemovePartyAction, onValidate]);

    const overrideProps = {
        canIdentify: {
            availableValues: getTypelistValues('canIdentifyParty', isPropertyLOB(lobCode) ? 'allWithoutEAS' : null),
            value: _.get(data.value, 'canIdentifyParty'),
        },
        contactRoleInput: {
            availableValues: getTypelistValues('contactRole')
        },
        deleteButton: {
            onClick: () => wrapDeleteButtonOnClick()
        },
        contactInvolvementInput: {
            availableValues: getFilteredInvolvementsForRoleMemo,
            visible: !!contactRole
        },
        contactComponent: {
            isContactTypeVisible: (contactTypeRestrictedMemo === undefined),
            visible: includeWasInjuredIntoCondition(contactVisible)
        },
        addressComponent: {
            visible: includeWasInjuredIntoCondition(addressVisible)
        },
        reportNumberInput: {
            visible: includeWasInjuredIntoCondition((contactRole === 'police_dept' && contactInvolvement === 'other') && !!contactInvolvement)
        },
        licensePlateInput: {
            visible: includeWasInjuredIntoCondition(licensePlateVisible && !!contactInvolvement)
        },
        insurerNameInput: {
            visible: includeWasInjuredIntoCondition((contactRole === 'victim' || contactRole === 'caused_damage') && !!contactInvolvement)
        },
        policyNumberInput: {
            visible: includeWasInjuredIntoCondition((contactRole === 'victim' || contactRole === 'caused_damage') && !!contactInvolvement)
        },
        wasInjuredInput: {
            availableValues: getWasInjuredValues,
            visible: wasInjuredVisible()
        },
        partyInfoDiv: {
            visible: canIdentifyParty === 'yes'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            contact: Contact,
            address: FNOLAddress
        },
        resolveCallbackMap: {
            onValueChange: onValueChange,
            onValidate: setComponentValidation,
            handleValueChange: handleValueChange
        }
    };

    if (visible === false) {
        return (<div />);
    }

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={data}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={setComponentValidation}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default AdditionalParty;
