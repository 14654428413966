import nnCustomMessages from '../nnCustomMessages';

const LOBS = {
    property: ['HomeownersLine_HOE'],
    auto: ['PersonalAutoLine'],
    motor: ['inlb_MotorbikeLine'],
    bike: ['inlb_BikeLine']
};

const LOBS_TO_DISPLAY_NAMES = {
    PersonalAutoLine: {
        label: nnCustomMessages.lobsDescriptionPersonalAutoLine,
        imagePath: '/NN-lob_car.jpg'
    },
    HomeownersLine_HOE: {
        label: nnCustomMessages.lobsDescriptionHomeownersLineHOE,
        imagePath: '/NN-lob_home.jpg'
    },
    inlb_MotorbikeLine: {
        label: nnCustomMessages.lobsDescriptionMotorLine,
        imagePath: '/NN-lob-motor.jpg'
    },
    inlb_BikeLine: {
        label: nnCustomMessages.lobsDescriptionBikeLine,
        imagePath: '/NN-lob_bike.jpg'
    }
};

export function isAutoLOB(lobCode) {
    return LOBS.auto.indexOf(lobCode.code) !== -1;
}

export function isPropertyLOB(lobCode) {
    return LOBS.property.indexOf(lobCode.code) !== -1;
}

export function isMotorLOB(lobCode) {
    return LOBS.motor.indexOf(lobCode.code) !== -1;
}

export function isBikeLOB(lobCode) {
    return LOBS.bike.indexOf(lobCode.code) !== -1;
}

export function getLOBData(lobCode) {
    return LOBS_TO_DISPLAY_NAMES[lobCode];
}

export function getLobByCode(lobCode) {
    if (LOBS.auto.indexOf(lobCode) !== -1) return 'auto';

    if (LOBS.motor.indexOf(lobCode) !== -1) return 'motor';

    if (LOBS.bike.indexOf(lobCode) !== -1) return 'bike';

    return 'property';
}
