import _ from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const appendMetadataWithIndex = (metadata, index) => {
    const defaultChanges = {
        ...metadata,
        id: `${metadata.id}${index}`
    };

    if (_.isArray(metadata.content)) {
        return {
            ...defaultChanges,
            content: metadata.content.map((content) => appendMetadataWithIndex(content, index))
        };
    }

    return defaultChanges;
};
