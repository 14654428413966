import { Component } from 'react';
import PropTypes from 'prop-types';
import { renderContentFromMetadata, validateContentFromMetadata } from '@jutro/uiconfig';
import { withValidation, validationPropTypes } from 'gw-portals-validation-react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import styles from './ClauseComponent.module.scss';
import messages from './ClauseComponentVM.messages';

/**
 * Generate field metadata for terms
 * @param {object} term the clause terms
 * @param {string} termPathPrefix prefix for the term's path
 * @param {object} translator to translate the strings/objects
 * @returns {Object} the field metadata
 */
function getMetadataForField(term, termPathPrefix, translator) {
    const hasAvailableValues = _.has(term, 'options') && !_.isEmpty(term.options);
    const valueType = _.has(term, 'valueType') && term.valueType;

    const availableValues = hasAvailableValues && term.options.filter((option) => {
        // Filtering out the None Selected value as the backend sets the code value
        // for None Selected to be an empty string. This results in the option not
        // showing in the dropdown select component. We are handling this by using
        // None Selected as a placeholder which will always be shown to the user
        return option.name !== translator(messages.noneSelected);
    }).map((option) => {
        return {
            code: '',
            id: '',
            ...option
        };
    });

    if (hasAvailableValues && valueType === 'bit') {
        // chosenTerm for bit terms with more than 2 choices?
        return {
            component: 'toggle',
            componentProps: {
                path: `${termPathPrefix}.directBooleanValue`,
                value: term.directBooleanValue
            }
        };
    }

    if (!hasAvailableValues && valueType === 'datetime') {
        return {
            component: 'date',
            componentProps: {
                showTime: true,
                dataType: 'string',
                placeholder: 'MM/DD/YYYY hh:mm AM',
                onValueChange: 'onChangeClause',
                onBlur: 'onSyncCoverages',
                value: term.directDateValue,
                path: `${termPathPrefix}.directDateValue`
            }
        };
    }

    if (valueType === 'shorttext') {
        return {
            component: 'textarea',
            componentProps: {
                onValueChange: 'onChangeClause',
                onBlur: 'onSyncCoverages',
                path: `${termPathPrefix}.directStringValue`,
                value: term.directStringValue
            }
        };
    }

    if (hasAvailableValues && !valueType) {
        const componentDetails = {
            component: 'dropdownselect',
            componentProps: {
                availableValues,
                path: `${termPathPrefix}.chosenTerm`,
                value: term.chosenTerm
            }
        };

        if (_.some(term.options, ['name', translator(messages.noneSelected)])) {
            _.set(componentDetails, 'componentProps.placeholder', translator(messages.noneSelected));
            _.set(componentDetails, 'componentProps.alwaysShowPlaceholder', true);
        }
        return componentDetails;
    }
    const getMessageByMinMax = () => {
        let key = 'maxValue';
        if (term.directValueMax && term.directValueMin) {
            key = 'selectedValue';
        } else if (term.directValueMin) {
            key = 'minValue';
        }
        return messages[key];
    };
    return {
        component: 'inputnumber',
        componentProps: {
            onValueChange: 'onChangeClause',
            onBlur: 'onSyncCoverages',
            value: term.directValue,
            maxValue: term.directValueMax,
            minValue: term.directValueMin,
            path: `${termPathPrefix}.directValue`,
            messageProps: {
                validationMaxValue: translator(getMessageByMinMax(), {
                    maxValue: term.directValueMax,
                    minValue: term.directValueMin
                }),
                validationMinValue: translator(getMessageByMinMax(), {
                    maxValue: term.directValueMax,
                    minValue: term.directValueMin
                }),
            }
        }
    };
}

function isClauseLoading(clause, loadingClause) {
    if (_.isString(loadingClause)) {
        return loadingClause === clause.publicID;
    }
    if (_.isBoolean(loadingClause)) {
        return loadingClause;
    }
    return false;
}

function getPath(changedValuePath) {
    // onBlur event returns an object instead of path as a String
    const pathToNormalise = _.isObject(changedValuePath)
        ? changedValuePath.model : changedValuePath;

    return pathToNormalise;
}

function getRelativePath(changedValuePath) {
    const [relativePath] = changedValuePath.match(/(selected|terms\.children\[\d+\](\.\w+)+)/);

    return relativePath.replace(/\.children/g, '');
}

class SingleClauseComponentVM extends Component {
    /**
     * @memberof gw-components-platform-react.SingleClauseComponentVM
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.path - path to clause in the view modal
     * @prop {object} propTypes.value - the clause
     * @prop {function} propTypes.onChangeSubmissionAndSync - callback when change is made
     * @prop {function} propTypes.onChangeClause - callback when change is made
     * @prop {function} propTypes.onSyncCoverages - callback to check if clause should call backend
     * @prop {string} propTypes.loadingClause - the clause publicID that is loading
     * @prop {string} propTypes.loadingClauseMessage - loading message to be shown while loading
     * @prop {string} propTypes.isEditable - if the clauses should not be editable
     * @prop {string} propTypes.labelTop - if the clause term label should be on the top
     * @prop {string} propTypes.containerClassName - clause container class
     * @prop {string} propTypes.idPrefex - string to prefix all the ID's
     * @prop {string} propTypes.description - clause description
     * @prop {string} propTypes.onValidate - returns if the values are valid
     * @prop {bool} propTypes.showAmount - determine to show amount next to clause name
     */

    static propTypes = {
        path: PropTypes.string.isRequired,
        value: PropTypes.shape({}).isRequired,
        onChangeSubmissionAndSync: PropTypes.func,
        onChangeClause: PropTypes.func,
        onSyncCoverages: PropTypes.func,
        onScheduleChange: PropTypes.func,
        onValidate: PropTypes.func,
        loadingClause: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        loadingClauseMessage: PropTypes.string,
        isEditable: PropTypes.bool,
        labelTop: PropTypes.bool,
        containerClassName: PropTypes.string,
        idPrefex: PropTypes.string,
        labelPosition: PropTypes.string,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        showAmount: PropTypes.bool,
        ...validationPropTypes
    };

    static defaultProps = {
        onChangeSubmissionAndSync: undefined,
        onChangeClause: undefined,
        onSyncCoverages: undefined,
        onScheduleChange: undefined,
        loadingClause: undefined,
        loadingClauseMessage: '',
        onValidate: undefined,
        isEditable: true,
        labelTop: false,
        containerClassName: undefined,
        idPrefex: '',
        labelPosition: 'left',
        description: undefined,
        showAmount: true
    };

    static contextType = TranslatorContext;

    componentDidMount() {
        const {
            onValidate,
            value: clause,
            isComponentValid,
            registerComponentValidation,
        } = this.props;

        if (onValidate) {
            registerComponentValidation(this.isMetadataValid);
            onValidate(isComponentValid, clause.publicID);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            onValidate,
            value: newClause,
            isComponentValid,
            hasValidationChanged
        } = this.props;
        const { value: oldClause } = prevProps;

        if ((!_.isEqual(newClause, oldClause) || hasValidationChanged) && onValidate) {
            onValidate(isComponentValid, newClause.publicID);
        }
    }

    /**
     * Generate clauses schedule metadata from clause
     * @param {object} clauseSchedule the clause schedule
     * @param {string} clausePublicID the clause publicID
     * @returns {object | null} the metadata to render
     */
    getScheduleData(clauseSchedule, clausePublicID) {
        const {
            path,
            idPrefex,
            labelPosition
        } = this.props;
        const schedulePath = `${path}.schedule`;

        if (_.isEmpty(clauseSchedule)) {
            return null;
        }

        return [{
            id: `${idPrefex}ClauseSchedule_[${clausePublicID}]`,
            type: 'field',
            component: 'ScheduleItemsComponent',
            componentProps: {
                path: schedulePath,
                onScheduleChange: 'onScheduleChange',
                value: clauseSchedule,
                labelPosition,
                showTitle: false
            }
        }];
    }

    /**
     * Generate clauses term metadata from clause
     * @param {object} clauseTerms the clause terms
     * @param {string} clausePublicID the clause publicID
     * @returns {object | null} the metadata to render
     */
    getTermMetaData(clauseTerms, clausePublicID) {
        const {
            isEditable,
            path,
            labelTop,
            idPrefex,
            labelPosition
        } = this.props;

        const translator = this.context;
        return clauseTerms.map((term, index) => {
            const termPathPrefix = `${path}.terms.children[${index}]`;
            let clauseTermClass = 'clause_term';
            clauseTermClass += !isEditable ? ' clauseTermReadonly' : '';
            const termComponent = {
                id: `${idPrefex}ClauseTerm_[${clausePublicID}]_[${index}]`,
                type: 'field',
                componentProps: {
                    label: term.name,
                    path: `${termPathPrefix}.chosenTerm`,
                    readOnly: !isEditable,
                    value: term.chosenTerm,
                    labelPosition: labelPosition,
                    layout: labelTop ? 'full-width' : null,
                    onValueChange: 'onChangeAndSyncClause',
                    required: isEditable && term.required,
                    className: clauseTermClass
                }
            };

            return _.merge(
                {}, termComponent, getMetadataForField(term, termPathPrefix, translator)
            );
        });
    }

    /**
     * Generate clauses sub element metadata from clause
     * @param {object} clause the clause to get data from
     * @returns {object | null} the metadata to render
     */
    getClauseData(clause) {
        const { idPrefex } = this.props;
        const terms = !_.isEmpty(clause.terms)
            ? this.getTermMetaData(clause.terms, clause.publicID) : null;
        const schedules = _.has(clause, 'schedule') ? this.getScheduleData(clause.schedule, clause.publicID) : null;
        const clauseSubElements = _.merge([], terms, schedules);

        return !_.isEmpty(clauseSubElements) ? [{
            id: `${idPrefex}ClauseElementContainer_[${clause.publicID}]`,
            type: 'container',
            component: 'div',
            componentProps: { className: 'clause_element_container' },
            contentLayout: { component: 'grid' },
            content: clauseSubElements
        }] : null;
    }

    isMetadataValid = () => {
        const metadata = this.generateMetadata();
        return validateContentFromMetadata(metadata);
    }

    /**
     * Generate metadata from value provided from props
     * @returns {object} the metadata to render
     */
    generateMetadata = () => {
        const {
            value: clause = {},
            path,
            loadingClause,
            loadingClauseMessage,
            isEditable,
            containerClassName,
            description,
            idPrefex,
            showAmount
        } = this.props;

        const isTooltipVisible = (
            description || (clause.description !== clause.name && !_.isEmpty(clause.description))
        );

        return {
            content: [{
                id: `${idPrefex}Clause_[${clause.publicID}]`,
                type: 'field',
                component: 'ClauseComponent',
                componentProps: {
                    id: `${idPrefex}_${clause.publicID}`,
                    displayName: isEditable ? clause.name : description || clause.description,
                    showAmount,
                    amount: clause.amount,
                    path: `${path}.selected`,
                    readOnly: clause.required,
                    value: clause.selected,
                    checked: clause.selected,
                    onValueChange: 'onChangeAndSyncClause',
                    isEditable: isEditable,
                    isLoading: isClauseLoading(clause, loadingClause),
                    loadingMessage: loadingClauseMessage,
                    containerClassName: containerClassName,
                    description: isTooltipVisible ? description || clause.description : undefined
                },
                content: clause.selected ? this.getClauseData(clause) : null
            }]
        };
    };

    /**
     * Changes value and calls backend if needed
     * @param {object} value the new value
     * @param {string} changedValuePath the path to change
     * @returns {Promise}
     */
    handleScheduleChange = (value, changedValuePath) => {
        const { onScheduleChange } = this.props;

        if (onScheduleChange) {
            return Promise.resolve(onScheduleChange(value, changedValuePath));
        }
        return Promise.resolve();
    }

    /**
     * Changes value and calls backend if needed
     * @param {object} value the new value
     * @param {string} changedValuePath the path to change
     * @returns {Promise}
     */
    handleChangeAndSyncClause = (value, changedValuePath) => {
        const { onChangeSubmissionAndSync, value: clause } = this.props;
        if (_.get(clause, getRelativePath(changedValuePath)) !== value) {
            if (onChangeSubmissionAndSync) {
                return Promise.resolve(onChangeSubmissionAndSync(value, changedValuePath));
            }
        }
        return Promise.resolve();
    };

    /**
     * Changes value
     * @param {object} value the new value
     * @param {string} changedValuePath the path to change
     * @returns {Promise}
     */
    handleChangeClause = (value, changedValuePath) => {
        const { onChangeClause, value: clause } = this.props;
        if (_.get(clause, getRelativePath(changedValuePath)) !== value) {
            if (onChangeClause) {
                return Promise.resolve(onChangeClause(value, changedValuePath));
            }
        }
        return Promise.resolve();
    };

    /**
     * Calls backend if needed
     * @param {object} evt event
     * @param {string} changedValues the path to change
     * @returns {Promise}
     */
    handleSyncCoverages = (evt, changedValues) => {
        const { onSyncCoverages } = this.props;
        const { beforeValue, value: newValue, model } = changedValues;
        const actualChangedPath = getPath(model);

        if (beforeValue !== newValue) {
            if (onSyncCoverages) {
                return Promise.resolve(onSyncCoverages(newValue, actualChangedPath));
            }
        }
        return Promise.resolve();
    };

    render() {
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onValueChange: this.handleChangeAndSyncClause,
                onChangeAndSyncClause: this.handleChangeAndSyncClause,
                onChangeClause: this.handleChangeClause,
                onSyncCoverages: this.handleSyncCoverages,
                onScheduleChange: this.handleScheduleChange
            },
        };

        const overrides = {
            '@field': {
                showOptional: true
            }
        };

        return renderContentFromMetadata(this.generateMetadata(), overrides, resolvers);
    }
}

export const SingleClauseVM = SingleClauseComponentVM;
export default withValidation(SingleClauseComponentVM);
