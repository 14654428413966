import { useContext, useCallback } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';

// eslint-disable-next-line max-len
function useTypelistHandler() {
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);

    const getValuesFromTypelistIncludeFilter = useCallback((dto, field, filterName) => {
        const typeList = viewModelService.create(
            {},
            'cc',
            dto
        );

        let result;
        if (!filterName) {
            result = typeList[field].aspects.availableValues[0].typelist.codes;
        } else {
            result = typeList[field].aspects.availableValues[0].typelist.filters
                .filter((f) => f.name === filterName)[0].codes;
        }
        return result.map((t) => {
            return {
                code: t.code,
                name: translator({
                    id: t.name,
                    defaultMessage: t.name
                })
            };
        });
    }, [translator, viewModelService]);

    return { getValuesFromTypelistIncludeFilter };
}

export default useTypelistHandler;
