import _ from 'lodash';
import { getTypecodeTranslation } from '../../../utils/TypeCodeTranslationUtil';
import {
    buildVehicleDescription,
    buildDriverDescription,
    buildRepairDetailsDamage
} from '../VehicleSummarySnapshotCommonFunctions';
import messages from './WizardFNOLSummaryAuto.messages';

export function buildVehicleDamage(wizardDTO, translator) {
    if (!wizardDTO.value.vehicleDamage) {
        return '';
    }

    let damageString = '';
    wizardDTO.vehicleDamage.vehicleDamagePoints
        .value.forEach((damagePoint) => {
            damageString += `${getTypecodeTranslation(translator, damagePoint.damagePoint, 'inlb_VehicleDamagePoints')}\n`;
        });

    const vehicleDamage = wizardDTO.vehicleDamage.value;
    if (vehicleDamage.dmgElectronica) damageString += `${translator(messages.wizardFnolSummaryPageAboutVehicleElectronics)}\n`;
    if (vehicleDamage.dmgSpoilerAntena) damageString += `${translator(messages.wizardFnolSummaryPageAboutVehicleSpoiler)}\n`;
    if (vehicleDamage.dmgRimsWheelCover) damageString += `${translator(messages.wizardFnolSummaryPageAboutVehicleRims)}\n`;
    if (vehicleDamage.dmgOtherDamages) damageString += `${translator(messages.wizardFnolSummaryPageAboutVehicleOtherDamage)} - ${vehicleDamage.otherDamagesText}\n`;
    if (vehicleDamage.dmgGlassBreak) damageString += `${translator(messages.wizardFnolSummaryPageAboutVehicleOtherDamageGlassBreak)}\n`;
    if (vehicleDamage.dmgTires) damageString += `${translator(messages.wizardFnolSummaryPageAboutVehicleOtherDamageTires)}\n`;
    if (vehicleDamage.dmgBottom) damageString += `${translator(messages.wizardFnolSummaryPageAboutVehicleOtherDamageBottom)}\n`;
    if (vehicleDamage.dmgNoVisibleDamage) damageString += `${translator(messages.wizardFnolSummaryPageAboutVehicleOtherDamageNoVisibleDamage)}\n`;

    return damageString;
}

export function buildOtherVehicleDamage(wizardDTO, translator) {
    if (!wizardDTO.value.otherDamages) {
        return '';
    }

    let damageString = '';
    const otherDamagesDTO = wizardDTO.otherDamages.value;
    if (otherDamagesDTO.dmgTrailerDamageLight) damageString += `${translator(messages.wizardFnolSummaryOtherDamagesTrailerLight)}\n`;
    if (otherDamagesDTO.dmgTrailerDamageHeavy) {
        const trailerDamage = wizardDTO.trailerDamage.value;
        damageString += `${translator(messages.wizardFnolSummaryOtherDamagesTrailerHeavy)}\n`;
        damageString += trailerDamage.trailer.vin
            ? `${translator(messages.wizardFnolSummaryTrailerDamageTrailerDetails)} - ${trailerDamage.trailer.licensePlate}, ${trailerDamage.trailer.vin}`
            : `${translator(messages.wizardFnolSummaryTrailerDamageTrailerDetails)} - ${trailerDamage.trailer.licensePlate}`;
        damageString += `\n${translator(messages.wizardFnolSummaryTrailerDamageDamageLabel)}:`;
        if (trailerDamage.dmgTires) damageString += ` ${translator(messages.wizardFnolSummaryTrailerDamageTires)},`;
        if (trailerDamage.dmgBottom) damageString += ` ${translator(messages.wizardFnolSummaryTrailerDamageBottom)},`;
        if (trailerDamage.dmgOtherDamages) {
            damageString += ` ${translator(messages.wizardFnolSummaryTrailerDamageOther)} - ${trailerDamage.otherDamagesText},`;
        }
        damageString = damageString.slice(0, -1);
        damageString += '\n';
    }
    if (otherDamagesDTO.dmgBaggageOrBikeTrailer) damageString += `${translator(messages.wizardFnolSummaryOtherDamagesBikeTrailer)}\n`;
    if (otherDamagesDTO.dmgBicycle) damageString += `${translator(messages.wizardFnolSummaryOtherDamagesBicycleDamage)}\n`;
    if (otherDamagesDTO.dmgElectronicsInclComputers) damageString += `${translator(messages.wizardFnolSummaryOtherDamagesElectronics)}\n`;
    if (otherDamagesDTO.dmgLuggageClothingEquipment) damageString += `${translator(messages.wizardFnolSummaryOtherDamagesClothingEquipment)}\n`;
    if (otherDamagesDTO.dmgOther) damageString += `${translator(messages.wizardFnolSummaryOtherDamagesOtherDamage)}\n`;
    damageString = damageString.slice(0, -1);

    return damageString;
}

export function notEmptyVehicleDamages(wizardDTO) {
    if (!wizardDTO.value.vehicleDamage) {
        return false;
    }

    const { vehicleDamage } = wizardDTO.value;
    const emptyVehiclePoints = !_.isEmpty(vehicleDamage.vehicleDamagePoints);
    const emptyOtherDamagePoints = !!vehicleDamage.dmgElectronica
        || !!vehicleDamage.dmgSpoilerAntena
        || !!vehicleDamage.dmgRimsWheelCover
        || !!vehicleDamage.dmgOtherDamages
        || !!vehicleDamage.dmgGlassBreak
        || !!vehicleDamage.dmgTires
        || !!vehicleDamage.dmgBottom
        || !!vehicleDamage.dmgNoVisibleDamage;
    return emptyVehiclePoints || emptyOtherDamagePoints;
}

export function generateAutoPartSnapshot(translator, wizardDTO) {
    const autoDataSnapshot = [];

    if (!_.isEmpty(wizardDTO.value.vehicleDamage)) {
        autoDataSnapshot.push({
            label: translator(messages.wizardFnolSummaryPageAboutVehicle),
            value: buildVehicleDescription(wizardDTO)
        });
    }

    if (!_.isEmpty(wizardDTO.value.vehicleDamage)) {
        autoDataSnapshot.push({
            label: translator(messages.wizardFnolSummaryPageWhoWasDriver),
            value: buildDriverDescription(wizardDTO, translator)
        });
    }

    if (notEmptyVehicleDamages(wizardDTO)) {
        autoDataSnapshot.push({
            label: translator(messages.fnolSummaryVehicleDamage),
            value: buildVehicleDamage(wizardDTO, translator)
        });
    }

    if (!_.isEmpty(buildOtherVehicleDamage(wizardDTO, translator))) {
        autoDataSnapshot.push({
            label: translator(messages.wizardFnolSummaryPageOtherVehicleDamages),
            value: buildOtherVehicleDamage(wizardDTO, translator)
        });
    }

    if (!_.isEmpty(wizardDTO.value.repairDetails)) {
        autoDataSnapshot.push({
            label: translator(messages.wizardFnolSummaryPageRepairDetails),
            value: buildRepairDetailsDamage(wizardDTO, translator)
        });
    }

    return autoDataSnapshot;
}
