/* eslint-disable max-len */
import { useMemo } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withRouter } from 'react-router-dom';
import metadata from './INGHeader.metadata.json5';
import styles from './INGHeader.module.scss';
import INGHeaderLogo from './INGHeaderLogo';

function INGHeader() {
    const isTestEnvironment = useMemo(() => {
        return process.env.REACT_APP_IS_TEST_ENVIRONMENT === 'true';
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {},
        resolveComponentMap: {
            ingHeader: INGHeaderLogo
        }
    };

    const overrideProps = {
        headerGridContainer: {
            columns: isTestEnvironment
                ? ['auto', 'auto']
                : ['auto']
        },
        demoEnvironmentLabel: {
            visible: isTestEnvironment
        }
    };

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

export default withRouter(INGHeader);
