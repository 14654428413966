import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { getConfiguration } from '@jutro/config';
import PrivateRoute from './PrivateRoute';

function DynamicRoute({ children }) {
    const config = getConfiguration() || {};
    const cloudAuthEnabled = config.JUTRO_AUTH_ENABLED || false;

    if (cloudAuthEnabled) {
        return (
            <Route>{children}</Route>
        );
    }
    return (
        <PrivateRoute>{children}</PrivateRoute>
    );
}

DynamicRoute.propTypes = {
    children: PropTypes.node.isRequired
};

export default DynamicRoute;
