/* eslint-disable max-len */
import React, { useCallback, useContext, useEffect } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { CheckboxField } from '@jutro/components';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import useTypelistValues from '../../hooks/useTypelistHandler';
import { getLossCauseLegalAssistance } from '../../utils/LossCausesUtil';
import { getTypecodeTranslation } from '../../utils/TypeCodeTranslationUtil';
import metadata from './BasicInformationPropertyLegal.metadata.json5';
import styles from './BasicInformationPropertyLegal.module.scss';

function BasicInformationPropertyLegal(props) {
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { getValuesFromTypelistIncludeFilter } = useTypelistValues();
    const {
        id,
        onValueChange,
        writeValue,
        value: fnolVM,
        onValidate,
        coverages
    } = props;

    if (!fnolVM.value.legalAssistanceDamage) {
        fnolVM.value.legalAssistanceDamage = {};
    }

    const {
        onValidate: onComponentValidate,
        isComponentValid
    } = useValidation('BasicInformationPropertyCivil');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const getTypelistValues = useCallback((field, filterName) => {
        return getValuesFromTypelistIncludeFilter(
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.LegalAssistanceDamageDTO',
            field,
            filterName,
        );
    }, [getValuesFromTypelistIncludeFilter]);

    const onDamageCauseChange = useCallback((newValue) => {
        writeValue(newValue, 'legalAssistanceDamage.damageReason');
        fnolVM.value.frontEndLossCause = getLossCauseLegalAssistance(newValue, coverages);
    }, [coverages, fnolVM.value, writeValue]);

    const handleValueChange = useCallback((value, changedPath) => {
        onValueChange(value, changedPath);
    }, [onValueChange]);

    const overrideProps = {
        damageToMyProperty: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'DmgProperty',
                name: getTypecodeTranslation(translator, 'DmgProperty', 'inlb_FrontendLAReason')
            }]
        },
        damageToMeOrFamily: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'DmgToMeOrFamily',
                name: getTypecodeTranslation(translator, 'DmgToMeOrFamily', 'inlb_FrontendLAReason')
            }]
        },
        damageToMyPet: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'DmgMyPet',
                name: getTypecodeTranslation(translator, 'DmgMyPet', 'inlb_FrontendLAReason')
            }]
        },
        contractualDispute: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'ContractualDispute',
                name: getTypecodeTranslation(translator, 'ContractualDispute', 'inlb_FrontendLAReason')
            }]
        },
        troubleNeighbour: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'TroubleNeighbour',
                name: getTypecodeTranslation(translator, 'TroubleNeighbour', 'inlb_FrontendLAReason')
            }]
        },
        criminalDefense: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'CriminalDefense',
                name: getTypecodeTranslation(translator, 'CriminalDefense', 'inlb_FrontendLAReason')
            }]
        },
        iWasSummoned: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'WasSummoned',
                name: getTypecodeTranslation(translator, 'WasSummoned', 'inlb_FrontendLAReason')
            }]
        },
        other: {
            onValueChange: onDamageCauseChange,
            availableValues: [{
                code: 'Other',
                name: getTypecodeTranslation(translator, 'Other', 'inlb_FrontendLAReason')
            }]
        },
        myPetContainer: {
            visible: (fnolVM.value.legalAssistanceDamage.damageReason === 'DmgMyPet')
        },
        otherContainer: {
            visible: (fnolVM.value.legalAssistanceDamage.damageReason === 'Other')
        },
        meOrMemberWhoInput: {
            availableValues: getTypelistValues('who', 'PropertyLegalAssistanceFilter')
        },
        meOrMemberAgeInput: {
            availableValues: getTypelistValues('age')
        },
        damageToMyPropertyContainer: {
            visible: (fnolVM.value.legalAssistanceDamage.damageReason === 'DmgProperty')
        },
        propertyOtherSpecify: {
            visible: (!!fnolVM.value.legalAssistanceDamage.other)
        },
        meOrFamilyDetailsContainer: {
            visible: (fnolVM.value.legalAssistanceDamage.damageReason === 'DmgToMeOrFamily')
        },
        meOrFamilyMaterialSpecifyContainer: {
            visible: (!!fnolVM.value.legalAssistanceDamage.material)
        },
        meOrFamilyPhysicalSpecifyContainer: {
            visible: (!!fnolVM.value.legalAssistanceDamage.physical)
        },
        otherSpecifyContainer: {
            visible: (fnolVM.value.legalAssistanceDamage.damageReason === 'Other')
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            checkboxField: CheckboxField
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={fnolVM.value}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onComponentValidate}
            />
        </div>
    );
}

export default BasicInformationPropertyLegal;
