/* eslint-disable max-len */
import nnCustomMessages from '../nnCustomMessages';

const REDIRECTION_CONTEXT_TO_MESSAGES = [
    {
        context: 'WasVehicleStolen_Yes',
        headerMessage: nnCustomMessages.vehicleStolenContextHeader,
        bodyMessage: nnCustomMessages.vehicleStolenContextBody,
        showTryAgaingButton: false,
        showPhoneCallButton: true
    },
    {
        context: 'IsVehicleRoadworthy_No',
        headerMessage: nnCustomMessages.vehicleNotRoadworthyContextHeader,
        bodyMessage: nnCustomMessages.vehicleNotRoadworthyContextBody,
        showTryAgaingButton: false,
        showPhoneCallButton: true
    },
    {
        context: 'MotorLegalAssistance',
        headerMessage: nnCustomMessages.motorLegalAssistanceContextHeader,
        bodyMessage: nnCustomMessages.motorLegalAssistanceContextBody,
        showTryAgaingButton: false,
        showPhoneCallButton: false
    },
    {
        context: 'BikePhysicalDamage',
        headerMessage: nnCustomMessages.bikePhysicalDamageContextHeader,
        bodyMessage: nnCustomMessages.bikePhysicalDamageContextBody,
        showTryAgaingButton: false,
        showPhoneCallButton: false
    },
    {
        context: 'LegalAssistanceProperty',
        headerMessage: nnCustomMessages.legalAssitancePropertyContextHeader,
        bodyMessage: nnCustomMessages.legalAssitancePropertyContextBody,
        showTryAgaingButton: false,
        showPhoneCallButton: false
    },
    {
        context: 'LegalAssitanceAuto',
        headerMessage: nnCustomMessages.legalAssitanceAutoContextHeader,
        bodyMessage: nnCustomMessages.legalAssitanceAutoContextBody,
        showTryAgaingButton: false,
        showPhoneCallButton: false
    },
];

const CONTEXT_TO_ACTION_DATA = [
    {
        context: 'PlaceOfGlassCrashRepair_CarGlass',
        actionData: {
            title: nnCustomMessages.placeOfGlassCrashRepairCarGlassContextTitle,
            message: nnCustomMessages.placeOfGlassCrashRepairCarGlassContextBody,
            status: 'warning',
            confirmButtonText: nnCustomMessages.placeOfGlassCrashRepairCarGlassContextConfirmButton,
            cancelButtonText: nnCustomMessages.placeOfGlassCrashRepairCarGlassContextReturnButton
        },
    }
];

export function getMessagesBasedOnContext(context) {
    const contextObject = REDIRECTION_CONTEXT_TO_MESSAGES.filter((contextDetails) => contextDetails.context === context);
    return contextObject || {};
}

export function getActionDataBasedOnContext(context) {
    const contextObject = CONTEXT_TO_ACTION_DATA.filter((contextDetails) => contextDetails.context === context);
    return contextObject || {};
}
