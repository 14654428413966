import React, { useCallback } from 'react';

import { WizardSingleErrorComponent } from 'gw-components-platform-react';
import WizardPageHeader from '../../components/WizardPageHeader/WizardPageHeader';
import { pageTemplateProps } from '../../prop-types/wizardPropTypes';
import DigitalWizardPageTemplate from '../DigitalWizardPageTemplate';

import styles from './DigitalWizardPageWithTitleTemplate.module.scss';

function DigitalWizardPageWithTitleTemplate(props) {
    const {
        children, acknowledgeError, errorsForStep, isSideHeader, ...otherProps
    } = props;

    const renderWizardHeader = useCallback(
        (wizardData) => {
            const getSubmissionVM = wizardData.submissionVM || wizardData;
            return (
                <WizardPageHeader wizardSubmission={getSubmissionVM} isSideHeader={isSideHeader} />
            );
        },
        [isSideHeader]
    );

    return (
        <div className={styles.wizardContainer}>
            <DigitalWizardPageTemplate
                renderContextComponent={renderWizardHeader}
                showContextComponent={false}
                showWizardHeader
                {...otherProps}
            >
                <WizardSingleErrorComponent
                    acknowledgeError={acknowledgeError}
                    issuesList={errorsForStep}
                />
                {children}
            </DigitalWizardPageTemplate>
        </div>
    );
}

DigitalWizardPageWithTitleTemplate.propTypes = pageTemplateProps;
export default DigitalWizardPageWithTitleTemplate;
