import React, { useCallback, useContext, useEffect } from 'react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { CheckboxField } from '@jutro/components';
import FNOLAddress from '../../Address/Address';
import PropertyDamageDetail from '../PropertyDamageDetail/PropertyDamageDetail';
import useBreakpointHandler from '../../../hooks/useBreakpointHandler';
import styles from './PropertyDamage.module.scss';
import metadata from './PropertyDamage.metadata.json5';


function PropertyDamage(props) {
    const {
        id,
        onValueChange,
        data: propertyDamageDTO,
        path,
        index,
        onValidate,
        showLossAndDamage
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const propertyDamageVM = viewModelService.create(
        {},
        'cc',
        'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.PropertyDamageDTO'
    );
    propertyDamageVM.value = propertyDamageDTO;

    const translator = useContext(TranslatorContext);

    const {
        onValidate: setComponentValidation,
        isComponentValid
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback((value, changedPath) => {
        propertyDamageDTO.haveChanged = true;
        onValueChange(value, changedPath);
    }, [onValueChange, propertyDamageDTO]);

    const translateTypeKey = useCallback((typeList, typeKey) => {
        return translator({
            id: `${typeList}.${typeKey}`,
            defaultMessage: typeKey
        });
    }, [translator]);

    const propertyDetailOnChange = useCallback((value, changedPath, changedIndex) => {
        const targetPath = `damageDetailsList[${changedIndex}].${changedPath}`;

        if (targetPath.includes('damageDetailsList')) {
            if (value === true && !propertyDamageDTO.isSelected) {
                propertyDamageDTO.isSelected = true;
            }
            if (propertyDamageDTO.damageDetailsList.every((dd) => { return !dd.isSelected; })) {
                propertyDamageDTO.isSelected = false;
            }
        }
        if (targetPath === 'isSelected' && value === false && propertyDamageDTO.damageDetailsList) {
            const newDamageDetails = propertyDamageDTO.damageDetailsList.map((dd) => (
                { ...dd, isSelected: false }));
            propertyDamageDTO.damageDetailsList = newDamageDetails;
        }
        propertyDamageDTO.haveChanged = true;
        onValueChange(value, targetPath);
    }, [onValueChange, propertyDamageDTO]);

    const generateOverridesForDetails = useCallback(() => {
        if (propertyDamageDTO.damageDetailsList) {
            const generatedDetailsOverride = propertyDamageDTO.damageDetailsList
                .map((details, indx) => {
                    const newOverride = {
                        [`propertyDamageDetail${indx}`]: {
                            // eslint-disable-next-line max-len
                            onValueChange: (value, changedPath) => propertyDetailOnChange(value, changedPath, indx),
                        }
                    };
                    return newOverride;
                });
            return Object.assign({}, ...generatedDetailsOverride);
        }
        return {};
    }, [propertyDamageDTO.damageDetailsList, propertyDetailOnChange]);

    const lossCauseText = translateTypeKey('typekey.inlb_FrontEndLossCause', propertyDamageDTO.lossCode);
    const damageText = translateTypeKey('typekey.inlb_FrontEndDamageType', propertyDamageDTO.damageCode);
    const headerText = `${lossCauseText}: ${damageText}`;

    const overrides = {
        ...generateOverridesForDetails(),
        lossAndDamageHeader: {
            visible: showLossAndDamage,
            content: {
                id: headerText,
                defaultMessage: headerText
            }
        },
        isSelectedPropertyCheckbox: {
            label: {
                id: 'propertyDamage.dmgPropertyLabel',
                defaultMessage: translateTypeKey('typekey.inlb_FrontEndDmgProperty', propertyDamageDTO.damagedProperty)
            }
        },
        damagedPropertyAddress: {
            visible: (propertyDamageDTO.isSelected && propertyDamageDTO.damagedProperty === 'parked_vehicle'),
            onWriteValue: handleValueChange,
            isCityMandatory: true
        },
        otherDamagesDescripion: {
            visible: (propertyDamageDTO.isSelected && propertyDamageDTO.damagedProperty === 'other')
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleValueChange,
            onValidate: setComponentValidation
        },
        resolveComponentMap: {
            checkboxField: CheckboxField,
            propertyDamageDetail: PropertyDamageDetail,
            address: FNOLAddress
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={propertyDamageVM}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={setComponentValidation}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default PropertyDamage;
