import React from 'react';
import {
    IconButton,
    Tooltip
} from '@jutro/components';
import styles from './CustomRadioButtonField.module.scss';

function CustomRadioButtonField(props) {
    const {
        tooltipMessage
    } = props;

    return (
        <Tooltip
            animation="fade"
            placement="bottom"
            content={tooltipMessage}
            trigger="click"
            hideOnClick
        >
            <span>
                <IconButton
                    className={styles.icon}
                    icon="mi-help-outline"
                />
            </span>
        </Tooltip>
    );
}

export default CustomRadioButtonField;
