/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useContext, useEffect, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { Link, ModalNextProvider } from '@jutro/components';
import FNOLAddress from '../../Address/Address';
import FNOLRepairEstimatesWrapper from '../estimatesComponents/FNOLRepairEstimatesWrapper/FNOLRepairEstimatesWrapper';
import { carGlassURL } from '../../../utils/Constants';
import PartneredVendors from '../PartneredVendors/PartneredVendors';
import RepairPreferredVendorPopup from '../RepairPreferredVendorPopup/RepairPreferredVendorPopup';
import NewVendorFragment from '../NewVendorFragment/NewVendorFragment';
import metadata from './WizardFNOLAutoRepair.metadata.json5';
import styles from './WizardFNOLAutoRepair.module.scss';
import messages from './WizardFNOLAutoRepair.messages';

function WizardFNOLAutoRepair(props) {
    const translator = useContext(TranslatorContext);

    const {
        id,
        history,
        value: repairDTO,
        onValidate,
        writeValue,
        path,
        wizardDTO,
        setIsLoading,
        resetValuesToSnapshotIfNeeded
    } = props;

    const {
        onValidate: onComponentValidate,
        isComponentValid,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation('WizardFNOLAutoRepair');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const componentValidation = useCallback(() => {
        const repairDTOValue = repairDTO.value;

        switch (repairDTO.repairOptionChoice.value.code) {
            case 'PreferredVendor': return !_.isEmpty(repairDTOValue.repairFacilityAddress);
            case 'NoRepair': return repairDTOValue.alreadyReceivedEstimate === false
                || (!_.isEmpty(repairDTOValue.estimatedRepairCost)
                && repairDTOValue.estimatedRepairCost.amount !== null
                && repairDTOValue.estimatedRepairCost.amount !== 0);
            default: return true;
        }
    }, [repairDTO]);

    useEffect(() => {
        registerComponentValidation(componentValidation);
    }, [registerComponentValidation, componentValidation]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;
        writeValue(value, fullPath);
    }, [writeValue, path]);

    const writeFromFullPath = useCallback((value, changedPath) => {
        writeValue(value, changedPath);
    }, [writeValue]);

    const showModal = useCallback(
        async () => {
            const results = await ModalNextProvider.showModal(
                <RepairPreferredVendorPopup />
            );
            return results;
        }, []
    );

    const renderSecondaryLabelPreferredVendor = useCallback(
        () => {
            return (
                <React.Fragment>
                    <span>{translator(messages.wizardFNOLAutoRepairChoicePreferredVendor)}</span>
                    <span> - </span>
                    <Link onClick={showModal}>{translator(messages.wizardFNOLAutoRepairChoicePreferredVendorMoreInfo)}</Link>
                </React.Fragment>
            );
        }, [translator]
    );

    const onValueChangeWithRepairDetailsReset = useCallback((value, changedPath) => {
        const parentDTO = {
            repairOptionChoice: value,
            repairFacilityAddress: {},
            estimatedRepairCost: {}
        };

        writeValue(parentDTO, path);
        if (value !== 'PreferredVendor') {
            disregardFieldValidation(['wizardFNOLAutoRepairChoicePrefferedVendorComponent']);
        }

        if (value !== 'NoRepair') {
            disregardFieldValidation(['FNOLRepairEstimates', 'fNOLRepairEstimatesReceived']);
        }
        if (value !== 'NewVendor') {
            disregardFieldValidation(['wizardFNOLAutoRepairChoiceNewVendorAddress']);
            disregardFieldValidation(['NewVendorRepairScreen']);
        }
    }, [writeValue, path, disregardFieldValidation]);

    const shouldEnableCarGlassLogic = useCallback(() => {
        return wizardDTO.frontEndClaimCause.value === 'GlassBreak';
    }, [wizardDTO.frontEndClaimCause.value]);

    const makeAppointment = () => {
        return setTimeout(() => {
            window.location.replace(carGlassURL());
        }, 3000);
    };

    const overrideProps = {
        wizardFNOLAutoRepairCarGlassHeader: {
            visible: shouldEnableCarGlassLogic()
        },
        wizardFNOLAutoRepairChoicePreferredVendor: {
            value: repairDTO.repairOptionChoice.value.code,
            renderName: renderSecondaryLabelPreferredVendor,
            onValueChange: onValueChangeWithRepairDetailsReset
        },
        wizardFNOLAutoRepairChoicePrefferedVendorContent: {
            visible: repairDTO.repairOptionChoice.value.code === 'PreferredVendor'
        },
        wizardFNOLAutoRepairCarGlassContainer: {
            visible: shouldEnableCarGlassLogic()
        },
        wizardFNOLAutoRepairChoiceCarGlass: {
            value: repairDTO.repairOptionChoice.value.code,
            onValueChange: onValueChangeWithRepairDetailsReset
        },
        wizardFNOLAutoRepairChoiceCarGlassContent: {
            visible: repairDTO.repairOptionChoice.value.code === 'CarGlass'
        },
        wizardFNOLAutoRepairChoiceNewVendor: {
            value: repairDTO.repairOptionChoice.value.code,
            onValueChange: onValueChangeWithRepairDetailsReset
        },
        wizardFNOLAutoRepairChoiceNewVendorContent: {
            visible: repairDTO.repairOptionChoice.value.code === 'NewVendor'
        },
        wizardFNOLAutoRepairNewVendorComponent: {
            history: history,
            handleValueChange: handleValueChange,
            onValidate: onValidate,
            garageCheckRequired: true,
            repairDTO: repairDTO
        },
        wizardFNOLAutoRepairChoiceHaventDecided: {
            value: repairDTO.repairOptionChoice.value.code,
            onValueChange: onValueChangeWithRepairDetailsReset
        },
        wizardFNOLAutoRepairChoiceHaventDecidedContent: {
            visible: repairDTO.repairOptionChoice.value.code === 'NoRepair'
        },
        fNOLRepairEstimatesReceived: {
            writeValue: handleValueChange,
            repairDTO: repairDTO,
            showBuildingContent: false,
            wizardDTO: wizardDTO
        },
        wizardFNOLAutoRepairChoicePrefferedVendorComponent: {
            history: history,
            writeValue: handleValueChange,
            writeValueDirectly: writeFromFullPath,
            setIsLoading: setIsLoading,
            repairDTO: repairDTO,
            wizardDTO: wizardDTO,
            resetValuesToSnapshotIfNeeded: resetValuesToSnapshotIfNeeded
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            estimatesReceivedWrapperComponent: FNOLRepairEstimatesWrapper,
            address: FNOLAddress,
            partneredVendors: PartneredVendors,
            newVendorComponent: NewVendorFragment
        },
        resolveCallbackMap: {
            onValidate: onComponentValidate,
            makeAppointment: makeAppointment
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={repairDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onComponentValidate}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default WizardFNOLAutoRepair;
