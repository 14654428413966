import { defineMessages } from 'react-intl';

export default defineMessages({
    deleteButton: {
        id: 'anonymous.fnol.components.additionalParty.Delete',
        defaultMessage: 'Delete'
    },
    contactRoleInput: {
        id: 'anonymous.fnol.components.additionalParty.Type',
        defaultMessage: 'Type'
    },
    canIdentifyParty: {
        id: 'anonymous.fnol.components.additionalParty.Can you identify involved party?',
        defaultMessage: 'Can you identify involved party?'
    },
    canIdentifyPartyYes: {
        id: 'anonymous.fnol.components.additionalParty.canIdentify.Yes',
        defaultMessage: 'Yes'
    },
    canIdentifyPartyNo: {
        id: 'anonymous.fnol.components.additionalParty.canIdentify.No',
        defaultMessage: 'No'
    },
    contactInvolvementInput: {
        id: 'anonymous.fnol.components.additionalParty.Involvement',
        defaultMessage: 'Involvement'
    },
    wasInjuredInput: {
        id: 'anonymous.fnol.components.additionalParty.Was injured',
        defaultMessage: 'Was injured'
    },
    licensePlateInput: {
        id: 'anonymous.fnol.components.additionalParty.License plate',
        defaultMessage: 'License plate'
    },
    reportNumberInput: {
        id: 'anonymous.fnol.components.additionalParty.Report number',
        defaultMessage: 'Report number'
    },
    insurerNameInput: {
        id: 'anonymous.fnol.components.additionalParty.Insurer name',
        defaultMessage: 'Insurer'
    },
    policyNumberInput: {
        id: 'anonymous.fnol.components.additionalParty.Policy number',
        defaultMessage: 'Policy number'
    },
    wasInjuredInputValueYes: {
        id: 'anonymous.fnol.components.additionalParty.WasInjured.yes',
        defaultMessage: 'Yes'
    },
    wasInjuredInputValueNo: {
        id: 'anonymous.fnol.components.additionalParty.WasInjured.no',
        defaultMessage: 'No'
    }

});
