import _ from 'lodash';
import { dateSeparator } from '../../utils/Constants';
import { getTypecodeTranslation } from '../../utils/TypeCodeTranslationUtil';
import messages from './WizardFNOLSummaryPage.messages';

export function buildAddressString(address, translator) {
    let addressDetails = '';
    if (address.street) addressDetails += `${address.street}`;
    if (address.streetNumber) addressDetails += _.isEmpty(addressDetails) ? `${address.streetNumber}` : ` ${address.streetNumber}`;
    if (address.boxNumber) addressDetails += `/${address.boxNumber}`;

    if (address.postalCode || address.city || address.country) addressDetails += '\n';
    if (address.postalCode) addressDetails += _.isEmpty(addressDetails) ? `${address.postalCode}` : `${address.postalCode}`;
    if (address.city) addressDetails += _.isEmpty(addressDetails) ? `${address.city}` : ` ${address.city}`;
    if (address.country) addressDetails += `(${getTypecodeTranslation(translator, address.country, 'Country')})`;

    return addressDetails;
}

export function buildDateString(dateObject) {
    const separator = dateSeparator();
    if (dateObject.hour) {
        const hourDisplay = dateObject.hour < 10 ? `0${dateObject.hour}` : `${dateObject.hour}`;
        const minuteDisplay = dateObject.minute < 10 ? `0${dateObject.minute}` : `${dateObject.minute}`;

        return `${dateObject.day}${separator}${dateObject.month + 1}${separator}${dateObject.year}, ${hourDisplay}:${minuteDisplay}`;
    }
    return `${dateObject.day}${separator}${dateObject.month + 1}${separator}${dateObject.year}`;
}

export function buildBankAccountDetails(bankDetails, translator) {
    let bankDetailsString = '';
    bankDetailsString += `\n\n${translator(messages.wizardFnolSummaryPageRepairDetailsBankAccountDetails)}`;

    if (!_.isEmpty(bankDetails.bankAccountName)) {
        bankDetailsString += `\n${bankDetails.bankAccountName}`;
    }

    if (!_.isEmpty(bankDetails.bankAccountNumber)) {
        bankDetailsString += `\n${bankDetails.bankAccountNumber}`;
    }

    if (!_.isEmpty(bankDetails.bicNumber)) {
        bankDetailsString += `\n${bankDetails.bicNumber}`;
    }
    return bankDetailsString;
}
