/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { DropdownSelectField, Button } from '@jutro/components';
import styles from './DocumentUploadList.module.scss';
import messages from './DocumentUploadList.messages';

class DocumentUploadListReadOnly extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        value: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const translator = this.context;
        const {
            availableDocumentTypes,
            value,
            defaultDocumentType,
            onResetUploadedDocuments
        } = this.props;

        console.log('defaultdctype', defaultDocumentType);
        const docList = value.map((doc, indx) => (
            <div className={cx(styles.oneDocumentDiv)} key={`dv-${doc.id}`}>
                <div className={cx(styles.docTypeSelect)}>
                    <DropdownSelectField
                        key={`ds-${doc.id}`}
                        availableValues={availableDocumentTypes}
                        value={doc.documentType}
                        label={messages.documentTypeLabel}
                        placeholder={messages.documentTypePlaceholder}
                        id="documentTypeDropdown"
                        readOnly={true}
                    />
                </div>
                <div className={cx(styles.fileUploadComponent)}>
                    <ul>
                        {doc.files.map((file) => (
                            <li>{file.fileName} ({(file.size / (1024 * 1024)).toFixed(2)} MB)</li> 
                        ))}
                    </ul>
                </div>
            </div>
        ));

        

        return (
            <div className={cx(styles.documentListDiv)}>
                <h2>
                    { translator(messages.documentsTitle) }
                </h2>
                <p>
                    { translator(messages.documentsSubTitle) }
                </p>
                <div className={cx(styles.docsDiv)}>
                    {docList}
                </div>
                <Button onClick={onResetUploadedDocuments}>
                    {translator(messages.uploadNewDocuments)}
                </Button>
            </div>
        );
    }
}

export default DocumentUploadListReadOnly;
