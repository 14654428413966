import { defineMessages } from 'react-intl';

export default defineMessages({
    aboutVehicleDamage: {
        id: 'anonymous.wizard.fnol.pages.loss-details.About the vehicle damage',
        defaultMessage: 'About the vehicle damage'
    },
    wizardFnolLossDetailsSelectPart: {
        id: 'anonymous.wizard.fnol.pages.loss-details.Select which part of the car was damaged.',
        defaultMessage: 'Select which part of the car was damaged.'
    },
    wizardFnolLossDetailsIndicatePart: {
        id: 'anonymous.wizard.fnol.pages.loss-details.You can indicate the damaged part in the picture below and/or select the corresponding checkbox(es).',
        defaultMessage: 'You can indicate the damaged part in the picture below and/or select the corresponding checkbox(es).'
    },
    dmgBuiltinElectronics: {
        id: 'anonymous.fnol.components.damages.Built in electronics (audio, DVD, carkit, GPS, ...)',
        defaultMessage: 'Built in electronics (audio, DVD, carkit, GPS, ...)'
    },
    dmgSpoilerAntena: {
        id: 'anonymous.fnol.components.damages.Spoiler, antenna, wiper',
        defaultMessage: 'Spoiler, antenna, wiper'
    },
    dmgRimsWheelCover: {
        id: 'anonymous.fnol.components.damages.Rims, wheel covers',
        defaultMessage: 'Rims, wheel covers'
    },
    dmgOtherDamages: {
        id: 'anonymous.fnol.components.damages.Other damages',
        defaultMessage: 'Other damage'
    },
    additionalDamageCheckboxes: {
        id: 'anonymous.wizard.fnol.pages.loss-details.If vehicle has other damage, select the checkbox.',
        defaultMessage: 'If the vehicle has suffered another damage, please select below the corresponding checkbox(es).'
    },
    dmgGlassBreak: {
        id: 'anonymous.fnol.components.lossDetails.Glass Break',
        defaultMessage: 'Only a glass break'
    },
    dmgTires: {
        id: 'anonymous.fnol.components.lossDetails.Tires',
        defaultMessage: 'Tires'
    },
    dmgBottom: {
        id: 'anonymous.fnol.components.lossDetails.Bottom',
        defaultMessage: 'Bottom'
    },
    dmgNoVisibleDamage: {
        id: 'anonymous.fnol.components.lossDetails.No visible damage',
        defaultMessage: 'No visible damage'
    },
    addOtherDamagesTitle: {
        id: 'anonymous.wizard.fnol.pages.loss-details.Would you like to add other damages?',
        defaultMessage: 'Would you like to add other damages?'
    },
    dmgTrailerLessThan750kg: {
        id: 'anonymous.fnol.components.lossDetails.otherDamages.Trailer damage below 750 kg',
        defaultMessage: 'Damages to a trailer below 750 kg'
    },
    dmgTrailerMoreThan750kg: {
        id: 'anonymous.fnol.components.lossDetails.otherDamages.Trailer damage above 750 kg',
        defaultMessage: 'Damages to a trailer above 750 kg'
    },
    dmgTrailerBaggageOrBikeTrailer: {
        id: 'anonymous.fnol.components.lossDetails.otherDamages.Baggage or bike carrier',
        defaultMessage: 'Baggage carrier or bike carrier'
    },
    dmgOtherBicycle: {
        id: 'anonymous.fnol.components.lossDetails.other.Bicycle',
        defaultMessage: 'Transported bicycle'
    },
    dmgOtherElectronics: {
        id: 'anonymous.fnol.components.lossDetails.other.Electronics including computers',
        defaultMessage: 'Electronics including computers'
    },
    dmgOtherLuggage: {
        id: 'anonymous.fnol.components.lossDetails.other.Luggage, clothing & equipment',
        defaultMessage: 'Luggage, clothing & equipment'
    },
    dmgOtherOther: {
        id: 'anonymous.fnol.components.lossDetails.other.Other',
        defaultMessage: 'Other'
    },
    TrailerDamagesTitle: {
        id: 'anonymous.wizard.fnol.pages.loss-details.Trailer damages',
        defaultMessage: 'Damages to the trailer'
    },
    trailerLicensePlateInput: {
        id: 'anonymous.fnol.components.lossDetails.trailer.License Plate Number',
        defaultMessage: 'License plate number'
    },
    trailerVinInput: {
        id: 'anonymous.fnol.components.lossDetails.trailer.VIN',
        defaultMessage: 'Chassis number'
    },
    trailerDamageBoxesTitle: {
        id: 'anonymous.wizard.fnol.pages.loss-details.trailer.Select which part of trailer was damaged.',
        defaultMessage: 'Select which part of trailer was damaged.'
    },
    trailerDmgTires: {
        id: 'anonymous.fnol.components.lossDetails.trailer.Tires',
        defaultMessage: 'Tires'
    },
    trailerDmgBottom: {
        id: 'anonymous.fnol.components.lossDetails.trailer.Bottom',
        defaultMessage: 'Bottom'
    },
    trailerDmgOtherDamages: {
        id: 'anonymous.fnol.components.lossDetails.trailer.Other',
        defaultMessage: 'Other'
    },
    trailerOtherDamagesText: {
        id: 'anonymous.fnol.components.lossDetails.trailer.Other damages',
        defaultMessage: 'Other damage'
    },
    titleVehicleDamagesPresent: {
        id: 'anonymous.wizard.fnol.pages.loss-details.Select which part of the car was damaged *.',
        defaultMessage: 'Select which part of the car was damaged *.'
    },
    titleMissingVehicleDamages: {
        id: 'anonymous.wizard.fnol.pages.loss-details.It is mandatory to select which part of the car was damaged *.',
        defaultMessage: 'It is mandatory to select which part of the car was damaged *.'
    }
});
