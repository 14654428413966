/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-console */
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { CheckboxField } from '@jutro/components';
import styles from './LossDetailsMotor.module.scss';
import metadata from './LossDetailsMotor.metadata.json5';
import messages from './LossDetailsMotor.messages';

function LossDetailsMotor(props) {
    const {
        id,
        handleValueChange,
        value: fnolVM,
        onValidate
    } = props;

    const fnolDTO = fnolVM.value;

    const {
        onValidate: setComponentValidation,
        registerComponentValidation,
        isComponentValid
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    if (!fnolDTO.motorDamage) {
        fnolDTO.motorDamage = {};
    }

    const validationVehicleDamagesCallback = useCallback(() => {
        if (!fnolDTO.motorDamage) {
            return false;
        }

        const { motorDamage } = fnolDTO;

        return !!motorDamage.frontDamaged
        || !!motorDamage.backDamaged
        || !!motorDamage.leftSideDamaged
        || !!motorDamage.rightSideDamaged
        || !!motorDamage.bottomDamaged
        || !!motorDamage.windshieldDamaged
        || !!motorDamage.noVisibleDamage
        || !!motorDamage.totalLoss
        || !!motorDamage.otherPartsDamaged;
    }, [fnolDTO]);

    useEffect(() => {
        registerComponentValidation(validationVehicleDamagesCallback);
    }, [registerComponentValidation, validationVehicleDamagesCallback]);

    const overrides = {
        otherDamagesDescription: {
            visible: fnolDTO.motorDamage.otherPartsDamaged === true
        },
        motorOtherDamageOtherDescription: {
            visible: fnolDTO.motorDamage.otherDamagesOther === true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleValueChange,
            onValidate: setComponentValidation,
        },
        resolveComponentMap: {
            checkboxField: CheckboxField
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={fnolVM}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={setComponentValidation}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default LossDetailsMotor;
