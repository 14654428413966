import { defineMessages } from 'react-intl';

export default defineMessages({
    aboutTheBike: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.About the involved bike',
        defaultMessage: 'About the involved bike'
    },
    bikeMakeInput: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Make',
        defaultMessage: 'Make'
    },
    bikeYearInput: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Purchase Year',
        defaultMessage: 'Purchase year'
    },
    aboutTheBikeTheft: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Where was your bike located at the moment of theft',
        defaultMessage: 'Location of the bike at the moment of theft?'
    },
    whereWasBikeLocatedPlaceholder: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Please select',
        defaultMessage: 'Please select'
    },
    locatedInEnclosedCommonAreaGarage: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.EnclosedCommonArea.Garage',
        defaultMessage: 'Garage'
    },
    locatedInEnclosedCommonAreaBasement: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.EnclosedCommonArea.Basement',
        defaultMessage: 'Basement'
    },
    locatedInEnclosedCommonAreaOther: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.EnclosedCommonArea.Other',
        defaultMessage: 'Other'
    },
    locatedInPrivateRoom_WasRoomLocked: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Locked room?',
        defaultMessage: 'Locked room?'
    },
    sinceWhenWasBikePlacedThere: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Since when was the Bike placed here?',
        defaultMessage: 'When was the bike placed at this location?'
    },
    aboutTheSecurityMeasures: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Security measures',
        defaultMessage: 'Security measures'
    },
    securityMeasures_RequiredLockUsed: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Required lock was used?',
        defaultMessage: 'Required lock was used?'
    },
    securityMeasures_DoYouHaveBothLockKeys: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Do you have both Keys of the Lock?',
        defaultMessage: 'Do you have both Keys of the Lock?'
    },
    securityMeasures_WasAFixedPointsUsed: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Was a fixed point used?',
        defaultMessage: 'Was a fixed point used?'
    },
    securityMeasures_ForseenAGPSTracker: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Was you bike foreseen of a GPS tracker',
        defaultMessage: 'Was you bike foreseen of a GPS tracker'
    },
    aboutTheInvolvedCyclist: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.The involved cyclist',
        defaultMessage: 'The involved cyclist'
    },
    whoWasTheCyclistInput: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Who was the cyclist?',
        defaultMessage: 'Who was the cyclist?'
    },
    wasTheCyclistInjured: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Was the cyclist injured?',
        defaultMessage: 'Was the cyclist injured?'
    },
    otherFirstName: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.First Name',
        defaultMessage: 'First Name'
    },
    otherLastName: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Last Name',
        defaultMessage: 'Last Name'
    },
    otherDateOfBirth: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Date of birth',
        defaultMessage: 'Date of birth'
    },
    aboutTheDamagedBikeTitle: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.About the damaged Bike',
        defaultMessage: 'Select all the damaged bike-parts'
    },
    aboutTheDamagedBikeMandatoryDamageLine1: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.It is mandatory to select which part of the bike was damaged*',
        defaultMessage: 'It is mandatory to select which part of the bike was damaged*'
    },
    aboutTheDamagedBikeMandatoryDamageLine2: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.You can indicate the damaged part by selecting the corresponding checkbox(es)',
        defaultMessage: 'You can indicate the damaged part by selecting the corresponding checkbox(es)'
    },
    frontWheelDamaged: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Front Wheel',
        defaultMessage: 'Front Wheel'
    },
    backWheelDamaged: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Back Wheel',
        defaultMessage: 'Back Wheel'
    },
    handelbarsDamaged: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Handelbars',
        defaultMessage: 'Handlebars'
    },
    frameDamaged: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Frame',
        defaultMessage: 'Frame'
    },
    chainDamaged: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Chain',
        defaultMessage: 'Chain'
    },
    brakesDamaged: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Brakes',
        defaultMessage: 'Brakes'
    },
    pedalsDamaged: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Pedals',
        defaultMessage: 'Pedals'
    },
    totalLossDamaged: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Total Loss/Theft',
        defaultMessage: 'Total Loss/Theft'
    },
    otherPartsDamaged: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Other',
        defaultMessage: 'Other'
    },
    otherPartsDamagedDescription: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Specify',
        defaultMessage: 'Specify*'
    },
    noVisibleDamage: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.No damage',
        defaultMessage: 'No damage'
    },
    aboutTheOtherDamagesBikeTitle: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Other Damage',
        defaultMessage: 'Other Damage'
    },
    otherDamagesBikeBag: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Bike Bag',
        defaultMessage: 'Bike Bag'
    },
    otherDamagesBikeLock: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Bike lock',
        defaultMessage: 'Bike lock'
    },
    otherDamagesBasker: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Basket',
        defaultMessage: 'Basket'
    },
    otherDamagesBikeTrailer: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Bike trailer',
        defaultMessage: 'Bike trailer'
    },
    otherDamagesChildSeat: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.Child seat',
        defaultMessage: 'Child seat'
    },
    otherDamagesGPS: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.GPS',
        defaultMessage: 'GPS'
    },
    otherDamagesOtherDamage: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.OtherDamages.Other',
        defaultMessage: 'Other'
    },
    otherDamagesOtherDamageDescription: {
        id: 'anonymous.wizard.fnol.pages.loss-details.bike.OtherDamages.Specify',
        defaultMessage: 'Specify*'
    }
});
