import moment from 'moment';

const BASE_DATE_FORMAT = 'DD/MM/YYYY'
const BASE_DATETIME_FORMAT = 'DD/MM/YYYY, HH:MM'

export function formatDateToBaseFormat(date, includeTime) {
    const momentDate = moment(date);

    return includeTime 
        ? momentDate.format(BASE_DATETIME_FORMAT)
        : momentDate.format(BASE_DATE_FORMAT);
}