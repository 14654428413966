import _ from 'lodash';
import motorDamageMessages from '../../lossDetailsComponents/LossDetailsMotor/LossDetailsMotor.messages';
import {
    buildVehicleDescription,
    buildDriverDescription,
    buildRepairDetailsDamage
} from '../VehicleSummarySnapshotCommonFunctions';
import autoSummaryMessages from '../WizardFNOLSummaryAuto/WizardFNOLSummaryAuto.messages';

export function notEmptyMotorDamages(wizardDTO) {
    if (!wizardDTO.value.motorDamage) {
        return false;
    }

    const { motorDamage } = wizardDTO.value;
    return !!motorDamage.frontDamaged
        || !!motorDamage.backDamaged
        || !!motorDamage.leftSideDamaged
        || !!motorDamage.rightSideDamaged
        || !!motorDamage.bottomDamaged
        || !!motorDamage.windshieldDamaged
        || !!motorDamage.noVisibleDamage
        || !!motorDamage.totalLoss
        || !!motorDamage.otherPartsDamaged;
}

export function notEmptyMotorOtherDamages(wizardDTO) {
    if (!wizardDTO.value.motorDamage) {
        return false;
    }

    const { motorDamage } = wizardDTO.value;
    return !!motorDamage.otherDamagesHelmet
        || !!motorDamage.otherDamagesClothing
        || !!motorDamage.otherDamagesLuggage
        || !!motorDamage.otherDamagesOther;
}

export function buildMotorDamageSnapshot(wizardDTO, translator) {
    if (!wizardDTO.value.motorDamage) {
        return '';
    }

    let damageString = '';

    const motorDamage = wizardDTO.motorDamage.value;
    if (motorDamage.frontDamaged) damageString += `${translator(motorDamageMessages.frontMotorDamage)}\n`;
    if (motorDamage.backDamaged) damageString += `${translator(motorDamageMessages.backMotorDamge)}\n`;
    if (motorDamage.leftSideDamaged) damageString += `${translator(motorDamageMessages.leftFlankMotorDamage)}\n`;
    if (motorDamage.rightSideDamaged) damageString += `${translator(motorDamageMessages.rightFlankMotorDamage)}\n`;
    if (motorDamage.bottomDamaged) damageString += `${translator(motorDamageMessages.bottomMotorDamage)}\n`;
    if (motorDamage.windshieldDamaged) damageString += `${translator(motorDamageMessages.windshieldMotorDamage)}\n`;
    if (motorDamage.noVisibleDamage) damageString += `${translator(motorDamageMessages.noVisibleDamageMotorDamage)}\n`;
    if (motorDamage.totalLoss) damageString += `${translator(motorDamageMessages.totalLossMotorDamage)}\n`;
    if (motorDamage.otherPartsDamaged) damageString += `${translator(motorDamageMessages.otherMotorDamage)} - ${motorDamage.otherPartDamagedText}\n`;

    return damageString;
}

export function buildMotorOtherDamageSnapshot(wizardDTO, translator) {
    if (!wizardDTO.value.motorDamage) {
        return '';
    }

    let damageString = '';

    const motorDamage = wizardDTO.motorDamage.value;
    if (motorDamage.otherDamagesHelmet) damageString += `${translator(motorDamageMessages.otherMotorDamagesHelmet)}\n`;
    if (motorDamage.otherDamagesClothing) damageString += `${translator(motorDamageMessages.otherMotorDamagesClothing)}\n`;
    if (motorDamage.otherDamagesLuggage) damageString += `${translator(motorDamageMessages.otherMotorDamagesLuggage)}\n`;
    if (motorDamage.otherDamagesOther) damageString += `${translator(motorDamageMessages.otherMotorDamagesOther)} - ${motorDamage.otherDamagesOtherTest}\n`;

    return damageString;
}

export function generateMotorPartSnapshot(translator, wizardDTO) {
    const motorDataSnapshot = [];

    if (!_.isEmpty(wizardDTO.value.vehicleDamage)) {
        motorDataSnapshot.push({
            label: translator(autoSummaryMessages.wizardFnolSummaryPageAboutVehicle),
            value: buildVehicleDescription(wizardDTO)
        });
    }

    if (!_.isEmpty(wizardDTO.value.vehicleDamage)) {
        motorDataSnapshot.push({
            label: translator(autoSummaryMessages.wizardFnolSummaryPageWhoWasDriver),
            value: buildDriverDescription(wizardDTO, translator)
        });
    }

    if (notEmptyMotorDamages(wizardDTO)) {
        motorDataSnapshot.push({
            label: translator(motorDamageMessages.aboutVehicleDamage),
            value: buildMotorDamageSnapshot(wizardDTO, translator)
        });
    }

    if (notEmptyMotorOtherDamages(wizardDTO)) {
        motorDataSnapshot.push({
            label: translator(motorDamageMessages.otherMotorDamagesHeader),
            value: buildMotorOtherDamageSnapshot(wizardDTO, translator)
        });
    }

    if (!_.isEmpty(wizardDTO.value.repairDetails)) {
        motorDataSnapshot.push({
            label: translator(autoSummaryMessages.wizardFnolSummaryPageRepairDetails),
            value: buildRepairDetailsDamage(wizardDTO, translator)
        });
    }

    return motorDataSnapshot;
}
