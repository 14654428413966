export default {
    name: 'NNTheme',
    prefix: 'nnTheme',
    rootStyle: {
        '--GW-FONT-FAMILY': 'var(--GW-FONT-NNDAGNY)',
        '--GW-FONT-NNDAGNY': '"NN Dagny Text", "Open Sans Variable", "Source Sans Variable", Arial, sans-serif',
        '--GW-TEXT-COLOR-1': '#414141',
        '--GW-BRAND-COLOR-1': '#EE7F00',
        '--GW-BRAND-COLOR-1-HOVER': '#ea650d',
        '--GW-BRAND-COLOR-1-ACTIVE': '#e64415',
        '--GW-BRAND-COLOR-2': 'var(--GW-BRAND-COLOR-1)',
        '--GW-COLOR-BACKGROUND-1': '#ffffff',
        '--GW-COLOR-BACKGROUND-2': '#ffffff',
        '--GW-ICON-FONT-SIZE-1': '0.9rem',
        '--GW-FONT-SIZE-LABEL': '1rem',
        '--GW-FONT-SIZE-BODY': '1rem',
        '--GW-HEADER-HEIGHT': '4.2rem',
        '--GW-FOCUS-COLOR': 'var(--GW-BRAND-COLOR-1)',
        '--GW-FOCUS-COLOR-LIGHT': 'var(--GW-BRAND-COLOR-1)',

        // Section for custom NN global variables
        '--NN-FONT-SIZE-SMALL': '0.75rem',
        '--NN-HEADER-COLOR': 'var(--GW-COLOR-BACKGROUND-1)',
        '--NN-FOOTER-COLOR': '#e5ded9',

        // Title component
        '--NN-TITLE-FIRST-COLOR': 'var(--GW-BRAND-COLOR-1)',
        '--NN-TITLE-SECOND-COLOR': '#ea650d',
        '--NN-TITLE-THIRD-COLOR': '#e64415',

        '--NN-THEMED-TITLE-COLOR': 'var(--GW-BRAND-COLOR-1)',

        // Selectable Policy
        '--NN-SELECTABLE-POLICY-SELECTED-POLICY-HEADER-TEXT-COLOR': 'var(--GW-TEXT-COLOR-1)',
        '--NN-SELECTABLE-POLICY-UNSELECTED-POLICY-HEADER-TEXT-COLOR': 'var(--GW-BRAND-COLOR-1)',
        '--NN-SELECTABLE-POLICY-SELECTED-POLICY-TEXT-COLOR': 'var(--GW-TEXT-COLOR-1)',
        '--NN-SELECTABLE-POLICY-UNSELECTED-POLICY-TEXT-COLOR': 'var(--GW-TEXT-COLOR-1)'

    },
    componentStyles: {

    }
};
