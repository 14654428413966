const WIZARD_STEP_TO_PROGRESS = {
    '/loss-details': 'lossDetails',
    '/repair': 'repair',
    '/additional-information': 'additionalInformation',
    '/contact-details': 'contactDetails',
    '/summary': 'summary'
};

const PROGRESS_TO_WIZARD_STEP = {
    lossDetails: '/loss-details',
    repair: '/repair',
    additionalInformation: '/additional-information',
    contactDetails: '/contact-details',
    summary: '/summary'
};

export function getProgressBasedOnWizardStep(wizardStep) {
    return WIZARD_STEP_TO_PROGRESS[wizardStep];
}

export function getWizardStepBasedOnProgress(progress) {
    return PROGRESS_TO_WIZARD_STEP[progress];
}
