/* eslint-disable max-len */
import { useCallback, useContext } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import metadata from './BasicInformationQuestionSetCheckbox.metadata.json5';
import styles from './BasicInformationQuestionSetCheckbox.module.scss';

function BasicInformationQuestionSetCheckbox(props) {
    const translator = useContext(TranslatorContext);
    const {
        data,
        onIsSingleSelect,
        onGetDamageType,
        onQuestionSetPick,
        onGetQuestionSetValue
    } = props;
    const damageType = onGetDamageType();

    const getTypecodeTranslation = useCallback((code, typelist) => {
        return translator({
            id: `typekey.${typelist}.${code}`,
            defaultMessage: code
        });
    }, [translator]);

    const overrideProps = {
        basicInfoQuestionSetCheckboxContainer: {
            visible: !onIsSingleSelect()
        },
        basicInfoQuestionSetCheckboxValue: {
            label: getTypecodeTranslation(data, 'inlb_QuestionSet'),
            onValueChange: (value) => onQuestionSetPick(value, damageType, data),
            value: onGetQuestionSetValue(damageType, data)
        },
        basicInfoQuestionSetRadioButtonValue: {
            onValueChange: (value) => onQuestionSetPick(value, damageType, data),
            value: onGetQuestionSetValue(damageType, data) ? getTypecodeTranslation(data, 'inlb_QuestionSet') : '',
            availableValues: [{
                id: getTypecodeTranslation(data, 'inlb_QuestionSet'),
                displayName: getTypecodeTranslation(data, 'inlb_QuestionSet')
            }],
            visible: onIsSingleSelect()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        }
    };

    return renderContentFromMetadata(
        metadata.componentContent,
        overrideProps,
        resolvers
    );
}

export default BasicInformationQuestionSetCheckbox;
