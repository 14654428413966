import { defineMessages } from 'react-intl';

export default defineMessages({
    aboutVehicleDamage: {
        id: 'anonymous.fnol.components.motorLossDetails.About the vehicle damage',
        defaultMessage: 'About the vehicle damage'
    },
    mandatoryToSelectMotorDamage: {
        id: 'anonymous.fnol.components.motorLossDetails.It is mandatory to select which part of the motor was damaged *.',
        defaultMessage: 'It is mandatory to select which part of the motorbike or moped was damaged *.'
    },
    indicateMotorDamageBySelectingCheckboxes: {
        id: 'anonymous.fnol.components.motorLossDetails.You can indicate the damaged part by selecting the corresponding checkbox(es).',
        defaultMessage: 'You can indicate the damaged part by selecting the corresponding checkbox(es).'
    },
    frontMotorDamage: {
        id: 'anonymous.fnol.components.motorLossDetails.Front',
        defaultMessage: 'Front'
    },
    backMotorDamge: {
        id: 'anonymous.fnol.components.motorLossDetails.Back',
        defaultMessage: 'Back'
    },
    leftFlankMotorDamage: {
        id: 'anonymous.fnol.components.motorLossDetails.Left flank',
        defaultMessage: 'Left flank'
    },
    rightFlankMotorDamage: {
        id: 'anonymous.fnol.components.motorLossDetails.Right Flank',
        defaultMessage: 'Right Flank'
    },
    bottomMotorDamage: {
        id: 'anonymous.fnol.components.motorLossDetails.Bottom',
        defaultMessage: 'Bottom'
    },
    windshieldMotorDamage: {
        id: 'anonymous.fnol.components.motorLossDetails.Windshield',
        defaultMessage: 'Windshield'
    },
    totalLossMotorDamage: {
        id: 'anonymous.fnol.components.motorLossDetails.Total Loss',
        defaultMessage: 'Total Loss'
    },
    noVisibleDamageMotorDamage: {
        id: 'anonymous.fnol.components.motorLossDetails.No visible damage',
        defaultMessage: 'No visible damage'
    },
    otherMotorDamage: {
        id: 'anonymous.fnol.components.motorLossDetails.Other',
        defaultMessage: 'Other'
    },
    otherMotorDamagePlaceholder: {
        id: 'anonymous.fnol.components.motorLossDetails.other.Specify',
        defaultMessage: 'Specify'
    },
    otherMotorDamagesHeader: {
        id: 'anonymous.fnol.components.motorLossDetails.Other damage',
        defaultMessage: 'Other damage'
    },
    otherMotorDamagesTitle: {
        id: 'anonymous.fnol.components.motorLossDetails.Would you like to add other damages?',
        defaultMessage: 'Would you like to add other damages?'
    },
    otherMotorDamagesHelmet: {
        id: 'anonymous.fnol.components.motorLossDetails.Helmet',
        defaultMessage: 'Helmet'
    },
    otherMotorDamagesClothing: {
        id: 'anonymous.fnol.components.motorLossDetails.Clothing',
        defaultMessage: 'Clothing'
    },
    otherMotorDamagesLuggage: {
        id: 'anonymous.fnol.components.motorLossDetails.Luggage',
        defaultMessage: 'Luggage'
    },
    otherMotorDamagesOther: {
        id: 'anonymous.fnol.components.motorLossDetails.Other',
        defaultMessage: 'Other'
    },
});
