export default class PrivateCustomerValuesProvider {

    getRedirectionDetails = () => {
        return {
            baseUrlForClient: '/',
            state: {}
        };
    }

    isBrokerContext = () => {
        return false;
    }

    getTypefilterNameForReporterRelation = () => {
        return 'FrontEndSelectable'
    }

    saveAndResumeEnabled = () => {
        return true
    }

    getAuthenticationChannel = () => {
        return "B2C"
    }
    
}
