import { defineMessages } from 'react-intl';

export default defineMessages({
    aditionalPartiesH2: {
        id: 'anonymous.fnol.components.additionalPartiesList.Other parties',
        defaultMessage: 'Other parties'
    },
    additionalPartiesAccordionTitle: {
        id: 'anonymous.fnol.components.additionalPartiesList.Additional party involved',
        defaultMessage: 'Additional involved party'
    },
    addMoreContacts: {
        id: 'anonymous.fnol.components.additionalPartiesList.Where there any other parties involved?',
        defaultMessage: 'Were there any other parties involved?'
    },
    addMoreContactsYes: {
        id: 'anonymous.fnol.components.additionalPartiesList.moreContacts.Yes',
        defaultMessage: 'Yes'
    },
    addMoreContactsNo: {
        id: 'anonymous.fnol.components.additionalPartiesList.moreContacts.No',
        defaultMessage: 'No'
    },
    areThereMoreParties: {
        id: 'anonymous.fnol.components.additionalPartiesList.Additional party: specify if there are more parties',
        defaultMessage: 'Additional party: specify if there are more parties involved'
    },
    summaryProvideDetails: {
        id: 'anonymous.fnol.components.additionalPartiesList.Provide details',
        defaultMessage: 'Provide details'
    },
    summaryString: {
        id: 'anonymous.fnol.components.additionalPartiesList.Additional party summary',
        defaultMessage: 'Additional party: {sum}'
    },
    addAnotherParty: {
        id: 'anonymous.fnol.components.additionalPartiesList.Add another party',
        defaultMessage: 'Add another party'
    },
    notIdentified: {
        id: 'anonymous.fnol.components.additionalPartiesList.Not identified',
        defaultMessage: 'Not identified'
    },
    seeEAS: {
        id: 'anonymous.fnol.components.additionalPartiesList.See European Accident Statement',
        defaultMessage: 'See European Accident Statement'
    }
});
