import React from 'react';
import PropTypes from 'prop-types';

const customizedHeaderComponent = ({
    priority, className, title, value
}) => {
    let HeaderComponent = 'div';
    if (priority) {
        HeaderComponent = `h${priority}`;
    }
    return <HeaderComponent className={className}>{value || title}</HeaderComponent>;
};

customizedHeaderComponent.propTypes = {
    priority: PropTypes.number.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string
};
customizedHeaderComponent.defaultProps = {
    className: undefined,
    value: undefined,
    title: undefined
};
export default customizedHeaderComponent;
