/* eslint-disable max-len */
import _ from 'lodash';
import { useCallback, useContext } from 'react';
import { BreakpointTrackerContext } from '@jutro/layout';

function useBreakpointHandler() {
    const breakpoint = useContext(BreakpointTrackerContext);

    const isMobileDevice = useCallback(() => {
        return breakpoint === 'phone' || breakpoint === 'tablet';
    }, [breakpoint]);

    return [isMobileDevice];
}

export default useBreakpointHandler;
