import { defineMessages } from 'react-intl';

export default defineMessages({
    documentTypeLabel: {
        id: 'anonymous.fnol.components.documentUploadList.Document Type',
        defaultMessage: 'Document type'
    },
    deleteThisDocument: {
        id: 'anonymous.fnol.components.documentUploadList.Delete this document',
        defaultMessage: 'Delete this document'
    },
    invalidFileType: {
        id: 'anonymous.fnol.components.documentUploadList.Invalid file type',
        defaultMessage: 'Invalid file type: {invalidFileNamesString}. Allowed file types for this document are: {allowedFileTypesString}.'
    },
    documentsTitle: {
        id: 'anonymous.fnol.components.documentUploadList.Documents',
        defaultMessage: 'Documents'
    },
    filesAreTooBig: {
        id: 'anonymous.fnol.components.documentUploadList.File size limit reached',
        defaultMessage: 'File size limit reached. Maximum total files size is {maxSize}'
    },
    fileLimitExceeded: {
        id: 'anonymous.fnol.components.documentUploadList.You have reached the maximum amount of {maxCount} uploaded documents.',
        defaultMessage: 'You have reached the maximum amount of {maxCount} uploaded documents.'
    },
    documentsSubTitle: {
        id: 'anonymous.fnol.components.documentUploadList.DocumentsSubtitle',
        defaultMessage: 'Please upload all the documents that might help in bring you the best service including: photos, videos, estimate of the damage, police report or European Accident Statement.'
    },
    documentsSaveAndResumeParagraph: {
        id: 'anonymous.fnol.components.documentUploadList.If you would not have all documents already available, you can leave here the screens and complete your notification within 2 days to complete it by logging in again.',
        defaultMessage: 'If you would not have all documents already available, you can leave here the screens and complete your notification within 2 days to complete it by logging in again.'
    },
    documentTypePlaceholder: {
        id: 'anonymous.fnol.components.documentUploadList.documentTypePlaceHolder',
        defaultMessage: 'Please select here the Document Type'
    },
    uploadNewDocuments: {
        id: 'anonymous.fnol.components.documentUploadList.Upload new documents',
        defaultMessage: 'Upload new documents'
    }
});
