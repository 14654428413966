import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputMaskField } from '@jutro/components';
import CreditCardUtil from 'gw-portals-util-js/CreditCardUtil';
import messages from './CreditCardNumberComponent.messages';

class CreditCardNumberComponent extends Component {
    /**
     * @memberof gw-components-platform-react.CreditCardNumberComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.creditCardIssuer - Issuer of card
     */

    static propTypes = {
        creditCardIssuer: PropTypes.string.isRequired,
        ...InputMaskField
    };

    render() {
        const { creditCardIssuer, ...inputMaskFieldProps } = this.props;

        return (
            <InputMaskField
                {...inputMaskFieldProps}
                id="creditCardNumber"
                mask={CreditCardUtil.getInputMaskForIssuerCode(creditCardIssuer)}
                label={messages.creditCardNumeber}
                alwaysShowMask
            />
        );
    }
}

export default CreditCardNumberComponent;
