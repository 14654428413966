import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTracking } from 'gw-portals-tracking-react';
import styles from './TabSetComponent.module.css';
import TabContext from './TabContext';

class TabSetComponent extends Component {
    static propTypes = {
        defaultActiveTab: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    };

    static defaultProps = {
        defaultActiveTab: undefined
    };

    state = { activeTab: null };

    componentDidMount() {
        const { defaultActiveTab } = this.props;
        this.setState({ activeTab: defaultActiveTab });
    }

    isTabSelected = (tabID) => {
        const { activeTab } = this.state;
        return activeTab === tabID;
    };

    setActiveTab = (tabID) => {
        this.setState({ activeTab: tabID });
    };

    render() {
        const { activeTab } = this.state;
        const { children } = this.props;
        const contextCallbackMap = {
            setActiveTab: this.setActiveTab,
            activeTab
        };

        const containerClass = classNames('gw-header-body-grid-container', styles.container);

        return (
            <div className={containerClass}>
                <TabContext.Provider value={contextCallbackMap}>{children}</TabContext.Provider>
            </div>
        );
    }
}

export default withTracking(TabSetComponent);
