import { defineMessages } from 'react-intl';

export default defineMessages({
    fNOLPartneredVendorsContainer: {
        id: "anonymous.fnol.repair.preferredVendor.Please find below the list of closest authorized repair facilities based on your home address. In order to select one, expand it and click on 'Select Facility' button",
        defaultMessage: "Please find below the list of closest authorized repair facilities based on your home address. In order to select one, expand it and click on 'Select Facility' button"
    },
    selectedFacilityHeader: {
        id: 'anonymous.fnol.repair.preferredVendor.Current selected facility details:',
        defaultMessage: 'Current selected facility details:'
    },
    nameHeader: {
        id: 'anonymous.fnol.repair.preferredVendor.Name',
        defaultMessage: 'Name'
    },
    distanceHeader: {
        id: 'anonymous.fnol.repair.preferredVendor.Distance',
        defaultMessage: 'Distance (in km)'
    },
    brandHeader: {
        id: 'anonymous.fnol.repair.preferredVendor.Brand Garage',
        defaultMessage: 'Brand garage'
    },
    phoneNumberHeader: {
        id: 'anonymous.fnol.repair.preferredVendor.Phone Number',
        defaultMessage: 'Phone number'
    },
    emailAddress: {
        id: 'anonymous.fnol.repair.preferredVendor.Email Address: ',
        defaultMessage: 'Email address: '
    },
    facilityAddress: {
        id: 'anonymous.fnol.repair.preferredVendor.Facility Address: ',
        defaultMessage: 'Facility address: '
    },
    selectFacilityButton: {
        id: 'anonymous.fnol.repair.preferredVendor.Select facility',
        defaultMessage: 'Select facility'
    },
    differentSearchCriteria: {
        id: 'anonymous.fnol.repair.preferredVendor.I would like to search for repair facility with different search criteria',
        defaultMessage: 'I would like to search for repair facility with different search criteria'
    },
    differentSearchCriteriaFacilityName: {
        id: 'anonymous.fnol.repair.preferredVendor.Repair facility Name',
        defaultMessage: 'Name of the repair facility'
    },
    differentSearchCriteriaFacilityZipCode: {
        id: 'anonymous.fnol.repair.preferredVendor.Zip Code',
        defaultMessage: 'Zip code'
    },
    differentSearchCriteriaFacilityMake: {
        id: 'anonymous.fnol.repair.preferredVendor.Make',
        defaultMessage: 'Make'
    },
    differentSearchCriteriaFacilityButton: {
        id: 'anonymous.fnol.repair.preferredVendor.Search repairers',
        defaultMessage: 'Search a repair shop'
    },
    differentSearchCriteriaVendorsNotFoundTitle: {
        id: 'anonymous.fnol.repair.preferredVendor.No results',
        defaultMessage: 'No results'
    },
    differentSearchCriteriaVendorsNotFoundSubTitle: {
        id: "anonymous.fnol.repair.preferredVendor.Based on provided criteria we were unable to provide any partnered facilities. Please try to change the data or provide facility details yourself by choosing option 'I'll provide facility details myself'",
        defaultMessage: "Based on provided criteria we were unable to provide any partnered facilities. Please try to change the data or provide facility details yourself by choosing option 'I'll provide facility details myself'"
    },
    differentSearchCriteriaVendorsNotFoundOkButton: {
        id: 'anonymous.fnol.repair.preferredVendor.Ok',
        defaultMessage: 'OK'
    }
});
