import React from 'react';
import PropTypes from 'prop-types';
import createHOC from 'react-create-hoc';
import useErrors from '../hooks/useErrors';

export const withErrors = createHOC('withErrorBoundary', (ComponentToWrap) => {
    return function WithErrors(props) {
        const errorProps = useErrors();

        return <ComponentToWrap {...props} {...errorProps} />;
    };
}, { factory: false });

export default withErrors;

export const errorPropTypes = {
    throwReactError: PropTypes.func.isRequired,
};
