import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { Loader } from '@jutro/components';

import styles from './PleaseWait.module.scss';



function PleaseWait(props) {
    const { value } = props;
    const targetClass = value ? cx(styles.loadingDivShow) : cx(styles.loadingDivHide) 

    return (
        <div className={targetClass}>
            <Loader />
        </div>
    );
}

export default PleaseWait;
