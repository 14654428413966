import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolContactDetailsTitle: {
        id: 'anonymous.fnol.contactDetails.Contact Details',
        defaultMessage: 'Your contact details'
    },
    fnolContactDetailsBrokerTitle: {
        id: 'anonymous.fnol.contactDetails.Agent Contact Details',
        defaultMessage: 'Agent contact details'
    },
    fnolContactDetailsChangeContactDetails: {
        id: 'anonymous.fnol.contactDetails.Would you like to change your Contact Details, that are stored on your policy contract?',
        defaultMessage: 'Would you like to change your contact details, that are stored on your policy contract?'
    },
    fnolContactDetailsFirstName: {
        id: 'anonymous.fnol.contactDetails.First Name',
        defaultMessage: 'First name'
    },
    fnolContactDetailsLastName: {
        id: 'anonymous.fnol.contactDetails.Last Name',
        defaultMessage: 'Last name'
    },
    fnolContactDetailsBirthDate: {
        id: 'anonymous.fnol.contactDetails.Date of birth',
        defaultMessage: 'Date of birth'
    },
    fnolContactDetailsIsInjured: {
        id: 'anonymous.fnol.contactDetails.Are you injured',
        defaultMessage: 'Are you injured'
    },
    fnolContactDetailsIsInjuredInvolvement: {
        id: 'anonymous.fnol.contactDetails.Were you involved as a passenger or person outside the vehicle?',
        defaultMessage: 'Were you involved as a passenger or person outside the vehicle?'
    },
    fnolContactDetailsIsInjuredInvolvementPassenger: {
        id: 'anonymous.fnol.contactDetails.injuryInvolvement.Passenger',
        defaultMessage: 'Passenger'
    },
    fnolContactDetailsIsInjuredInvolvementPersonOutsideVehicle: {
        id: 'anonymous.fnol.contactDetails.injuryInvolvement.Person outside the vehicle',
        defaultMessage: 'A person outside the vehicle'
    },
    fnolContactDetailsEmailAddress: {
        id: 'anonymous.fnol.contactDetails.Email address',
        defaultMessage: 'Email address'
    },
    fnolContactDetailsPhoneNumber: {
        id: 'anonymous.fnol.contactDetails.Phone number',
        defaultMessage: 'Phone number'
    },
    fnolContactDetailsGenders: {
        id: 'anonymous.fnol.contactDetails.Gender',
        defaultMessage: 'Gender'
    },
    fnolContactDetailsLanguageType: {
        id: 'anonymous.fnol.contactDetails.Language',
        defaultMessage: 'Language'
    },
    fnolContactDetailsRelationToPolicyOwner: {
        id: 'anonymous.fnol.contactDetails.What is your relation to Policy Owner?',
        defaultMessage: 'What is your relation to the Policy Holder?'
    },
    fnolCotanctDetailsGenderMale: {
        id: 'anonymous.fnol.wizard.pages.contactDetails.gender.Male',
        defaultMessage: 'Male'
    },
    fnolCotanctDetailsGenderFemale: {
        id: 'anonymous.fnol.wizard.pages.contactDetails.gender.Female',
        defaultMessage: 'Female'
    },
    fnolCotanctDetailsGenderOther: {
        id: 'anonymous.fnol.wizard.pages.contactDetails.gender.Other',
        defaultMessage: 'Other'
    }
});
