import { defineMessages } from 'react-intl';

export default defineMessages({
    authenticatedFlowInformationalPopupTitle: {
        id: "authenticated.initial.popup.We're sorry to hear that you have experienced an unpleasant occurance.",
        defaultMessage: "We're sorry to hear that you have experienced an unpleasant occurance."
    },
    authenticatedFlowInformationalPopupBody: {
        id: 'authenticated.initial.popup.We want to make the claims process as easy as possible for you, so have any relevant documents such as an estimate, photos, or videos ready to upload. This will speed up the processing of your claim and help us assist you better.',
        defaultMessage: 'We want to make the claims process as easy as possible for you, so have any relevant documents such as an estimate, photos, or videos ready to upload. This will speed up the processing of your claim and help us assist you better.'
    },
    authenticatedFlowInformationalPopupBodySaveAndR: {
        id: 'authenticated.initial.popup.If you would be interrupted in your online declaration (receiving an error message, closing the browser on purpose or encountering an timeout, …) be aware that your input is securely saved and that you can pick it up right where you left it within the next 2 days.',
        defaultMessage: 'If you would be interrupted in your online declaration (receiving an error message, closing the browser on purpose or encountering an timeout, …) be aware that your input is securely saved and that you can pick it up right where you left it within the next 2 days.'
    },
    authenticatedFlowInformationalPopupOkButton: {
        id: 'authenticated.initial.popup.button.Ok',
        defaultMessage: 'Ok'
    }
});
