/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useEffect, useCallback, useContext } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import ClientContext from '../../../contexts/ClientContext/ClientContext';
import BankAccountNumberComponent from '../../BankAccountNumberComponent/BankAccountNumberComponent';
import metadata from './WizardFNOLEBikeRepair.metadata.json5';
import styles from './WizardFNOLEBikeRepair.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from './WizardFNOLEBikeRepair.messages';
import { isNotEmptyOrZero } from '../../../utils/CurrencyAmountUtils';

function WizardFNOLEBikeRepair(props) {
    const {
        id,
        value: repairDTO,
        onValidate,
        writeValue,
        path
    } = props;

    const {
        onValidate: onComponentValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('WizardFNOLEBikeRepair');

    const clientContext = useContext(ClientContext);
    const isBrokerContext = clientContext.isBrokerContext();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const componentValidation = useCallback(() => {
        const repairDTOValue = repairDTO.value;

        const bikeEstimateProvided = isNotEmptyOrZero(repairDTOValue.estimatedRepairCost);
        const otherEstimateProvided = isNotEmptyOrZero(repairDTOValue.estimatedOtherRepairCosts);

        return bikeEstimateProvided || otherEstimateProvided;
    }, [repairDTO]);

    useEffect(() => {
        registerComponentValidation(componentValidation);
    }, [registerComponentValidation, componentValidation]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;
        writeValue(value, fullPath);
    }, [writeValue, path]);

    const overrideProps = {
        ebikeEstimationBankAccountNumberComponent: {
            writeValue: handleValueChange,
            isBrokerContext: true,
            onValidate: onComponentValidate,
            visible: isBrokerContext && (isNotEmptyOrZero(repairDTO.value.estimatedRepairCost) || isNotEmptyOrZero(repairDTO.value.estimatedOtherRepairCosts)),
            accountNumberRequired: repairDTO.value.settledViaPVProcedure === true
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            BankAccountNumberComponent
        },
        resolveCallbackMap: {
            onValidate: onComponentValidate
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={repairDTO}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onComponentValidate}
                onValueChange={handleValueChange}
            />
        </div>
    );
}

export default WizardFNOLEBikeRepair;
